import React from "react"
import "./style.scss"

interface Props {
    info: string
    checked: Boolean
    onClick: () => void
    children?: React.ReactNode
    disabled?: boolean
}
export default ({checked, info, children, disabled, ...props}: Props) => {
    const isChecked = checked && !disabled ? "checked" : "not"
    return (
        <div className="infoCheckSelectionContainer">
            <div className={`selectContainer ${isChecked}`} {...props}>
                <div className="selectContent">
                    <span>{info}</span>
                    <span />
                </div>
            </div>
            {checked && (
                <div className={`childrenContainer ${isChecked}`}>{children}</div>
            )}
        </div>
    )
}
