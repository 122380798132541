import "./style.scss";
import { ISDCLine } from "@samedaycustom/types/order/@types/line";
import React from "react";

interface IProps {
  lineItem: ISDCLine;
  showArtFunc: () => void;
}

export default ({ lineItem, showArtFunc }: IProps) => {
  return (
    <div className="lineItemCard__container">
      <div className="lineItemCard__topContent">
        <div className="lineItemCard__imageContainer">
          <img
            src={`${lineItem?.views?.find(
              lineItem =>
                lineItem?.area_name?.toLowerCase()?.includes("front") ||
                lineItem?.view_name?.toLowerCase() === "front"
            )?.thumbnail || lineItem?.views[0]?.thumbnail}`}
            alt=""
            className="lineItemCard__img"
          />
        </div>
        <div className="lineItemCard__mainDetailsGroup">
          <span className="boldPrice"> {lineItem?.deco_line_id || "-"} </span>
          <p> {lineItem?.product?.name || "-"} </p>
          {(lineItem?.product?.color && (
            <div>
              <span
                style={{
                  display: "block",
                  margin: "10px 0"
                }}
              >
                {" "}
                {lineItem?.product?.color?.name}{" "}
              </span>
            </div>
          )) ||
            "N/A"}
          <span
            style={{
              color: "#007bff",
              fontSize: "14px",
              fontWeight: 500,
              marginTop: "20px",
              display: "inline-block",
              cursor: "pointer"
            }}
            onClick={showArtFunc}
          >
            {" "}
            View artwork{" "}
          </span>
        </div>
      </div>
      <div className="lineItemCard__btmContent">
        <div className="lineItemCard__detailsGroup">
          <h2> Size/Quantity</h2>
          <p> {lineItem.qty} </p>
          <p>
            {" "}
            {lineItem?.items &&
              lineItem.items.map(
                lineItemement =>
                  `${lineItemement?.size}:${lineItemement?.qty_total}${(lineItem
                    ?.items.length > 1 &&
                    ", ") ||
                    ""}`
              )}
          </p>
        </div>
        {/* <div className="lineItemCard__detailsGroup">
          <h2> Total Price </h2>
          <p> ${lineItem.price} </p>
        </div> */}
      </div>
    </div>
  );
};
