import {
  CUSTOMERS_ACTION_TYPES,
  FETCHING_CUSTOMER,
  FETCH_CUSTOMER,
  FETCH_CUSTOMERS,
} from "vendor/providers/actions/customers/type";
import { VENDOR_CUSTOMER_STATE_TYPE, CUSTOMER_STATE } from "./initialState";

export default (
  state = CUSTOMER_STATE,
  action: CUSTOMERS_ACTION_TYPES
): VENDOR_CUSTOMER_STATE_TYPE => {
  switch (action.type) {
    case FETCH_CUSTOMERS:
      return {
        ...state,
        customers: {
          ...state.customers,
          data: action.payload.customers,
          metadata: {
            ...state.customers.metadata,
            all: action.payload.customersCount,
          },
        },
      };
    case FETCH_CUSTOMER:
      return {
        ...state,
        customer: {
          ...state.customer,
          data: action.payload.customer,
        },
      };
    case FETCHING_CUSTOMER:
      return {
        ...state,
        customer: {
          ...state.customer,
          loading: action.payload.loading,
        },
      };
    default:
      return state;
  }
};
