import "./style.scss";

import { Box, Text } from "../_components";
import { ReactComponent as ArrowDown } from "./assets/arrow-down.svg";
import React from "react";

export { default as LocationDropDown } from "./Location-Dropdown";
export { default as NavDropDown } from "./NavDropdown";
export { default as AlternativeDropDowns } from "./AlternativeDropDowns";
export { default as DateRangeDropdown } from "./DateGroupDropdown";
export { default as DateGroupCalendar } from "./DateGroupDropdown/DateGroupCalendar";
export * from "./DateGroupDropdown";

export type Position =
  | "top left"
  | "top center"
  | "top right"
  | "right top"
  | "right center"
  | "right bottom"
  | "bottom left"
  | "bottom center"
  | "bottom right"
  | "left top"
  | "left center"
  | "left bottom"
  | "center center";

export type IDropItems = Array<{ label; value; onClick?: (e: any) => void }>;
export interface IDropDownProps {
  onClick: (val) => void;
  listItems: IDropItems;
  value: string;
  loading: boolean;
  placeholder: string;
  className?: string;
  // label: string
  position: Position;
}

export interface DropDown {
  text: string;
  options: Array<{
    text: string;
    onClick?: () => void;
  }>;
}

export const DropDown = ({ text, options }: DropDown) => {
  const [showDropDown, setshowDropDown] = React.useState(false);
  const DropDownListItem = ({ text, onClick }) => (
    <Text
      className="__listitem"
      paddingBottom="5px"
      width="100%"
      onClick={onClick}
      cursor="pointer"
    >
      {text}
    </Text>
  );
  return (
    Array.isArray(options) && (
      <Box
        position="relative"
        fontFamily="inherit"
        className="dropdown__container"
      >
        {showDropDown && (
          <Box
            position="fixed"
            top="0"
            bottom="0"
            left="0"
            right="0"
            height="100vh"
            onClick={(e: any) => setshowDropDown(false)}
          />
        )}
        <Box
          d="flex"
          flexDirection="row"
          alignItems="center"
          fontFamily="inherit"
          cursor="pointer"
          className="__title"
          onClick={() => setshowDropDown(!showDropDown)}
        >
          <Text fontFamily="inherit" style={{ paddingRight: "0.5rem" }}>
            {text}
          </Text>
          <ArrowDown />
        </Box>

        <Box position="relative" onMouseLeave={() => setshowDropDown(false)}>
          <Box
            position="absolute"
            minWidth="200px"
            display={(showDropDown && "block") || "none"}
            width="fit-content"
            background="#fff"
            boxShadow="0px 6px 18px rgba(98, 125, 152, 0.24)"
            borderRadius="4px"
            padding="10px"
            paddingBottom="0px"
            marginTop="5px"
            zIndex={9999}
            className="__list"
          >
            {Array.isArray(options) &&
              options.map(el => (
                <DropDownListItem text={el.text} onClick={el.onClick} />
              ))}
          </Box>
        </Box>
      </Box>
    )
  );
};
