import React from "react";
import { Box, Text } from "../../_components";
import { ReactComponent as Folder } from "../assets/file-text.svg";
import { DefaultCheck } from "@samedaycustom/core-ui";

export interface SelectableFolderProps {
  title: string;
  isSelected?: boolean;
  setSelected?: () => void;
}
export default ({
  title,
  isSelected,
  setSelected,
  ...props
}: SelectableFolderProps) => {
  return (
    <Box
      d="flex"
      flexDirection="row"
      alignItems="center"
      border={`1px solid ${!isSelected ? "#627D98" : "#006EE5"}`}
      width="fit-content"
      padding="10px"
      height="32px"
      borderRadius="4px"
      boxSizing="border-box"
      background={!isSelected ? "transparent" : "#E5F1FF"}
      cursor="pointer"
      {...props}
    >
      <Folder
        style={{ marginRight: 10 }}
        fill={isSelected ? "#006EE5" : "#486581"}
      />
      <Text color={!isSelected ? "#102A43" : "#006EE5"}>{title}</Text>
      <DefaultCheck
        checked={isSelected}
        label=""
        height={13}
        width={13}
        setChecked={setSelected}
        style={{
          marginLeft: 10,
          borderRadius: "50%",
          backgroundColor: !isSelected ? "transparent" : "#007bff"
        }}
      />
    </Box>
  );
};
