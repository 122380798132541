import "./style.scss";

import { useToast } from "@samedaycustom/hooks";
import React, { useState } from "react";
import fetch from "cross-fetch";
import SavedLayoutsDesktop from "./versions/Desktop";

export interface ISavedLayoutCard {
  imageURLs: string[];
  deleteUrl: string;
  onBuyNow?: () => void;
  onEdit?: () => void;
  onSetupGroup?: () => void;
  deleteSavedLayouts?: (url: string) => void;
  deleting?: boolean;
  fetchLayouts?: (isSilent?: boolean) => Promise<any>;
  color?: string;
  designName: string;
  designSubName?: string;
  status?: string;
  primaryButtonStyle?: {
    backgroundColor?: string;
    color?: string;
  };
  secondaryButtonStyle?: {
    backgroundColor?: string;
    color?: string;
  };
}
export default ({ fetchLayouts, primaryButtonStyle, secondaryButtonStyle, ...props }: ISavedLayoutCard) => {
  const toast = useToast();
  const [deleting, setDeleting] = useState<boolean>(false);

  /**
   * delete saved layouts
   * @param url
   */
  const deleteSavedLayouts = async (url: string) => {
    if (url) {
      setDeleting(true);
      await fetch(url).then(r => r.text());
      toast({
        title: "Saved layouts",
        description: "Saved layout deleted",
        position: "bottom-left",
        status: "success",
        duration: 3000,
        isClosable: true
      });
      await fetchLayouts(true);
      setDeleting(false);
    }
  };
  return (
    <SavedLayoutsDesktop
      {...props}
      deleteSavedLayouts={deleteSavedLayouts}
      deleting={deleting}
      primaryButtonStyle={primaryButtonStyle}
      secondaryButtonStyle={secondaryButtonStyle}
    />
  );
};
