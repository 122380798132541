import React from "react";
import "./style.scss";
import { NavLink } from "react-router-dom";
import { ReactComponent as Lock } from "@samedaycustom/assets/images/customer-padlock.svg";
import { useHistory } from "react-router-dom";
import { Box } from "../../_components";
interface Iprops {
  image: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  url: string;
  title: string;
  locked: boolean;
  isWhiteLabel?: boolean;
  backgroundColor?: string;
  textColor?: string;
  onClick?: () => void;
}

const SidebarItem = ({
  image: Image,
  url,
  title,
  locked,
  onClick,
  isWhiteLabel,
  backgroundColor,
  textColor,
}: Iprops) => {
  const history = useHistory();
  return (
    <NavLink
      // className={locked ? "disabled" : ""}
      onClick={onClick}
      to={url}
    >
      <Box
        className={`SidebarItem ${
          isSidebarActive(history, url) ? getActiveSidebarClass(isWhiteLabel) : ""
        }`}
        style={{
          ...(isWhiteLabel &&
            isSidebarActive(history, url) && {
              backgroundColor: backgroundColor,
              color: textColor,
            }),
        }}
        {...(isWhiteLabel && {
          _hover: {
            backgroundColor: backgroundColor,
            color: textColor,
          },
        })}
      >
        <div className="SidebarItem__main">
          <Box
            className="__icon"
            {...(isWhiteLabel && {
              _hover: {
                backgroundColor: backgroundColor,
                textColor: textColor,
              },
            })}
          >
            <Image />
          </Box>
          <span
            style={{
              ...(isWhiteLabel &&
                isSidebarActive(history, url) && {
                  color: textColor,
                }),
            }}
          >
            {title}
          </span>
        </div>
        {locked ? <Lock /> : null}
      </Box>
    </NavLink>
  );
};

const getActiveSidebarClass = (isWhiteLabel: boolean) => {
  isWhiteLabel ? "" : "ActiveSideBar";
};

const isSidebarActive = (history: any, url: string) =>
  history.location?.pathname?.toLowerCase().includes(url?.toLowerCase());

export default SidebarItem;
