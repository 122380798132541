/* eslint-disable no-case-declarations */
import {
  OPERATION_ORDERS_LIST,
  OPERATION_ORDERS_STATE,
  OPERATION_STATE,
  OrdersActionType,
} from "@samedaycustom/types/app/Operations";
import { ISDCLine } from "@samedaycustom/types/order/@types/line";
import _, { orderBy, uniqBy } from "lodash";
import {
  DELETE_ORDER_NOTE_BY_ID,
  FAILED_OPERATIONS,
  FETCH_DELIVERY_RECORD,
  FETCH_OPERATION_ORDERS,
  FETCH_OPERATION_PRODUCTION,
  FETCH_OPERATION_SHIPPING,
  FETCH_ORDER_LINEITEMS,
  UPDATEL_LINE_ITEM,
  FETCH_ORDER_NOTES,
  FETCH_SINGLE_ORDER,
  FETCH_TIMELINE,
  FETCHING_DELIVERY_RECORD,
  FETCHING_OPERATIONS,
  ORDER_MESSAGE_READ,
  REMOVE_OPERATION_ORDER,
  REMOVE_ORDER_ARTWORK_IMAGE,
  RESET_FAILED_ORDERS_OPERATION,
  RESET_NOTE_COUNT,
  RESET_NOTES,
  RESET_ORDER,
  RESET_ORDER_LINEITEMS,
  RESET_ORDER_NOTES,
  RESET_RECORD_DELIVERY_RESULT,
  SEARCH_OPERATION_ORDERS,
  SET_FILTER_PARAMS,
  SET_IS_FETCHING_TIMELINE,
  SET_ISFETCHING_LINEITEM,
  SET_ISFILTERING,
  SET_ISUPDATING_STATUS,
  SET_ITEM_STATUS,
  SET_ITEM_STATUS_FAILED,
  SET_ITEM_STATUS_SUCCESS,
  SET_ORDERS_TAB_LOCATION,
  SET_ORDERS_TARGET_LOCATION,
  SET_PRODUCTION_TARGET_LOCATION,
  SET_SHIPPING_TARGET_LOCATION,
  SHOW_ARTWORK_BAR,
  SHOW_MESSAGE_BAR,
  SHOW_NOTE_BAR,
  UPDATE_CAR_DELIVERY_INFORMATION,
  UPDATE_DELIVERY_RECORD_STATUS,
  UPDATE_NEW_NOTE_COUNT,
  UPDATE_ORDER_ARTWORK_IMAGE,
  UPDATE_ORDER_MESSAGE_VALUE,
  UPDATE_ORDER_NOTE_BY_ID,
  UPDATE_ORDER_NOTE_VALUE,
  UPDATE_ORDER_NOTES,
  UPDATE_ORDER_TIMER_INTERVAL,
  UPDATE_ORDERS_LINEITEMS_DELIVERED,
  UPDATE_PICKUP_NOTIFICATION,
  UPDATE_RECORD_DELIVERY_RESULT,
  UPDATE_SHIPPING_INFORMATION,
  UPDATE_SHIPPING_RATES,
  UPDATE_TOP_PANEL_SELECTION,
  ACTIVE_ORDER_NEW_MESSAGE_TAG,
  CAR_DELIVERY_STATUS_UPDATE,
  UPDATE_SINGLE_ORDER,
} from "vendor/providers/actions/operations/types";

import { operationOrdersState } from "../initialstate";

export default (
  state = operationOrdersState,
  action: OrdersActionType
): OPERATION_STATE<OPERATION_ORDERS_STATE> => {
  switch (action?.type) {
    case FETCH_OPERATION_ORDERS:
      let actionData: OPERATION_ORDERS_STATE[] | OPERATION_ORDERS_LIST[] = action?.data;
      const { orders } = action || {};

      if (orders) {
        actionData = orders;
      } else if (action.isNew) {
        const hasOrderInState = state.orders.data.find((o) => action.data[0]?.id === o.id);
        const orderInState = {
          ...hasOrderInState,
          ...action.data[0],
        };
        actionData = [orderInState, ...state.orders.data];
      } else {
        actionData = orderBy(actionData, "dateOrdered", "desc");
      }

      actionData = uniqBy(actionData, "id");

      const data = {
        [action.stageType]: {
          ...state[action.stageType],
          done: true,
          data: actionData,
          metadata: action.metadata ?? state.orders.metadata,
        },
      };
      return {
        ...state,
        ...data,
      };
    case ORDER_MESSAGE_READ:
      const unread = action.unread;
      const orderId = action.order_id;
      const newData = [...state.orders.data];
      const hasOrderInState = state.orders.data.find((o) => orderId === o.id);
      const hasOrderInStateIndex = state.orders.data.findIndex((o) => orderId === o.id);
      const orderInState = {
        ...hasOrderInState,
        unreadMessages: unread,
      };

      if (hasOrderInStateIndex !== -1) newData[hasOrderInStateIndex] = orderInState;

      const newUnreadOrdersData = {
        [action.stageType]: {
          ...state[action.stageType],
          done: true,
          data: newData,
          metadata: action.metadata ?? state.orders.metadata,
        },
      };
      return {
        ...state,
        ...newUnreadOrdersData,
        order: {
          ...state.order,
          unreadStore: Number(state?.order?.unreadStore ?? 0),
        },
      };

    case REMOVE_OPERATION_ORDER:
      /**
       * remove order by action order_id from all stage type of order table
       */
      const removed_data = ["orders", "production", "delivery"].reduce((_a, c) => {
        const stage = state[c];
        const old_data = Array.isArray(stage?.data) ? stage?.data : [];
        const new_data =
          Array.isArray(old_data) && old_data?.length
            ? old_data.filter((o) => o.id !== action.order_id)
            : old_data;

        return {
          ..._a,
          [c]: {
            ...stage,
            data: new_data,
          },
        };
      }, {});

      return {
        ...state,
        ...removed_data,
        type: state.order?.id === action.order_id ? action?.type : null,
      };

    case FETCH_OPERATION_PRODUCTION:
      const productionData = {
        [action.stageType]: {
          ...state[action.stageType],
          done: true,
          data: action?.data,
          metadata: action.metadata ?? state.orders.metadata,
        },
      };
      return {
        ...state,
        ...productionData,
      };

    case FETCH_OPERATION_SHIPPING:
      const deliveryData = {
        [action.stageType]: {
          ...state[action.stageType],
          done: true,
          data: action?.data,
          metadata: action.metadata ?? state.orders.metadata,
        },
      };
      return {
        ...state,
        ...deliveryData,
      };

    case FETCHING_OPERATIONS:
      const loadingData = {
        [action.stageType]: {
          ...state[action.stageType],
          loading: action?.loading,
        },
      };
      return {
        ...state,
        ...loadingData,
      };

    case FAILED_OPERATIONS:
      const failedData = {
        [action.stageType]: {
          ...state[action.stageType],
          error: action?.error,
          errorMessage: action?.errorMessage,
        },
      };
      return {
        ...state,
        ...failedData,
      };

    case RESET_FAILED_ORDERS_OPERATION:
      const resetFailedData = {
        [action.stageType]: {
          ...state[action.stageType],
          error: false,
          errorMessage: null,
        },
      };
      return {
        ...state,
        ...resetFailedData,
      };

    case FETCH_SINGLE_ORDER:
      const order: OPERATION_ORDERS_STATE = {
        ...action.order,
        customer: {
          ...action.order?.customer,
          firstName: action.order?.customer?.firstName.toLowerCase(),
          lastName: action.order?.customer?.lastName.toLowerCase(),
          address1: action.order?.customer?.address1?.toLowerCase(),
        },
      };

      return { ...state, order, type: null };
    case UPDATE_SINGLE_ORDER:
      const updateOrder: OPERATION_ORDERS_STATE = {
        ...state.order,
        ...action.data,
      };
      return { ...state, order: updateOrder, type: null };
    case RESET_ORDER:
      return { ...state, order: operationOrdersState.order, type: null };

    case FETCH_ORDER_NOTES:
      return { ...state, notes: action.notes };

    case RESET_ORDER_NOTES:
      return { ...state, notes: [] };

    case RESET_ORDER_LINEITEMS:
      return { ...state, lineItems: [] };

    case FETCH_ORDER_LINEITEMS:
      let i = 0;
      let lineitems = orderBy(
        action.lineItems,
        function(e) {
          const sortKey = e.items[i]?.size;
          i++;
          return sortKey;
        },
        ["desc"]
      ) as ISDCLine[];

      lineitems = lineitems.map((el) => {
        const items = orderBy(
          el?.items,
          function(e) {
            return e?.size;
          },
          ["asc"]
        );

        return { ...el, items };
      });

      return { ...state, lineItems: lineitems };

    case UPDATEL_LINE_ITEM:
      const oldLineItems = [...state.lineItems];
      const lineIndex = oldLineItems.findIndex((o) => o.id === action?.line?.id);

      if (lineIndex !== -1) oldLineItems[lineIndex] = action.line;

      return { ...state, lineItems: oldLineItems };

    case FETCH_DELIVERY_RECORD:
      return {
        ...state,
        deliveryRecord: orderBy(action.deliveryRecord, "dateRecorded", "desc"),
      };

    case UPDATE_DELIVERY_RECORD_STATUS:
      const records = [...state.deliveryRecord];
      const record = Object.assign({}, state.recordResult);
      const deliveryRecord = records.map((o) => {
        if (o.id === action.recordResult.recordID) o.trackingStatus = action.recordResult.status;
        return o;
      });

      const recordResult =
        typeof record?.id !== "undefined" && record?.id === action.recordResult?.recordID
          ? {
              ...record,
              trackingStatus: action.recordResult.status,
              postmateCourier: action.recordResult?.postmateCourier || record?.postmateCourier,
            }
          : record;

      return {
        ...state,
        deliveryRecord,
        recordResult,
      };

    case CAR_DELIVERY_STATUS_UPDATE:
      return {
        ...state,
        recordResult: {
          ...(state.recordResult ?? {}),
          ...(action.recordResult ?? {}),
        },
      };

    case FETCHING_DELIVERY_RECORD:
      return { ...state, isFetchingDeliveryRecord: action.isFetchingDeliveryRecord };

    case SET_ISFETCHING_LINEITEM:
      return {
        ...state,
        isFetchingLineItem: action.isFetchingLineItem,
      };

    case SET_IS_FETCHING_TIMELINE:
      return {
        ...state,
        isFetchingTimeline: action.isFetchingTimeline,
      };

    case FETCH_TIMELINE:
      return {
        ...state,
        timelines: action.timelines,
      };

    case SHOW_ARTWORK_BAR:
      return {
        ...state,
        showartworkbar: action.showartworkbar,
      };

    case SHOW_NOTE_BAR:
      return {
        ...state,
        shownotebar: action.shownotebar,
      };
    case SHOW_MESSAGE_BAR:
      return {
        ...state,
        showmessagebar: action.showmessagebar,
        order: {
          ...state.order,
          unreadStore: 0,
        },
      };
    case SET_ORDERS_TARGET_LOCATION:
      return {
        ...state,
        targetLocation: {
          ...state.targetLocation,
          orders: action.location,
        },
      };

    case SET_SHIPPING_TARGET_LOCATION:
      return {
        ...state,
        targetLocation: {
          ...state.targetLocation,
          shipping: action.location,
        },
      };

    case SET_PRODUCTION_TARGET_LOCATION:
      return {
        ...state,
        targetLocation: {
          ...state.targetLocation,
          production: action.location,
        },
      };

    case UPDATE_ORDERS_LINEITEMS_DELIVERED:
      return { ...state, deliveringItems: action.deliveringItems };

    case UPDATE_SHIPPING_INFORMATION:
      return {
        ...state,
        shippingInfo: {
          ...state.shippingInfo,
          ...action.shippingInfo,
        },
      };

    case UPDATE_CAR_DELIVERY_INFORMATION:
      return { ...state, carDeliveryInfo: action.carDeliveryInfo };

    case UPDATE_ORDER_ARTWORK_IMAGE:
      return {
        ...state,
        artworkImage: {
          ...state.artworkImage,
          ...action.artworkImage,
        },
      };

    case REMOVE_ORDER_ARTWORK_IMAGE:
      const artworkImage = _.omit(state, action.view_id);
      return {
        ...state,
        artworkImage,
      };

    case UPDATE_ORDER_NOTE_VALUE:
      return {
        ...state,
        noteValue: action.noteValue,
      };
    case UPDATE_ORDER_MESSAGE_VALUE:
      return {
        ...state,
        messageValue: action.messageValue,
      };

    case UPDATE_TOP_PANEL_SELECTION:
      return {
        ...state,
        production: {
          ...state.production,
          topPanelSelection: action.topPanelSelection,
        },
      };
    case UPDATE_RECORD_DELIVERY_RESULT: {
      const deliveryRecords = [...state.deliveryRecord];
      const newDeliveryRecord = deliveryRecords.map((o) => {
        if (o.id === action.recordResult?.id) return { ...o, ...action.recordResult };
        return o;
      });

      const recordResult = { ...state.recordResult, ...action.recordResult };
      return {
        ...state,
        deliveryRecord: newDeliveryRecord,
        recordResult,
      };
    }
    case RESET_RECORD_DELIVERY_RESULT: {
      return {
        ...state,
        recordResult: operationOrdersState.recordResult,
      };
    }

    case UPDATE_SHIPPING_RATES:
      const rates = orderBy(action.rates, ["shipping_amount.amount"], ["asc"]);
      return {
        ...state,
        rates,
      };

    case UPDATE_ORDER_NOTES:
      const _notes = _.uniqBy([action.note, ...state.notes], "id");
      return { ...state, notes: _notes };

    case UPDATE_NEW_NOTE_COUNT:
      return { ...state, newNoteCount: ++state.newNoteCount };

    case RESET_NOTE_COUNT:
      return { ...state, newNoteCount: 0 };

    case UPDATE_PICKUP_NOTIFICATION:
      return { ...state, notifyPickup: action.notifyPickup };

    case RESET_NOTES:
      return { ...state, notes: [] };

    case UPDATE_ORDER_NOTE_BY_ID:
      const notes = state.notes.map((o) =>
        o.id === action.note_id ? { ...o, ...action.note, id: action.note_id } : o
      );
      return { ...state, notes };

    case DELETE_ORDER_NOTE_BY_ID:
      const notesAfterDelete = state.notes.filter((o) => o.id !== action.note_id);
      return { ...state, notes: notesAfterDelete };

    case UPDATE_ORDER_TIMER_INTERVAL:
      return {
        ...state,
        dueDateInterval: action.dueDateInterval,
      };

    /**
     *
     *
     * set tab target on list pages
     */
    case SET_ORDERS_TAB_LOCATION:
      const tabs = {
        [action.stageType]: {
          ...state[action.stageType],
          tab_target: action.target ?? state.orders.tab_target,
        },
      };
      return {
        ...state,
        ...tabs,
      };

    case SET_ITEM_STATUS:
      const actionItemsInState = {
        ...state.itemStatus.items[`${action.itemStatus.line_id}`],
      };
      const items = {
        ...state.itemStatus.items,
        [`${action.itemStatus.line_id}`]: {
          ...actionItemsInState,
          ...action.itemStatus,
        },
      };
      const itemStatus = {
        ...state.itemStatus,
        internalStatus: action.itemStatus.static_internal_status,
        items,
      };
      return {
        ...state,
        itemStatus,
      };

    case SET_ITEM_STATUS_FAILED:
      return {
        ...state,
      };

    case SET_ITEM_STATUS_SUCCESS:
      return {
        ...state,
      };

    case SET_ISUPDATING_STATUS:
      return {
        ...state,
        itemStatus: {
          ...state.itemStatus,
          internalStatus: action?.itemStatus?.static_internal_status,
          items: {
            ...state.itemStatus.items,
            [`${action.itemStatus.line_id}`]: {
              ...state.itemStatus.items[`${action.itemStatus.line_id}`],
              loading: action.itemStatusloading,
            },
          },
        },
      };

    case SET_FILTER_PARAMS:
      const listFilterParams = {
        [action.stageType]: {
          ...state[action.stageType],
          filterParams: action.filterParams,
        },
      };
      return {
        ...state,
        ...listFilterParams,
      };

    case SEARCH_OPERATION_ORDERS: {
      const { searchText } = action;

      const listSearchParams = {
        [action.stageType]: {
          ...state[action.stageType],
          searchParam: searchText,
        },
      };
      return {
        ...state,
        ...listSearchParams,
      };
    }

    case SET_ISFILTERING:
      const listFilteringType = {
        [action.stageType]: {
          ...state[action.stageType],
          isFiltering: action.isFiltering,
        },
      };
      return {
        ...state,
        ...listFilteringType,
      };

    case ACTIVE_ORDER_NEW_MESSAGE_TAG:
      // If the order id is opened or active, update the unreadMessage
      // Find the message in the list and update it
      let unreadStore = state.order?.unreadStore;
      if (state.order && state.order.id === action.order_id) {
        unreadStore = (state.order?.unreadStore ?? 0) + 1;
      }

      // // Update order list
      // let orderList = [...state.orders.data]
      // const orderIdxInList = orderList.findIndex(
      //     _odr => _odr.id === action.order_id
      // )
      // if (orderIdxInList >= 0) {
      //     orderList[orderIdxInList].unreadStore = newOrderObj?.unreadStore ?? 0
      // }
      return {
        ...state,
        order: { ...state.order, unreadStore },
        // orders: {
        //     ...state.orders,
        //     data: [...orderList],
        // },
      };

    default:
      return state;
  }
};
