import { DELIVERY_METHODS_TYPE } from "@samedaycustom/types/app/Settings";

export const deliveryMethodState: DELIVERY_METHODS_TYPE = {
  pickup: {
    available: false,
  },
  shipping: {
    fedex: false,
    ups: false,
    usps: false,
  },
  car: {
    in_house: false,
    third_party: false,
  },
};
