import { camelCase } from "lodash";
import * as roleActions from "vendor/providers/actions/stores/roles/actionTypes";
import { STORE_ROLES_FLOW_INITIALSTATE } from "../../../initialState";

const rolesReducer = (state = STORE_ROLES_FLOW_INITIALSTATE, action) => {
  switch (action.type) {
    case roleActions.START_ROLE_ACTION:
      return {
        ...state,
        type: action.payload.actionType,
        targetRole: {},
        loading: true,
        done: false,
        error: false,
        errorMessage: "",
        fetch: false,
      };

    case roleActions.GET_ROLES:
      return {
        ...state,
        type: action.type,
        roles: [...action.payload],
        done: true,
        error: false,
        loading: false,
      };

    case roleActions.GET_TARGET_ROLE: {
      const actionPermissions = action?.payload?.details?.permissions || {};

      const permissions =
        Object.keys(actionPermissions).length > 0
          ? Object.entries(actionPermissions).reduce(
              (a, permission) => ({ ...a, [camelCase(permission[0])]: permission[1] }),
              {}
            )
          : {};

      return {
        ...state,
        type: action.type,
        targetRole: { ...action.payload.details, permissions },
        done: true,
        error: false,
        loading: false,
      };
    }

    case roleActions.CREATE_ROLE:
      return {
        ...state,
        type: action.type,
        roles: [...state.roles, action.payload],
        done: true,
        error: false,
        loading: false,
      };

    case roleActions.EDIT_ROLE:
      return {
        ...state,
        type: action.type,
        roles: state.roles.map((role: any) => {
          if (role.id === action.payload.id) {
            return {
              id: action.payload.id,
              ...action.payload.updatedDetails,
            };
          } else {
            return role;
          }
        }),
        targetRole: {},
        done: true,
        error: false,
        loading: false,
      };

    case roleActions.DELETE_ROLE: {
      const deletedRoles = action.payload.split(",");
      return {
        ...state,
        type: action.type,
        roles: state.roles.filter((role: any) => {
          if (!deletedRoles.includes(role.id)) {
            return {
              ...role,
            };
          }
        }),
        done: true,
        error: false,
        loading: false,
      };
    }

    case roleActions.FAILED_ROLE_ACTION:
      return {
        ...state,
        type: action.payload.actionType,
        loading: false,
        done: false,
        error: true,
        errorMessage: action.payload.message,
      };

    case roleActions.ROLES_STATE_RESET:
      return {
        ...state,
        type: "",
        loading: false,
        done: false,
        error: false,
        errorMessage: "",
      };

    case roleActions.SET_ROLES_FETCH:
      return {
        ...state,
        fetch: true,
      };
    default:
      return state;
  }
};

export default rolesReducer;
