import {
  AUTH_STATE_TYPE,
  SIGNIN_DATA,
  SIGNUP_DATA,
  SIGNUP_FLOW_STATE_TYPE,
} from "@samedaycustom/types/app";
import request from "axios";
import localforage from "localforage";
import { ThunkAction } from "redux-thunk";
import api from "vendor/api/Api.constants";
import { pusher_beam } from "vendor/modules/pusher";
import {
  DELETE_AUTH_TOKEN_ACTION,
  UPDATE_AUTH_TOKEN_ACTION,
  UPDATE_SIGNUP_STATE_ACTION,
} from "vendor/providers/actions/types";
import { persistor } from "vendor/providers/store";
import { showAutoLogoutPopup } from "../App";

type Actions = { type: string };
type ThunkResult<R> = ThunkAction<R, SIGNUP_FLOW_STATE_TYPE, undefined, Actions>;

export const disableNextBtn = (condition: boolean) => ({
  type: UPDATE_SIGNUP_STATE_ACTION,
  disableNextBtn: condition,
});

export const disableBackBtn = (condition: boolean) => ({
  type: UPDATE_SIGNUP_STATE_ACTION,
  disableBackBtn: condition,
});

export const updateSignupFlowData = (
  payload: SIGNUP_FLOW_STATE_TYPE
): ThunkResult<Promise<SIGNUP_FLOW_STATE_TYPE>> => {
  return async (dispatch, getState: any) => {
    dispatch({ type: UPDATE_SIGNUP_STATE_ACTION, ...payload });

    return Promise.resolve(getState());
  };
};

// complete signup flow authentication with API
export const completeSignupFlow = (payload: SIGNUP_DATA): ThunkResult<Promise<SIGNUP_DATA>> => {
  return () => {
    return request.post(
      api.CREATE_VENDORS,
      { ...payload },
      {
        headers: {
          "Content-Type": api.CONTENT_TYPE,
        },
      }
    );
  };
};

export const forgotPassword = (email: string): ThunkResult<Promise<SIGNUP_DATA>> => {
  return () => {
    return request.get(`${api.FORGOT_PASSWORD}/?email=${email}`);
  };
};

export const resetPassword = (
  password: string,
  resetToken: string
): ThunkResult<Promise<AUTH_STATE_TYPE>> => {
  return () => {
    return request.post(
      `${api.FORGOT_PASSWORD}/${resetToken}`,
      { password },
      {
        headers: {
          "Content-Type": api.CONTENT_TYPE,
        },
      }
    );
  };
};

export const verifyEmail = (token: string): Promise<any> => {
  return request.get(`${api.EMAIL_VERIFY}/${token}`, {
    headers: {
      "Content-Type": api.CONTENT_TYPE,
    },
  });
};

export const reverifyEmail = (email: string): Promise<any> => {
  return request.post(
    `${api.EMAIL_REVERIFY}/`,
    { email },
    {
      headers: {
        "Content-Type": api.CONTENT_TYPE,
      },
    }
  );
};

// sign in authentication with api
export const signin = (
  payload: SIGNIN_DATA
): ThunkResult<Promise<{ type: string; data?: AUTH_STATE_TYPE }>> => {
  return (dispatch) => {
    return request
      .post(
        `${api.LOGIN_VENDOR}`,
        { ...payload },
        {
          headers: {
            "Content-Type": api.CONTENT_TYPE,
          },
        }
      )
      .then(async (res) => {
        const resData = res.data.data || res.data;
        if (resData?.error) return Promise.reject(resData);
        const isMultiVendor = res.data.data.isMultivendor;
        // dispatch(getStatus(res.data.data?.setup))

        return Promise.resolve(
          dispatch({
            type: UPDATE_AUTH_TOKEN_ACTION,
            data: { isLoggedin: true, ...resData, isMultiVendor },
          })
        );
      });
  };
};

export const logout = () => async (dispatch) => {
  dispatch({
    type: DELETE_AUTH_TOKEN_ACTION,
  });
  await localforage.removeItem("TOKEN");
  persistor.flush();
  if (pusher_beam) await pusher_beam.stop();
};

export const logOutAuto = (response: any) => (dispatch: any) => {
  if (response?.data?.code === 420) {
    dispatch(showAutoLogoutPopup());
    dispatch(logout());
  }
};
