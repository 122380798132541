import { createAsyncThunk } from "@reduxjs/toolkit";
import * as apiConst from "vendor/api/Api.constants";
import { api, apiInstance } from "vendor/providers/api";
import qs from "query-string";
import Axios, { Canceler } from "axios";
import { getStartAndEndDateFromPeriod } from "@samedaycustom/helpers/src/dateDropdown";

const CancelToken = apiInstance.CancelToken;
let financ_cancel: Canceler;
let payment_cancel: Canceler;
let financ_count_cancel: Canceler;

export const getVendorPayments = createAsyncThunk(
  "payments/getVendorPayments",
  async (params?: {
    cn_before?: any;
    cn_after?: any;
    location_id?: string;
    period?: string;
    start?: string;
    end?: string;
  }) => {
    // tslint:disable-next-line: no-unused-expression
    payment_cancel && payment_cancel();
    if (params?.period) {
      const { start, end } = await getStartAndEndDateFromPeriod(params?.period);
      params.start = start;
      params.end = end;
      params.period = "";
    }
    const response = await api
      .get(
        `${apiConst.default.PAYMENT}/?${qs.stringify({
          ...params,
          start_date: params?.start,
          end_date: params?.end,
        })}`,
        {
          cancelToken: new CancelToken((c) => {
            payment_cancel = c;
          }),
        }
      )
      .catch((err) => Promise.reject(err.response?.data?.message));
    return response.data;
  }
);

export const recordVendorPayments = createAsyncThunk(
  "payments/recordVendorPayments",
  async (param: {
    vendorId: string;
    comment: string;
    amount: number;
    start_date: string;
    end_date: string;
    location_id?: string;
  }) => {
    const response = await api
      .post(`${apiConst.default.PAYMENT}/${param?.vendorId}`, {
        ...param,
        start_date: new Date(param?.start_date),
        end_date: new Date(param?.end_date),
      })
      .catch((err) => Promise.reject(err.response?.data?.message));
    return response.data?.data;
  }
);

export const uploadPaymentProof = createAsyncThunk(
  "payments/uploadPaymentProof",
  async (param: {
    vendorId: string;
    file: File;

    onProgress?: (progressEvent: any) => void;
  }) => {
    const formData = new FormData();
    formData.append("image", param.file as any);
    const response = await api
      .post(`${apiConst.default.PAYMENT}/${param?.vendorId}/documents`, formData, {
        onUploadProgress: param?.onProgress,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .catch((err) => Promise.reject(err.response?.data?.message));
    return response.data?.data;
  }
);

export const getOrderFinancials = createAsyncThunk(
  "payments/getOrderFinancials",
  async (param?: {
    location_id?: string;
    period?: string;
    start?: string;
    end?: string;
    tod?: string;
    bod?: string;
  }) => {
    // tslint:disable-next-line: no-unused-expression
    financ_cancel && financ_cancel();
    if (param?.period) {
      const { start, end } = await getStartAndEndDateFromPeriod(param?.period);
      param.start = start;
      param.end = end;
      param.period = "";
    }
    const query = qs.stringify({
      ...param,
      start_date: param?.start,
      end_date: param?.end,
    });

    const response = await api
      .get(`${apiConst.default.PAYMENT}/order_financial?${query}`, {
        cancelToken: new CancelToken((c) => {
          financ_cancel = c;
        }),
      })
      .catch((err) => {
        if (err instanceof Axios.Cancel) return Promise.reject({ message: "cancelled" });
        return Promise.reject(err.response?.data?.message);
      });

    return response.data;
  }
);
export const getOrderFinancialsTotal = createAsyncThunk(
  "payments/getOrderFinancialsTotal",
  async (params?: { location_id?: string; period?: string; start?: string; end?: string }) => {
    let startDate = "";
    let endDate = "";
    // If params has start and end date, convert it to iso format
    if (params?.start && params?.end) {
      startDate = new Date(params?.start).toISOString();
      endDate = new Date(params?.end).toISOString();
    } else if (params?.period) {
      // If params has period, get start and end date from period
      const { start, end } = await getStartAndEndDateFromPeriod(params?.period);
      startDate = new Date(start).toISOString();
      endDate = new Date(end).toISOString();
    } else {
      // Use a default period of 7 days
      const { start, end } = await getStartAndEndDateFromPeriod("7days");
      startDate = new Date(start).toISOString();
      endDate = new Date(end).toISOString();
    }
    // tslint:disable-next-line: no-unused-expression
    financ_count_cancel && financ_count_cancel();
    // const query = qs.stringify({ ...param, vendor_id: param?.vendorId });
    const response = await api
      .get(
        `${
          apiConst.default.PAYMENT
        }/order_financial/total?start_date=${startDate}&end_date=${endDate}&location_id=${params?.location_id ||
          ""}`,
        {
          cancelToken: new CancelToken((c) => {
            financ_count_cancel = c;
          }),
        }
      )
      .catch((err) => {
        if (err instanceof Axios.Cancel) return Promise.reject({ message: "cancelled" });
        return Promise.reject(err.response?.data?.message);
      });
    return response.data?.data;
  }
);

/**
 * Download order financials
 */
export const downloadOrderFinancials = createAsyncThunk(
  "payments/downloadOrderFinancials",
  async (params?: { location_id?: string; period?: string; start?: string; end?: string }) => {
    if ((!params?.start || !params?.end) && params?.period) {
      const { start, end } = await getStartAndEndDateFromPeriod(params?.period);
      params.start = new Date(start).toISOString();
      params.end = new Date(end).toISOString();
      params.period = "";
    }
    const query = qs.stringify({
      ...params,
      start_date: params?.start,
      end_date: params?.end,
    });

    const response = await api
      .get(`${apiConst.default.PAYMENT}/order_financial/download?${query}`, {
        responseType: "blob",
      })
      .catch((err) => Promise.reject(err.response?.data?.message));

    return response.data;
  }
);

export const downloadOrderPayout = createAsyncThunk(
  "payments/downloadOrderPayout",
  async (params?: { location_id?: string; period?: string; start?: string; end?: string }) => {
    if ((!params?.start || !params?.end) && params?.period) {
      const { start, end } = await getStartAndEndDateFromPeriod(params?.period);
      params.start = start;
      params.end = end;
      params.period = "";
    }
    const query = qs.stringify({
      ...params,
      start_date: params?.start,
      end_date: params?.end,
    });

    const response = await api
      .get(`${apiConst.default.PAYMENT}/download?${query}`, {
        responseType: "blob",
      })
      .catch((err) => Promise.reject(err.response?.data?.message));

    return response.data;
  }
);
