import { SIGN_UP_FLOW_STATE, AUTH_STATE } from "vendor/providers/initialState";
import { SIGNUP_FLOW_STATE_TYPE, AUTH_STATE_TYPE } from "@samedaycustom/types/app";
import {
  COMPLETE_SIGNUP_FLOW_ACTION,
  FAILED_SIGN_UP_ACTION,
  UPDATE_AUTH_TOKEN_ACTION,
  UPDATE_SIGNUP_STATE_ACTION,
  DELETE_AUTH_TOKEN_ACTION,
} from "vendor/providers/actions/types";

// authentication reducers
export const AUTH = (
  state = AUTH_STATE,
  action: { type: string; payload: AUTH_STATE_TYPE }
): AUTH_STATE_TYPE => {
  switch (action.type) {
    case UPDATE_AUTH_TOKEN_ACTION:
      return {
        ...state,
        ...action.payload,
      };
    case DELETE_AUTH_TOKEN_ACTION:
      return {
        ...state,
        token: "",
      };

    default:
      return state;
  }
};

// signup reducers
export default (
  state = SIGN_UP_FLOW_STATE,
  action: SIGNUP_FLOW_STATE_TYPE
): SIGNUP_FLOW_STATE_TYPE => {
  switch (action.type) {
    case UPDATE_SIGNUP_STATE_ACTION:
      return {
        ...state,
        ...action,
        vendorDetails: {
          ...state.vendorDetails,
          ...action.vendorDetails,
        },
      };
    case COMPLETE_SIGNUP_FLOW_ACTION:
      return {
        ...state,
        ...action,
        vendorDetails: {
          ...state.vendorDetails,
          ...action.vendorDetails,
        },
        done: true,
      };
    case FAILED_SIGN_UP_ACTION:
      return {
        ...state,
        ...action,
        vendorDetails: {
          ...state.vendorDetails,
          ...action.vendorDetails,
        },
        done: false,
        error: true,
      };
    default:
      return state;
  }
};
