import React from "react";
import "./style.scss";
export default ({
  price,
  currency
}: {
  price: number | string;
  currency?: string;
}) => {
  return (
    <span className="price_view">
      {currency && <span className="__first">{currency}</span>}
      <span className="__first">{price.toString().split(".")[0]}</span>
      <span className="__last_container">
        <span className="last">.{price.toString().split(".")[1] || "00"}</span>
      </span>
    </span>
  );
};
