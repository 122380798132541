import "./style.scss";

import Spinner from "@atlaskit/spinner";
import { Box, Grid, ChakraInput as Input, Text } from "../../_components";
import {
  PrimaryButton,
  RowTag,
  TagType,
  TeamTag
} from "@samedaycustom/core-ui";
import { downloadUrl } from "@samedaycustom/helpers";
import { useToast, useUpload } from "@samedaycustom/hooks";
import { ArtworkImage } from "@samedaycustom/types/app/Operations";
import {
  ISDCArtworks,
  ISDCArtworkVersions
} from "@samedaycustom/types/order/@types/line";
import { ISDCOrder } from "@samedaycustom/types/order/@types/order";
import React, { useState } from "react";

import { ReactComponent as EmptyImage } from "../assets/empty-image.svg";
import { ReactComponent as Plus } from "../assets/plus.svg";
import ArtworkVersion from "./ArtworkVersion";
import ShowThumbNail from "./ShowThumbnail";

// import { AlternativeDropDowns } from "@samedaycustom/core-ui";
// import DropdownMenu, {
// 	DropdownItemGroup
// } from "@atlaskit/dropdown-menu";

export interface Artwork {
  id?: string;
  title: string | React.ReactNode;
  size?: string;
  dimension?: string;
  status?: TagType;
  artworks?: ISDCArtworks;
  order?: ISDCOrder;
  production_url?: string;
  thumbnail?: {
    label?: string;
    url?: string;
  };
  proof?: {
    label?: string;
    url?: string;
  };
  downloadFile?: string;
  isApproved?: boolean;
  isLoading?: boolean;
  onApprove?: (e: any) => void;
  onSendNote?: (e: ArtworkImage) => void;
  onUploadFile: (file: File) => Promise<any>;
  onRemoveArtwork: (id: string | any) => void;
  artworkImage: ArtworkImage;
  onArtworkVersionsChange?: (value: {
    label: any;
    value: ISDCArtworkVersions;
  }) => void;
}
export default React.memo(
  ({
    id,
    title,
    dimension,
    thumbnail,
    isApproved,
    status,
    downloadFile,
    onApprove,
    order,
    proof,
    onSendNote,
    isLoading,
    onUploadFile,
    production_url,
    onRemoveArtwork,
    artworks,
    artworkImage,
    onArtworkVersionsChange
  }: Artwork) => {
    const fileInputRef = React.useRef(null);
    const isAcceptedAndActive = React.useMemo(
      () => order?.accepted && order?.active,
      [order]
    );
    status =
      status === "S" ? "S.A" : status === "N.S" ? "R" : (status as TagType);

    const {
      onBrowseFile,
      onDrag,
      files,
      onDragEnd,
      onDragEnter,
      onDragLeave,
      onDragOver,
      onDrop,
      ondragover,
      loadingFile,
      setLoadingFile
    } = useUpload("doc", { isMultiple: false, key: id });

    React.useMemo(() => {
      if (files.length >= 1) {
        setLoadingFile(true);
        onUploadFile(files[0]).then(result => {
          setLoadingFile(false);
        });
      }
    }, [files]);

    // React.useMemo(() => {
    // 	console.log("loading");
    // 	setLoadingFile(loadingFile);
    // }, [loadingFile]);

    const artworksOptions = React.useMemo(() => {
      const artworksOptions = [];
      if (artworks?.original?.url)
        artworksOptions.push({
          label: "Original",
          value: "original"
        });
      if (artworks?.uploaded?.url)
        artworksOptions.push({
          label: "Uploaded",
          value: "uploaded"
        });
      if (artworks?.awaiting?.url)
        artworksOptions.push({
          label: "Awaiting Approval",
          value: "awaiting"
        });
      if (artworks?.approved?.url)
        artworksOptions.push({
          label: "Approved",
          value: "approved"
        });
      return artworksOptions;
    }, [artworks]);

    const onDownload = (e: string) => downloadUrl(e);

    const OverLayer = React.useMemo(() => {
      if (loadingFile || ondragover)
        return (
          <Box
            position="absolute"
            height="120px"
            width="120px"
            top="0"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            border="1px dashed #829AB1"
            borderRadius="8px"
            background="rgba(0,0,0,0.2)"
          >
            <Box
              d="flex"
              width="100%"
              height="100%"
              alignItems="center"
              justifyContent="center"
            >
              {(loadingFile && <Spinner size="small" invertColor={true} />) || (
                <Plus height="50px" width="50px" fill="#fff" />
              )}
            </Box>
          </Box>
        );
    }, [ondragover, loadingFile]);

    const _onChange = (e: any) => onBrowseFile(e);

    return (
      <Box
        d="flex"
        flexDirection="column"
        background="#FFFFFF"
        borderRadius="4px"
        border="1px solid #D9E2EC"
        padding="10px 20px 20px 20px"
        width="100%"
        marginBottom="1rem"
      >
        <Box
          d="inline-flex"
          justifyContent="space-between"
          alignItems="center"
          paddingBottom="1.5rem"
        >
          <Box
            fontFamily="Inter"
            fontWeight={500}
            color="#243B53"
            fontSize="14px"
            textTransform="capitalize"
          >
            <Box d="inline-flex">
              {title}&nbsp;
              {status && <RowTag tag={status} isTag />}
            </Box>
            <TeamTag url={production_url} />
          </Box>

          {!isApproved && (
            <Box>
              <PrimaryButton
                background="#E5F1FF !important"
                color="#007BFF"
                height="36px"
                isDisabled={!isAcceptedAndActive || isLoading}
                cursor={(isAcceptedAndActive && "pointer") || "not-allowed"}
                onClick={(isAcceptedAndActive && onApprove) || null}
                _hover={{ background: "#E5F1FF" }}
                _focus={{ background: "#E5F1FF" }}
                _disabled={{ background: "#E5F1FF" }}
              >
                Approve side
              </PrimaryButton>
            </Box>
          )}
        </Box>

        {dimension && (
          <Box width="100%" paddingBottom="2rem">
            <Text color="#486581" fontFamily="Inter" fontWeight={500}>
              Dimensions
            </Text>
            <Text>{dimension}</Text>
          </Box>
        )}

        <Box
          d="flex"
          flexDirection="row"
          width="100%"
          alignItems="flex-end"
          paddingBottom="1rem"
          className="artwork__thumbnail"
        >
          <Box>
            <ShowThumbNail
              thumbnail={thumbnail ? (thumbnail.url as string) : ""}
              label={thumbnail?.label as string}
              proof={proof && (proof.url as string)}
              downloadFile={downloadFile as string}
              onDownload={onDownload}
            />
          </Box>

          <Box
            d="flex"
            position="relative"
            flexDirection="column"
            width="120px"
            height="120px"
            alignItems="center"
            justifyContent="center"
            border={
              artworkImage && !artworkImage[id as string]?.url
                ? "1px dashed #829AB1"
                : ""
            }
            borderRadius="8px"
            marginLeft="1.6rem"
            onDragEnter={onDragEnter}
            onDrop={onDrop}
            onDragLeave={onDragLeave}
            onDragEnd={onDragEnd}
            onDragOver={onDragOver}
            onDrag={onDrag}
          >
            {OverLayer}
            {artworkImage && !artworkImage[id as string]?.url ? (
              <>
                <EmptyImage
                  style={{
                    width: "48px",
                    height: "48px"
                  }}
                />
                <Text
                  textAlign="center"
                  fontSize="11px"
                  paddingTop="0.4rem"
                  fontFamily="Inter"
                  fontWeight={500}
                  color="#243B53"
                >
                  Drag artwork here or&nbsp;
                  <label htmlFor="browse">
                    <Input
                      ref={fileInputRef}
                      type="file"
                      hidden
                      onChange={_onChange}
                      disabled={!isAcceptedAndActive}
                    />
                    <span
                      onClick={async () => fileInputRef.current.click()}
                      style={{
                        color: "#007BFF",
                        cursor:
                          (isAcceptedAndActive && "pointer") || "not-allowed"
                      }}
                    >
                      browse
                    </span>
                  </label>
                </Text>
              </>
            ) : (
              <ShowThumbNail
                thumbnail={artworkImage && artworkImage[id as string]?.url}
                downloadFile={downloadFile as string}
                proof={artworkImage && artworkImage[id as string]?.url}
                label={artworkImage && artworkImage[id as string]?.fieldname}
                loading={loadingFile}
                onDownload={onDownload}
                onDelete={() => onRemoveArtwork(id)}
                willDelete
              />
            )}
          </Box>
        </Box>
        {Array.isArray(artworksOptions) && artworksOptions.length > 1 && (
          <Box width="100%">
            <RowTag
              text="other design versions available"
              appearance="neutral"
            />
            <Grid
              gridTemplateColumns="repeat(auto-fill, minmax(100px, 1fr))"
              gridColumnGap="18px"
              gridRowGap="10px"
              marginTop="12px"
              width="100%"
            >
              {artworksOptions.map(o => (
                <ArtworkVersion
                  version={o.value}
                  onClick={() => onArtworkVersionsChange(o)}
                />
              ))}
            </Grid>

            {/* <PopupSelect
            isSearchable={false}
            className="artwork_select"
            classNamePrefix="react-select"
            formatGroupLabel={formatGroupLabel}
            target={({ ref }) => (
              <Button
                background="transparent !important"
                ref={ref}
                width="100%"
                color="#007bff"
                fontWeight={500}
                fontSize="14px"
                height="100%"
              >
                Artwork versions
                <ChevronDownIcon label="downicon" />
              </Button>
            )}
            popperProps={{
              placement: "bottom",
              strategy: "fixed"
            }}
            searchThreshold={10}
            onChange={value => onArtworkVersionsChange(value)}
            options={[
              {
                heading: "All artwork versions",
                options: artworksOptions
              }
            ]}
            placeholder="Artwork versions"
          /> */}
          </Box>
        )}
      </Box>
    );
  }
);
