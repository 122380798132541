import { DASHBOARD_DATA } from "@samedaycustom/types/app";

export const START_DASHBOARD_ACTION = "START_DASHBOARD_ACTION";
export const START_DASHBOARD_ACTION_SILENT = "START_DASHBOARD_ACTION_SILENT";
export const FAIL_DASHBOARD_ACTION = "FAIL_DASHBOARD_ACTION";
export const GET_DASHBOARD_STATUS_ACTION = "GET_DASHBOARD_STATUS";
export const DASHBOARD_STATE_RESET = "DASHBOARD_STATE_RESET";

export type ALL_DASHBOARD_ACTIONS_TYPE =
  | typeof GET_DASHBOARD_STATUS_ACTION
  | typeof START_DASHBOARD_ACTION_SILENT;

interface ISTART_DASHBOARD_ACTION {
  type: typeof START_DASHBOARD_ACTION | typeof START_DASHBOARD_ACTION_SILENT;
  payload: ALL_DASHBOARD_ACTIONS_TYPE;
}

interface IFAIL_DASHBOARD_ACTION {
  type: typeof FAIL_DASHBOARD_ACTION;
  payload: { type: ALL_DASHBOARD_ACTIONS_TYPE; msg: string };
}

interface IGET_DASHBOARD_STATUS_ACTION {
  type: typeof GET_DASHBOARD_STATUS_ACTION;
  payload: DASHBOARD_DATA;
}

interface IDASHBOARD_STATE_RESET {
  type: typeof DASHBOARD_STATE_RESET;
}

export type DASHBOARD_ACTION_TYPES =
  | ISTART_DASHBOARD_ACTION
  | IFAIL_DASHBOARD_ACTION
  | IGET_DASHBOARD_STATUS_ACTION
  | IDASHBOARD_STATE_RESET;
