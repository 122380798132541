import "../style.scss";

import React from "react";
import AnimatedNumber from "react-animated-number";

import { ReactComponent as DownTick } from "../assets/downTick.svg";
import { ReactComponent as UpTick } from "../assets/upTick.svg";

interface Props {
	decline?: boolean;
	Rate: number;
	Label: string;
	TotalNumber: number;
}

export default ({ decline, Rate, Label, TotalNumber }: Props) => {
	return (
		<div className="dashBoardTile">
			<div className="main">
				<h2>
					<AnimatedNumber
						component="text"
						value={TotalNumber}
						style={{
							transition: "1s ease-out",
							transitionProperty:
								"background-color, color, opacity"
						}}
						duration={500}
						formatValue={(n) => Math.floor(parseInt(n))}
					/>
				</h2>
				{/* <span
					style={{
						display: "flex",
						alignItems: "center",
						marginBottom: "6px"
					}}
				>
					{Rate < 0 ? <DownTick /> : <UpTick />}
					<span
						className={`rateText ${
							Rate < 0 ? "redHeading" : "greenHeading"
						}`}
					>
						{Math.abs(Rate)}%
					</span>
				</span> */}
			</div>
			<p className="tileLabelText">{Label}</p>
		</div>
	);
};
