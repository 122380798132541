import { AUTH_STATE_TYPE } from "@samedaycustom/types/app";
import { UPDATE_AUTH_TOKEN_ACTION } from "vendor/providers/actions/types";

export const START_AUTH_ACTION = "START_AUTH_ACTION";
export const FAIL_AUTH_ACTION = "FAIL_AUTHACTION";
export const FORGOT_PASSWORD_ACTION = "FORGOT_PASSWORD_ACTION";
export const SIGN_IN_ACTION = "SIGN_IN_ACTION";
export const SIGN_UP_ACTION = "SIGN_UP_ACTION";
export const ONBOARDING_STATE_RESET = "ONBOARDING_STATE_RESET";
export const LOGOUT_ACTION = "LOGOUT_ACTION";
export const VERIFY_ACTION = "VERIFY_ACTION";
export const RESET_PASSWORD_ACTION = "RESET_PASSWORD_ACTION";
export const CHECK_EMAIL_AVAILABILITY = "CHECK_EMAIL_AVAILABILITY";
export const CREATE_PASSWORD_ACTION = "CREATE_PASSWORD_ACTION";
export const REVERIFY_ACTION = "REVERIFY_ACTION";

export type ACTION_GROUP_TYPE =
  | typeof FORGOT_PASSWORD_ACTION
  | typeof SIGN_IN_ACTION
  | typeof SIGN_UP_ACTION
  | typeof UPDATE_AUTH_TOKEN_ACTION
  | typeof VERIFY_ACTION
  | typeof RESET_PASSWORD_ACTION
  | typeof CHECK_EMAIL_AVAILABILITY
  | typeof CREATE_PASSWORD_ACTION
  | typeof REVERIFY_ACTION;

interface ISTART_AUTH_ACTION {
  type: typeof START_AUTH_ACTION;
  payload: ACTION_GROUP_TYPE;
}

interface IFAIL_AUTH_ACTION {
  type: typeof FAIL_AUTH_ACTION;
  payload: {
    type: ACTION_GROUP_TYPE;
    msg: string;
  };
}

interface IFORGOT_PASSWORD_ACTION {
  type: typeof FORGOT_PASSWORD_ACTION;
}

interface ISIGN_IN_ACTION {
  type: typeof UPDATE_AUTH_TOKEN_ACTION;
  payload: AUTH_STATE_TYPE;
  // add the payload later
}

interface ISIGN_IN_VIEW {
  type: typeof SIGN_IN_ACTION;
}

interface ISIGN_UP_ACTION {
  type: typeof SIGN_UP_ACTION;
  payload: AUTH_STATE_TYPE;
}

interface ISTATE_RESET {
  type: typeof ONBOARDING_STATE_RESET;
}

interface ILOGOUT {
  type: typeof LOGOUT_ACTION;
}

interface IVERIFY {
  type: typeof VERIFY_ACTION;
}

interface IRESET_PASSWORD_ACTION {
  type: typeof RESET_PASSWORD_ACTION;
}

interface ICHECK_EMAIL_AVAILABILITY {
  type: typeof CHECK_EMAIL_AVAILABILITY;
  payload: boolean;
}

interface ICREATE_PASSORD_ACTION {
  type: typeof CREATE_PASSWORD_ACTION;
}

interface IREVERIFY {
  type: typeof REVERIFY_ACTION;
}

export type AUTH_ACTION_TYPES =
  | ISTART_AUTH_ACTION
  | IFAIL_AUTH_ACTION
  | IFORGOT_PASSWORD_ACTION
  | ISIGN_IN_ACTION
  | ISIGN_UP_ACTION
  | ISTATE_RESET
  | ILOGOUT
  | ISIGN_IN_VIEW
  | IVERIFY
  | IRESET_PASSWORD_ACTION
  | ICHECK_EMAIL_AVAILABILITY
  | ICREATE_PASSORD_ACTION
  | IREVERIFY;
