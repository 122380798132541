import "./style.scss";

import { Box, Button, IconButton, Input, Text } from "@chakra-ui/react";
import { ReactComponent as ImageExpand } from "@samedaycustom/assets/images/image-expand.svg";
import { ReactComponent as ArrowDown } from "@samedaycustom/assets/images/arrowdown.svg";
import { PrimaryButton, RowTag, TagType, TeamTag } from "@samedaycustom/core-ui";
import { useUpload } from "@samedaycustom/hooks";
import { ArtworkImage } from "@samedaycustom/types/app/Operations";
import { ISDCArtworks, ISDCArtworkVersions } from "@samedaycustom/types/order/@types/line";
import { ISDCOrder } from "@samedaycustom/types/order/@types/order";
import React, { useState } from "react";
import { useLightgallery } from "react-lightgallery";

import ArtworkVersion from "./ArtworkVersion";
import ShowThumbNail from "./ShowThumbnail";

// import { AlternativeDropDowns } from "@samedaycustom/core-ui";
// import DropdownMenu, {
// 	DropdownItemGroup
// } from "@atlaskit/dropdown-menu";

export interface Artwork {
  id?: string;
  title: string | React.ReactNode;
  status?: TagType;
  artworks?: ISDCArtworks;
  order?: ISDCOrder;
  production_url?: string;
  thumbnail?: {
    label?: string;
    url?: string;
  };
  isApproved?: boolean;
  isLoading?: boolean;
  onApprove?: () => Promise<any>;
  onUploadFile: (file: File) => Promise<any>;
  artworkImage: ArtworkImage;
  onArtworkVersionsChange?: (value: { label: any; value: ISDCArtworkVersions }) => void;
  updated_proof_url?: string;
  updated_thumbnail?: string;
}
export default React.memo(
  ({
    id,
    title,
    thumbnail,
    isApproved,
    status,
    onApprove,
    order,
    onUploadFile,
    production_url,
    artworks,
    onArtworkVersionsChange,
    updated_proof_url,
    updated_thumbnail,
  }: Artwork) => {
    const fileInputRef = React.useRef(null);
    const [approving, setapproving] = useState(false);
    const isAcceptedAndActive = React.useMemo(() => order?.accepted && order?.active, [order]);
    status = status === "S" ? "S.A" : status === "N.S" ? "R" : (status as TagType);
    const [showOldFile, setShowOldFile] = React.useState(false);

    const {
      onBrowseFile,
      onDrag,
      files,
      onDragEnd,
      onDragEnter,
      onDragLeave,
      onDragOver,
      onDrop,
      loadingFile,
      setLoadingFile,
    } = useUpload("doc", { isMultiple: false, key: id });

    React.useMemo(() => {
      if (files.length >= 1) {
        setLoadingFile(true);
        onUploadFile(files[0]).then((result) => {
          setLoadingFile(false);
        });
      }
    }, [files]);

    const artworksOptions = React.useMemo(() => {
      const artworksOptions = [];
      if (production_url)
        artworksOptions.push({
          label: "Production",
          value: "production",
          url: production_url,
        });
      if (updated_proof_url)
        artworksOptions.push({
          label: "Updated Production",
          value: "updated-production",
          url: updated_proof_url,
        });
      if (artworks?.original?.url)
        artworksOptions.push({
          label: "Original",
          value: "original",
          // If production_url is a zip file, 
          // then use the zip file as the original file
          url: production_url.split(".").pop() === "zip" ? production_url : "",
        });
      if (artworks?.awaiting?.url)
        artworksOptions.push({
          label: "Awaiting Approval",
          value: "awaiting",
        });
      if (artworks?.approved?.url)
        artworksOptions.push({
          label: "Approved",
          value: "approved",
        });

      return artworksOptions;
    }, [artworks, production_url, updated_proof_url]);

    const _onChange = (e: any) => onBrowseFile(e);

    const ExpandImage = ({ groupName }: { groupName: string }) => {
      const { openGallery } = useLightgallery();
      return (
        <IconButton
          icon={<ImageExpand />}
          background="transparent !important"
          isDisabled={false}
          cursor={"pointer"}
          onClick={() => {
            openGallery(groupName);
          }}
          // _hover={{ background: "#E5F1FF" }}
          // _focus={{ background: "#E5F1FF" }}
          // _disabled={{ background: "#E5F1FF" }}
          aria-label={""} />
      );
    };

    return (
      <Box
        d="flex"
        position={"relative"} 
        flexDirection="column"
        background="#FFFFFF"
        borderRadius="4px"
        border="1px solid #D9E2EC"
        padding="10px 20px 20px 20px"
        width="100%"
        maxW="225px"
        marginBottom="1rem"
      >
        <Box
          d="inline-flex"
          justifyContent="space-between"
          alignItems="center"
          paddingBottom="1.5rem"
        >
          <Box
            fontFamily="Inter"
            fontWeight={500}
            color="#243B53"
            fontSize="14px"
            textTransform="capitalize"
            width="100%"
          >
            <Box d="inline-flex" justifyContent="space-between" width="100%">
              <Text>{title}</Text>
              {status && <RowTag tag={status} isTag />}
            </Box>
          </Box>
        </Box>
        <Box pos="relative">
          <ShowThumbNail
            thumbnail={thumbnail ? (thumbnail.url as string) : ""}
            label={thumbnail?.label as string}
            downloadFile={production_url}
            loading={loadingFile}
            id={id}
          />
          <Box
            position="absolute"
            top="0"
            left={"0px"}
            display="inline-flex"
            justifyContent="flex-start"
            width="100%"
          >
            <ExpandImage groupName={id} />
          </Box>
          {production_url && (
            <Box
              position="absolute"
              bottom="10px"
              d="inline-flex"
              justifyContent="center"
              width="100%"
            >
              <TeamTag url={production_url} />
            </Box>
          )}
        </Box>

        {!isApproved && (
          <Box marginTop="16px" d="flex" justifyContent="center" width="100%">
            <PrimaryButton
              background="#E5F1FF !important"
              color="#007BFF"
              height="36px"
              width="100%"
              isDisabled={!isAcceptedAndActive || approving}
              isLoading={approving}
              cursor={isAcceptedAndActive ? "pointer" : "not-allowed"}
              onClick={() => {
                if (isAcceptedAndActive) {
                  setapproving(true);
                  onApprove()
                    .then(() => {
                      setapproving(false);
                    })
                    .catch(() => setapproving(false));
                }
              }}
              _hover={{ background: "#E5F1FF" }}
              _focus={{ background: "#E5F1FF" }}
              _disabled={{ background: "#E5F1FF" }}
            >
              Approve side
            </PrimaryButton>
          </Box>
        )}
        <Box
          background="#E5F1FF"
          width="100%"
          border={"1px dashed #007BFF"}
          borderRadius="4px"
          marginTop="16px"
          marginBottom="16px"
          padding="8px"
          onDragEnter={onDragEnter}
          onDrop={onDrop}
          onDragLeave={onDragLeave}
          onDragEnd={onDragEnd}
          onDragOver={onDragOver}
          onDrag={onDrag}
        >
          <Text
            textAlign="center"
            fontSize="12px"
            fontFamily="Inter"
            fontWeight={500}
            color="#243B53"
          >
            Upload artwork or&nbsp;
            <label htmlFor="browse">
              <Input
                ref={fileInputRef}
                type="file"
                hidden
                onChange={_onChange}
                disabled={!isAcceptedAndActive}
              />
              <span
                onClick={async () => fileInputRef.current.click()}
                style={{
                  color: "#007BFF",
                  cursor: (isAcceptedAndActive && "pointer") || "not-allowed",
                }}
              >
                browse
              </span>
            </label>
          </Text>
        </Box>

        {Array.isArray(artworksOptions) && (
          <Box width="100%" height={showOldFile ? "140px" : ""}>
            {artworksOptions.map((o) => o?.url === updated_proof_url ? (
              <ArtworkVersion
                url={o?.url}
                version={o.value}
                onClick={() => onArtworkVersionsChange(o)}
              />
            ) : !updated_proof_url ? (
              <ArtworkVersion
                url={o?.url}
                version={o.value}
                onClick={() => onArtworkVersionsChange(o)}
              />
            ) : (null))}
            {updated_proof_url ? (<Box width={"full"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
              <Box onClick={() => setShowOldFile(!showOldFile)} cursor={"pointer"} display={"flex"} gap={"6px"} py={"5px"} px={"12px"} borderRadius={"40px"} justifyContent={"center"} alignItems={"center"} bg={"#F0F4F8"} height={"28px"}>
                <Text color={"#07121D"} fontSize={"13px"} fontWeight={"500"} lineHeight={"18px"}>Other</Text>
                <ArrowDown />
              </Box>
            </Box>) : (null)}

            {showOldFile ? (<Box boxShadow={"0px 6px 18px rgba(98, 125, 152, 0.24)"} borderRadius={"6px"} p={"5px"} backgroundColor={"#fff"} mb={"80px"} position={"absolute"} right={"0"} >
              {artworksOptions.map((o) => o?.url !== updated_proof_url ? (
                <ArtworkVersion
                  url={o?.url}
                  version={o.value}
                  onClick={() => onArtworkVersionsChange(o)}
                  updated_proof_url={updated_proof_url}
                />
              ) : (null))}
            </Box>) : (null)}
          </Box>
        )}
        {
          artworks?.uploaded?.url && <Button backgroundColor="#F7C948" onClick={() => onArtworkVersionsChange({
            value: 'uploaded',
            label: 'upload'
          })} fontWeight="500">Uploaded Design</Button>
        }
      </Box>
    );
  }
);
