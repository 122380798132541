import "./style.scss";
import React from "react";
import { Text } from "../../_components";

interface Props {
  title: string;
  leftContent?: React.ElementType;
}
export default ({ title, leftContent: LeftContent, ...props }: Props) => {
  return (
    <div className="settingsHeaderContainer">
      <div className="__header">
        <Text
          fontSize="2xl"
          color="#007BFF"
          fontWeight="bold"
          fontFamily="Roboto"
        >
          {title}
        </Text>
        {props["children"]}
        {LeftContent && <div>{<LeftContent />}</div>}
      </div>
    </div>
  );
};
