import "./style.scss";

import { Button, Icon, Text } from "../../_components";
import { Circle as CircleProgress } from "@samedaycustom/core-ui";
import React, { ReactElement, FunctionComponent, SVGProps } from "react";

export interface StoreSettingsOptionsProps {
  title: string;
  body: string;
  Icon: ReactElement | FunctionComponent<SVGProps<SVGSVGElement>>;
  buttonTitle: string;

  /**Listens to events when button is clicked */
  onButtonClick?: () => void;

  /**  Listens to events when clicked on the div*/
  onClick?: () => void;

  progress?: number;
  showProgress?: boolean;
  showDone?: boolean;
  url?: string;
}

export default ({
  Icon: PIcon,
  body,
  onButtonClick,
  title,
  onClick,
  buttonTitle,
  progress,
  showDone,
  showProgress
}: StoreSettingsOptionsProps) => {
  const MainIcon =
    typeof PIcon === "string" ? (
      <Icon name={PIcon} color="#898888" fontSize={35} />
    ) : (
      PIcon
    );

  return (
    <div className="StoreSettingsCardContainer" {...{ onClick }}>
      <p className="--leftContent">
        <div className="__iconContainer">{MainIcon}</div>
      </p>
      <div className="__content">
        <Text fontFamily="Inter Bold" className="__header">
          {title}
        </Text>
        <Text fontSize="10px" className="__body">
          {body}
        </Text>
        <Button
          variant="outline"
          variantColor="blue"
          borderColor="#83BFFF"
          width={150}
          onClick={onButtonClick}
        >
          <Text>{buttonTitle}</Text>
        </Button>
      </div>
      <div className="--rightContent">
        {(showProgress && (
          <CircleProgress
            progress={progress}
            progressStyle={{ width: 50, height: 50 }}
            radius={20}
            height={25}
            width={25}
            progressColor="#067EFF"
            progressWidth={2}
            progressTextStyle={{
              color: "#067EFF",
              top: 15,
              left: 15,
              fontSize: 12,
              fontWeight: "bold"
            }}
          />
        )) ||
          (showDone && (
            <Icon
              name="check-circle"
              style={{ color: "#06A806", fontSize: 20 }}
            />
          )) ||
          null}
      </div>
    </div>
  );
};
