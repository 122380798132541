import "./style.scss";

import React from "react";

type StageProps = {
  stages: number;
  activeStage: number;
};

export const StageIndicator: React.FC<StageProps> = ({
  stages,
  activeStage
}: StageProps) => {
  return (
    <div className="stageIndicator">
      {new Array(stages).fill(null).map((stage, i) => (
        <div
          className={`stageBar ${i + 2 <= activeStage ? "activeStage" : ""}`}
        />
      ))}
    </div>
  );
};
