import { combineReducers } from "redux";

import adminMessageReducer from "../features/AdminMesage";
import notifications from "../features/notification";
import orderLine from "../features/orderline";
import payments from "../features/payment";
import locationProducts from "../features/storeLocationProduct";
import { locationsApi } from "../features/storeLocationProduct/async";
import { appReducer } from "./App";
import { deliveryMethod } from "./App/Settings/Store/DeliveryMethod";
import notification from "./App/Settings/Store/Notification";
import { printingMethod } from "./App/Settings/Store/PrintingMethod";
import { productionSpeed } from "./App/Settings/Store/ProductionSpeed";
import { setupWorkHours } from "./App/Settings/Store/SetupWorkHours";
import { storeProfileReducer } from "./App/Settings/Store/StoreProfile";
import signupFlow, { AUTH } from "./Auth";
import businessProfileReducer from "./BusinessProfile";
import dashboardreducer from "./DashboardData";
import profileImageReducer from "./imageUploads";
import messagingReducer from "./Messaging";
import onboardingReducer from "./Onboarding";
import operationOrders from "./operations/Orders";
import ReportState from "./Reports";
import setupStateReducer from "./SetupStatus";
import storeLocationsreducer from "./store/locations";
import rolesReducer from "./store/roles";
import storeSettings from "./store/settings";
import usersReducer from "./store/users";
import userProfileReducer from "./user/profile";
import cartsReducer from "./abandoned-carts";
import customersReducer from "./customers";
import { printingMethodsApi } from "../features/printingMethods";

export default combineReducers({
  auth: AUTH,
  app: appReducer,
  roles: rolesReducer,
  signupFlow,
  users: usersReducer,
  userProfile: userProfileReducer,
  locations: storeLocationsreducer,
  report: ReportState,
  deliveryMethod,
  productionSpeed,
  setupWorkHours,
  printingMethod,
  notification,
  storeProfile: storeProfileReducer,
  operationsOrders: operationOrders,
  onboarding: onboardingReducer,
  businessProfile: businessProfileReducer,
  profileImage: profileImageReducer,
  storeSetting: storeSettings,
  setupState: setupStateReducer,
  dashboardState: dashboardreducer,
  messages: messagingReducer,
  adminMessage: adminMessageReducer,
  orderLine,
  payments,
  notifications,
  locationProducts,
  [locationsApi.reducerPath]: locationsApi.reducer,
  carts: cartsReducer,
  customers: customersReducer,
  [printingMethodsApi.reducerPath]: printingMethodsApi.reducer,
});
