import React from "react";
import "./style.scss";
import { ReactComponent as DoneImg } from "../assets/welcomeProgressIcon.svg";
import Skeleton from "react-loading-skeleton";

export interface Props {
  label: string;
  number: number;
  done: boolean;
  optional?: boolean;
  loading: boolean;
  state?: boolean;
  onItemClick?: () => void;
}

export default ({
  label,
  number,
  done,
  optional,
  loading,
  onItemClick
}: Props) => {
  return (
    <div className="progressItemContainer" onClick={onItemClick}>
      <div className="progressNumberContainerSvg">
        {loading ? (
          <Skeleton circle={true} height={24} width={24} />
        ) : done ? (
          <DoneImg />
        ) : (
          <div className="progressNumber">
            <span> {number} </span>
          </div>
        )}
        {}
      </div>
      {loading ? (
        <Skeleton width={200} />
      ) : (
        <div className="progressItemText">
          <span
            className={
              // done ? "progressLabel strikeLabel" : "progressLabel"
              "progressLabel"
            }
          >
            {label}
          </span>
          {optional ? <span className="optionalProgress">Optional</span> : null}
        </div>
      )}
    </div>
  );
};
