import * as userProfileAction from "vendor/providers/actions/user/profile/actionTypes";
import { USER_PROFILE_INITIALSTATE } from "vendor/providers/initialState";

const userProfileReducer = (state = USER_PROFILE_INITIALSTATE, action) => {
  switch (action.type) {
    case userProfileAction.START_PROFILE_ACTIONS:
      return {
        ...state,
        loading: true,
        done: false,
        error: false,
        type: action.payload,
        errorMessage: "",
      };
    case userProfileAction.GET_PROFILE:
      return {
        ...state,
        loading: false,
        done: true,
        error: false,
        type: action.type,
        profile: { ...action.payload },
      };
    case userProfileAction.EDIT_PROFILE:
      return {
        ...state,
        loading: false,
        done: true,
        error: false,
        type: action.type,
        profile: { ...state.profile, ...action.payload },
      };
    case userProfileAction.CHANGE_PASSWORD:
      return {
        ...state,
        loading: false,
        done: true,
        error: false,
        type: action.type,
      };
    case userProfileAction.FAIL_PROFILE_ACTIONS:
      return {
        ...state,
        loading: false,
        done: false,
        error: true,
        type: action.payload.type,
        errorMessage: action.payload.message,
      };
    case userProfileAction.STATE_RESET:
      return {
        ...state,
        loading: false,
        done: false,
        error: false,
        type: "",
        errorMessage: "",
      };
    default:
      return state;
  }
};

export default userProfileReducer;
