import Spinner from "@atlaskit/spinner";
import { Box, ChakraInput as Input, Text } from "../_components";
import { usePrevious } from "@samedaycustom/hooks";
import React, { useMemo } from "react";

import { ReactComponent as Minus } from "./assets/minus-solid.svg";
import { ReactComponent as Plus } from "./assets/plus-circle.svg";

export interface INumberCounter {
  value?: number;
  isLoading?: boolean;
  onChange?: (
    value: number,
    type?: "INCREMENT" | "DECREMENT"
  ) => {
    disableIncrement?: boolean;
    disableDecrement?: boolean;
  };
  disableIncrement?: boolean;
  disableDecrement?: boolean;
  isDisabled?: boolean;
}
export const NumberCounter = React.memo(
  ({
    isLoading,
    value = 0,
    onChange,
    disableDecrement,
    disableIncrement,
    isDisabled
  }: Partial<INumberCounter>) => {
    // const [, , option] = useField(name ? name : null);

    const [newValue, setvalue] = React.useState(value);
    const [isdisableDecrement, setisdisableDecrement] = React.useState(
      disableDecrement
    );
    const [isdisableIncrement, setisdisableIncrement] = React.useState(
      disableIncrement
    );
    const prevD = usePrevious<boolean>(disableDecrement);
    const prevI = usePrevious<boolean>(disableIncrement);
    const prevV = usePrevious<number>(value);

    useMemo(() => {
      if (prevD.current !== disableDecrement)
        setisdisableDecrement(disableDecrement);
      if (prevI.current !== disableIncrement)
        setisdisableIncrement(disableIncrement);
      if (prevV.current !== value) setvalue(value);
    }, [disableDecrement, disableIncrement, value]);

    /**
     *
     * on incrementing
     */
    const increment = () => {
      const oldval = Number(newValue) + 1;
      const newval = (Number(oldval) >= 0 && Number(oldval)) || 0;

      const onchangevalue = onChange && onChange(newval, "INCREMENT");
      // name && option.setValue({ [name]: newval });

      setvalue(newval);

      if (typeof onchangevalue?.disableDecrement !== "undefined")
        return setisdisableDecrement(onchangevalue.disableDecrement);

      if (typeof onchangevalue?.disableIncrement !== "undefined")
        return setisdisableIncrement(onchangevalue.disableIncrement);
    };

    /**
     *
     *
     * on decrementing
     */
    const decrement = () => {
      const oldval = Number(newValue) - 1;
      const newval = (Number(oldval) >= 0 && Number(oldval)) || 0;

      const onchangevalue = onChange && onChange(newval, "DECREMENT");
      // name && option.setValue({ [name]: newval });

      setvalue(newval);
      if (typeof onchangevalue?.disableDecrement !== "undefined")
        return setisdisableDecrement(onchangevalue.disableDecrement);

      if (typeof onchangevalue?.disableIncrement !== "undefined")
        return setisdisableIncrement(onchangevalue.disableIncrement);
    };
    return (
      <Box
        d="flex"
        background="#F0F4F8"
        borderRadius="20px"
        alignItems="center"
        justifyContent="space-around"
        padding="3px"
        width="80%"
      >
        {(!isLoading && (
          <React.Fragment>
            <Box
              onClick={
                !isdisableDecrement && !isDisabled && newValue > 0
                  ? decrement
                  : null
              }
              userSelect="none"
              height="16px"
              width="16px"
              position="sticky"
              left="0"
            >
              <Minus
                fill={
                  !isdisableDecrement && !isDisabled && newValue > 0
                    ? "#007BFF"
                    : "#829AB1"
                }
                cursor={
                  !isdisableDecrement && !isDisabled && newValue > 0
                    ? "pointer"
                    : "not-allowed"
                }
                height="16px"
                width="16px"
              />
            </Box>
            <Input
              value={newValue}
              paddingLeft="0.5rem"
              paddingRight="0.5rem"
              background="inherit"
              border="none"
              borderRadius="20px"
              width="inherit"
              textAlign="center"
              type="number"
              outline={"none"}
              height="initial"
              _active={{ outline: "none", border: "none" }}
              _focus={{ outline: "none", border: "none" }}
              isDisabled={isDisabled}
              onFocus={(e: any) => e.target.select()}
              onChange={(e: any) => {
                let numval = parseInt(e.target.value) || 0;
                setvalue(numval);
              }}
              color="#102A43"
            />
            <Box
              onClick={
                !isdisableIncrement && increment && !isDisabled
                  ? increment
                  : null
              }
              userSelect="none"
              height="16px"
              width="16px"
              position="sticky"
              right="0"
            >
              <Plus
                fill={
                  !isdisableIncrement && !isDisabled ? "#007BFF" : "#829AB1"
                }
                cursor={
                  !isdisableIncrement && !isDisabled ? "pointer" : "not-allowed"
                }
                height="16px"
                width="16px"
              />
            </Box>
          </React.Fragment>
        )) || (
          <Text>
            <Spinner size="xsmall" />
          </Text>
        )}
      </Box>
    );
  }
);
