import React from "react"
import ContentLoader from "react-content-loader"

interface Props {
    color?: string
    backgroundColor?: string
    speed?: number
    width?: number
    height?: number
}
const ProfileLoader = ({color, backgroundColor, height, speed, width}: Props) => (
    <ContentLoader
        speed={speed || 2}
        width={width || 400}  
        height={height || 200}
        viewBox={`0 0 ${width || "80%"} ${height || "160"}`}
        backgroundColor={backgroundColor || "#f3f3f3"}
        foregroundColor={color || "#196BD82F"}
    >
        <rect x="40" y="0" rx="3" ry="3" width="100" height="10" />
        <rect x="40" y="15" rx="3" ry="3" width="200" height="10" />
        <circle cx="15" cy="15" r="15" />
        <rect x="40" y="45" rx="3" ry="3" width="100" height="10" />
        <rect x="40" y="60" rx="3" ry="3" width="200" height="10" />
        <circle cx="15" cy="55" r="15" />
        <rect x="40" y="90" rx="3" ry="3" width="100" height="10" />
        <rect x="40" y="105" rx="3" ry="3" width="200" height="10" />
        <circle cx="15" cy="100" r="15" />
        <rect x="40" y="135" rx="3" ry="3" width="100" height="10" />
        <rect x="40" y="150" rx="3" ry="3" width="200" height="10" />
        <circle cx="15" cy="145" r="15" />
        {/* <rect x="0" y="64" rx="3" ry="3" width="0" height="30" />
        <rect x="0" y="12" rx="3" ry="3" width="82" height="10" />
        <rect x="0" y="30" rx="3" ry="3" width="170" height="10" />
        <div style={{
            height: '30px',
            width: '100%'
        }}></div>
        <rect x="0" y="12" rx="3" ry="3" width="82" height="10" />
        <rect x="0" y="30" rx="3" ry="3" width="170" height="10" /> */}
    </ContentLoader>
)

export default ProfileLoader
