export const GET_USERS = "GET_USERS";
export const CREATE_USER = "CREATE_USERS";
export const EDIT_USER = "EDIT_USERS";
export const DELETE_USER = "DELETE_USER";
export const START_USER_ACTION = "START_USER_ACTION";
export const FAILED_USER_ACTION = "FAILED_USER_ACTION";
export const GET_TARGET_USER = "GET_TARGET_USER";
export const USERS_STATE_RESET = "USERS_STATE_RESET";
export const EDIT_STORES_LIST = "EDIT_STORES_LIST";
export const SILENT_EDIT_STORES_LIST = "SILENT_EDIT_STORES_LIST";
export const SET_USER_ACTIVE = "SET_USER_ACTIVE";
export const SET_USERS_FETCH = "SET_USERS_FETCH";
