import {
  DIRTY_TYPE,
  ModalConfirmStateType,
  ModalShowType,
  RECENT_ORDER_TYPE,
  ThunkResult,
} from "@samedaycustom/types/app";
import { ORDER_STATUS } from "@samedaycustom/types/order/@types/order";
import { ReactElement } from "react";
import { APP_REDUCER_ACTION } from "vendor/providers/reducers/App";

import {
  ADD_TO_RECENT_DEL_ORDERS,
  ADD_TO_RECENT_ORDERS,
  ADD_TO_RECENT_PROD_ORDERS,
  DELETED,
  DELETING,
  ERROR,
  FETCHED,
  FETCHING,
  HIDE_MODAL,
  ORDER_STATUS_RESET,
  ORDER_STATUS_UPDATED,
  REMOVE_FROM_RECENT_DEL_ORDERS,
  REMOVE_FROM_RECENT_ORDERS,
  REMOVE_FROM_RECENT_PROD_ORDERS,
  SET_APP_TITLE,
  SET_AUTO_LOGOUT,
  SET_CONNECTION_STATUS,
  SET_ISDIRTY,
  SET_LOADING,
  SET_PAGE_LOADING,
  SET_PREVIOUS_COMPONENT,
  SET_TARGET_LOCATION,
  SHOW_MODAL,
  SHOW_MODAL_CONFIRM,
  SUBMITTING_MODAL,
  SUCCESS,
  ORDER_DELIVERY_METHOD_UPDATED,
  ORDER_DUE_DATE_CHANGE_REQUEST_UPDATE,
  ORDER_ARTWORK_CHANGE_UPDATE,
} from "../types";

/**
 *
 * @param loading
 *
 */

//  TODO make this a dynamic loading action dispatcher
export const setLoading = <T>(loading: boolean, type?: T, options?: Record<string, unknown>) => {
  return (dispatch: any) => {
    return !type
      ? dispatch({ type: SET_LOADING, isLoading: loading })
      : dispatch({ type, loading, ...options });
  };
};
export const setPageLoading = (loading: boolean): ThunkResult<APP_REDUCER_ACTION> => {
  return (dispatch) => {
    return dispatch({ type: SET_PAGE_LOADING, appLoading: loading });
  };
};

export const realTimeUpdateStatus = (orderId: string, status: ORDER_STATUS) => ({
  type: ORDER_STATUS_UPDATED,
  updatedOrderStatus: {
    orderId,
    status,
  },
});
export const resetOrderStatus = () => ({
  type: ORDER_STATUS_RESET,
});

export const isDeleting = (): APP_REDUCER_ACTION => ({
  type: DELETING,
});

export const isDeleted = (message: string): APP_REDUCER_ACTION => ({
  type: DELETED,
  deleteMessage: message,
});

export const isFetching = (): APP_REDUCER_ACTION => ({
  type: FETCHING,
});

export const isFetched = (): APP_REDUCER_ACTION => ({
  type: FETCHED,
});
export const isSuccess = (): APP_REDUCER_ACTION => ({
  type: SUCCESS,
});

export const setError = <T extends { errorMessage?: string }>(
  message: string,
  type?: string,
  options?: T
) => {
  return (dispatch: any) => {
    return !type
      ? dispatch({ type: ERROR, error: message })
      : dispatch({ type, ...{ ...options, error: !!message, errorMessage: message } });
  };
};
export const resetError = (type: string, options?: any) => ({
  type,
  error: false,
  errorMessage: null,
  ...options,
});

export const setSuccess = (message: string): APP_REDUCER_ACTION => ({
  type: SUCCESS,
  message,
});

/**
 * @function catchError handles http error messages
 * @param error
 */
export const catchError = <T>(error: typeof Error | any, type?: string, options?: T) => {
  return (dispatch: any) => {
    let message = null;
    if (error?.response?.hasOwnProperty("data")) {
      if (Array.isArray(error.response.data.message))
        for (const element of error.response.data.message) {
          message =
            (element.param && `${element.param}: ${element.msg}`) ||
            (element.field && `${element.field}: ${element.msg}`) ||
            element;
          dispatch(setError(message || "Error occured while processing request"));
        }
      else if (typeof error.response.data.message === "string")
        message = error.response.data.message;
    }

    return dispatch(
      setError<T>(message || "Error occured while processing request", type, options)
    );
  };
};

export const hideModal = (): APP_REDUCER_ACTION => ({
  type: HIDE_MODAL,
});

export const submittingModal = (condition: boolean): APP_REDUCER_ACTION => ({
  type: SUBMITTING_MODAL,
  condition,
});
export const showModal = (parameters: ModalShowType): APP_REDUCER_ACTION => ({
  type: SHOW_MODAL,
  ...parameters,
});

export const showConfirm = (parameters: ModalConfirmStateType): APP_REDUCER_ACTION => ({
  type: SHOW_MODAL_CONFIRM,
  confirm: parameters,
});
export const updateChanges = (values: any) => ({ type: "CHANGES", changes: values });

export const setTargetLocation = (location: Record<string, unknown>): APP_REDUCER_ACTION => ({
  type: SET_TARGET_LOCATION,
  targetLocation: location,
});

export const setPreviousComponent = (PreviousComponent: ReactElement): APP_REDUCER_ACTION => ({
  type: SET_PREVIOUS_COMPONENT,
  PreviousComponent,
});

export const setIsDirty = (dirty: DIRTY_TYPE) => {
  return {
    type: SET_ISDIRTY,
    dirty,
  };
};

export const addRecentOrder = (orderItem: RECENT_ORDER_TYPE): APP_REDUCER_ACTION => ({
  type: ADD_TO_RECENT_ORDERS,
  order: orderItem,
});

export const removeRecentOrder = (orderItem: RECENT_ORDER_TYPE): APP_REDUCER_ACTION => ({
  type: REMOVE_FROM_RECENT_ORDERS,
  order: orderItem,
});

// recently opened produced orders

export const addRecentProdOrder = (orderItem: RECENT_ORDER_TYPE): APP_REDUCER_ACTION => ({
  type: ADD_TO_RECENT_PROD_ORDERS,
  order: orderItem,
});

export const removeRecentProdOrder = (orderItem: RECENT_ORDER_TYPE): APP_REDUCER_ACTION => ({
  type: REMOVE_FROM_RECENT_PROD_ORDERS,
  order: orderItem,
});

// recently opened delivered orders

export const addRecentDelOrder = (orderItem: RECENT_ORDER_TYPE): APP_REDUCER_ACTION => ({
  type: ADD_TO_RECENT_DEL_ORDERS,
  order: orderItem,
});

export const removeRecentDelOrder = (orderItem: RECENT_ORDER_TYPE): APP_REDUCER_ACTION => ({
  type: REMOVE_FROM_RECENT_DEL_ORDERS,
  order: orderItem,
});

export const setAppTitle = (title: string): APP_REDUCER_ACTION => ({
  type: SET_APP_TITLE,
  appTitle: title,
});

export const setConnectionStatus = (status: boolean): APP_REDUCER_ACTION => ({
  type: SET_CONNECTION_STATUS,
  connectionStatus: status,
});

export const showAutoLogoutPopup = (): APP_REDUCER_ACTION => ({
  type: SET_AUTO_LOGOUT,
});

export const realTimeUpdateDeliveryMethod = (orderId: string, deliveryMethod: string) => ({
  type: ORDER_DELIVERY_METHOD_UPDATED,
  updatedOrderDeliveryMethod: {
    orderId,
    deliveryMethod,
  },
});

export const realTimeUpdateDueDate = (
  orderId: string,
  decoOrderId: string,
  timeZone: string,
  status: "approved" | "declined",
  newDueDate?: string
) => ({
  type: ORDER_DUE_DATE_CHANGE_REQUEST_UPDATE,
  updatedOrderDueDate: {
    orderId,
    decoOrderId,
    timeZone,
    status,
    newDueDate,
  },
});

export const realTimeUpdateArtwork = (
  orderId: string,
  decoOrderId: string,
  timeZone: string,
  status: "approved" | "declined",
) => ({
  type: ORDER_ARTWORK_CHANGE_UPDATE,
  updatedOrderArtwork: {
    orderId,
    decoOrderId,
    timeZone,
    status,
  },
});
