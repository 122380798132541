import "./style.scss";

import { Box, Text } from "../../_components";
import { ReactComponent as ArrowDown } from "../assets/arrow-down.svg";
import { Table } from "../";
import React, { ReactElement, useMemo, useCallback } from "react";
import { animated, useSpring } from "react-spring";

export interface FoldableTable {
  head?: Object;
  row?: Array<Object>;
  isOpen?: boolean;
  onOpen?: (show: boolean) => void;
  rightHeader?: ReactElement | any;
  showMorePosition?: "flex-end" | "flex-start" | "center";
  border?: string;
  hidePagination?: boolean
}
export default ({
  head,
  row,
  isOpen = true,
  onOpen,
  rightHeader,
  showMorePosition = "flex-end",
  border = "1px solid #627D98",
  hidePagination = false,
  ...props
}: FoldableTable) => {
  const [show, setshow] = React.useState(isOpen);

  /**
   * for controlled gesture
   */
  useMemo(() => {
    if (typeof isOpen !== "undefined") setshow(isOpen);
  }, [isOpen]);

  const toggle = useCallback(() => {
    setshow(!show);
    onOpen && onOpen(show);
  }, [show]);

  const BODY_SHOW_HEIGHT = 130;
  const BODY_SHOW_Y = -20;
  const SHOW_ICON = -90;
  const BODY_SHOW_OPACITY = 1;
  const ZERO = 0;

  const { y, bodyY, bodyHeight, mainBodyHeight, o } = useSpring({
    from: {
      y: ZERO,
      bodyY: ZERO,
      bodyHeight: ZERO,
      o: show ? ZERO : BODY_SHOW_OPACITY
    },
    y: show ? SHOW_ICON : ZERO,
    bodyY: show ? BODY_SHOW_Y : ZERO,
    o: show ? ZERO : BODY_SHOW_OPACITY,
    bodyHeight: show ? ZERO : BODY_SHOW_HEIGHT,
    mainBodyHeight: show ? 100 : 200,
    config: { duration: 200 }
  });

  return (
    <animated.div
      style={{
        background: "transparent",
        padding: "8px",
        border: `${border}`,
        boxSizing: "border-box",
        borderRadius: "4px",
        height: mainBodyHeight.interpolate(
          height => `${height >= 130 ? "fit-content" : `${height}px`}`
        )
      }}
      className="foldable__container"
    >
      <Box
        d="flex"
        flexDirection="row"
        alignItems="flex-start"
        className="__header"
        width="100%"
      >
        <animated.div
          style={{
            transform: y.interpolate(
              // @ts-ignore
              y => `rotate(${y}deg)`
            ),
            paddingRight: "10px",
            cursor: "pointer"
          }}
          onClick={toggle}
        >
          <ArrowDown fill="#102A43" width={15} height={15} />
        </animated.div>

        <Box d="flex" flexDirection="row" width="100%">
          {rightHeader && rightHeader}
          <Box
            d="flex"
            alignItems="flex-end"
            justifyContent="flex-end"
            width="20%"
          >
            <Text
              textDecoration="underline"
              color="#006EE5"
              fontFamily="Inter"
              fontWeight={500}
              alignSelf={showMorePosition}
              cursor="pointer"
              userSelect="none"
              onClick={toggle}
            >
              {!show ? "Show less" : "Show more"}
            </Text>
          </Box>
        </Box>
      </Box>
      <animated.div
        style={{
          pointerEvents: show ? "none" : "all",
          transform: bodyY.interpolate(
            // @ts-ignore
            y => `translateY(${y}px)`
          ),
          height: bodyHeight.interpolate(
            height => `${height >= 130 ? "fit-content" : `${height}px`}`
          ),
          // @ts-ignore
          opacity: o.interpolate(
            [0.1, 0.2, 0.6, 1],
            // @ts-ignore
            [0, 0.1, 0.5, 1]
          ),
          // maxHeight: "200px",
          overflow: "auto",
          padding: "0.5rem",
          marginTop: o.interpolate(o => `${o + 0.5}rem`)
        }}
        className="--content"
      >
        <Table
          head={head}
          rows={row}
          thPadding="7px"
          tdBorderBottom="1px solid #BCCCDC"
          tdPaddingBottom="5px"
          emptyView={
            <Text paddingBottom="30px" fontFamily="Inter" fontWeight={500}>
              No item found
            </Text>
          }
          hidePagination={hidePagination}
          {...props}
        />
      </animated.div>
    </animated.div>
  );
};
