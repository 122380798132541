import { Box, BoxProps, Text } from "../../_components";
import { ReactComponent as Empty } from "../assets/message-icon.svg";
import React from "react";

export interface IMessageEmptyView extends BoxProps {
  message: string;
}
export default ({ message, ...props }: IMessageEmptyView) => (
  <Box
    d="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    {...props}
  >
    <Empty />
    <Text textAlign="center" width="35%">
      {message}
    </Text>
  </Box>
);
