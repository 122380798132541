import {
  APP_STATE_TYPE,
  DIRTY_TYPE,
  ModalConfirmStateType,
  RECENT_ORDER_TYPE,
} from "@samedaycustom/types/app";
import { CSSProperties, ReactElement } from "react";
import {
  ADD_TO_RECENT_DEL_ORDERS,
  ADD_TO_RECENT_ORDERS,
  ADD_TO_RECENT_PROD_ORDERS,
  DELETED,
  DELETING,
  ERROR,
  FETCHED,
  FETCHING,
  HIDE_MODAL,
  NAV_OPEN,
  ORDER_STATUS_RESET,
  ORDER_STATUS_UPDATED,
  REMOVE_FROM_RECENT_DEL_ORDERS,
  REMOVE_FROM_RECENT_ORDERS,
  REMOVE_FROM_RECENT_PROD_ORDERS,
  SET_APP_TITLE,
  SET_AUTO_LOGOUT,
  SET_CONNECTION_STATUS,
  SET_ISDIRTY,
  SET_LOADING,
  SET_PAGE_LOADING,
  SET_PREVIOUS_COMPONENT,
  SET_TARGET_LOCATION,
  SHOW_MODAL,
  SHOW_MODAL_CONFIRM,
  SUBMITTING_MODAL,
  SUCCESS,
  ORDER_DELIVERY_METHOD_UPDATED,
  ORDER_DUE_DATE_CHANGE_REQUEST_UPDATE,
  ORDER_ARTWORK_CHANGE_UPDATE,
} from "vendor/providers/actions/types";
import { APP_STATE } from "vendor/providers/initialState";

export interface ModalAction {
  confirm?: ModalConfirmStateType;
  modalType?: "success" | "danger" | "warning" | "text";
  duration?: number;
  message?: string;
  onClose?: (e: any) => void;
  component?: React.ReactNode | any;
  bodyStyle?: CSSProperties;
}

export interface APP_REDUCER_ACTION extends ModalAction {
  type: string;
  isLoading?: boolean;
  appLoading?: boolean;
  error?: string;
  deleteMessage?: string;
  changes?: any;
  navIsOpen?: boolean;
  targetLocation?: Record<string, unknown>;
  PreviousComponent?: ReactElement;
  dirty?: DIRTY_TYPE;
  condition?: boolean;
  order?: RECENT_ORDER_TYPE;
  appTitle?: string;
  connectionStatus?: boolean;
  updatedOrderStatus?: {
    orderId: string;
    status: string;
  };
  updatedOrderDeliveryMethod?: {
    orderId: string;
    deliveryMethod: string;
  };
  updatedOrderDueDate?: {
    orderId: string;
    decoOrderId: string;
    timeZone: string;
    status: "approved" | "declined";
    newDueDate: string;
  };
  updatedOrderArtwork?: {
    orderId: string;
    decoOrderId: string;
    timeZone: string;
    status: "approved" | "declined";
  };
}
export const appReducer = (state = APP_STATE, action: APP_REDUCER_ACTION): APP_STATE_TYPE => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case SET_PAGE_LOADING:
      return {
        ...state,
        appLoading: action.appLoading,
      };
    case DELETING:
      return {
        ...state,
        deleting: true,
        deleted: false,
      };
    case DELETED:
      return {
        ...state,
        deleting: false,
        deleted: true,
        deleteMessage: action.deleteMessage,
      };
    case FETCHING:
      return {
        ...state,
        done: false,
        error: false,
        fetching: true,
        fetched: false,
      };
    case FETCHED: {
      return {
        ...state,
        error: false,
        isLoading: false,
        fetching: false,
        fetched: true,
        errorMessage: "",
      };
    }
    case SUCCESS:
      return {
        ...state,
        done: true,
        successMessage: action.message,
        error: false,
        fetching: false,
        isLoading: false,
      };
    case ERROR:
      return {
        ...state,
        fetching: false,
        deleting: false,
        error: true,
        errorMessage: action.error,
      };
    case HIDE_MODAL:
      return {
        ...state,
        fetching: false,
        deleting: false,
        deleted: false,
        error: false,
        done: false,
        showModal: false,
        modalIsSubmitting: false,
        modal: {
          ...state.modal,
          confirm: {
            ...state.modal.confirm,
            isOpen: false,
            component: null,
            title: null,
            okText: null,
            cancelText: null,
          },
        },
      };

    case SUBMITTING_MODAL:
      return {
        ...state,
        modalIsSubmitting: action.condition,
      };

    case SHOW_MODAL:
      return {
        ...state,
        modal: {
          ...state.modal,
          modalType: action.modalType,
          message: action.message,
          onClose: action.onClose,
          component: action?.component,
          bodyStyle: action?.bodyStyle,
        },
        showModal: true,
        duration: action.duration,
      };
    case SHOW_MODAL_CONFIRM:
      return {
        ...state,
        modal: {
          ...state.modal,
          modalType: null,
          confirm: {
            isOpen: true,
            ...action.confirm,
          },
        },
        showModal: true,
        duration: action.duration,
      };
    case "CHANGES":
      return {
        ...state,
        valueChangesData: action.changes,
      };
    case NAV_OPEN:
      return {
        ...state,
        navIsOpen: action.navIsOpen,
      };

    case SET_ISDIRTY:
      return { ...state, dirty: action.dirty };

    case SET_TARGET_LOCATION:
      return {
        ...state,
        targetLocation: action.targetLocation,
      };
    case SET_PREVIOUS_COMPONENT:
      return {
        ...state,
        PreviousComponent: action.PreviousComponent,
      };

    case ADD_TO_RECENT_ORDERS: {
      // recent orders alteration process
      let newArray = [];
      if (state.recentOrders.length < 6) {
        if (
          state.recentOrders.filter((order) => order.orderId === action.order.orderId).length > 0
        ) {
          newArray = [...state.recentOrders];
        } else {
          newArray = [
            action.order,
            ...state.recentOrders.filter((order) => order.orderId !== action.order.orderId),
          ];
        }
      } else {
        if (
          state.recentOrders.filter((order) => order.orderId === action.order.orderId).length > 0
        ) {
          newArray = [...state.recentOrders];
        } else {
          newArray = [action.order, ...state.recentOrders.slice(0, 5)];
        }
        // find if the order already exists, if yes just rearrange the arrange
        // else take the first two of the the existing array and just add them to the array already containing the new order
      }
      return {
        ...state,
        recentOrders: [...newArray],
      };
    }
    case REMOVE_FROM_RECENT_ORDERS:
      return {
        ...state,
        recentOrders: [
          ...state.recentOrders.filter((order) => order.orderId !== action.order.orderId),
        ],
      };
    case ADD_TO_RECENT_PROD_ORDERS: {
      let newProdArray = [];
      if (state.recentProduced.length < 6) {
        if (
          state.recentProduced.filter((order) => order.orderId === action.order.orderId).length > 0
        ) {
          newProdArray = [...state.recentProduced];
        } else {
          newProdArray = [
            action.order,
            ...state.recentProduced.filter((order) => order.orderId !== action.order.orderId),
          ];
        }
      } else {
        if (
          state.recentProduced.filter((order) => order.orderId === action.order.orderId).length > 0
        ) {
          newProdArray = [...state.recentProduced];
        } else {
          newProdArray = [action.order, ...state.recentProduced.slice(0, 5)];
        }
        // find if the order already exists, if yes just rearrange the arrange
        // else take the first two of the the existing array and just add them to the array already containing the new order
      }
      return {
        ...state,
        recentProduced: [...newProdArray],
      };
    }
    case REMOVE_FROM_RECENT_PROD_ORDERS:
      return {
        ...state,
        recentProduced: [
          ...state.recentProduced.filter((order) => order.orderId !== action.order.orderId),
        ],
      };
    case ADD_TO_RECENT_DEL_ORDERS: {
      let newDelArray = [];
      if (state.recentDelivered.length < 6) {
        if (
          state.recentDelivered.filter((order) => order.orderId === action.order.orderId).length > 0
        ) {
          newDelArray = [...state.recentDelivered];
        } else {
          newDelArray = [
            action.order,
            ...state.recentDelivered.filter((order) => order.orderId !== action.order.orderId),
          ];
        }
      } else {
        if (
          state.recentDelivered.filter((order) => order.orderId === action.order.orderId).length > 0
        ) {
          newDelArray = [...state.recentDelivered];
        } else {
          newDelArray = [action.order, ...state.recentDelivered.slice(0, 5)];
        }
        // find if the order already exists, if yes just rearrange the arrange
        // else take the first two of the the existing array and just add them to the array already containing the new order
      }
      return {
        ...state,
        recentDelivered: [...newDelArray],
      };
    }

    case REMOVE_FROM_RECENT_DEL_ORDERS:
      return {
        ...state,
        recentDelivered: [
          ...state.recentDelivered.filter((order) => order.orderId !== action.order.orderId),
        ],
      };
    case SET_APP_TITLE:
      return {
        ...state,
        appTitle: action.appTitle,
      };
    case SET_CONNECTION_STATUS: {
      return {
        ...state,
        internetAccess: action.connectionStatus,
      };
    }
    case SET_AUTO_LOGOUT: {
      return {
        ...state,
        showAutoLogoutModal: true,
      };
    }
    case ORDER_STATUS_UPDATED:
      // eslint-disable-next-line no-case-declarations
      const orderStatus = {
        ...state.updatedOrderStatus,
        ...action.updatedOrderStatus,
      };
      return {
        ...state,
        updatedOrderStatus: orderStatus,
      };
    case ORDER_STATUS_RESET:
      return {
        ...state,
        updatedOrderStatus: {
          orderId: null,
          status: null,
        },
      };
    case ORDER_DELIVERY_METHOD_UPDATED: {
      const orderDeliveryMethod = {
        ...state.updatedOrderDeliveryMethod,
        ...action.updatedOrderDeliveryMethod,
      };
      return {
        ...state,
        updatedOrderDeliveryMethod: orderDeliveryMethod,
      };
    }
    case ORDER_DUE_DATE_CHANGE_REQUEST_UPDATE: {
      const orderDueDate = {
        ...state.updatedOrderDueDate,
        ...action.updatedOrderDueDate,
        dateDue: action.updatedOrderDueDate?.newDueDate ?? state.updatedOrderDueDate?.newDueDate,
      };
      return {
        ...state,
        updatedOrderDueDate: orderDueDate,
      };
    }
    case ORDER_ARTWORK_CHANGE_UPDATE: {
      const updatedOrder = {
        ...state.updatedOrderArtwork,
        ...action.updatedOrderArtwork,
      };
      return {
        ...state,
        updatedOrderArtwork: updatedOrder,
      };
    }
    default:
      return state;
  }
};
