import {
  SIGN_UP_ACTION,
  RESET_PASSWORD_ACTION,
  CHECK_EMAIL_AVAILABILITY,
} from "vendor/providers/actions/onboarding/actionTypes";
import {
  START_AUTH_ACTION,
  FAIL_AUTH_ACTION,
  FORGOT_PASSWORD_ACTION,
  ONBOARDING_STATE_RESET,
  LOGOUT_ACTION,
  SIGN_IN_ACTION,
  VERIFY_ACTION,
  CREATE_PASSWORD_ACTION,
  REVERIFY_ACTION,
} from "vendor/providers/actions/onboarding/actionTypes";
import { onBoardingState } from "../../initialState";
import { UPDATE_AUTH_EMAIL_NOT_CONFIRMED } from "../../actions/types";

export default (state = onBoardingState, action) => {
  switch (action.type) {
    case START_AUTH_ACTION:
      return {
        ...state,
        loading: true,
        type: action.payload,
        error: false,
        errorMessage: "",
        done: false,
      };
    case FAIL_AUTH_ACTION:
      return {
        ...state,
        loading: false,
        type: action.payload.type,
        error: true,
        errorMessage: action.payload.msg,
        done: false,
      };
    case LOGOUT_ACTION:
      return {
        ...state,
        loading: false,
        type: "",
        error: false,
        errorMessage: "",
        done: false,
      };
    case FORGOT_PASSWORD_ACTION:
      return {
        ...state,
        loading: false,
        type: FORGOT_PASSWORD_ACTION,
        done: true,
        confirmation: "FORGOT_PASSWORD",
      };
    case UPDATE_AUTH_EMAIL_NOT_CONFIRMED:
      return {
        ...state,
        type: UPDATE_AUTH_EMAIL_NOT_CONFIRMED,
        done: true,
      };
    case ONBOARDING_STATE_RESET:
      return {
        ...state,
        loading: false,
        type: "",
        done: false,
        error: false,
        errorMessage: "",
      };
    case SIGN_IN_ACTION:
      return {
        ...state,
        loading: false,
        type: SIGN_IN_ACTION,
        done: true,
        confirmation: "REGISTERATION",
      };
    case CREATE_PASSWORD_ACTION:
      return {
        ...state,
        loading: false,
        done: true,
        type: CREATE_PASSWORD_ACTION,
      };
    case SIGN_UP_ACTION:
      return {
        ...state,
        loading: false,
        done: true,
        type: SIGN_UP_ACTION,
        confirmation: "",
      };
    case VERIFY_ACTION:
      return {
        ...state,
        loading: false,
        done: true,
        type: VERIFY_ACTION,
      };
    case REVERIFY_ACTION:
      return {
        ...state,
        loading: false,
        done: true,
        type: REVERIFY_ACTION,
      };
    case RESET_PASSWORD_ACTION:
      return {
        ...state,
        loading: false,
        done: true,
        type: RESET_PASSWORD_ACTION,
        validEmail: false,
      };
    case CHECK_EMAIL_AVAILABILITY:
      return {
        ...state,
        loading: false,
        done: true,
        type: CHECK_EMAIL_AVAILABILITY,
        validMail: action.payload,
      };
    default:
      return state;
  }
};
