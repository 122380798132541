import "./style.scss";

import { ISDCOrderTimeLine } from "@samedaycustom/types/order/@types/timeline";
import React from "react";

import TlCard from "./tl-card";

interface Iprops {
  tl: Array<ISDCOrderTimeLine>;
  onReview: () => void;
  tzone?: string;
  isWhiteLabel?: boolean;
  primaryColor?: string;
}

const TL = ({ tl, onReview, tzone, isWhiteLabel, primaryColor }: Iprops) => {
  const trackLinksItems = React.useMemo(() => tl.filter(o => o?.trackLink), [
    tl
  ]);
  return (
    <div className="TL__Group">
      {tl.map((t, i) => (
        <TlCard
          key={i}
          tl={t}
          idx={i}
          active={i === tl.length - 1}
          trackLinks={trackLinksItems}
          onReview={onReview}
          tzone={tzone}
          isWhiteLabel={isWhiteLabel}
          primaryColor={primaryColor}
        />
      ))}
    </div>
  );
};

export default TL;
