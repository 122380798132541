import React from "react"
import ContentLoader from "react-content-loader"

interface Props {
    color?: string
    backgroundColor?: string
    speed?: number
    width?: number
    height?: number
}
const ProfileLoader = ({color, backgroundColor, height, speed, width}: Props) => (
    <ContentLoader
        speed={speed || 2}
        width={width || 400}
        height={height || 160}
        viewBox={`0 0 ${width || "400 "} ${height || "160"}`}
        backgroundColor={backgroundColor || "#f3f3f3"}
        foregroundColor={color || "#00ffff"}
    >
        <rect x="65" y="12" rx="3" ry="3" width="88" height="6" />
        <rect x="65" y="30" rx="3" ry="3" width="52" height="6" />
        <rect x="0" y="64" rx="3" ry="3" width="410" height="6" />
        <rect x="0" y="80" rx="3" ry="3" width="380" height="6" />
        <rect x="0" y="96" rx="3" ry="3" width="178" height="6" />
        <circle cx="30" cy="30" r="30" />
    </ContentLoader>
)

export default ProfileLoader
