import { OptionType } from "vendor/pages/Settings/Store/PrintingMethod";

import { IPrintingMethodState } from "@samedaycustom/types/app/Settings";
import { printingMethodState } from "./initialstate";
import {
  UPDATE_PRINTING_METHODS,
  SWITCH_PRINTING_METHOD,
} from "vendor/providers/actions/App/Settings/Store/PrintingMethod/types";
export const printingMethod = (
  state = printingMethodState,
  action: {
    type: string;
    data?: IPrintingMethodState;
    id?: number;
    values: { value: OptionType; formValues: IPrintingMethodState };
  }
): any => {
  switch (action.type) {
    case UPDATE_PRINTING_METHODS:
      return {
        ...state,
        ...action.data,
      };

    case SWITCH_PRINTING_METHOD: {
      const value = action.values.value;
      const formValues = action.values.formValues;
      const element = {
        ...state,
        ...formValues,
        [`${value.id}`]: !state[`${value.id}`],
      };
      return element;
    }
    default:
      return state;
  }
};
