import { deliveryMethodState } from "./initialstate";
import { DELIVERY_METHODS_TYPE } from "@samedaycustom/types/app/Settings";
import { UPDATE_DELIVERY_METHODS_STATE } from "vendor/providers/actions/App/Settings/Store/DeliveryMethod/types";

export const deliveryMethod = (
  state = deliveryMethodState,
  action: {
    type: string;
    data?: DELIVERY_METHODS_TYPE;
  }
): DELIVERY_METHODS_TYPE => {
  switch (action.type) {
    case UPDATE_DELIVERY_METHODS_STATE:
      return action.data ? action.data : deliveryMethodState;
    default:
      return state;
  }
};
