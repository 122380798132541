import { Text } from "@samedaycustom/core-ui";
import { Fallback, NotFound, useModal } from "@samedaycustom/core-ui";
import { componentLoader } from "@samedaycustom/helpers";
import { useToast } from "@samedaycustom/hooks";
import { APP_STATE_TYPE, AUTH_STATE_TYPE } from "@samedaycustom/types/app";
import React, { lazy, Suspense, useEffect, useState } from "react";
import { DefaultRootState, useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Prompt, Route, Switch } from "react-router-dom";
import AnalyticsWrapper from "vendor/components/Hubspot";
import { PageEvent, PageEvents } from "vendor/models/Page";
import { leavePageEvent } from "vendor/modules/events";
import CreatePasswordPage from "vendor/pages/Onboarding/Create-Password";
import ResetPassword from "vendor/pages/Onboarding/ForgotPassword/ResetPassword";
import TCSpage from "vendor/pages/TCS";
import { setIsDirty } from "vendor/providers/actions/App";
import PrivateRoute from "vendor/routes/PrivateRoute";

import RouteValidator from "../components/RouteValidator";
import ProtectedRoute from "./ProtectedRoute";
import {
  CONFIRMATION,
  CONFIRM_REGISTERATION,
  CONTACT,
  CREATE_PASSWORD,
  FAQ,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_RESET,
  HOMEPAGE,
  RESEND_CONFIRMATION,
  SIGN_IN_PAGE,
  SIGN_UP_PAGE,
  TCS,
} from "./screen";

// import ForgotPassword from "vendor/pages/ForgotPassword"
const ConfirmationPage = lazy(() =>
  componentLoader(() => import("vendor/pages/Onboarding/Confirmation"), 3)
);
const singleVendorApplication = lazy(() => componentLoader(() => import("./Application"), 3));
const Signup = lazy(() => componentLoader(() => import("vendor/pages/Onboarding/SignUp"), 3));
const SignIn = lazy(() => componentLoader(() => import("vendor/pages/Onboarding/SignIn"), 3));
const ResendConfirmation = lazy(() =>
  componentLoader(() => import("vendor/pages/Onboarding/ResendConfirmation"), 3)
);
const ForgotPassword = lazy(() =>
  componentLoader(() => import("vendor/pages/Onboarding/ForgotPassword"), 3)
);

const RegisterationConfirmed = lazy(() =>
  componentLoader(() => import("vendor/pages/Onboarding/ConfirmRegisteration"), 3)
);

const LandingPage = lazy(() => componentLoader(() => import("vendor/pages/Landing"), 3));

const FaqPage = lazy(() => componentLoader(() => import("vendor/pages/Landing/FAQs"), 3));

const ContactPage = lazy(() => componentLoader(() => import("vendor/pages/Landing/Contact"), 3));

const MainRoute = () => {
  const { dirty, showAutoLogoutModal } = useSelector<DefaultRootState, APP_STATE_TYPE>(
    (state) => state["app"]
  );
  const state = useSelector<DefaultRootState, AUTH_STATE_TYPE>((state) => state["auth"]);

  const modal = useModal();
  const dispatch = useDispatch();
  const [showCon, setShow] = useState(false);

  const [showConfirmation, setShowConfirmation] = useState<PageEvent>({
    action: "CLEAN",
    data: null,
  });

  const toast = useToast();

  const authed = React.useMemo(() => {
    return state.token && state.emailConfirmed ? true : false;
  }, [state]);

  const isDirty = React.useMemo(() => {
    return dirty.isDirty || showConfirmation.action === "DIRTY";
  }, [showConfirmation, dirty]);

  useEffect(() => {
    if (showAutoLogoutModal) {
      toast({
        title: "Account Updates",
        description:
          "Some changes have been made to your account, we are logging you out and you can log back in.",
        position: "bottom-left",
        status: "error",
        duration: 7000,
        isClosable: true,
      });
    }
  }, [showAutoLogoutModal]);

  useEffect(() => {
    leavePageEvent.subscribe(async ({ action, ...payload }) => {
      switch (action) {
        case PageEvents.DIRTY:
          {
            const leavePagePayload: PageEvent = { action, ...payload };

            setShowConfirmation(leavePagePayload);

            window.leavePagePayload = leavePagePayload;
          }
          break;

        case PageEvents.CLEAN:
          {
            const leavePagePayload: PageEvent = {
              action: "CLEAN",
              data: null,
            };

            setShowConfirmation(leavePagePayload);

            window.leavePagePayload = leavePagePayload;
          }
          break;

        default:
          break;
      }
    });
  }, []);

  // @depreciated
  useEffect(() => {
    if (dirty.isDirty && showCon) {
      modal.form({
        title: "You have unsaved changes",
        cancelText: "Discard Changes",
        okText: "Save Changes",

        cancelOnClick: () => {
          dirty?.setValues(dirty.initialValues);
          dispatch(setIsDirty({ isDirty: false }));
          setShow(false);
          modal.hide();
        },
        okOnClick: async (_values, helpers) => {
          const result = await dirty.handleSubmit(dirty.values, helpers);
          dispatch(setIsDirty({ isDirty: false }));
          setShow(false);
          if (result) modal.hide();
        },
        component: (
          <Text>
            You are about to leave this page without saving your changes. Are you sure you want to
            leave the page?
          </Text>
        ),
      });
    }
  }, [dirty, showCon]);

  async function submitConfirmation(confirmation: PageEvent) {
    if (typeof confirmation?.submit === "function") {
      const result = await confirmation?.submit(confirmation.data);
      if (result) {
        leavePageEvent.next({
          action: "CLEAN",
          data: null,
        });
      }

      return result;
    }
  }

  async function cancelConfirmation(confirmation: PageEvent) {
    if (typeof confirmation?.cancel === "function") {
      await confirmation.cancel();
      leavePageEvent.next({
        action: "CLEAN",
        data: null,
      });

      modal.hide();
    }
  }

  function getUserConfirmation(_message: string, callback: (ok: boolean) => void) {
    setShow(true);
    modal.form({
      title: "You have unsaved changes",
      cancelText: "Discard Changes",
      okText: "Save Changes",

      cancelOnClick: async () => {
        if ("leavePagePayload" in window) {
          await cancelConfirmation(window.leavePagePayload);
          callback(false);
        }
      },

      okOnClick: async (_, helpers) => {
        if ("leavePagePayload" in window) {
          helpers.setSubmitting(true);
          const result = await submitConfirmation(window.leavePagePayload);

          helpers.setSubmitting(false);

          if (result) {
            callback(true);
            modal.hide();
          }
        }
      },
      component: (
        <Text>
          You are about to leave this page without saving your changes. Are you sure you want to
          leave the page?
        </Text>
      ),
    });
  }

  return (
    <BrowserRouter getUserConfirmation={getUserConfirmation}>
      <Prompt when={isDirty} message="message" />
      <AnalyticsWrapper>
        <Suspense fallback={<Fallback />}>
          <RouteValidator>
            <Switch>
              <ProtectedRoute
                strict
                exact
                authed={authed}
                path={HOMEPAGE}
                component={LandingPage}
              />
              <ProtectedRoute strict exact authed={authed} path={FAQ} component={FaqPage} />
              <ProtectedRoute strict exact authed={authed} path={CONTACT} component={ContactPage} />
              <ProtectedRoute strict exact authed={authed} path={SIGN_IN_PAGE} component={SignIn} />
              <ProtectedRoute
                authed={authed}
                path={CONFIRM_REGISTERATION}
                component={RegisterationConfirmed}
              />
              <ProtectedRoute authed={authed} path={SIGN_UP_PAGE} component={Signup} />
              <ProtectedRoute
                authed={authed}
                path={FORGOT_PASSWORD}
                exact
                component={ForgotPassword}
              />
              <ProtectedRoute
                authed={authed}
                path={CONFIRMATION}
                exact
                component={ConfirmationPage}
              />
              <ProtectedRoute
                authed={authed}
                path={`${FORGOT_PASSWORD_RESET}`}
                component={ResetPassword}
              />
              <ProtectedRoute
                authed={authed}
                path={CREATE_PASSWORD}
                component={CreatePasswordPage}
              />
              <ProtectedRoute authed={authed} path={TCS} component={TCSpage} />
              <ProtectedRoute
                authed={authed}
                path={RESEND_CONFIRMATION}
                component={ResendConfirmation}
              />
              <PrivateRoute authed={authed} component={singleVendorApplication} />
              <Route path={"*"} exact component={NotFound} />
            </Switch>
          </RouteValidator>
        </Suspense>
      </AnalyticsWrapper>
    </BrowserRouter>
  );
};

export default MainRoute;
