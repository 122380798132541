import React from 'react'
import '../style.scss'


interface Props {
    TotalNo: string
    children: React.ReactNode
}


export default ({TotalNo, children}: Props) => {
    return (
        <div className="dashBoardTile">
            <div className="main">
                <h2>
                    { TotalNo }
                </h2>
            </div>
            {children}
        </div>
    )
}

