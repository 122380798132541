import {
  SETUP_ACTION_TYPES,
  START_SETUP_ACTION,
  FAIL_SETUP_ACTION,
  GET_SETUP_STATUS_ACTION,
  SETUP_STATE_RESET,
} from "vendor/providers/actions/SetupState/actionTypes";
import { welcomeState } from "../../initialState";
import { WELCOME_PAGE_STATE } from "@samedaycustom/types/app";
export default (state = welcomeState, action: SETUP_ACTION_TYPES): WELCOME_PAGE_STATE => {
  switch (action.type) {
    case START_SETUP_ACTION:
      return {
        ...state,
        loading: true,
        type: action.payload,
        error: false,
        errorMessage: "",
        fetch: false,
        done: false,
      };
    case FAIL_SETUP_ACTION:
      return {
        ...state,
        done: false,
        loading: false,
        error: true,
        errorMessage: action.payload.msg,
        type: action.payload.type,
      };
    case GET_SETUP_STATUS_ACTION:
      return {
        ...state,
        done: true,
        type: GET_SETUP_STATUS_ACTION,
        loading: false,
        error: false,
        errorMessage: "",
        setupData: action.payload,
      };
    case SETUP_STATE_RESET:
      return {
        ...state,
        done: false,
        loading: false,
        error: false,
        errorMessage: "",
        type: "",
      };
    default:
      return state;
  }
};
