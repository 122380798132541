import React from "react";
import { Button, IconButton, Text } from "../../_components";
import { ReactComponent as Check } from "@samedaycustom/assets/images/artwork-check.svg";
import { ReactComponent as Upload } from "@samedaycustom/assets/images/artwork-upload.svg";
import { ReactComponent as TickUp } from "@samedaycustom/assets/images/artwork-tick-up.svg";

export interface ArtworkVersionProp {
  version: "original" | "uploaded" | "awaiting" | "approved";
  onClick: () => void;
}
const ArtworkVersion: React.FC<ArtworkVersionProp> = props => {
  const composeVersion = React.useMemo(() => {
    switch (props.version) {
      case "original":
        return {
          label: "Original artwork",
          background: "#E3F9E5",
          color: "#0F8613",
          iconBackground: "#C1F2C7",
          icon: <Check />
        };
      case "uploaded":
        return {
          label: "Uploaded",
          background: "#E5F1FF",
          iconBackground: "#B2D7FF",
          color: "#007BFF",
          icon: <Upload />
        };
      case "awaiting":
        return {
          label: "Awaiting approval",
          background: "#E5F1FF",
          iconBackground: "#B2D7FF",
          color: "#007BFF",
          whitespace: "normal",
          icon: <TickUp />
        };
      case "approved":
        return {
          label: "Approved",
          background: "#E3F9E5",
          color: "#0F8613",
          iconBackground: "#C1F2C7",
          icon: <Check />
        };
      default:
        break;
    }
  }, [props.version]);
  return (
    <Button
      display="inline-flex"
      background={composeVersion.background}
      borderRadius="48px"
      padding="5px 10px 5px 5px"
      width="fit-content"
      height="fit-content"
      alignItems="center"
      _hover={{
        background: composeVersion.background,
        outlineColor: composeVersion.iconBackground
      }}
      _active={{
        background: composeVersion.background,
        borderColor: composeVersion.iconBackground,
        outlineColor: "#000"
      }}
      _focus={{
        boxShadow: `0 0 0 3px ${composeVersion.iconBackground}`
      }}
      onClick={props.onClick}
    >
      <IconButton
        aria-label={composeVersion.label}
        background={composeVersion.iconBackground}
        icon={composeVersion.icon}
        height="28px"
        width="28px"
        minWidth="28px"
        borderRadius="50%"
      />
      <Text
        paddingLeft="5px"
        fontWeight={500}
        fontSize="14px"
        color={composeVersion.color}
        whiteSpace={composeVersion?.whitespace || "unset"}
      >
        {composeVersion.label}
      </Text>
    </Button>
  );
};

export default ArtworkVersion;
