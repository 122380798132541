import React from "react";
import { IRowTag, RowTag } from "@samedaycustom/core-ui";


export type TagType =
  | "waiting_on_customer"
  | "waiting_designer"
  | "contacted"
  | "negotiation"
  | "abandoned"
  | "purchased"
  | "unassigned";
  
    
const CartStatusTag = ({ type }: { type: TagType }) => {
    let tagProps: IRowTag = { text: 'Unassigned' }
    
    //build tagprops
    switch (type) {
        case "abandoned":
            tagProps = { text: "Abandoned", backgroundColor: "#FFE3E3", color: "#E12D39", isTag: true }
            break;
        case "contacted":
            tagProps = { text: "Contacted", backgroundColor: "#E5F1FF", color: "#007BFF", isTag: true }
            break;
        case "negotiation":
            tagProps = { text: "Negotiation", backgroundColor: "#E5F1FF", color: "#007BFF", isTag: true }
            break;
        case "purchased":
            tagProps = { text: "Purchased", color: "#31B237", backgroundColor: "#E3F9E5", isTag: true }
            break;
        case "waiting_designer":
            tagProps = { text: "Waiting Designer", backgroundColor: "#F0F4F8", color: "#07121D", isTag: true }
            break;
        case "waiting_on_customer":
            tagProps = { text: "Waiting On Customer", backgroundColor: "#F0F4F8", color: "#07121D", isTag: true }
            break;
        default:
            tagProps = { text: "Unassigned", backgroundColor: "#F7C948", color: "#07121D", isTag: true }
            break;
    }

    return (
        <RowTag {...tagProps} />
    )
}

export default CartStatusTag;
