import "./style.scss";

import { Box, ChakraTag as Tag, TagLeftIcon, TagLabel } from "../../_components";
import { useToast } from "@samedaycustom/hooks";
import { ReactComponent as Download } from "../assets/download-outline-o.svg";
import React from "react";
import Popup from "reactjs-popup";

export default React.memo(
  ({
    thumbnail,
    label,
    downloadFile,
    onDelete,
    onDownload,
    willDelete
  }: {
    proof?: string;
    thumbnail: string;
    downloadFile: string;
    label: string;
    loading?: boolean;
    onDelete?: (e?: any) => void;
    onDownload?: (e?: any) => void;
    willDelete?: boolean;
  }) => {
    const toast = useToast();
    return (
      <Box position="relative">
        {willDelete && (
          <Box
            position="absolute"
            right="0"
            top="5px"
            width="fit-content"
            onClick={onDelete}
            zIndex={1}
            cursor="pointer"
          >
            <Tag size={"sm"} variantColor="#FFE3E3" background="#FFE3E3">
              <TagLeftIcon
                color="#CF1124"
                fontWeight="bold"
                icon="small-close"
                size="19px"
                marginRight="0"
                marginTop="-1px"
              />
              <TagLabel
                fontWeight="bold"
                fontFamily="Inter"
                textTransform="uppercase"
                color="#CF1124"
              >
                Delete
              </TagLabel>
            </Tag>
          </Box>
        )}
        <Popup
          trigger={
            <img src={thumbnail} alt={label} aria-label={label} role="image" />
          }
          arrow={false}
          position="center center"
          on="hover"
          contentStyle={{
            width: "120px",
            height: "120px",
            background: "rgba(51, 78, 104, 0.32)",
            borderRadius: "8px",
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "flex-end"
          }}
          className="thumbNailMobile"
        >
          <Box
            background="#fff"
            d="flex"
            justifyContent="center"
            alignItems="center"
            boxShadow="0px 6px 18px rgba(98, 125, 152, 0.24)"
            borderRadius="4px"
            padding="4px"
            onClick={(e: any) =>
              downloadFile
                ? onDownload(downloadFile)
                : toast({
                    title: "No production file found for download",
                    position: "bottom-left",
                    duration: 3000,
                    status: "info"
                  })
            }
          >
            <Download fill="#000" style={{ cursor: "pointer" }} />
          </Box>
        </Popup>
      </Box>
    );
  }
);
