import "./style.scss";

import AButton from "@atlaskit/button";
import Popup from "@atlaskit/popup";
import { OptionType, SelectProps } from "@atlaskit/select";
import Spinner from "@atlaskit/spinner";
import { Box, Text } from "../../_components";
import React, { useState } from "react";
import Style from "style-it";

import { ReactComponent as ArrowDown } from "../assets/arrowdown.svg";

// import {Select, SelectProps, Box, Text, Button} from "../../_components"
// import React from "react"
// import Popup from "reactjs-popup"
export type SelectValue = { value: string; label: string };
//@ts-ignore
interface Props extends SelectProps<OptionType> {
  doubleIcon?: boolean;
  isLoading?: boolean;
  children?: any;
  value?: SelectValue;
  background?: string;
  color?: string;
  indicatorColor?: string;
  borderRadius?: string;
  width?: string;
  height?: string;
  onChange?: (e: SelectValue) => void;
}
export default ({
  children,
  doubleIcon,
  isLoading,
  background,
  color,
  border,
  borderRadius,
  width,
  height,
  onChange,
  value: originalValue,
  indicatorColor = "#627D98",
  placeholder,
  isDisabled,
  ...props
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState<SelectValue>(
    originalValue || { label: null, value: null }
  );
  const onValueChange = (value: SelectValue) => {
    setIsOpen(false);
    setValue(value);
    onChange && onChange(value);
  };

  /** select value */
  React.useMemo(() => {
    const child = Array.isArray(children)
      ? children
      : (typeof children === "object" &&
          !Array.isArray(children) &&
          Array.from([
            {
              props: {
                children: children.props["children"],
                value: children.props["value"]
              }
            }
          ])) ||
        [];
    if (originalValue && child) {
      const childValue = child.find(
        el => el.props.value === originalValue.value
      );
      if (childValue)
        setValue({
          label: childValue.props.children,
          value: childValue.props.value
        });
    }
  }, [children, originalValue]);

  /** options html element */
  const options = React.useMemo(() => {
    // console.log(children)
    const child = Array.isArray(children)
      ? children
      : (typeof children === "object" &&
          !Array.isArray(children) &&
          Array.from([
            {
              props: {
                children: children.props["children"],
                value: children.props["value"],
                ...children.props
              }
            }
          ])) ||
        [];

    return child.map((el, index) => (
      <option
        key={index}
        value={el.props.value}
        className="---item"
        onClick={() =>
          onValueChange({
            value: el.props.value,
            label: el.props.children
          })
        }
        {...el.props}
      >
        {el.props.children}
      </option>
    ));
  }, [children, value]);

  const DropdownIndicator = ({ color }) => {
    return (
      <Box
        d="flex"
        flexDirection={isLoading ? "row" : "column"}
        alignItems="center"
        paddingLeft={isLoading ? "5px" : "0px"}
      >
        {isLoading ? (
          <Spinner size="small" />
        ) : (
          <>
            {doubleIcon && (
              <ArrowDown
                height={8}
                width={8}
                style={{
                  fill: `${color || "#868686"}`,
                  transform: "rotate(180deg)",
                  marginBottom: 2
                }}
              />
            )}
            <ArrowDown
              style={{ marginLeft: "5px" }}
              height={8}
              width={8}
              fill={`${color || "#868686"}`}
            />
          </>
        )}
      </Box>
    );
  };
  const Content = () => (
    <Box fontFamily="Inter" fontWeight="normal" className="selectTag">
      <Text
        className="---item"
        isTruncated
        style={{
          color: "#829AB1",
          textTransform: "uppercase",
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: "11px",
          cursor: "default"
        }}
      >
        Set Internal Status
      </Text>
      {options}
    </Box>
  );
  return Style.it(
    `
            .selectTag__container .__select {
                height:  ${height || "2.2rem"};
                display:flex;
                justify-content:space-between;
            }
            .selectTag__container .__select button{
                height:inherit;
            }
            .selectTag__container .__select .__select-btn{
                padding-right: 15px;
                 background: ${background || "initial"};
                height:  ${height || "2.2rem"};
                width: ${"100%"};
                border-color: #9fb3c8;
                border: ${border || "1px solid #9fb3c8"};
                border-radius: ${borderRadius || "3px"};
                display:flex;
                justify-content:space-between;
            }
            .selectTag__container .__select span{
                color:${color || "initial"};
                display: flex;
                justify-content: space-between;
                width: 100%;
            }
         `,

    <Box className="selectTag__container">
      <Popup
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        content={Content}
        placement="bottom-start"
        trigger={triggerProps => (
          <Box
            className="__select"
            height="20px"
            onClick={() => !isDisabled && setIsOpen(true)}
          >
            <AButton
              {...triggerProps}
              onClick={() => !isDisabled && setIsOpen(true)}
              className="__select-btn"
              width="100%"
            >
              <Text
                fontFamily="Inter"
                fontWeight={500}
                color="inherit"
                width="inherit"
                textAlign="left"
                fontSize="13px"
                isTruncated
              >
                {value.label || placeholder || "Select..."}
              </Text>
            </AButton>
            <Box
              onClick={() => !isDisabled && setIsOpen(true)}
              position="relative"
              alignItems="center"
              d="flex"
            >
              <Box
                position="absolute"
                right="5px"
                top={isLoading ? "8px" : "12px"}
                bottom="0"
                height="100%"
              >
                <DropdownIndicator color={indicatorColor} />
              </Box>
            </Box>
          </Box>
        )}
      />
    </Box>
  );
};
