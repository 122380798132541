import { createSlice, current, PayloadAction } from "@reduxjs/toolkit";
import { ISDCLine } from "@samedaycustom/types/order/@types/line";
import { orderBy } from "lodash";
import { ActionStatus } from "types/app/Messaging";
import { getOrderLine, getOrderLines } from "./async/index";

export interface OrderLineState {
  line: {
    [key: string]: {
      data: ISDCLine;
      status: ActionStatus;
      error: any;
    };
  };
  /**
   * @deprecated
   * @description - use state.operationsOrders.lineItems instead
   */
  lines: {
    data: ISDCLine[];
    status: ActionStatus;
    error: any;
  };
}
const initialState: OrderLineState = {
  line: {},
  lines: {
    data: [],
    status: "idle",
    error: null,
  },
};
// @deprecated
const orderLineSplice = createSlice({
  name: "orderLine",
  initialState,
  reducers: {
    updateOrderLine(state, action: PayloadAction<ISDCLine>) {
      if (action?.payload?.id) {
        state.line = { ...state.line };
      } else {
        const newPayload = {
          [action.payload.id]: {
            data: action.payload,
            status: "idle" as ActionStatus,
            error: null,
          },
        };
        state.line = { ...newPayload, ...state.line };
      }
    },
    resetOrderLine(state) {
      state.line = { ...initialState.line };
    },
  },
  extraReducers: {
    // single line
    [getOrderLine.pending]: (state, action) => {
      const lineId = action?.meta?.arg?.line_id;
      if (current(state.line)?.hasOwnProperty(lineId)) state.line[lineId].status = "loading";
    },

    [getOrderLine.fulfilled]: (state, action) => {
      const lineId = action?.meta?.arg?.line_id;
      if (current(state.line)?.hasOwnProperty(lineId)) {
        state.line[lineId].status = "succeeded";
        state.line[lineId].data = action.payload;
      }
    },
    [getOrderLine.rejected]: (state, action) => {
      const lineId = action?.meta?.arg?.line_id;
      if (current(state.line)?.hasOwnProperty(lineId)) {
        state.line[lineId].status = "failed";
        state.line[lineId].error = action.error.message;
      }
    },

    /**
     * all lines
     */
    [getOrderLines.pending]: (state, action) => {
      const hideLoader = action?.meta?.arg?.hideLoader;
      if (!hideLoader) state.lines.status = "loading";
    },

    [getOrderLines.fulfilled]: (state, action) => {
      state.line = initialState.line;
      state.lines.status = "succeeded";
      let i = 0;
      let lineitems = orderBy(
        action.payload,
        function(e) {
          const sortKey = e.items[i]?.size;
          i++;
          return sortKey;
        },
        ["desc"]
      ) as ISDCLine[];

      lineitems = lineitems.map((el) => {
        const items = orderBy(
          el?.items,
          function(e) {
            return e?.size;
          },
          ["asc"]
        );
        return { ...el, items };
      });

      state.lines.data = lineitems;
    },
    [getOrderLines.rejected]: (state, action) => {
      state.lines.status = "failed";
      state.lines.error = action.error.message;
    },
  },
});

export const { updateOrderLine, resetOrderLine } = orderLineSplice.actions;
/**
 * @deprecated
 * @description - use state.operationsOrders.lineItems instead
 */
export const orderLineSelector = (state) => state[orderLineSplice.name] as OrderLineState;
/**
 * @deprecated
 * @description - use state.operationsOrders.lineItems instead
 */
export default orderLineSplice.reducer;
