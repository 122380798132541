import { ProductionSpeedRushType } from "types/app/Settings";

export const GET_PRODUCTION_SPEED = "GET_PRODUCTION_SPEED";
export const UPDATE_PRODUCTION_SPEED = "UPDATE_PRODUCTION_SPEED";
export const EDIT_PRODUCTION_SPEED = "EDIT_PRODUCTION_SPEED";
export const SWITCH_PRODUCTION_ORDER = "SWITCH_PRODUCTION_ORDER";
export const DELETE_PRODUCTION_SPEED = "DELETE_PRODUCTION_SPEED";
export const DELETING_PRODUCTION_SPEED = "DELETE_PRODUCTION_SPEED";
export const PRODUCTION_SPEED_UPDATE_FAILED = "PRODUCTION_SPEED_UPDATE_FAILED";
export const FAILED_PRODUCTION_SPEED = "FAILED_PRODUCTION_SPEED";
export const TOGGLE_PRODUCTION_SPEED_ERROR = "TOGGLE_PRODUCTION_SPEED_ERROR";

export type ProductionSpeed = {
  id: string | number;
  maxQty: number;
  minQty: number;
  time: number;
  upperBound: number;
  timeUnit?: "hour" | "minute";
  superRushOrder: ProductionSpeedRushType;
  rushOrder: ProductionSpeedRushType;
  isDefault: boolean;
};
