import * as PusherPushNotifications from "@pusher/push-notifications-web";
import fetch from "cross-fetch";
import dotenv from "dotenv";
import Pusher, { Channel } from "pusher-js";
import * as api from "vendor/api/Api.constants";
import { isLocal } from "../environment";
import { logger } from "../plugins/logging";

dotenv.config();

Pusher.logToConsole = isLocal();

const authorizer = (
  channel,
  _options
): {
  authorize: (socketId: any, callback: any) => void;
} => {
  return {
    authorize: (socketId, callback) => {
      fetch(api.default.PUSHER_AUTH, {
        method: "POST",
        headers: new Headers({ "Content-Type": "application/json" }),
        body: JSON.stringify({
          socket_id: socketId,
          channel_name: channel.name,
        }),
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error(`Received ${res.status} from ${api.default.PUSHER_AUTH}`);
          }
          return res.json();
        })
        .then((data) => {
          callback(null, data);
        })
        .catch((err) => {
          callback(new Error(`Error calling auth endpoint: ${err}`), {
            auth: "",
          });
        });
    },
  };
};

export const pusher = new Pusher(process.env.REACT_APP_PUSHER_API, {
  cluster: process.env.REACT_APP_PUSHER_CLUSTER || "us2",
  authorizer,
  pongTimeout: 100,
});

let pusher_beam: PusherPushNotifications.Client = null;

try {
  pusher_beam = new PusherPushNotifications.Client({
    instanceId: process.env.REACT_APP_PUSHER_BEAM_INSTANCE_ID,
  });
} catch (error) {
  logger.error(error);
}

export { pusher_beam, Channel };
