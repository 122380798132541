import {
  OrderListPaginationInfo,
  OPERATION_LIST_STATE,
  ReportAggregateInfo,
} from "@samedaycustom/types/app/Operations";

export const FETCH_OPERATION_ORDERS_REPORTS = "FETCH_OPERATION_ORDERS_REPORTS";
export const FETCHING_OPERATION_ORDERS_REPORTS = "FETCHING_OPERATION_ORDERS_REPORTS";
export const FAILED_OPERATION_ORDERS_REPORTS = "FAILED_OPERATION_ORDERS_REPORTS";

export interface IReportData {
  accepted: number;
  dateDue: string;
  dateFulfilled: string;
  dateOrdered: string;
  fulfilled: number;
  orderID: string;
  storeID: string;
  vendorID: string;
  customerEmail: string;
  orderPrice: string;
  accuracy: number;
  rating: number;
}
export interface REPORT_DATA_STATE extends OPERATION_LIST_STATE<IReportData> {
  metadata: ReportAggregateInfo;
}

export interface REPORT_STATE {
  reports: Partial<REPORT_DATA_STATE>;
}
export type REPORT_LIST_ACTION<T> = {
  type: typeof FETCH_OPERATION_ORDERS_REPORTS;
  metadata: ReportAggregateInfo;
  loading?: boolean;
  error: boolean;
  errorMessage: string;
  data?: T[];
};
