// recieves an input of the user's access list
import { ACCESS_LIST } from "vendor/helpers/constants/permissions";
import * as screen from "vendor/routes/screen";
// recieves the path that the user is trying to access
export default (isAdmin: any, accessList: string[], path: string) => {
  if (!isAdmin && path.includes(screen.WELCOME_PAGE)) return false;

  if (isAdmin) return true;

  if (!Array.isArray(accessList)) return false;

  let pathCategory = null;
  if (path.includes(screen.USERS_AND_PERMISSIONS)) pathCategory = ACCESS_LIST.USER_AND_PERMISSIONS;
  else if (path.includes(screen.STORE_LOCATION)) pathCategory = ACCESS_LIST.LOCATIONS;
  else if (path.includes(screen.OPERATION)) pathCategory = ACCESS_LIST.OPERATIONS;
  else if (path.includes(screen.SETTINGS_PAGE)) pathCategory = ACCESS_LIST.SETTINGS;
  else if (path.includes(screen.DASHBOARD)) pathCategory = ACCESS_LIST.DASHBOARD;
  else if (path.includes(screen.BUSINESS_PROFILE)) pathCategory = ACCESS_LIST.BUSINESS_PROFILE;
  else if (path.includes(screen.MESSAGE_PAGE)) pathCategory = ACCESS_LIST.MESSAGES;
  else if (path.includes(screen.REPORTS)) pathCategory = ACCESS_LIST.REPORTS;

  if (!pathCategory) return true;

  return accessList?.includes(pathCategory);
};

/**
 * returns pages that needs to be rendered first after login if they have access to the page
 * @param accessList
 * @returns
 */
export const travseToAccessibleRoute = (accessList: string[]) => {
  if (!Array.isArray(accessList)) return null;

  // Dashboard
  // Orders
  // Production
  // Delivery
  if (accessList.includes(ACCESS_LIST.OPERATIONS)) return screen.OPERATION_ORDERS;
  if (accessList.includes(ACCESS_LIST.DASHBOARD)) return screen.DASHBOARD;

  return screen.WELCOME_PAGE;
};
