import {
  AccordionButton as ChakraAccordionButton,
  AccordionButtonProps,
  Accordion as ChakraAccordion,
  AccordionProps,
  AccordionItem as ChakraAccordionItem,
  AccordionItemProps,
  AccordionPanel as ChakraAccordionPanel,
  AccordionPanelProps,
} from "@chakra-ui/react";
import React from "react";

const AccordionButton = ({ ...props }: AccordionButtonProps) => {
  return <ChakraAccordionButton {...props} />;
};

const Accordion = ({ ...props }: AccordionProps) => {
  return <ChakraAccordion {...props} />;
};

const AccordionItem = ({ ...props }: AccordionItemProps) => {
  return <ChakraAccordionItem {...props} />;
};

const AccordionPanel = ({ ...props }: AccordionPanelProps) => {
  return <ChakraAccordionPanel {...props} />;
};

export { AccordionButton, AccordionPanel, Accordion, AccordionItem };
export type { AccordionButtonProps };
