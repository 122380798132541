import {
  START_MESSAGES_ACTION,
  MESSAGES_ACTION_TYPE,
  FAIL_MESSAGES_ACTION,
  GET_MESSAGES_THREADS_LIST,
  GET_NEW_MESSAGE,
  UPDATE_MESSAGE_NOTIFICATION_STATUS,
  UPDATE_UNREAD_MESSAGE_COUNT,
  CLEAR_MESSAGING_STATE,
  MARK_AS_READ,
  GET_MESSAGES,
  RESET_MESSAGES,
  START_FETCHING_MESSAGES,
  UPDATE_MESSAGE_THREAD_COUNT,
  ERROR_FETCHING_MESSAGES,
  MARK_AS_UNREAD,
} from "vendor/providers/actions/Messages/actionTypes.d";
import { MessagesState } from "../../initialState";
import { MESSAGES_STATE_TYPE, ISDCMessages } from "@samedaycustom/types/app/Messaging";
import _ from "lodash";
export default (state = MessagesState, action: MESSAGES_ACTION_TYPE): MESSAGES_STATE_TYPE => {
  switch (action.type) {
    case START_MESSAGES_ACTION:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: "",
        type: action.payload,
        done: false,
      };
    case FAIL_MESSAGES_ACTION:
      return {
        ...state,
        loading: false,
        error: true,
        newMessageCount: 0,
        errorMessage: action.payload.error,
        type: action.payload.type,
        done: false,
      };
    case GET_MESSAGES_THREADS_LIST: {
      let normalizedMessagingData: { [key: string]: ISDCMessages } = {};
      // let unreadCount = 0
      if (action.paginated) {
        normalizedMessagingData = { ...state.messagingData };
      }
      action.payload.forEach((m: ISDCMessages) => {
        normalizedMessagingData[m.orderID] = m;
        // unreadCount += m.unread?.store
      });

      // setPageTitle(unreadCount)
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: "",
        done: true,
        type: GET_MESSAGES_THREADS_LIST,
        // newMessageCount: unreadCount,
        messagingData: normalizedMessagingData,
        hasMore: action.hasMore,
      };
    }
    case GET_MESSAGES: {
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: "",
        done: true,
        type: GET_MESSAGES,
        messages: {
          ...state.messages,
          loading: false,
          error: false,
          errorMessage: "",
          done: true,
          data: action.payload,
          type: GET_MESSAGES,
        },
      };
    }
    case RESET_MESSAGES: {
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: "",
        done: false,
        type: RESET_MESSAGES,
        newMessageCount: 0,
        isNew: false,
        messages: {
          ...state.messages,
          loading: false,
          done: false,
          error: false,
          errorMessage: "",
          data: [],
          type: RESET_MESSAGES,
        },
      };
    }
    case START_FETCHING_MESSAGES: {
      return {
        ...state,
        messages: {
          ...state.messages,
          loading: true,
          error: false,
          errorMessage: "",
          done: false,
          data: action.payload,
          type: START_FETCHING_MESSAGES,
        },
      };
    }
    case ERROR_FETCHING_MESSAGES: {
      return {
        ...state,
        messages: {
          ...state.messages,
          loading: false,
          error: true,
          errorMessage: "",
          done: false,
          type: ERROR_FETCHING_MESSAGES,
        },
      };
    }
    case CLEAR_MESSAGING_STATE:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: "",
        done: false,
        type: "",
      };

    case UPDATE_UNREAD_MESSAGE_COUNT:
      return {
        ...state,
        newMessageCount: action.newMessageCount,
      };

    case UPDATE_MESSAGE_NOTIFICATION_STATUS:
      /**
       * Check the current order that is opened, if the order_id is same as
       * the order_id from the action, then show the isNew Icon...
       * Update the order slice
       */
      return {
        ...state,
        newMessageCount: state.newMessageCount + 1,
        isNew: action.isNew,
      };

    case UPDATE_MESSAGE_THREAD_COUNT:
      return {
        ...state,
        messageThreadCount: action.messageThreadCount,
        isNew: action.isNew,
      };

    case GET_NEW_MESSAGE: {
      let newMessagingData: { [key: string]: ISDCMessages } = {};

      let unreadCount = 0;
      if (action?.payload?.orderID) {
        newMessagingData = Object.assign({}, state.messagingData);
        unreadCount =
          action.payload.origin === "customer" || action.payload.type === "NOTIFICATION"
            ? (state.messagingData[action.payload.orderID]?.unread?.store || 0) + 1
            : state.messagingData[action.payload.orderID]?.unread?.store;

        const payloadData = {
          [action.payload.orderID]: {
            ...state.messagingData[action.payload.orderID],
            ...action.payload,
            messages: {
              ...state.messagingData[action.payload.orderID]?.messages,
              [action.payload.id]: { ...action.payload },
            },
            lastMessageAt: action.payload.timestamp,
            lastMessageID: action.payload.id,
            unread: {
              ...state.messagingData[action.payload.orderID]?.unread,
              store: unreadCount,
            },
          },
        };

        /**
         * this should please the new thread at the top of the list
         */
        delete newMessagingData[action.payload.orderID];
        newMessagingData = { ...payloadData, ...newMessagingData };
      }
      if (action.payload.id !== action.payload.tempId) {
        delete newMessagingData[action.payload.orderID]?.messages[action.payload.tempId];
      }
      const message = [action.payload, ...state.messages.data];
      const messages = _.uniqBy(message, "id");

      // setPageTitle(unreadCount)
      return {
        ...state,
        // newMessageCount: unreadCount,
        messagingData: Object.keys(newMessagingData).length
          ? newMessagingData
          : state.messagingData,
        messages: {
          ...state.messages,
          done: true,
          data: messages,
        },
        type: GET_NEW_MESSAGE,
      };
    }
    case MARK_AS_READ: {
      const newMessagingThread: { [key: string]: ISDCMessages } = {
        ...state.messagingData,
        [action.payload.orderID]: {
          ...state.messagingData[action.payload.orderID],
          unread: {
            ...state.messagingData[action.payload.orderID]?.unread,
            store: 0,
          },
        },
      };

      const oldUnreadForOrder = state.messagingData[action.payload.orderID]?.unread?.store;

      const newUnread = state.newMessageCount - oldUnreadForOrder;
      const unreadCount = oldUnreadForOrder
        ? newUnread > 0
          ? newUnread
          : 0
        : state.newMessageCount;

      setPageTitle(unreadCount);

      return {
        ...state,
        newMessageCount: unreadCount,
        messagingData: newMessagingThread,
      };
    }
    case MARK_AS_UNREAD: {
      const newMessagingThread: { [key: string]: ISDCMessages } = {
        ...state.messagingData,
      };

      const { orderIds } = action.payload;

      orderIds.forEach((orderId: string) => {
        newMessagingThread[orderId] = {
          ...newMessagingThread[orderId],
          unread: {
            ...newMessagingThread[orderId]?.unread,
            store: 1,
          },
        };
      });

      const newUnread = state.newMessageCount + orderIds.length;
      const unreadCount = newUnread > 0 ? newUnread : 0;

      setPageTitle(unreadCount);

      return {
        ...state,
        newMessageCount: unreadCount,
        messagingData: newMessagingThread,
      };
    }
    default:
      return state;
  }
};

function setPageTitle(unreadCount) {
  if (unreadCount > 0) {
    document.title = `${unreadCount} new message`;
  } else {
    document.title = "SameDayCustom Vendor Platform";
  }
}
