import { IPrintingMethodState } from "@samedaycustom/types/app/Settings";
export const printingMethodState: IPrintingMethodState = {
  dtg: false,
  dtgMin: 0,
  emb: false,
  embMin: 0,
  htf: false,
  htfMin: 0,
  pct: false,
  pctMin: 0,
  ssc: false,
  sscMin: 0,
  sub: false,
  subMin: 0,
  vin: false,
  vinMin: 0,
};
