/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, current, PayloadAction } from "@reduxjs/toolkit";
import { ActionStatus } from "@samedaycustom/types/app/Messaging";

import {
  getOrderFinancials,
  getOrderFinancialsTotal,
  getVendorPayments,
  recordVendorPayments,
} from "./async";

export interface PaymentData {
  id: string;
  vendorName: string;
  vendorID: string;
  city: string;
  amount: number;
  state: string;
  locationName: string;
  startDate: string;
  endDate: string;
  proofUrl: string;
  datePaid: string;
  confirmation_number: string;
  comment: string;
}
export interface OrderFinancialData {
  id: string;
  vendorName: string;
  city: string;
  state: string;
  locationName: string;
  total_payout: number;
  order_payout: number;
  sm_value: number;
  cc_value: number;
  server_value: number;
  sales_tax_value: number;
  commission_value: number;
  decoOrderId: string;
  date_fulfilled: string;
  billable_amount: number;
}
export interface PaymentsState {
  vendor_payments: {
    data: PaymentData[];
    count: number;
    status: ActionStatus;
    error: string;
    errorType?: "fetch" | "post";
    statusType?: "fetch" | "post";
  };
  order_financials: {
    data: OrderFinancialData[];
    status: ActionStatus;
    count: number;
    error: string;
    errorType?: "fetch" | "post";
    meta: {
      count?: number;
      total_cc_fees: number;
      total_order_volume: number;
      total_payout: number;
      total_sales_com: number;
      total_sales_tax: number;
      total_server_fees: number;
      total_shipping_cost: number;
      total_order_processed?: number;
    };
    total: {
      status: ActionStatus;
      error: string;
      errorType?: "fetch" | "post";
      total_cc_fees: number;
      total_order_volume: number;
      total_order_processed: number;
      total_payout: number;
      total_sales_com: number;
      total_sales_tax: number;
      total_server_fees: number;
      total_shipping_cost: number;
    };
  };
}
const initialState: PaymentsState = {
  vendor_payments: {
    data: [],
    count: 0,
    status: "idle",
    error: null,
    errorType: "fetch",
  },
  order_financials: {
    data: [],
    count: 0,
    status: "idle",
    error: null,
    errorType: "fetch",
    meta: {
      total_cc_fees: 0,
      total_order_volume: 0,
      total_payout: 0,
      total_sales_com: 0,
      total_sales_tax: 0,
      total_server_fees: 0,
      total_shipping_cost: 0,
    },
    total: {
      status: "idle",
      error: null,
      errorType: "fetch",
      total_cc_fees: 0,
      total_order_volume: 0,
      total_payout: 0,
      total_sales_com: 0,
      total_sales_tax: 0,
      total_server_fees: 0,
      total_shipping_cost: 0,
      total_order_processed: 0,
    },
  },
};
const paymentsSplice = createSlice({
  name: "payments",
  initialState,
  reducers: {
    addPayments(state, action: PayloadAction<PaymentData>) {
      state.vendor_payments.data = [action.payload, ...current(state.vendor_payments.data)];
    },
    updatePayment(state, action: PayloadAction<PaymentData>) {
      const findPaymentIndex = state.vendor_payments.data.findIndex(
        (o) => o.id === action.payload.id
      );
      const findPayment = state.vendor_payments.data[findPaymentIndex];
      state.vendor_payments.data[findPaymentIndex] = {
        ...findPayment,
        ...action.payload,
      };
    },

    resetPaymemts(state) {
      state.vendor_payments = initialState.vendor_payments;
    },
    resetOrderFinancials(state) {
      state.order_financials = initialState.order_financials;
    },
  },
  extraReducers: (builder) => {
    /**
     * Payments
     * @param state
     * @param action
     */
    builder
      .addCase(getVendorPayments.pending, (state, action) => {
        const hasPagination = action?.meta?.arg?.cn_after || action?.meta?.arg?.cn_before;
        state.vendor_payments.status = hasPagination ? "idle" : "loading";
      })
      .addCase(
        getVendorPayments.fulfilled,
        (
          state,
          action: PayloadAction<{
            data: PaymentData[];
            meta: { count: number };
          }>
        ) => {
          state.vendor_payments.data = action.payload?.data;
          state.vendor_payments.count = Number(action.payload?.meta?.count || 0);
          state.vendor_payments.status = "succeeded";
        }
      )

      .addCase(getVendorPayments.rejected, (state, action) => {
        state.vendor_payments.status = "failed";
        state.vendor_payments.error = action.error.message;
        state.vendor_payments.errorType = "fetch";
      })

      .addCase(recordVendorPayments.pending, (state, _action: PayloadAction<PaymentData>) => {
        state.vendor_payments.status = "loading";
        state.vendor_payments.statusType = "post";
      })
      .addCase(recordVendorPayments.fulfilled, (state, action: PayloadAction<PaymentData>) => {
        state.vendor_payments.status = "succeeded";
        state.vendor_payments.statusType = "post";
        state.vendor_payments.data = [action.payload, ...current(state.vendor_payments.data)];
      })
      .addCase(recordVendorPayments.rejected, (state, action) => {
        state.vendor_payments.status = "failed";
        state.vendor_payments.error = action.error.message;
        state.vendor_payments.errorType = "post";
      })

      /**
       * Order Financials
       * @param state
       * @param action
       */
      .addCase(getOrderFinancials.pending, (state, action) => {
        const hasPagination = action?.meta?.arg?.bod || action?.meta?.arg?.tod;
        state.order_financials.status = hasPagination ? "idle" : "loading";
      })
      .addCase(getOrderFinancials.fulfilled, (state, action: PayloadAction<any>) => {
        const data = action.payload?.data;
        const meta = action.payload?.meta ?? {};
        state.order_financials.data = data;
        if (meta && Object.keys(meta).length > 1) {
          // If the request is not from pagination, update the meta data which contains the aggregate
          state.order_financials.meta = action.payload?.meta;
        }
        state.order_financials.status = "succeeded";
      })
      .addCase(getOrderFinancials.rejected, (state, action) => {
        /**
         * If the request is cancelled, do not update the state
         */
        if (action.error?.message === "cancelled") return;
        state.order_financials.status = "failed";
        state.order_financials.error = action.error.message;
      })
      // total
      .addCase(getOrderFinancialsTotal.pending, (state) => {
        state.order_financials.total.status = "loading";
      })
      .addCase(getOrderFinancialsTotal.fulfilled, (state, action) => {
        state.order_financials.total = action.payload;
      })
      .addCase(getOrderFinancialsTotal.rejected, (state, action) => {
        /**
         * If the request is cancelled, do not update the state
         */
        if (action.error?.message === "cancelled") return;
        state.order_financials.total.status = "failed";
        state.order_financials.total.error = action.error.message;
      });
  },
});

export const {
  resetPaymemts,
  addPayments,
  resetOrderFinancials,
  updatePayment,
} = paymentsSplice.actions;

export const paymentsSelector = (state: any) => state[paymentsSplice.name] as PaymentsState;

export default paymentsSplice.reducer;
