import { STORE_LOCATIONS_INITIALSTATE } from "vendor/providers/initialState";
import * as LocationActionTypes from "vendor/providers/actions/stores/locations/actionTypes";

export default (state = STORE_LOCATIONS_INITIALSTATE, action) => {
  switch (action.type) {
    case LocationActionTypes.START:
      return {
        ...state,
        loading: true,
        error: false,
        type: action.payload,
        done: false,
        errorMessage: "",
        fetch: false,
      };
    case LocationActionTypes.GET_LOCATIONS:
      return {
        ...state,
        loading: false,
        locations: [...action.payload],
        error: false,
        type: LocationActionTypes.GET_LOCATIONS,
        done: true,
      };
    case LocationActionTypes.ADD_LOCATION:
      return {
        ...state,
        loading: false,
        locations:
          action.payload instanceof Array
            ? [...state.locations, ...action.payload]
            : [...state.locations, action.payload],
        error: false,
        type: LocationActionTypes.ADD_LOCATION,
        done: true,
      };
    case LocationActionTypes.EDIT_LOCATION:
      return {
        ...state,
        loading: false,
        locations: state.locations.map((location) => {
          if (location.id === action.payload.id) {
            return {
              ...location,
              ...action.payload.location,
            };
          }
          return location;
        }),
        error: false,
        type: LocationActionTypes.EDIT_LOCATION,
        done: true,
      };
    case LocationActionTypes.ACCEPT_ORDERS_ACTIONS:
      return {
        ...state,
        loading: false,
        locations: state.locations.map((location) => {
          if (location.id === action.payload.id) {
            return {
              ...location,
              ...action.payload.value,
            };
          }
          return location;
        }),
        error: false,
        type: LocationActionTypes.ACCEPT_ORDERS_ACTIONS,
        done: true,
      };
    case LocationActionTypes.DELETE_LOCATIONS:
      return {
        ...state,
        loading: false,
        locations: state.locations.filter((location) => location.id !== action.payload),
        error: false,
        type: LocationActionTypes.DELETE_LOCATIONS,
        done: true,
      };
    case LocationActionTypes.FAIL:
      return {
        ...state,
        loading: false,
        done: false,
        error: true,
        type: action.payload.type,
        errorMessage: action.payload.message,
      };
    case LocationActionTypes.LOCATION_STATE_RESET:
      return {
        ...state,
        loading: false,
        done: false,
        error: false,
        errorMessage: "",
      };
    case LocationActionTypes.EDIT_USERS_LIST:
      return {
        ...state,
        loading: false,
        done: true,
        error: false,
        errorMessage: "",
        locations: state.locations.map((location) => {
          if (location.id === action.payload.storeId) {
            return {
              ...location,
              userIDs: [...location.userIDs, ...action.payload.added].filter(
                (id) => !action.payload.removed.includes(id)
              ),
            };
          }
          return location;
        }),
      };
    case LocationActionTypes.SILENT_EDIT_USERS_LIST:
      return {
        ...state,
        locations: state.locations.map((location) => {
          if (location.id === action.payload.storeId) {
            return {
              ...location,
              userIDs:
                action.payload.type === "add"
                  ? [...location.userIDs, ...action.payload.arr]
                  : [...location.userIDs].filter((id) => !action.payload.arr.includes(id)),
            };
          }
          return location;
        }),
      };
    case LocationActionTypes.GET_USERS_LIST:
      return {
        ...state,
        loading: false,
        done: true,
        error: false,
        errorMessage: "",
        locations: state.locations.map((location) => {
          if (location.id === action.payload.storeId) {
            return {
              ...location,
              userIDs: action.payload.usersList.map(({ id }) => id),
            };
          }
          return location;
        }),
      };
    case LocationActionTypes.SET_LOCATION_FETCH:
      return {
        ...state,
        fetch: true,
      };
    default:
      return state;
  }
};
