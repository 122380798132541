import React from "react";
import "./style.scss";
import Popup from "reactjs-popup";

interface Props {
	label: string;
	hoverText: string;
}

export default ({ label, hoverText }: Props) => {
	if (!hoverText) {
		return (
			<div className="selectedMultiOption">
				<span> {label} </span>
			</div>
		);
	}
	return (
		<Popup
			on="hover"
			trigger={(open) => {
				return (
					<div className="selectedMultiOption">
						<span> {label} </span>
					</div>
				);
			}}
			position="bottom center"
			closeOnDocumentClick={true}
			arrow={false}
			contentStyle={{
				width: "unset",
				border: "none",
				padding: "0",
				height: "unset",
				borderRadius: "4px",
				boxShadow: "0px 5px 10px rgba(98, 125, 152, 0.1)"
			}}
		>
			<span className="multiOptionToolTip">{hoverText}</span>
		</Popup>
	);
};
