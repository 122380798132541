export const UPDATE_SIGNUP_STATE_ACTION = "UPDATE_SIGNUP_STATE_ACTION";
export const COMPLETE_SIGNUP_FLOW_ACTION = "COMPLETE_SIGNUP_FLOW_ACTION";
export const FAILED_SIGN_UP_ACTION = "FAILED_SIGNUP_FLOW";
export const UPDATE_AUTH_TOKEN_ACTION = "UPDATE_AUTH_TOKEN_ACTION";
export const UPDATE_AUTH_EMAIL_NOT_CONFIRMED = "UPDATE_AUTH_EMAIL_NOT_CONFIRMED";
export const DELETE_AUTH_TOKEN_ACTION = "DELETE_AUTH_TOKEN_ACTION";

export const SET_TARGET_LOCATION = "SET_TARGET_LOCATION";
export const SET_PREVIOUS_COMPONENT = "SET_PREVIOUS_COMPONENT";

export const SET_ISDIRTY = "SET_ISDIRTY";

export const SET_LOADING = "SET_LOADING";
export const SET_PAGE_LOADING = "SET_PAGE_LOADING";
export const DELETING = "DELETING";
export const DELETED = "DELETED";
export const FETCHING = "FETCHING";
export const FETCHED = "FETCHED";
export const SUCCESS = "SUCCESS";
export const ERROR = "ERROR";
export const HIDE_MODAL = "HIDE_MODAL";
export const SUBMITTING_MODAL = "SUBMITTING_MODAL";
export const SHOW_MODAL = "SHOW_MODAL";
export const SHOW_MODAL_CONFIRM = "SHOW_MODAL_CONFIRM";
export const NAV_OPEN = "NAV_OPEN";
export const ADD_TO_RECENT_ORDERS = "ADD_TO_RECENT_ORDERS";
export const REMOVE_FROM_RECENT_ORDERS = "REMOVE_FROM_RECENT_ORDERS";

export const SET_SETTING_LOCATION = "SET_SETTING_LOCATION";

export const SET_IS_FETCHING_TIMELINE = "SET_IS_FETCHING_TIMELINE";

export const SET_APP_TITLE = "SET_APP_TITLE";

export const ADD_TO_RECENT_PROD_ORDERS = "ADD_TO_RECENT_PROD_ORDERS";

export const REMOVE_FROM_RECENT_PROD_ORDERS = "REMOVE_FROM_RECENT_PROD_ORDERS";

export const REMOVE_FROM_RECENT_DEL_ORDERS = "REMOVE_FROM_RECENT_DEL_ORDERS";

export const ADD_TO_RECENT_DEL_ORDERS = "ADD_TO_RECENT_DEL_ORDERS";

export const SET_CONNECTION_STATUS = "SET_CONNECTION_STATUS";

export const SET_AUTO_LOGOUT = "SET_AUTO_LOGOUT";

export const ORDER_STATUS_UPDATED = "ORDER_STATUS_UPDATED";
export const ORDER_STATUS_RESET = "ORDER_STATUS_RESET";
export const ORDER_DELIVERY_METHOD_UPDATED = "ORDER_DELIVERY_METHOD_UPDATED";
export const ORDER_DUE_DATE_CHANGE_REQUEST_UPDATE = "ORDER_DUE_DATE_CHANGE_REQUEST_UPDATE";
export const ORDER_ARTWORK_CHANGE_UPDATE = "ORDER_ARTWORK_CHANGE_UPDATE";
