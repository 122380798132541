import "./react-app-env.d";

import { MainModal as Modal } from "@samedaycustom/core-ui";
import moment from "moment-business-days";
import React from "react";

import Navigator from "./routes";

moment.updateLocale("en", {
  workingWeekdays: [1, 2, 3, 4, 5, 6],
});

const App: React.FC = () => {
  return (
    <Modal>
      <Navigator />
    </Modal>
  );
};
export default App;
