import React, { FunctionComponent, SVGProps } from "react";
import { Text } from "../../_components";
import "./style.scss";
interface Props {
  children?: React.ReactNode;
  headerText: string;
  Icon?: FunctionComponent<SVGProps<SVGSVGElement>> | React.ReactElement;
  className?: string;
}
export default ({ Icon, children, className, headerText, ...props }: Props) => {
  return (
    <div className={`cardWithHeaderContainer ${className}`}>
      <div className="cardWithHeaderContent">
        <div className="__header">
          {Icon && Icon}
          <Text>{headerText}</Text>
        </div>
      </div>
      {props["children"]}
    </div>
  );
};
