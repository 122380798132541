import { Box, useMediaQuery, IconButton, Text } from "@chakra-ui/react";
import { ReactComponent as EditIcon } from "@samedaycustom/assets/images/editPen.svg";
import { ReactComponent as SDCLogoWhite } from "@samedaycustom/assets/images/sdc-white-logo.svg";
import { ReactComponent as Trash } from "@samedaycustom/assets/images/trash.svg";
import { PrimaryButton, SecondaryButton } from "@samedaycustom/core-ui";
import { ImageSlider } from "@samedaycustom/core-ui";
import React from "react";

import { ISavedLayoutCard } from "..";

const SavedLayoutsDesktop = ({
  imageURLs,
  onBuyNow,
  deleteUrl,
  onEdit,
  onSetupGroup,
  designName,
  designSubName,
  status,
  deleteSavedLayouts,
  deleting,
  color,
  primaryButtonStyle,
  secondaryButtonStyle,
}: ISavedLayoutCard) => {
  const [isLessThan800] = useMediaQuery("(max-width: 800px)");
  return (
    <Box
      className="savedlayoutCard"
      border="1px solid #F0F4F8"
      boxSizing="border-box"
      boxShadow="0px 4px 6px rgba(159, 179, 200, 0.1), 0px 2px 4px rgba(159, 179, 200, 0.06)"
      background="#FFFFFF"
      borderRadius="12px"
      width={["unset", "unset"]}
      minWidth={["330px", "340px"]}
      maxW={["max-content", "min-content"]}
      minHeight="445px"
      padding="24px"
    >
      <Box
        minHeight="280px"
        className="__header"
        width="100%"
        position="relative"
        borderRadius="4px"
        marginBottom="18px"
      >
        <Box
          width="100%"
          d="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="flex-end"
          height="100%"
          position="absolute"
          zIndex="9"
        >
          {deleteUrl && (
            <IconButton
              d="flex"
              width="32px"
              aria-label="Delete layout"
              height="32px"
              background="rgba(239,78,78,0.6)"
              borderRadius="4px"
              onClick={() => deleteSavedLayouts(deleteUrl)}
              isLoading={deleting}
              isDisabled={deleting}
              _loading={{
                color: "#fff",
              }}
              alignItems="center"
              justifyContent="center"
              icon={<Trash fill="#fff" />}
            />
          )}
          {!primaryButtonStyle && <SDCLogoWhite />}
        </Box>
        {Array.isArray(imageURLs) && (
          <ImageSlider imageUrlArr={imageURLs} className="savedlayoutCard__image" />
        )}
      </Box>
      <Box className="__footer" textAlign="center">
        <Text
          fontWeight="bold"
          fontSize={["20px", "15px"]}
          lineHeight={["25px", "20px"]}
          textAlign="center"
          textTransform="capitalize"
          color="#334E68"
        >
          {designSubName ? designSubName : "-"}
        </Text>
        <Text
          marginBottom="20px"
          color="#102A43"
          fontWeight="normal"
          fontSize={["16px", "14px"]}
          lineHeight="20px"
          textTransform="capitalize"
        >
          {designName ? `Product: ${designName}` : "-"}
        </Text>
        <Box width="100%" d="flex" justifyContent="space-between" flexDirection={["column"]}>
          <PrimaryButton
            d="inline-flex"
            fontSize="14px"
            onClick={onEdit}
            fontWeight={[600, 500]}
            marginBottom={["16px"]}
            height={["52px !important"]}
            {...(primaryButtonStyle && {
              backgroundColor: primaryButtonStyle.backgroundColor,
              color: primaryButtonStyle.color,
              _hover: {
                backgroundColor: primaryButtonStyle.backgroundColor,
                color: primaryButtonStyle.color,
              },
            })}
          >
            {!isLessThan800 && <EditIcon fill={primaryButtonStyle?.color ?? "white"} />}
            <Text marginLeft="7px">Edit Design</Text>
          </PrimaryButton>
          <SecondaryButton
            onClick={onEdit}
            fontWeight={[600, 500]}
            height={["52px !important"]}
            {...(secondaryButtonStyle && {
              backgroundColor: secondaryButtonStyle.backgroundColor,
              color: secondaryButtonStyle.color,
              _hover: {
                backgroundColor: secondaryButtonStyle.backgroundColor,
                color: secondaryButtonStyle.color,
              },
            })}
          >
            Buy Now
          </SecondaryButton>
        </Box>
      </Box>
    </Box>
  );
};

export default SavedLayoutsDesktop;
