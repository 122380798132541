import {
  OPERATION_LIST_STATE,
  IProductionItemStatus,
} from "@samedaycustom/types/app/Operations/index";
import { generate } from "shortid";

import {
  ISDCCarDelivery,
  ISDCCarDeliveryPackage,
  ISDCDeliveryShipping,
  ISDCPackages,
} from "@samedaycustom/types/order/@types/delivery";
import { OPERATION_STATE, OPERATION_ORDERS_STATE } from "@samedaycustom/types/app/Operations";
import { PRODUCTION_STATUS_TYPE } from "@samedaycustom/types/order/@types/line";

export const initialPackages: ISDCPackages[] = [
  {
    id: generate(),
    dimensions: {
      length: 0,
      width: 0,
      height: 0,
      unit: "inch",
    },

    weight: {
      ounce: 0,
      pounds: 0,
    },
    overrideWeight: { ounce: 0, pounds: 0 },
    numberOfboxes: 1,
  },
];

export const initialCarDeliveryPackage: ISDCCarDeliveryPackage[] = [
  {
    id: generate(),
    name: "bag",
    quantity: 1,
    size: "small",
  },
];

export const initialShippingInfo: ISDCDeliveryShipping = {
  carrier: "FEDEX",
  date: new Date().toString(),
  packages: initialPackages,
  customer: {
    address1: "",
    address2: "",
    state: "",
    stateCode: "",
    country: "",
    countryCode: "",
    zipcode: "",
    firstName: "",
    lastName: "",
    mobile: "",
    city: "",
    carrier: "",
    cityCode: "",
  },
  store: {
    address1: "",
    locationName: "",
    zipcode: "",
    stateCode: "",
    mobile: "",
    city: "",
  },
  sendCharges: false,
};

export const initialCarDelivery: ISDCCarDelivery = {
  quoteID: "",
  comment: "",
  lines: [
    {
      lineID: "",
      sizes: [],
    },
  ],
  paymentBy: "CUSTOMER",
  packages: initialCarDeliveryPackage,
  customer: {
    address1: "",
    address2: "",
    state: "",
    stateCode: "",
    country: "",
    countryCode: "",
    zipcode: "",
    firstName: "",
    lastName: "",
    mobile: "",
    city: "",
    cityCode: "",
  },
  store: {
    address1: "",
    locationName: "",
    zipcode: "",
    stateCode: "",
    mobile: "",
    city: "",
  },
  packagesLength: 1,
  sendCharges: false,
};

export const INTIAL_ORDER_FILTER = {
  delivery: "All",
  status: "All",
  order: {
    start: "",
    end: "",
  },
  due: {
    start: "",
    end: "",
  },
};
export const intialOrderList: OPERATION_LIST_STATE<OPERATION_ORDERS_STATE> = {
  tab_target: 0,
  data: [],
  filterParams: INTIAL_ORDER_FILTER,
  loading: true,
  done: false,
  error: false,
  failed: false,
  metadata: {
    all: 0,
    open: 0,
    completed: 0,
  },
  isFiltering: false,
  errorMessage: null,
  searchParam: "",
};

export const intialItemStatus: IProductionItemStatus = {
  line_id: null,
  isMultiple: false,
  status: null as PRODUCTION_STATUS_TYPE,
  internal_status: null as PRODUCTION_STATUS_TYPE,
  static_internal_status: null,
  awaitingvalue: 0,
  size: null,
  sizes: [],
};

export const operationOrdersState: OPERATION_STATE<OPERATION_ORDERS_STATE> = {
  type: null,
  orders: intialOrderList,
  notifyPickup: true,
  production: { ...intialOrderList, topPanelSelection: [] },
  delivery: intialOrderList,
  targetLocation: {
    orders: null,
    production: null,
    shipping: null,
  },
  dueDateInterval: [],
  notes: [],
  newNoteCount: 0,
  lineItems: [],
  recordResult: {} as any,
  itemStatus: {
    internalStatus: null,
    items: {},
  },
  deliveryRecord: [],
  artworkImage: {},
  rates: [],
  isFetchingTimeline: false,
  deliveringItems: [],
  timelines: [],
  shippingInfo: {
    carrier: "FEDEX",
    date: new Date().toString(),
    packages: [
      {
        id: generate(),
        weight: {
          ounce: 0,
          pounds: 0,
        },
        dimensions: {
          height: 0,
          length: 0,
          width: 0,
          unit: "inch",
        },
        numberOfboxes: 1,
        overrideWeight: { ounce: 0, pounds: 0 },
        override: false,
      },
    ],
    packagesLength: 1,
    customer: {
      address: "",
      address1: "",
      address2: "",
      state: "",
      stateCode: "",
      country: "",
      countryCode: "",
      zipcode: "",
      firstName: "",
      lastName: "",
      mobile: "",
      city: "",
      carrier: "",
      cityCode: "",
    },
    store: {
      address1: "",
      locationName: "",
      zipcode: "",
      stateCode: "",
      mobile: "",
      city: "",
    },
  },
  carDeliveryInfo: initialCarDelivery,
  isFetchingLineItem: true,
  isFetchingDeliveryRecord: false,
  noteValue: {
    id: generate(),
    files: [],
    message: "",
    lineId: "",
    type: "GENERAL",
  },
  messageValue: {
    // id: generate(),
    type: "GENERAL",
    timestamp: Date.now(),
    files: [],
    content: "",
    lineID: "",
    origin: "store",
    orderID: "",
  },
};
