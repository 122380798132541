import mixpanel from 'mixpanel-browser';

const token = process.env.REACT_APP_MIXPANEL_TOKEN || '';

export enum MixpanelEvents {
    DESIGN_TOOL_CONVERSION = 'Design Tool Conversion',
}

export const initializeMixpanel = () => {
    mixpanel?.init(token, { debug: false, track_pageview: false, persistence: "localStorage" });
}


export const identifyMixpanel = (email: string) => {
    // to be called when user logs in
    if (email) {
        mixpanel?.identify(email);
    }
}

export const trackMixpanel = (event: string, data: any) => {
    if (event) {
        mixpanel?.track(event, data)
    }
}

export const resetMixpanel = () => {
    // after logout:
    mixpanel?.reset();
}
