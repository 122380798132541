import { ISDCAdminCustomers } from "@samedaycustom/types/customers";

export type CUSTOMERS_ACTION_PAYLOAD = {
  customers: ISDCAdminCustomers[];
  customer: ISDCAdminCustomers;
  customersCount?: number;
  loading?: boolean;
  done?: boolean;
};
export const FETCH_CUSTOMERS = "FETCH_CUSTOMERS";
export const FETCH_CUSTOMER = "FETCH_CUSTOMER";
export const FETCHING_CUSTOMER = "FETCHING_CUSTOMER";

export type CUSTOMER_ACTION_TYPE =
  | typeof FETCH_CUSTOMERS
  | typeof FETCH_CUSTOMER
  | typeof FETCHING_CUSTOMER;

export type CUSTOMERS_ACTION_TYPES = {
  type: CUSTOMER_ACTION_TYPE;
  payload?: Partial<CUSTOMERS_ACTION_PAYLOAD>;
};
