import React, {useEffect} from "react"

export default (htmlId: string, dependcies: any[], scroll = true) => {
    useEffect(() => {
        const out = document.getElementById(htmlId)
        if (out && scroll) {
            const isScrolledToBottom =
                out?.scrollHeight - out?.clientHeight >= out?.scrollTop + 1
            // scroll to bottom if isScrolledToBottom is true
            if (isScrolledToBottom) {
                out.scrollTop = out?.scrollHeight - out?.clientHeight
            }
        }
    }, [...dependcies])

    return null
}
