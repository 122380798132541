import { ISDCMessages, ISDCMessage } from "@samedaycustom/types/app/Messaging/index";
import {
  MESSAGES_ACTION_TYPE,
  ALL_MESSAGES_ACTION_TYPES,
  GET_NEW_MESSAGE,
  CLEAR_MESSAGING_STATE,
  START_MESSAGES_ACTION,
  FAIL_MESSAGES_ACTION,
  GET_MESSAGES_THREADS_LIST,
  UPDATE_MESSAGE_NOTIFICATION_STATUS,
  MARK_AS_READ,
  RESET_MESSAGES,
  GET_MESSAGES,
  UPDATE_MESSAGE_THREAD_COUNT,
  UPDATE_UNREAD_MESSAGE_COUNT,
  ERROR_FETCHING_MESSAGES,
  START_FETCHING_MESSAGES,
  IMARK_AS_UNREAD,
  MARK_AS_UNREAD,
} from "./actionTypes.d";
import { ThunkAction } from "redux-thunk";
import constants from "vendor/api/Api.constants";
import request from "axios";
import stateExtractor from "vendor/helpers/helperFunctions/stateExtractor";
import { logOutAuto } from "vendor/providers/actions/Auth";
import qs from "query-string";
import { ORDER_MESSAGE_READ } from "../operations/types";
import { getOrderLine } from "vendor/providers/features/orderline/async";

const startAction = (action: ALL_MESSAGES_ACTION_TYPES): MESSAGES_ACTION_TYPE => ({
  type: START_MESSAGES_ACTION,
  payload: action,
});
export const startMessagesAction = (): MESSAGES_ACTION_TYPE => ({
  type: START_FETCHING_MESSAGES,
  payload: START_FETCHING_MESSAGES,
});

export const failAction = (
  action: ALL_MESSAGES_ACTION_TYPES,
  error: string
): MESSAGES_ACTION_TYPE => ({
  type: FAIL_MESSAGES_ACTION,
  payload: {
    type: action,
    error: error,
  },
});
export const failMessagesAction = (error: string): MESSAGES_ACTION_TYPE => ({
  type: ERROR_FETCHING_MESSAGES,
  payload: {
    type: ERROR_FETCHING_MESSAGES,
    error: error,
  },
});

const getMessageThreads = ({
  Threads,
  paginated,
  hasMore,
}: {
  Threads: Array<ISDCMessages>;
  paginated?: boolean;
  hasMore?: boolean;
}): MESSAGES_ACTION_TYPE => ({
  type: GET_MESSAGES_THREADS_LIST,
  payload: Threads,
  paginated,
  hasMore,
});
export const getMessages = (Threads: Array<ISDCMessage>): MESSAGES_ACTION_TYPE => ({
  type: GET_MESSAGES,
  payload: Threads,
});
export const resetMessages = (): MESSAGES_ACTION_TYPE => ({
  type: RESET_MESSAGES,
});

const clearState = (): MESSAGES_ACTION_TYPE => ({
  type: CLEAR_MESSAGING_STATE,
});

export const updateMessageNotificationStatus = (isNew: boolean): MESSAGES_ACTION_TYPE => ({
  type: UPDATE_MESSAGE_NOTIFICATION_STATUS,
  isNew,
});

const getNewMessage = (message: ISDCMessage): MESSAGES_ACTION_TYPE => ({
  type: GET_NEW_MESSAGE,
  payload: message,
});

// extra kind of actions

export const clearMessagingState = () => (dispatch: any) => {
  dispatch(clearState);
};

export const getNewMessageSocketAction = (message: ISDCMessage) => (dispatch: any) => {
  dispatch(getNewMessage(message));
};
export const updateOrderUnread = ({ order_id, unread }) => (dispatch: any) => {
  dispatch({
    type: ORDER_MESSAGE_READ,
    order_id,
    unread: unread,
    stageType: "orders",
  });
};

export const updateMessageThreadCount = (count: number) => ({
  type: UPDATE_MESSAGE_THREAD_COUNT,
  messageThreadCount: count,
});

export const updateUnreadMessageCount = (count: number) => ({
  type: UPDATE_UNREAD_MESSAGE_COUNT,
  newMessageCount: count,
});

export const markAsRead = (orderID: string): MESSAGES_ACTION_TYPE => (dispatch, getState) => {
  const authState = stateExtractor(getState, "auth");
  const key = authState.token;
  dispatch({
    type: MARK_AS_READ,
    payload: {
      orderID: orderID,
    },
  });
  return request
    .patch(
      `${constants.MESSAGING}/${orderID}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${key}`,
          "Content-Type": constants.CONTENT_TYPE,
        },
      }
    )
    .then((res) => {
      if (res.data.status !== "fail") {
        const data = res.data.data;
        return data;
      } else {
        if (res.data.message instanceof Array) {
          dispatch(failMessagesAction(res.data.message[0].msg));
        } else {
          dispatch(failMessagesAction(res.data.message));
        }
      }
      return null;
    })
    .catch((err) => {
      if (err.response) {
        dispatch(logOutAuto(err.response));
        if (err.response.data.message instanceof Array) {
          dispatch(failMessagesAction(err.response.data.message[0].msg));
        } else {
          dispatch(failMessagesAction(err.response.data.message));
        }
      } else {
        dispatch(failMessagesAction(err.message));
      }
    });
};

export const markCustomerMessageAsUnread = (orderIds: string[]): IMARK_AS_UNREAD => ({
  type: MARK_AS_UNREAD,
  payload: { orderIds },
});

// const getMessages = (Message)

type MessagesAsyncActionType = ThunkAction<void, undefined, null, MESSAGES_ACTION_TYPE>;

/**
 *
 * Send Message to orderid
 * @param orderId
 * @param messages
 */
export const sendMessage = (orderId, location_id, messages: ISDCMessage) => async (
  dispatch,
  getState
) => {
  const authState = stateExtractor(getState, "auth");
  const { messageValue } = stateExtractor(getState, "operationsOrders");
  const orderLine = stateExtractor(getState, "orderLine");
  const key = authState.token;
  const url = `${constants.MESSAGING}/${orderId}?store=${location_id}`;
  return request
    .post(`${url}`, messages, {
      headers: {
        Authorization: `Bearer ${key}`,
        "Content-Type": constants.CONTENT_TYPE,
      },
    })
    .then((res) => {
      // if message value was set from artwork view then refresh the line item
      if (
        messageValue?.lineID &&
        messageValue?.viewID &&
        orderLine?.line?.hasOwnProperty(messageValue?.lineID)
      ) {
        dispatch(
          getOrderLine({
            line_id: messageValue?.lineID,
            order_id: orderId,
            location_id,
          })
        );
      }
      return res;
    })
    .catch((err) => {
      if (err.response) {
        dispatch(logOutAuto(err.response));
        if (err.response.data.message instanceof Array) {
          dispatch(failAction(GET_MESSAGES_THREADS_LIST, err.response.data.message[0].msg));
        } else {
          dispatch(failAction(GET_MESSAGES_THREADS_LIST, err.response.data.message));
        }
      } else {
        dispatch(failAction(GET_MESSAGES_THREADS_LIST, err.message));
      }
    });
};

export const getMessagesThread = (
  param?: {
    limit?: number;
    search?: string;
    locationId?: string;
    cursor?: number;
  },
  isSilent?: boolean
): MessagesAsyncActionType => (dispatch, getState) => {
  const authState = stateExtractor(getState, "auth");
  const key = authState.token;
  let url = `${constants.MESSAGING}`;

  const hasPagination = param?.cursor;
  if (Object.keys(param || {}).length) {
    url = `${url}?${qs.stringify({
      limit: param.limit,
      search: param?.search || "",
      locationId: param?.locationId || "",
      cursorId: param?.cursor || 0,
    })}`;
  } else {
    !isSilent && dispatch(startAction(GET_MESSAGES_THREADS_LIST));
  }

  return request
    .get(`${url}`, {
      headers: {
        Authorization: `Bearer ${key}`,
        "Content-Type": constants.CONTENT_TYPE,
      },
    })
    .then((res) => {
      if (res.data.status !== "fail") {
        const data = res.data.data?.data;
        const messageThreadCount = res.data?.data?.count;
        const hasMore = res.data.data?.hasMore;
        dispatch(getMessageThreads({ Threads: data, paginated: !!hasPagination, hasMore }));
        dispatch(updateMessageThreadCount(messageThreadCount));
      } else {
        if (res.data.message instanceof Array) {
          dispatch(failAction(GET_MESSAGES_THREADS_LIST, res.data.message[0].msg));
        } else {
          dispatch(failAction(GET_MESSAGES_THREADS_LIST, res.data.message));
        }
      }
    })
    .catch((err) => {
      if (err.response) {
        dispatch(logOutAuto(err.response));
        if (err.response.data.message instanceof Array) {
          dispatch(failAction(GET_MESSAGES_THREADS_LIST, err.response.data.message[0].msg));
        } else {
          dispatch(failAction(GET_MESSAGES_THREADS_LIST, err.response.data.message));
        }
      } else {
        dispatch(failAction(GET_MESSAGES_THREADS_LIST, err.message));
      }
    });
};

export const getMessagesByOrderID = (
  orderId,
  param?: {
    limit?: number;
    location_id?: string;
  }
): MessagesAsyncActionType => (dispatch, getState) => {
  const authState = stateExtractor(getState, "auth");
  const key = authState.token;
  dispatch(startMessagesAction());
  // dispatch(resetMessages())
  let url = `${constants.MESSAGING}/${orderId}`;

  if (param?.limit || param?.location_id) {
    url = `${url}?${qs.stringify({
      limit: param.limit ?? "",
      store: param?.location_id ?? "",
      location_id: param?.location_id ?? "",
    })}`;
  }

  return request
    .get(`${url}`, {
      headers: {
        Authorization: `Bearer ${key}`,
        "Content-Type": constants.CONTENT_TYPE,
      },
    })
    .then((res) => {
      if (res.data.status !== "fail") {
        const data = res.data.data;
        dispatch(getMessages(data));

        return data;
      } else {
        if (res.data.message instanceof Array) {
          dispatch(failMessagesAction(res.data.message[0].msg));
        } else {
          dispatch(failMessagesAction(res.data.message));
        }
      }

      return null;
    })
    .catch((err) => {
      if (err.response) {
        dispatch(logOutAuto(err.response));
        if (err.response.data.message instanceof Array) {
          dispatch(failMessagesAction(err.response.data.message[0].msg));
        } else {
          dispatch(failMessagesAction(err.response.data.message));
        }
      } else {
        dispatch(failMessagesAction(err.message));
      }
    });
};

export const getUnreadCustomerMessageCount = (): MessagesAsyncActionType => (
  dispatch,
  getState
) => {
  const authState = stateExtractor(getState, "auth");
  const key = authState.token;
  if (!key) return;
  return request
    .get(`${constants.MESSAGING}/count`, {
      headers: {
        Authorization: `Bearer ${key}`,
        "Content-Type": constants.CONTENT_TYPE,
      },
    })
    .then((res) => {
      if (res.data.status !== "fail") {
        const data = res.data.data?.unread_store_count;
        dispatch(updateUnreadMessageCount(parseInt(data)));

        return data;
      } else {
        if (res.data.message instanceof Array) {
          dispatch(failMessagesAction(res.data.message[0].msg));
        } else {
          dispatch(failMessagesAction(res.data.message));
        }
      }

      return null;
    })
    .catch((err) => {
      if (err.response) {
        dispatch(logOutAuto(err.response));
        if (err.response.data.message instanceof Array) {
          dispatch(failMessagesAction(err.response.data.message[0].msg));
        } else {
          dispatch(failMessagesAction(err.response.data.message));
        }
      } else {
        dispatch(failMessagesAction(err.message));
      }
    });
};
