import { STORE_USERS_FLOW_INITIALSTATE } from "vendor/providers/initialState";
import * as userActions from "vendor/providers/actions/stores/users/actionTypes";

const usersReducer = (state = STORE_USERS_FLOW_INITIALSTATE, action) => {
  switch (action.type) {
    case userActions.START_USER_ACTION:
      return {
        ...state,
        type: action.payload.actionType,
        targetUser: {},
        loading: true,
        done: false,
        error: false,
        errorMessage: "",
        fetch: false,
      };
    case userActions.GET_USERS:
      return {
        ...state,
        type: action.type,
        users: [...action.payload],
        done: true,
        error: false,
        loading: false,
      };
    case userActions.GET_TARGET_USER:
      return {
        ...state,
        type: action.type,
        targetUser: { ...action.payload },
        done: true,
        error: false,
        loading: false,
      };
    case userActions.CREATE_USER:
      return {
        ...state,
        type: action.type,
        users: [...state.users, action.payload].sort((a, b) => {
          if (a.firstName > b.firstName) {
            return 1;
          } else if (a.firstName < b.firstName) {
            return -1;
          } else {
            return 0;
          }
        }),
        done: true,
        error: false,
        loading: false,
      };
    case userActions.EDIT_USER:
      // const locationArr = Object.keys(action.payload.updatedDetails.stores) || Object.keys(action.payload.locations)
      // const targetKey = Object.keys(action.payload.updatedDetails.stores) ? "stores" : "locations"
      return {
        ...state,
        type: action.type,
        users: state.users.map((user) => {
          if (user.id === action.payload.id) {
            return {
              ...action.payload.updatedDetails,
            };
          } else {
            return user;
          }
        }),
        targetUser: {},
        done: true,
        error: false,
        loading: false,
      };
    case userActions.DELETE_USER: {
      const deletedUsersId = action.payload.split(",");
      return {
        ...state,
        type: action.type,
        users: state.users.filter((user) => {
          if (deletedUsersId.includes(user.id)) {
            return false;
          }

          return true;
        }),
        done: true,
        error: false,
        loading: false,
      };
    }
    case userActions.FAILED_USER_ACTION:
      return {
        ...state,
        type: action.payload.actionType,
        loading: false,
        done: false,
        error: true,
        errorMessage: action.payload.message,
      };
    case userActions.USERS_STATE_RESET:
      return {
        ...state,
        type: "",
        loading: false,
        done: false,
        error: false,
        errorMessage: "",
      };
    case userActions.EDIT_STORES_LIST:
      return {
        ...state,
        loading: false,
        done: true,
        error: false,
        errorMessage: "",
        users: state.users.map((user) => {
          if (user.id === action.payload.userId) {
            return {
              ...user,
              locations: action.payload.newArr,
            };
          }
          return user;
        }),
      };
    case userActions.SILENT_EDIT_STORES_LIST:
      return {
        ...state,
        users: state.users.map((user) => {
          if (user.id === action.payload.userId) {
            return {
              ...user,
              locations: action.payload.newArr,
            };
          }
          return user;
        }),
      };
    case userActions.SET_USER_ACTIVE:
      return {
        ...state,
        type: action.type,
        users: state.users.map((user) => {
          if (user.id === action.payload.id) {
            return {
              ...action.payload.data,
            };
          } else {
            return user;
          }
        }),
        targetUser: {},
        done: true,
        error: false,
        loading: false,
      };
    case userActions.SET_USERS_FETCH:
      return {
        ...state,
        fetch: true,
      };
    default:
      return state;
  }
};

export default usersReducer;
