import React from "react"

import ContentLoader from "react-content-loader"

interface Props {
    color?: string
    backgroundColor?: string
    speed?: number
    width?: number
    height?: number
    style?: any
}
export default ({
    backgroundColor = "f3f3f3",
    color = "00ffff",
    height = 50,
    speed = 2,
    width = 560,
    ...props
}: Props) => {
    return (
        <ContentLoader
            viewBox={`0 0 ${width} ${height}`}
            height={height}
            width={width}
            speed={speed}
            foregroundColor={color}
            {...props}
        >
            {/* <rect x="24" y="27" rx="5" ry="5" width="136" height="6" /> */}
            <rect x="25" y="1" rx="5" ry="5" width="174" height="35" />
            <circle cx={width - 140} cy="10" r="11" />
            {/* <rect x={width - 150} y="41" rx="5" ry="5" width="80" height="4" /> */}
        </ContentLoader>
    )
}
