export const GET_LOCATIONS = "GET_LOCATIONS";
export const START = "START";
export const FAIL = "FAIL";
export const ADD_LOCATION = "ADD_LOCATION";
export const EDIT_LOCATION = "EDIT_LOCATION";
export const DELETE_LOCATIONS = "DELETE_LOCATIONS";
export const LOCATION_STATE_RESET = "LOCATION_STATE_RESET";
export const EDIT_USERS_LIST = "EDIT_USERS_LIST";
export const SILENT_EDIT_USERS_LIST = "SILENT_EDIT_USERS_LIST";
export const GET_USERS_LIST = "GET_USERS_LIST";
export const SET_LOCATION_FETCH = "SET_LOCATION_FETCH";
export const ACCEPT_ORDERS_ACTIONS = "ACCEPT_ORDERS_ACTIONS";
