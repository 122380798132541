import { CombinedState, configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { DELETE_AUTH_TOKEN_ACTION } from "vendor/providers/actions/types";

import { isLocal, isProd } from "../environment";
import { getDefaultMiddleware } from "./middleware";
import reducers from "./reducers";

const persistConfig = {
  key: "root",
  storage,
  blacklist: [
    "deliveryMethod",
    "signupFlow",
    "users",
    "productionSpeed",
    "roles",
    "userProfile",
    "locations",
    "onboarding",
    "profileImage",
  ],
  whitelist: ["auth"],
};

//  this is to ensure that when the user logs out the data is returned to its initial state,
//  and the data is removed from local storage
function rootReducer(state, action): CombinedState<any> {
  if (action.type === DELETE_AUTH_TOKEN_ACTION) {
    storage.removeItem("persist:root");
    state = undefined;
  }

  return reducers(state, action);
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

const debugMiddleware = getDefaultMiddleware();

if (!isProd()) {
  debugMiddleware.push(logger);
}

export const store: any = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(...debugMiddleware),
});

export const persistor = persistStore(store);

//@ts-ignore
if (isLocal() && module?.hot) {
  //@ts-ignore
  module.hot.accept("./reducers", () => {
    const newRootReducer = require("./reducers").default;
    store.replaceReducer(newRootReducer);
  });
}
