import React from "react";
import { Box, Text } from "@chakra-ui/react";
import moment from "moment";
import { ArtworkNoteMessage } from "..";
export interface ArtworkNoteMessageCard {
  message: ArtworkNoteMessage;
  style?: React.CSSProperties;
}
export default ({ message, style }: ArtworkNoteMessageCard) => {
  return (
    <Box
      borderRadius="8px"
      style={style}
      marginBottom="1rem"
      padding="10px"
      fontSize="0.9rem"
    >
      <Text
        textTransform="capitalize"
        fontFamily="Inter"
        fontWeight={500}
        color="#102A43"
      >
        {message?.username} - {moment(message?.timestamp).format("DD-MM-YYYY")}
      </Text>
      <Text>{message?.message}</Text>
    </Box>
  );
};
