import React from "react";
import "./style.scss";
import Skeleton from "react-loading-skeleton";

interface Props {
	label: string;
	percentage: number;
	loading: boolean;
}

export default React.memo(function({ label, percentage, loading }: Props) {
	let root = document.documentElement;
	root.style.setProperty("--rangeEnd", `${percentage}%`);

	return (
		<div className="progressBarGroupContainer">
			<div className="progressTopLevel">
				<h2>{label}</h2>
				{loading ? (
					<Skeleton width={40} />
				) : (
					<div className="percentContainer">
						<span>{percentage}%</span>
					</div>
				)}
			</div>
			{loading ? (
				<Skeleton />
			) : (
				<div className="progressbarOuter">
					<div className="progressbarInner" />
				</div>
			)}
		</div>
	);
});
