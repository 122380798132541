import React from 'react'
import './style.scss'
import Skeleton from '@material-ui/lab/Skeleton'

export default () => {
    return (
        <div className="MOrderLoader__container">
            <div className="MOrderLoader__row">
                <div className="row1">
                    <Skeleton/>
                    <Skeleton/>
                </div>
                <div className="row1">
                    <Skeleton/>
                    <Skeleton/>
                </div>
                <Skeleton/>
            </div>
            <div className="MOrderLoader__row">
            <div className="row1">
                    <Skeleton/>
                    <Skeleton/>
                </div>
                <div className="row1">
                    <Skeleton/>
                    <Skeleton/>
                </div>
                <Skeleton/>
            </div>
            <div className="MOrderLoader__row">
            <div className="row1">
                    <Skeleton/>
                    <Skeleton/>
                </div>
                <Skeleton/>
                <Skeleton/>
            </div>
            <div className="MOrderLoader__row">
            <div className="row1">
                    <Skeleton/>
                    <Skeleton/>
                </div>
                <div className="row1">
                    <Skeleton/>
                    <Skeleton/>
                </div>
                <Skeleton/>
            </div>
            <div className="MOrderLoader__row">
            <div className="row1">
                    <Skeleton/>
                    <Skeleton/>
                </div>
                <div className="row1">
                    <Skeleton/>
                    <Skeleton/>
                </div>
                <Skeleton/>
            </div>
            <div className="MOrderLoader__row">
            <div className="row1">
                    <Skeleton/>
                    <Skeleton/>
                </div>
                <div className="row1">
                    <Skeleton/>
                    <Skeleton/>
                </div>
                <Skeleton/>
            </div>
            <div className="MOrderLoader__row">
            <div className="row1">
                    <Skeleton/>
                    <Skeleton/>
                </div>
                <div className="row1">
                    <Skeleton/>
                    <Skeleton/>
                </div>
                <Skeleton/>
            </div>
            <div className="MOrderLoader__row">
            <div className="row1">
                    <Skeleton/>
                    <Skeleton/>
                </div>
                <div className="row1">
                    <Skeleton/>
                    <Skeleton/>
                </div>
                <Skeleton/>
            </div>
        </div>
    )
}