import React, { useEffect } from "react";
import "./style.scss";
import { useHistory } from "react-router-dom";
// import Layout from "customer/components/Layout/UnAuthedLayout";
import { Formik } from "formik";
import * as Yup from "yup";
import { AuthedButton as Button } from "@samedaycustom/core-ui";
import { Input } from "@samedaycustom/core-ui";
import { CREATE_PASSWORD_ACTION } from "vendor/providers/actions/onboarding/actionTypes";
import QueryString from "query-string";
import * as onboardingAsyncActions from "vendor/providers/actions/onboarding";
import { useSelector, useDispatch, DefaultRootState } from "react-redux";
import { ONBOARDING_STATE_TYPE } from "@samedaycustom/types/app";
import { useToast } from "@samedaycustom/hooks";
import * as Routes from "vendor/routes/screen";
import { UnAuthedScreenLayout as Layout } from "@samedaycustom/core-ui";

const CreatePassword = () => {
  const dispatch = useDispatch();
  const toast = useToast();
  const history = useHistory();
  const onboardingState = useSelector<DefaultRootState, ONBOARDING_STATE_TYPE>(
    (state) => state["onboarding"]
  );
  const parsedObj = QueryString.parse(history.location.search + history.location.hash);

  useEffect(() => {
    document.title = "Create Password";
  }, []);

  // when the api has been successfully called
  useEffect(() => {
    if (onboardingState.error && onboardingState.type === CREATE_PASSWORD_ACTION) {
      dispatch(onboardingAsyncActions.stateReset());
      toast({
        title: "Error",
        description: onboardingState.errorMessage,
        position: "bottom-left",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [onboardingState.error]);

  useEffect(() => {
    if (onboardingState.done && onboardingState.type === CREATE_PASSWORD_ACTION) {
      dispatch(onboardingAsyncActions.stateReset());
      toast({
        title: "Created Password",
        description: "Created password successfully. Proceed to login",
        position: "bottom-left",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      history.push(Routes.SIGN_IN_PAGE, { email: parsedObj?.email });
    }
  }, [onboardingState.done]);

  return (
    <Layout>
      <div className="unAuthedPageWrapper">
        <div className="authActionBox">
          <h1 className="authActionBox__Header">Create your password</h1>
          <p>To access your account, you need to create a password</p>

          <div className="emailGroup">
            <h2>Email Address</h2>
            <p>{parsedObj?.email}</p>
          </div>

          <Formik
            initialValues={{
              password: "",
              confirmPassword: "",
            }}
            validationSchema={Yup.object({
              password: Yup.string()
                .required("Please enter your password")
                .min(6, "Enter a password with at least six (6) characters"),
              confirmPassword: Yup.string(),
            })}
            onSubmit={(values) => {
              dispatch(
                onboardingAsyncActions.createPasswordAsync({
                  token: parsedObj.token as string,
                  email: parsedObj.email as string,
                  password: values.password,
                })
              );
            }}
          >
            {({ handleSubmit }) => {
              return (
                <React.Fragment>
                  <div className="authActionBox__main">
                    <Input
                      type="input"
                      inputConfig={{
                        label: "Password",
                        id: "password",
                        name: "password",
                        type: "password",
                      }}
                    />
                    <Input
                      type="input"
                      inputConfig={{
                        label: "Confirm password",
                        id: "confirmPassword",
                        name: "confirmPassword",
                        type: "password",
                      }}
                      onKeyDown={(e) => e.key === "Enter" && handleSubmit(e as any)}
                    />
                  </div>
                  <Button
                    onKeyDown={(e) => e.key === "Enter" && handleSubmit(e as any)}
                    onClick={handleSubmit as any}
                    loaderColor="#fff"
                    loading={onboardingState.loading}
                    className="Btn--Primary authActionBox__Btn"
                  >
                    <span> Create Password </span>
                  </Button>
                </React.Fragment>
              );
            }}
          </Formik>
        </div>
      </div>
    </Layout>
  );
};

export default CreatePassword;
