import { REPORT_STATE } from "../../actions/Reports/types.d";

export const reportState: REPORT_STATE = {
  reports: {
    loading: false,
    error: false,
    errorMessage: null,
    data: [],
    done: false,
    metadata: {
      // all: 0,
      // completed: 0,
      // open: 0,
      average_rating: 0,
      total_accepted: 0,
      total_cancelled: 0,
      total_customer: 0,
      total_declined: 0,
      total_fulfilled: 0,
      total_order: 0,
      total_rating: 0,
      total_review: 0,
    },
  },
};
