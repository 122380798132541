import "./style.scss";

import { Button, ChakraInput as Input, Text } from "../../_components";
import { RadioInput } from "@samedaycustom/core-ui";
import { Select } from "@samedaycustom/core-ui";
import { SETUP_WORK_HOURS_DATA } from "@samedaycustom/types/app/Settings";
import { Field, getIn } from "formik";
import moment from "moment";
import React from "react";

export default React.memo(
  ({ onSwitch, available, day, formprops, id }: SETUP_WORK_HOURS_DATA) => {
    const lowerCaseTitle = day.toLowerCase();
    const switchName = `${lowerCaseTitle}available`;
    const fieldid = 0;

    const errorStyle = {
      borderColor: "red",
      borderWidth: 1
    };

    const getValue = (inputname: string) =>
      getIn(formprops.values, `${lowerCaseTitle}[${fieldid}].${inputname}`);

    const getOpenHourValue = parseInt(getValue("openHour"));
    const getOpenMinuteValue = parseInt(getValue("openMinute"));
    const getOpenDayAtValue = getValue("openAtDay");

    const getCloseHourValue = parseInt(getValue("closeHour"));
    const getCloseMinuteValue = parseInt(getValue("closeMinute"));
    const getCloseDayAtValue = getValue("closeAtDay");

    const openTime = moment(
      `${getOpenHourValue.toString()}:${getOpenMinuteValue.toString()} ${getOpenDayAtValue}`,
      ["h:mm A"]
    )
      .toDate()
      .getTime();

    const closeTime = moment(
      `${getCloseHourValue.toString()}:${getCloseMinuteValue.toString()} ${getCloseDayAtValue}`,
      ["h:mm A"]
    )
      .toDate()
      .getTime();

    const isCloseValid: boolean = openTime <= closeTime;

    const handleTimeChange = (e, name, length = 12) => {
      const { value } = e.target;
      if (isNaN(Number(value)) || Number(value) > length) return;
      formprops.setFieldValue(name, value);
    };

    return (
      <div className="contentContainer">
        <Field name={switchName}>
          {() => <RadioInput value={available} onChange={onSwitch} />}
        </Field>
        <div className="inputGroup">
          <Text>Day of the Week</Text>
          <Button backgroundColor="#D7E9FC" isFullWidth>
            <Text color="#007BFF" textTransform="capitalize">
              {day}
            </Text>
          </Button>
        </div>
        <div className="inputGroup">
          <Text>Opens at</Text>
          <div className="inputGroupRow">
            <Field name={`${lowerCaseTitle}[${fieldid}].openHour`} value="23">
              {({ field, meta: { error, touched } }) => (
                <Input
                  marginRight={2}
                  marginLeft={2}
                  accept="number"
                  maxLength={2}
                  pattern="^[0-9]$"
                  style={error && touched ? errorStyle : {}}
                  {...field}
                  value={
                    (formprops.values[lowerCaseTitle] &&
                      formprops.values[lowerCaseTitle][fieldid]["openHour"]) ||
                    field.value
                  }
                  onChange={(e: any) =>
                    handleTimeChange(
                      e,
                      `${lowerCaseTitle}[${fieldid}].openHour`
                    )
                  }
                />
              )}
            </Field>
            <Text>:</Text>
            <Field name={`${lowerCaseTitle}[${fieldid}].openMinute`}>
              {({ field, meta: { error, touched } }) => (
                <Input
                  marginRight={2}
                  marginLeft={2}
                  type="number"
                  accept="number"
                  maxLength={2}
                  pattern="^[0-9]$"
                  style={error && touched ? errorStyle : {}}
                  {...field}
                  value={
                    (formprops.values[lowerCaseTitle] &&
                      formprops.values[lowerCaseTitle][fieldid][
                        "openMinute"
                      ]) ||
                    field.value
                  }
                  onChange={(e: any) =>
                    handleTimeChange(
                      e,
                      `${lowerCaseTitle}[${fieldid}].openMinute`,
                      60
                    )
                  }
                />
              )}
            </Field>

            <Field name={`${lowerCaseTitle}[${fieldid}].openAtDay`}>
              {({ field, meta: { error, touched } }) => (
                <Select
                  doubleIcon
                  width={500}
                  style={error && touched ? errorStyle : {}}
                  {...field}
                >
                  <option value="AM">AM</option>
                  <option value="PM">PM</option>
                </Select>
              )}
            </Field>
          </div>
        </div>
        <div className="inputGroup">
          <Text>Closes at</Text>
          <div className="inputGroupRow">
            <Field name={`${lowerCaseTitle}[${fieldid}].closeHour`}>
              {({ field, meta: { error, touched } }) => (
                <Input
                  {...field}
                  marginRight={2}
                  marginLeft={2}
                  type="number"
                  accept="number"
                  maxLength={2}
                  style={(error && touched) || !isCloseValid ? errorStyle : {}}
                  pattern="^[0-9]$"
                  onChange={(e: any) =>
                    handleTimeChange(
                      e,
                      `${lowerCaseTitle}[${fieldid}].closeHour`
                    )
                  }
                />
              )}
            </Field>
            <Text>:</Text>
            <Field name={`${lowerCaseTitle}[${fieldid}].closeMinute`}>
              {({ field, meta: { error, touched } }) => (
                <Input
                  marginRight={2}
                  type="number"
                  accept="number"
                  maxLength={2}
                  style={
                    (error && touched) ||
                    (!isCloseValid && formprops.isValidating)
                      ? errorStyle
                      : {}
                  }
                  pattern="^[0-9]$"
                  marginLeft={2}
                  {...field}
                  onChange={(e: any) =>
                    handleTimeChange(
                      e,
                      `${lowerCaseTitle}[${fieldid}].closeMinute`,
                      60
                    )
                  }
                />
              )}
            </Field>

            <Field name={`${lowerCaseTitle}[${fieldid}].closeAtDay`}>
              {({ field }) => (
                <Select doubleIcon width={500} value="PM" {...field}>
                  <option value="AM">AM</option>
                  <option value="PM">PM</option>
                </Select>
              )}
            </Field>
          </div>
        </div>
      </div>
    );
  }
);
