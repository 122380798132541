import React from "react";
import { ReactComponent as AttachmentIcon } from "./assets/file-text.svg";
import "./style.scss";

interface IAttachment {
  text: string | React.ReactElement;
  style?: React.CSSProperties;
  fontSize?: string;
}
export const Attachment = ({ text }: IAttachment) => {
  return (
    <p className="hello">
      <AttachmentIcon /> &nbsp;
      {text}
    </p>
  );
};
