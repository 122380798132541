import React, { useState } from "react";
import { Box, ButtonProps } from "../../_components";
import { ReactComponent as Star } from "@samedaycustom/assets/images/ratingStar.svg";

export interface IRatings extends ButtonProps {
  totalRatings: number;
  rating: number;
  onRatingChange?: (value: number) => void; // to implement
}
export const Ratings = ({ totalRatings, rating, onChange }: IRatings) => {
  const _start = new Array(totalRatings).fill(0).map((o, i) => {
    return <Star fill={`${i >= rating ? "#9FB3C8" : "#FADB5F"} `} />;
  });
  return (
    <Box d="inline-flex" justifyContent="space-between">
      {_start}
    </Box>
  );
};
