import { ISDCMessages } from "@samedaycustom/types/app/Messaging";

export const START_MESSAGES_ACTION = "START_MESSAGES_ACTION";
export const FAIL_MESSAGES_ACTION = "FAIL_MESSAGES_ACTION";
export const GET_MESSAGES_THREADS_LIST = "GET_MESSAGES_THREADS_LIST";
export const GET_MESSAGES = "GET_MESSAGES";
export const RESET_MESSAGES = "RESET_MESSAGES";
export const START_FETCHING_MESSAGES = "START_FETCHING_MESSAGES";
export const ERROR_FETCHING_MESSAGES = "ERROR_FETCHING_MESSAGES";
export const GET_NEW_MESSAGE = "GET_NEW_MESSAGE";
export const GET_NEW_NOTE = "GET_NEW_NOTE";
export const CLEAR_MESSAGING_STATE = "CLEAR_MESSAGING_STATE";
export const UPDATE_MESSAGE_NOTIFICATION_STATUS = "UPDATE_MESSAGE_NOTIFICATION_STATUS";
export const UPDATE_UNREAD_MESSAGE_COUNT = "UPDATE_UNREAD_MESSAGE_COUNT";
export const MARK_AS_READ = "MARK AS READ";
export const MARK_AS_UNREAD = "MARK AS UNREAD";
export const UPDATE_MESSAGE_THREAD_COUNT = "UPDATE_MESSAGE_THREAD_COUNT";

export type ALL_MESSAGES_ACTION_TYPES =
  | GET_MESSAGES_THREADS_LIST
  | GET_NEW_MESSAGE
  | CLEAR_MESSAGING_STATE;

interface ISTART_MESSAGES_ACTION {
  type: typeof START_MESSAGES_ACTION;
  payload: ALL_MESSAGES_ACTION_TYPES;
}

interface IFAIL_MESSAGES_ACTION {
  type: typeof FAIL_MESSAGES_ACTION;
  payload: {
    type: ALL_MESSAGES_ACTION_TYPES;
    error: string;
  };
}

interface IGET_CONVERSATIONS {
  type: typeof GET_MESSAGES_THREADS_LIST;
  payload: Array<ISDCMessages>;
}

interface IGET_MESSAGE {
  type: typeof GET_NEW_MESSAGE;
  payload: ISDCMessages;
}
interface IGET_MESSAGE_NOTIFICATION {
  type: typeof GET_NEW_MESSAGE;
  isNew: boolean;
}

interface IMARK_AS_READ {
  type: typeof MARK_AS_READ;
  payload: {
    orderID: string;
  };
}

interface IMARK_AS_CUSTOMER_UNREAD {
  type: typeof MARK_AS_CUSTOMER_UNREAD;
  payload: {
    orderIds: string[];
  };
}

export type MESSAGES_ACTION_TYPE =
  | typeof ISTART_MESSAGES_ACTION
  | typeof IFAIL_MESSAGES_ACTION
  | typeof IGET_MESSAGES_THREADS_LIST
  | typeof IGET_MESSAGE
  | typeof ICLEAR_MESSAGING_STATE
  | typeof IGET_MESSAGE_NOTIFICATION
  | typeof IMARK_AS_READ
  | typeof UPDATE_MESSAGE_THREAD_COUNT;
