import { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

// @ts-ignore
const _hsq = (window._hsq = window._hsq || []);

// hubspot connection component
class AnalyticsListener extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
  };
  componentDidMount() {
    //@ts-ignore
    this.sendPageView(this.props.history.location);
    //@ts-ignore
    this.props.history.listen(this.sendPageView);
  }

  sendPageView(location: any) {
    _hsq.push(["setPath", location.pathname]);
    _hsq.push(["trackPageView"]);
  }

  render() {
    return this.props.children;
  }
}
//@ts-ignore
export default withRouter(AnalyticsListener);
