import { generate } from "shortid";
import { ProductionSpeed } from "@samedaycustom/types/app/Settings";
export const ProductionSpeedInitialStateData: ProductionSpeed = {
  id: generate(),
  maxQty: 10,
  minQty: 1,
  time: 1,
  timeUnit: "hour",
  upperBound: 0,
  superRushOrder: {
    timeUnit: "hour",
    time: 0,
    cost: 0,
    available: false,
    costUnit: "percent",
  },
  rushOrder: {
    available: false,
    costUnit: "percent",
    timeUnit: "hour",
    time: 0,
    cost: 0,
  },
  isDefault: true,
};

export const ProductionSpeedState: {
  data: ProductionSpeed[];
  value?: ProductionSpeed;
} = {
  data: [],
  value: ProductionSpeedInitialStateData,
};
