import React from "react"
import ContentLoader from "react-content-loader"

interface OperationHeaderLoaderProps {
    color?: string
    backgroundColor?: string
    speed?: number
    width?: number
    height?: number
}
const OperationHeaderLoader = ({
    color,
    backgroundColor,
    height,
    speed,
    width,
}: OperationHeaderLoaderProps) => (
    <ContentLoader
        speed={speed || 2}
        width={width || 1240}
        height={height || 320}
        viewBox={`0 0 ${width || "1240"} ${height || "320"}`}
        backgroundColor={backgroundColor || "#f3f3f3"}
        foregroundColor={color || "#00ffff"}
        style={{zIndex: 1}}
    >
        <rect x="5" y="10" rx="5" ry="5" width="124" height="25" />
        <rect x="5" y="80" rx="5" ry="5" width="124" height="23" />
        <rect x="224" y="154" rx="5" ry="5" width="124" height="23" />
        <rect x="224" y="10" rx="5" ry="5" width="124" height="24" />
        <rect x="5" y="150" rx="5" ry="5" width="124" height="23" />
        <rect x="224" y="80" rx="5" ry="5" width="124" height="24" />
        <rect x="734" y="10" rx="12" ry="12" width="192" height="158" />
        <rect x="950" y="10" rx="12" ry="12" width="192" height="158" />
    </ContentLoader>
)

export default OperationHeaderLoader
