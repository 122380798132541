import React from "react";
import { Box, Text } from "../../_components";
export default ({ message, EmptyIcon }) => (
  <Box
    d="flex"
    flexDirection="row"
    width="100%"
    alignItems="center"
    justifyContent="center"
    height="480px"
  >
    <Box
      d="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      textAlign="center"
    >
      {EmptyIcon}
      <Text paddingTop="1rem" width="80%">
        {message}
      </Text>
    </Box>
  </Box>
);
