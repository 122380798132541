import { days, listOfDays, sortDays } from "@samedaycustom/helpers";

import { isEmpty, keyBy } from "lodash";

import { setupWorkHoursState } from "./intialstate";
import {
  ISetupWorkHoursDaysOfWeekType,
  SETUP_WORK_HOURS_DATA,
} from "@samedaycustom/types/app/Settings";
import { UPDATE_SETUP_WORKS } from "vendor/providers/actions/App/Settings/Store/SetupWorkhour/types";
export const setupWorkHours = (
  state = setupWorkHoursState,
  action: {
    type: string;
    data?: ISetupWorkHoursDaysOfWeekType;
    id?: number;
    values: { values: SETUP_WORK_HOURS_DATA; formValues: any };
  }
): ISetupWorkHoursDaysOfWeekType => {
  switch (action.type) {
    case UPDATE_SETUP_WORKS: {
      /** reformation and parsing of data from backend */
      const data = Object.entries(action.data).map((element) => {
        /** parse the data to be read by frontend */
        const newDays: any = element[element.length - 1];
        newDays.day = days[element[0]];
        newDays.key = element[0];
        return newDays;
      });
      const sorted = sortDays(data, listOfDays);
      const reformedData = keyBy(sorted, "key");

      const result = !isEmpty(reformedData) ? reformedData : setupWorkHoursState;
      return result as any;
    }
    default:
      return state;
  }
};
