import { createAsyncThunk } from "@reduxjs/toolkit";
import qs from "query-string";
import { ESDCUserNotificationType } from "vendor/components/ViewNotification/Notification";
import API from "vendor/api/Api.constants";
import { api } from "vendor/providers/api";

export const getNotifications = createAsyncThunk(
  "notifications/getNotifications",
  async (param?: { last_created_at?: string; type?: ESDCUserNotificationType | "" }) => {
    let response;
    try {
      response = await api
        .get(`${API.NOTIFICATION}/?${qs.stringify(param)}`)
        .catch((err) => Promise.reject(err.response?.data?.message));
    } catch (error) {
      return Promise.reject(error);
    }

    const data = response?.data?.data;
    return data;
  }
);

export const getUnreadNotifications = createAsyncThunk(
  "notifications/getUnreadNotifications",
  async (_D, { getState }) => {
    const auth = getState()?.auth;
    let response;
    try {
      response = await api.get(`${API.NOTIFICATION}/unread`, {
        headers: { Authorization: "Bearer " + auth?.token },
      });
    } catch (error) {
      null; // TODO: handle error
    }
    const data = Number(response?.data?.data?.[0]?.count || 0);
    return data;
  }
);
