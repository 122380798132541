import { createSlice, PayloadAction, SliceCaseReducers } from "@reduxjs/toolkit";
import { cloneDeep, merge } from "lodash";
import {
  ILocationProductAvailabilityPayloadAction,
  ILocationProductColorsPayloadAction,
  ISDCLocationProduct,
} from "vendor/models/IStoreLocationProduct";
import { QueryResponse } from "vendor/models/Response";

const initialState: ISDCLocationProduct[] = [];

const locationProductSplice = createSlice<
  ISDCLocationProduct[],
  SliceCaseReducers<ISDCLocationProduct[]>
>({
  name: "locationProducts",
  initialState,
  reducers: {
    setProducts(
      state: ISDCLocationProduct[],
      action: PayloadAction<QueryResponse<ISDCLocationProduct[]>>
    ) {
      state.push(...cloneDeep(action.payload?.data));
    },
    updateProductAvailability(
      state,
      action: PayloadAction<ILocationProductAvailabilityPayloadAction>
    ) {
      const productToUpdate = state.find((product) => product.id === action.payload.productId);

      const productToUpdateIndex = state.findIndex(
        (product) => product.id === action.payload.productId
      );

      if (productToUpdate?.id) {
        const isAvailable = action.payload.isAvailable;

        Object.assign(productToUpdate, {
          isAvailable: isAvailable,
          colors: !isAvailable
            ? productToUpdate.colors?.map((cl) => ({ ...cl, isAvailable: false }))
            : productToUpdate?.colors,
        });

        state[productToUpdateIndex] = productToUpdate;
      }
    },

    updateProductColors(state, action: PayloadAction<ILocationProductColorsPayloadAction>) {
      const productToUpdate = state.find((product) => product.id === action.payload.productId);

      const productToUpdateIndex = state.findIndex(
        (product) => product.id === action.payload.productId
      );

      if (productToUpdate?.id) {
        const updatedProductColors = merge(productToUpdate.colors, action.payload.colors);
        productToUpdate.colors = updatedProductColors;

        state[productToUpdateIndex] = productToUpdate;
      }
    },
  },
});

export const {
  updateProductColors,
  updateProductAvailability,
  setProducts,
} = locationProductSplice.actions;

export const locationProductsSelector = (state: any): ISDCLocationProduct[] =>
  state[locationProductSplice.name];

export default locationProductSplice.reducer;
