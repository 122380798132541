import { Fallback } from "@samedaycustom/core-ui";
import qs from "query-string";
import React from "react";
import { useDispatch } from "react-redux";
import * as onboardingAsyncActions from "vendor/providers/actions/onboarding";

const RouteValidator = ({ children }: { children: any }) => {
  const dispatch = useDispatch();
  const [canRenderChildern, setcanRenderChildern] = React.useState(false);

  // route based token signing or verification
  const checkTokenVerification = async () => {
    if (window.location.search) {
      try {
        const query = qs.parse(window.location.search);
        let token = query["token"] as string;
        const admin = Boolean(query["admin"]) as boolean;
        if (token) {
          if (admin) {
            // get token from base64
            token = atob(token);
            await dispatch(onboardingAsyncActions.tokenSigninAsync(token));
            if (query?.orderID) {
              localStorage.setItem("TOKEN_VIEW_ORDER", JSON.stringify(query));
              setcanRenderChildern(true);
            }
          }
        }
      } catch (error) {
        setcanRenderChildern(true);
      }
    }
    setcanRenderChildern(true);
    return;
  };

  React.useLayoutEffect(() => {
    checkTokenVerification();
  }, []);

  return React.useMemo(() => {
    return canRenderChildern ? children : <Fallback />;
  }, [canRenderChildern, children]);
};

export default RouteValidator;
