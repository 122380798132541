import { Box, Text } from "../../_components";
import React from "react";
import { generate } from "shortid";

export type ListHead = {
  title: string;
  content?: string;
};
export type ListRow = {
  text: string;
  content?: string;
  textStyle?: Object;
  contentStyle?: Object;
};
export interface IListTable {
  head: Array<ListHead>;
  row: Array<Array<ListRow>>;
  fetching?: boolean;
}
export default ({ head, row, fetching }: IListTable) => {
  return (
    <Box>
      <Box
        d="grid"
        gridTemplateColumns="repeat(auto-fit, minmax(5rem, 1fr))"
        backgroundColor="#F0F4F8"
        justifyItems="flex-end"
        alignItems="center"
        // height="3rem"
        padding="0.6rem"
        key={generate()}
      >
        {head &&
          head.map(el => (
            <Box
              d="flex"
              flexDirection="row"
              width="60%"
              justifyContent="space-between"
              alignItems="center"
            >
              <Text
                color="#486581"
                fontSize="13px"
                fontFamily="Inter"
                fontWeight={500}
              >
                {el.title}
              </Text>
              <Text
                color="#334E68"
                fontSize="15px"
                fontFamily="Inter"
                fontWeight={500}
              >
                {el.content}
              </Text>
            </Box>
          ))}
      </Box>
      {/* <Box d="flex" flexDirection="column">
                {row &&
                    row.map(element => (
                        <Box
                            d="grid"
                            gridTemplateColumns="repeat(auto-fit, minmax(5rem, 1fr))"
                            justifyItems="flex-end"
                            alignItems="center"
                            padding="0.6rem"
                        >
                            {(!fetching &&
                                element &&
                                element.map(el => (
                                    <Box
                                        d="flex"
                                        flexDirection="row"
                                        width="60%"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <Text
                                            color="#486581"
                                            fontSize="0.8rem"
                                            fontFamily="Inter" fontWeight={500}
                                            paddingRight="0.8rem"
                                            {...el.textStyle}
                                        >
                                            {el.text && el.text}
                                        </Text>
                                        {el.content && (
                                            <Text
                                                color="#334E68"
                                                fontSize="13px"
                                                fontFamily="Inter" fontWeight={500}
                                                {...el.contentStyle}
                                            >
                                                {el.content}
                                            </Text>
                                        )}
                                    </Box>
                                ))) || <Spinner size="small" />}
                        </Box>
                    ))}
            </Box> */}
    </Box>
  );
};
