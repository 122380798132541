/// <reference types="react-scripts" />
import "./style.scss";

import { Box, Text } from "../_components";
// @ts-ignore
import { downloadUrl } from "@samedaycustom/helpers";
import React, { useContext } from "react";
import usePortal from "react-useportal";

import { ReactComponent as DownloadOutline } from "./download-outline.svg";

export type IProductionTopPanel = {
  worksheet: string;
  id: string;
};

export const topPanelContext = React.createContext<{
  show: boolean;
  payload: IProductionTopPanel[];
  onBackClick: () => void;
}>({
  show: false,
  payload: [
    {
      worksheet: "",
      id: ""
    }
  ],
  onBackClick: null
});

export const TopPanel = React.memo(() => {
  const { Portal } = usePortal();
  const { onBackClick, payload: select, show } = useContext(topPanelContext);
  return (
    show && (
      <Portal>
        <Box
          background="#102A43"
          color="#fff"
          height="60px"
          d="flex"
          flexDirection="row"
          position="fixed"
          top="0"
          justifyContent="space-between"
          zIndex={999}
          alignItems="center"
          paddingLeft="1rem"
          paddingRight="1rem"
          width="calc(100%)"
          className={`topPanel__container${!show ? "--hide" : "--show"}`}
          transition="all 1s ease-in-out"
        >
          <Box
            d="flex"
            flexDirection="row"
            width="20%"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box
              d="flex"
              flexDirection="row"
              alignItems="center"
              cursor="pointer"
              onClick={onBackClick}
            >
              <i className="fas fa-arrow-left"></i>
              <Text paddingLeft="10px" fontFamily="Inter" fontWeight={500}>
                Back
              </Text>
            </Box>
            <Text>{select && select.length} Items selected</Text>
          </Box>
          <Box
            d="flex"
            flexDirection="row"
            width="50%"
            justifyContent="flex-end"
            paddingRight="1rem"
          >
            <Text
              d="flex"
              cursor="pointer"
              alignItems="center"
              flexDirection="row"
              onClick={(e: any) => {
                for (const element of select) {
                  downloadUrl(element?.worksheet);
                }
              }}
            >
              <DownloadOutline style={{ marginRight: "10px" }} />
              Download worksheet
            </Text>
          </Box>
        </Box>
      </Portal>
    )
  );
});
