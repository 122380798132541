import { Box, Text } from "../../_components";
import { ReactComponent as Folder } from "../assets/folder-plain.svg";
import React from "react";

export default ({ title, showLine, onDownload }) => (
  <Box
    d="flex"
    justifyContent="space-between"
    width="100%"
    alignItems="center"
    padding="0.8rem"
    borderBottom={(showLine && "1px solid #D9E2EC") || "none"}
  >
    <Box d="flex" alignItems="center">
      <Folder />
      <Text paddingLeft="0.8rem">{title}</Text>
    </Box>
    <Box d="flex" alignItems="center" color="#007BFF">
      {/* <Text fontFamily="Inter" fontWeight={500} cursor="pointer">
                Print
            </Text> */}
      <Text
        paddingLeft="1rem"
        fontFamily="Inter"
        fontWeight={500}
        cursor="pointer"
        onClick={onDownload}
      >
        Download
      </Text>
    </Box>
  </Box>
);
