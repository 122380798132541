import React from "react";
import "./style.scss";
import { ReactComponent as PreviewImg } from "../assets/messagePreviewImage.svg";
import { ISDCMessages } from "@samedaycustom/types/app/Messaging";
import { ReactComponent as NoImage } from "@samedaycustom/assets/images/imgNone.svg";

interface Props {
	orderDetails: string;
	Message: ISDCMessages;
	dateTime: string;
	onClick: () => any;
}

export default ({ orderDetails, Message, dateTime, onClick }: Props) => {
	let lastMessageContent = null;
	if (Message.lastMessageID) {
		if (Message?.messages[Message?.lastMessageID]?.originalMessageID) {
			lastMessageContent = Message?.messages[
				Message?.messages[Message.lastMessageID]?.originalMessageID
			]?.options?.selected
				? "Yes I approve"
				: "No I do not approve";
		} else {
			if (Message?.messages[Message?.lastMessageID]?.content) {
				lastMessageContent =
					Message?.messages[Message.lastMessageID]?.content;
			} else {
				if (
					Message?.messages[Message?.lastMessageID]?.files?.length > 0
				) {
					lastMessageContent = (
						<span className="recentImages">
							{" "}
							<NoImage />{" "}
							<span>
								{" "}
								File{" "}
								{Message.messages[Message.lastMessageID]?.files
									?.length > 1
									? `x${
											Message.messages[
												Message.lastMessageID
											]?.files?.length
									  }`
									: ""}{" "}
							</span>{" "}
						</span>
					);
				} else {
					lastMessageContent = "Start a new conversation";
				}
			}
		}
	} else {
		lastMessageContent = "Start a new conversation";
	}

	return (
		<div
			className="orderPreviewGroup msgPrev"
			style={{ cursor: "pointer" }}
			onClick={onClick}
		>
			<div className="rightPreview">
				<PreviewImg />
				<div className="rightPreviewText">
					<h2> {orderDetails} </h2>
					<p> {lastMessageContent} </p>
					{/* <span className="previewText">
						{" "}
						{Message?.length > 25
							? Message?.substring(0, 25) + " ..."
							: Message}{" "}
					</span> */}
				</div>
			</div>
			<div className="leftPreview">
				<div className="leftPreviewText colflexStart">
					<span className="previewText">{dateTime}</span>
				</div>
			</div>
		</div>
	);
};
