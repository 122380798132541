import "./style.scss";

import { DatePicker } from "@atlaskit/datetime-picker";
import { Box, ChakraTag as Tag, TagCloseButton, TagLabel, Text } from "../_components";
import { ReactComponent as Filter } from "@samedaycustom/assets/images/filter.svg";
import {
  AlternativeDropDowns,
  DefaultButton,
  ErrorMessage,
  PrimaryButton
} from "@samedaycustom/core-ui";
import { Formik } from "formik";
import moment from "moment";
import React, { CSSProperties } from "react";

export type FilterTag = {
  id: string | number;
  text: string;
  tagStyle?: CSSProperties;
};
export type FilterValue = {
  delivery: string;
  status: string;
  order: FilterDate;
  due: FilterDate;
};
export type FilterDate = {
  start: string | number;
  end: string | number;
};

export interface FilterWithTag {
  StatusOptions: Array<{
    value: string;
    label: string;
  }>;
  filterText?: string;
  initialValue?: FilterValue;
  tagOptions?: Array<FilterTag>;
  onFilterClick?: (value: FilterValue) => void;
  onFilterReset?: (e: any) => void;
  onTagRemove?: (tag?: FilterTag) => void;
  hideDelivery?: boolean;
}
export const FilterContent = React.memo(
  ({
    filterText,
    tagOptions,
    onFilterClick,
    onFilterReset,
    initialValue,
    StatusOptions,
    onTagRemove,
    hideDelivery
  }: FilterWithTag) => {
    const [showFilter, setshowFilter] = React.useState(false);
    const [tag, setTag] = React.useState(tagOptions);
    const onTagRemoveClick = (tagId?: string | number) => {
      const newTag = tag.filter(el => el.id !== tagId);
      setTag(newTag);
      onTagRemove && onTagRemove(tag.find(el => el.id === tagId));
    };
    const initialFilter = {
      delivery: "All",
      status: "All",
      order: {
        start: "",
        end: ""
      },
      due: {
        start: "",
        end: ""
      }
    };

    const statusItems = StatusOptions?.map((o) => ({
      label: o?.label,
      value: o.value
    }));

    const FilterContent = () => (
      <Box>
        <Box
          position="fixed"
          top="0"
          left="0"
          right="0"
          bottom="0"
          height="100vh"
          zIndex={9999}
          onClick={() => setshowFilter(false)}
        />
        <Box
          background="#FFFFFF"
          boxShadow="0px 4px 12px rgba(98, 125, 152, 0.32)"
          position="absolute"
          className="filterContainer"
          color="#486581"
          id="picker"
          fontFamily="Inter"
          fontWeight={500}
        >
          <Formik
            enableReinitialize
            initialValues={initialValue}
            onSubmit={onFilterClick}
            onReset={onFilterReset}
          >
            {({
              values,
              // handleReset,
              handleSubmit,
              // handleChange,
              setFieldValue,
              resetForm,
              // initialValues,
              setValues,
              // setErrors,
              setFieldError,
              // errors
            }) => {
              const status =
                values?.status === "S"
                  ? "P.S"
                  : values?.status === "C"
                  ? "P.C"
                  : values?.status;
              return (
                <>
                  {!hideDelivery && (
                    <Box marginBottom="1rem" className="Filter__content">
                      <Text>Delivery Method</Text>
                      <AlternativeDropDowns
                        onClick={(e: any) => {
                          setValues({
                            ...values,
                            delivery: e
                          });
                        }}
                        placeholder="All"
                        className="Filter__dropdown"
                        value={values.delivery}
                        loading={false}
                        position="bottom center"
                        listItems={[
                          {
                            label: "All",
                            value: "All"
                          },
                          {
                            label: "Pickup",
                            value: "pickup"
                          },
                          {
                            label: "Car",
                            value: "car"
                          },
                          {
                            label: "Shipping",
                            value: "shipping"
                          }
                        ]}
                      />
                    </Box>
                  )}
                  <Box marginBottom="1rem" className="Filter__content">
                    <Text>Status</Text>
                    <AlternativeDropDowns
                      onClick={(e: any) => {
                        setValues({
                          ...values,
                          status: e
                        });
                      }}
                      placeholder="All"
                      value={status}
                      className="Filter__dropdown"
                      loading={false}
                      position="bottom center"
                      listItems={statusItems}
                    />
                  </Box>
                  <Box marginBottom="1rem" className="__content">
                    <Text>Order Date</Text>
                    <Box d="flex" flexDirection="row" width="100%">
                      <Box className="datePicker">
                        <DatePicker
                          value={values.order.start.toString()}
                          onChange={date => {
                            if (
                              values.order.end.toString() &&
                              moment(date).isAfter(values.order.end.toString())
                            ) {
                              setFieldError(
                                "order.start",
                                `Order start date must be earlier than order end date ${values.order.end.toString() &&
                                  moment(values.order.end.toString()).format(
                                    "DD MMM YYYY"
                                  )}`
                              );
                            } else setFieldValue("order.start", date);
                          }}
                          placeholder="Start Date"
                          locale="en-US"
                          hideIcon={true}
                        />
                      </Box>
                      <Box className="datePicker">
                        <DatePicker
                          value={values.order.end.toString()}
                          onChange={date => {
                            if (moment(date).isAfter(new Date(), "day")) {
                              setFieldError(
                                "order.end",
                                `End date must be today ${new Date().toDateString()}`
                              );
                              return;
                            }
                            if (
                              values.order.start.toString() &&
                              moment(date).isBefore(
                                values.order.start.toString(),
                                "day"
                              )
                            ) {
                              setFieldError(
                                "order.end",
                                `Order end date must be later than order start date ${values.order.start.toString() &&
                                  moment(values.order.start.toString()).format(
                                    "DD MMM YYYY"
                                  )}`
                              );

                              return;
                            }
                            setFieldValue("order.end", date);
                          }}
                          disabled={[values.order.start.toString()]}
                          placeholder="End Date"
                          locale="en-US"
                          hideIcon={true}
                        />
                      </Box>
                    </Box>
                    <ErrorMessage name="order.start" />
                    <ErrorMessage name="order.end" />
                  </Box>
                  <Box marginBottom="1rem" className="__content">
                    <Text>Due Date</Text>
                    <Box d="flex" flexDirection="row" width="100%">
                      <Box className="datePicker">
                        <DatePicker
                          value={values.due.start.toString()}
                          onChange={date => {
                            if (
                              values.due.end.toString() &&
                              moment(date).isAfter(values.due.end.toString())
                            ) {
                              setFieldError(
                                "due.start",
                                `Due start date must be earlier than due end date ${values.due.end.toString() &&
                                  moment(values.due.end.toString()).format(
                                    "DD MMM YYYY"
                                  )}`
                              );
                            } else setFieldValue("due.start", date);
                          }}
                          placeholder="Start Date"
                          locale="en-US"
                          hideIcon={true}
                        />
                      </Box>
                      <Box className="datePicker">
                        <DatePicker
                          value={values.due.end.toString()}
                          onChange={date => {
                            // if (moment(date).isAfter(new Date(), "day")) {
                            //   setFieldError(
                            //     "due.end",
                            //     `End date must be today ${new Date().toDateString()}`
                            //   );
                            //   return;
                            // }
                            if (
                              moment(date).isBefore(
                                values.due.start.toString(),
                                "day"
                              )
                            ) {
                              setFieldError(
                                "due.end",
                                `Please select order due date later than ${moment(
                                  values.due.start.toString()
                                ).format("DD MMM YYYY")}`
                              );
                              return;
                            }
                            setFieldValue("due.end", date);
                          }}
                          disabled={[values.due.end.toString()]}
                          placeholder="End Date"
                          locale="en-US"
                          hideIcon={true}
                        />
                      </Box>
                    </Box>
                    <ErrorMessage name="due.start" />
                    <ErrorMessage name="due.end" />
                  </Box>
                  <Box
                    d="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <DefaultButton
                      onClick={() =>
                        resetForm({
                          values: initialFilter
                        })
                      }
                    >
                      Reset
                    </DefaultButton>
                    <PrimaryButton onClick={handleSubmit as any}>
                      <Text>Filter</Text>
                    </PrimaryButton>
                  </Box>
                </>
              );
            }}
          </Formik>
        </Box>
      </Box>
    );
    return (
      <Box
        d="flex"
        flexDirection="row"
        alignItems="center"
        minWidth={230}
        fontSize="1rem"
      >
        <Box d="flex" flexDirection="row" alignItems="center">
          <Box position="relative">
            <Filter
              fill="#627D98"
              onClick={() => setshowFilter(!showFilter)}
              style={{
                cursor: "pointer",
                height: "12px",
                width: "12px",
                marginTop: "2px"
              }}
            />
            {showFilter && <FilterContent />}
          </Box>
          <Text
            fontFamily="Inter Bold"
            paddingLeft="0.6rem"
            onClick={() => setshowFilter(!showFilter)}
            cursor="pointer"
          >
            {filterText || "Filter By:"}
          </Text>
        </Box>
        <Box paddingLeft="1rem">
          {tag &&
            tag.map((item, i) => (
              <Tag
                key={i}
                size={"md"}
                borderRadius="2px"
                variant="solid"
                marginRight="5px"
                className="tag-container"
                style={item.tagStyle}
              >
                <TagLabel
                  fontFamily="Inter"
                  fontWeight={500}
                  textTransform="capitalize"
                  color="#243B53"
                >
                  {item.text}
                </TagLabel>
                <TagCloseButton
                  color="#627D98"
                  onClick={() => onTagRemoveClick(item.id)}
                />
              </Tag>
            ))}
        </Box>
      </Box>
    );
  }
);
