import * as Sentry from "@sentry/browser";
import dotenv from "dotenv";
import { initializeMixpanel } from "packages/helpers/src/mixpanel"
import * as serviceWorker from "./serviceWorker";

dotenv.config();

export const Stage = {
  PROD: "production",
  STAG: "staging",
  DEV: "dev",
};

export const log = {
  consoleEnabled: process.env.REACT_APP_CONSOLE_LOGS_ENABLED === "true",
  level: process.env.REACT_APP_LOG_LEVEL || "warn",
};

export const sentryDSN = process.env.REACT_APP_SENTRY_DSN;

export const version = process.env.REACT_APP_VERSION;

export function currentDomain(): string {
  return window.location.host;
}

export const isJest = process.env.REACT_APP_NODE_ENV === "test";

export const isProd = (): boolean => process.env.REACT_APP_NODE_ENV === Stage.PROD;

export const isLocal = (): boolean => process.env.REACT_APP_NODE_ENV === Stage.DEV;

export function initialize(): void {
  if (process.env.REACT_APP_NODE_ENV !== "development") {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
    });
  }
  initializeMixpanel();
  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.register({ env: process.env.REACT_APP_NODE_ENV as any });
}
