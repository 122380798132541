import React from "react"
import ContentLoader from "react-content-loader"

export default () => (
    <ContentLoader
        speed={1}
        width={500}
        height={160}
        viewBox="0 0 500 160"
        backgroundColor="#f3f3f3"
        foregroundColor="#196BD82F"
    >
        <rect x="113" y="20" rx="3" ry="3" width="140" height="11" />
        <circle cx="30" cy="25" r="15" />
        <rect x="112" y="42" rx="3" ry="3" width={window.innerWidth} height="100" />
    </ContentLoader>
)
