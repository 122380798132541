import React from "react";
import { Text, Box } from "@chakra-ui/react";

import { ReactComponent as FlySend } from "../../assets/flysend.svg";
import { ReactComponent as Attachment } from "../../assets/file-text.svg";
import { Tag } from "@samedaycustom/core-ui";
export interface IQuoteCards {
  title?: string;
  tagtext?: string;
  subtitleText?: string;
  content?: React.ReactElement | string;
  footerText?: string;
  attachmentText?: string;
  onSendClick?: () => void;
}
export default ({
  content,
  footerText,
  subtitleText,
  tagtext,
  title,
  attachmentText,
  onSendClick
}: IQuoteCards) => {
  return (
    <Box
      backgroundColor="#F0F4F8"
      borderRadius="16px"
      padding="0.9rem"
      marginRight="2rem"
      fontSize="0.9rem"
    >
      <Box
        d="flex"
        flexDirection="row"
        color="#334E68"
        alignItems="center"
        paddingBottom="2px"
        justifyContent="space-between"
      >
        {title && (
          <Text
            d="flex"
            flexDirection="row"
            fontFamily="Inter"
            fontWeight={500}
            color="#334E68"
            alignItems="center"
          >
            {title} &nbsp;
            {tagtext && (
              <Tag backgroundColor="#0062CC" color="#fff" text={tagtext} />
            )}
          </Text>
        )}
        {attachmentText && (
          <Text
            d="flex"
            flexDirection="row"
            fontFamily="Inter"
            fontWeight={500}
            color="#334E68"
            alignItems="center"
          >
            <Attachment /> &nbsp;
            {attachmentText}
          </Text>
        )}
      </Box>
      {subtitleText && (
        <Text color="#627D98" fontFamily="Inter" fontWeight={500}>
          {subtitleText}
        </Text>
      )}
      <Text paddingBottom="10px" paddingTop="5px">
        {content}
      </Text>
      {footerText && (
        <Text
          color="#007BFF"
          d="flex"
          flexDirection="row"
          alignItems="center"
          fontFamily="Inter"
          fontWeight={500}
          cursor="pointer"
          onClick={onSendClick}
          width="fit-content"
        >
          <FlySend /> &nbsp; {footerText}
        </Text>
      )}
    </Box>
  );
};
