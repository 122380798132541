import React from "react";
import "./style.scss";
import { useField } from "formik";
import NumberFormat, { NumberFormatValues } from "react-number-format";

// the component can serve as a formatted number input and also a currency input
interface Iprops {
  inputConfig: {
    label?: string;
    placeholder?: string;
    id: string;
    name: string;
    type: string;
    min?: number;
    max?: number;
  };
  prefix?: string;
}

const FormmattedInput = ({ inputConfig }: Iprops) => {
  const [field, meta, helpers] = useField(inputConfig);
  const { value } = meta;
  const { setValue } = helpers;
  return (
    <div
      className={
        meta.error && meta.touched
          ? "InputGroup InputGroup--error"
          : "InputGroup"
      }
    >
      {inputConfig.label ? (
        <label htmlFor={inputConfig.id}>{inputConfig.label}</label>
      ) : null}
      <NumberFormat
        thousandSeparator={true}
        allowNegative={false}
        allowEmptyFormatting={false}
        allowLeadingZeros={false}
        displayType="input"
        isNumericString={true}
        value={value ? value : ""}
        onValueChange={({ floatValue }: NumberFormatValues) =>
          setValue(Math.round(floatValue))
        }
        className={"input"}
      />
      {meta.error && meta.touched ? (
        <p className="inputError"> {meta.error} </p>
      ) : null}
    </div>
  );
};

export default FormmattedInput;
