import './style.scss';

import { AuthedButton } from '@samedaycustom/core-ui';
import React from 'react';

// @ts-ignore
export { default as OperationEmptyView } from "./operationEmptyView";
export { default as MessageEmptyView } from "./MessageEmptyView";

type EmptyProps = {
  image: React.ElementType;
  keyword: string;
  action(): void;
};

export const EmptyView: React.FC<EmptyProps> = ({
  image: Image,
  keyword,
  action
}) => {
  return (
    <div className="EmptyState">
      <Image />
      <p className="emptyText">
        <span className="capitalizedText"> {keyword}s </span>
        that you have added to your orgainsation will show here. Create a{" "}
        {keyword} to get started.
      </p>
      <AuthedButton className="actionBtn Btn--Primary" onClick={action}>
        <span> Add a {keyword} </span>
      </AuthedButton>
    </div>
  );
};
