import "./style.scss";

import { Box, Text } from "../../_components";
import { ReactComponent as ArrowDown } from "../assets/arrow-down.svg";
import React, { ReactElement } from "react";
import { animated, useSpring } from "react-spring";

export interface FoldableTable {
  body?: React.ReactElement;
  isOpen?: boolean;
  onOpen?: () => void;
  rightHeader?: ReactElement | any;
}
export default ({ body, isOpen, onOpen, rightHeader }: FoldableTable) => {
  const [show, setshow] = React.useState(isOpen);
  const toggle = () => {
    setshow(!show);
    onOpen && onOpen();
  };
  const BODY_SHOW_HEIGHT = 200;
  const BODY_SHOW_Y = -20;
  const SHOW_ICON = -90;
  const BODY_SHOW_OPACITY = 1;
  const ZERO = 0;

  const { y, bodyY, bodyHeight, o } = useSpring({
    from: {
      y: ZERO,
      bodyY: ZERO,
      bodyHeight: ZERO,
      o: show ? ZERO : BODY_SHOW_OPACITY
    },
    y: show ? SHOW_ICON : ZERO,
    bodyY: show ? BODY_SHOW_Y : ZERO,
    o: show ? ZERO : BODY_SHOW_OPACITY,
    bodyHeight: show ? ZERO : BODY_SHOW_HEIGHT,
    config: { duration: 200 }
  });

  return (
    <Box
      background="transparent"
      padding="10px"
      border="1px solid #627D98"
      boxShadow="border-box"
      borderRadius="4px"
      className="foldable__container"
      height="fit-content"
    >
      <Box
        d="flex"
        flexDirection="row"
        justifyContent="space-between"
        className="__header"
      >
        <Box className="--title" d="flex" flexDirection="row">
          <animated.div
            style={{
              transform: y.interpolate(
                // @ts-ignore
                y => `rotate(${y}deg)`
              ),
              height: "10px",
              marginRight: "10px",
              marginTop: "6px",
              cursor: "pointer"
            }}
            onClick={toggle}
          >
            <ArrowDown fill="#102A43" width={15} height={15} />
          </animated.div>
          {rightHeader && rightHeader}
        </Box>
        <Text
          textDecoration="underline"
          color="#006EE5"
          fontFamily="Inter"
          fontWeight={500}
          fontSize="0.9rem"
          alignSelf="flex-end"
          cursor="pointer"
          userSelect="none"
          paddingRight="1.5rem"
          onClick={toggle}
        >
          {!show ? "Show less" : "Show more"}
        </Text>
      </Box>
      <animated.div
        style={{
          pointerEvents: show ? "none" : "all",
          transform: bodyY.interpolate(
            // @ts-ignore
            y => `translateY(${y}px)`
          ),
          height: bodyHeight.interpolate(
            height => `${height >= 150 ? "fit-content" : `${height}px`}`
          ),

          // @ts-ignore
          opacity: o.interpolate(
            [0.1, 0.2, 0.6, 1],
            // @ts-ignore
            [0, 0.1, 0.5, 1]
          ),
          overflow: "auto",
          paddingTop: o.interpolate(o => `${o}rem`)
        }}
      >
        {body}
      </animated.div>
    </Box>
  );
};
