import { useScrollRestoration } from "@samedaycustom/hooks";
import React from "react";
import { Redirect, Route, Switch } from "react-router";
import { SIGN_IN_PAGE } from "vendor/routes/screen";

const PrivateRoute = ({ component: Component, authed, ...rest }) => {
  useScrollRestoration();
  return (
    <Switch>
      <Route
        {...rest}
        render={(props) =>
          authed === true ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: `${SIGN_IN_PAGE}`,
                state: { from: props.location },
              }}
            />
          )
        }
      />
    </Switch>
  );
};

export default PrivateRoute;
