import * as apiConst from "vendor/api/Api.constants";

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import setRTKAuthorization from "vendor/helpers/setRTKAuthorization";
import { IPrintingMethodsData } from "vendor/models/IStoreLocationProduct";
import { QueryResponse } from "vendor/models/Response";

export const printingMethodsApi = createApi({
  reducerPath: "printingMethodsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${apiConst.BASE_URL}/stores/`,
    prepareHeaders: setRTKAuthorization,
  }),
  endpoints: (builder) => ({
    getPrintingMethods: builder.query<QueryResponse<IPrintingMethodsData>, any>({
      query: (locationId) => `${locationId}/printing_methods`,
    }),

    updatePrintingMethods: builder.mutation<any, any>({
      query: ({ locationId, printingMethods }) => ({
        url: `${locationId}/printing_methods`,
        method: "POST",
        body: { printingMethods },
      }),
    }),
  }),
});

export const { useGetPrintingMethodsQuery, useUpdatePrintingMethodsMutation } = printingMethodsApi;
