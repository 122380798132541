// PageData is union of any data that needs that will be used as a reset when leaving page

export interface FunctionPageLeaveAction<U> {
  <T>(...arg: T[]): Promise<U> | U;
}

export const PageEvents = {
  DIRTY: "DIRTY",
  CLEAN: "CLEAN",
  SUBMIT: "SUBMIT",
};

export type PageEventType = keyof typeof PageEvents;

export type PageEvent<
  PageData = unknown,
  PageSubmitReturnType = unknown,
  PageCancelReturnType = unknown
> = {
  action: PageEventType;
  data: PageData;
  submit?: FunctionPageLeaveAction<PageSubmitReturnType>;
  cancel?: FunctionPageLeaveAction<PageCancelReturnType>;
};

export const LOCAL_LEAVE_STORAGE_KEY = "LEAVE_EVENT";
