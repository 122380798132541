import React, { useEffect } from "react";
import { generate } from "shortid";
import "./style.scss";
import { StoreProfileVerticalNavigationProps } from "@samedaycustom/types/app";

export type Ref = Array<HTMLDivElement>;

export default React.forwardRef<Ref, StoreProfileVerticalNavigationProps>(
	({ options, target, setTarget }, ref) => {
		useEffect(() => {
			document.documentElement.style.setProperty(
				"--translateY",
				`${target * 60}px`
			);
		}, [target]);

		return (
			<div className={"VerticalNav"}>
				<div
					className="VerticalNav__background"
					style={{
						transform: `transformY(${target}px)`
					}}
				>
					{""}
				</div>
				{options.map((option, idx) => (
					<div
						key={generate()}
						className={"verticalOption"}
						onClick={() => setTarget(idx)}
						ref={(refs) => ref && (ref["current"][idx] = refs)}
					>
						<div className="__content">
							{option.leftIcon && (
								<div className="leftIcon">
									{option.leftIcon}
								</div>
							)}
							<span>{option.title}</span>
						</div>
					</div>
				))}
			</div>
		);
	}
);
