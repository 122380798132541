import "./style.scss";

import ChevronDownIcon from "@atlaskit/icon/glyph/chevron-down";
import { OptionType, PopupSelect } from "@atlaskit/select";
import { GroupType } from "@atlaskit/select/dist/cjs/types";
import { Box, Button, Text } from "@chakra-ui/react";
import { downloadUrl as download } from "@samedaycustom/helpers";
import { ReturnUploadType, Upload, UploadType } from "@samedaycustom/core-ui";
import { TeamTag } from "@samedaycustom/core-ui";
import { ISDCArtworkOption } from "@samedaycustom/types/order/@types/line";
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

export interface DesignCardProps {
  img?: {
    url: string;
    label: string;
    imageProp?: Object;
  };
  name: React.ReactNode | string;
  orderId: React.ReactNode | string;
  downloadUrl?: {
    production: string;
    source_art: string;
    editable: string;
  };
  onArtworkUpload: UploadType;
  onRemoveArtwork: ReturnUploadType;
  artworksOptions: ISDCArtworkOption[];
  onArtworkVersionsChange?: (option: ISDCArtworkOption) => void;
}

export default ({
  name,
  orderId,
  downloadUrl,
  img,
  onArtworkUpload,
  onRemoveArtwork,
  artworksOptions,
  onArtworkVersionsChange
}: DesignCardProps) => {
  const formatGroupLabel = (option: GroupType<OptionType>) => {
    return (
      <Text
        color="#829AB1"
        fontWeight={600}
        fontSize="11px"
        textTransform="uppercase"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
      >
        {option.heading}
      </Text>
    );
  };
  return (
    <Box d="flex" flexDirection="column">
      <Box paddingBottom="15px">
        <Text
          textTransform="capitalize"
          color="#334E68"
          fontFamily="Inter"
          fontWeight={500}
          wordBreak="break-all"
        >
          {name}
        </Text>
        {/* <Text
					color="#334E68"
					paddingBottom="1.7rem"
					wordBreak="break-all"
				>
					{orderId}
				</Text> */}
      </Box>
      <Box
        d="flex"
        flexDirection="row"
        height="100%"
        className="design-container"
        gridGap="3rem"
      >
        <Box position="relative" width="160px">
          <LazyLoadImage
            src={img.url}
            aria-label={img.label}
            alt={img.label}
            aria-role="image"
          />
        </Box>
        <Box
          fontSize="1rem"
          width="20rem"
          d="block"
          height="100%"
          flexDirection="column"
          justifyContent="flex-end"
          className="DesignCard"
        >
          {downloadUrl.production && (
            <Box marginBottom="12px">
              <TeamTag url={downloadUrl.production} />
            </Box>
          )}
          <Box width="80%" marginBottom="12px">
            <Upload
              type="doc"
              onUpload={onArtworkUpload}
              onRemovedFile={onRemoveArtwork}
              isMultiple={false}
              beforeText="Upload artwork here or"
            />
          </Box>
          <Text color="#334E68" fontFamily="Inter" fontWeight={500}>
            Download(s)
          </Text>
          {downloadUrl.production && (
            <Text
              color="#006EE5"
              fontFamily="Inter"
              fontWeight={500}
              fontSize="14px"
              paddingBottom="0.5rem"
              cursor="pointer"
              width="fit-content"
              onClick={() => download(downloadUrl.production)}
            >
              Production File (DTG) - Original
            </Text>
          )}

          {downloadUrl?.source_art && (
            <Text
              color="#006EE5"
              fontFamily="Inter"
              fontWeight={500}
              fontSize="14px"
              paddingBottom="0.5rem"
              cursor="pointer"
              width="fit-content"
              onClick={() => download(downloadUrl.source_art)}
            >
              Source Artwork (DTG) - Original
            </Text>
          )}

          <Box className="DesignCard__Select">
            {artworksOptions &&
              Array.isArray(artworksOptions) &&
              artworksOptions.length >= 1 && (
                <PopupSelect
                  isSearchable={false}
                  className="artwork_select"
                  classNamePrefix="react-select"
                  formatGroupLabel={formatGroupLabel}
                  target={({ ref }) => (
                    <Button
                      ref={ref}
                      width="100%"
                      color="#007bff"
                      fontWeight={500}
                      fontSize="14px"
                      height="100%"
                      background="none"
                    >
                      Artwork versions
                      <ChevronDownIcon label="downicon" />
                    </Button>
                  )}
                  popperProps={{
                    placement: "bottom",
                    strategy: "fixed"
                  }}
                  searchThreshold={10}
                  onChange={value =>
                    onArtworkVersionsChange(value as ISDCArtworkOption)
                  }
                  options={[
                    {
                      heading: "All artwork versions",
                      options: artworksOptions
                    }
                  ]}
                  placeholder="Artwork versions"
                />
              )}
          </Box>
          {/* </Box> */}
        </Box>
      </Box>
    </Box>
  );
};
