import { useScrollRestoration } from "@samedaycustom/hooks";
import { AUTH_STATE_TYPE } from "@samedaycustom/types/app";
import React from "react";
import { DefaultRootState, useDispatch, useSelector } from "react-redux";
import { Redirect, Route } from "react-router";
import { DASHBOARD, OPERATION_VIEW_ORDER, WELCOME_PAGE } from "vendor/routes/screen";

import { travseToAccessibleRoute } from "vendor/helpers/helperFunctions/accessGiver";

export const ProtectedRoute = ({ component: Component, authed, ...rest }) => {
  const dispatch = useDispatch();
  const authSelector = useSelector<DefaultRootState, AUTH_STATE_TYPE>((state) => state["auth"]);
  useScrollRestoration();

  return (
    <Route
      {...rest}
      render={(props) =>
        authed === false ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: getNextPage(authSelector)(dispatch),
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const getNextPage = (authSelector: AUTH_STATE_TYPE) => (dispatch) => {
  const isAdmin = authSelector?.isAdmin;
  const viewOrderTokenData = localStorage.getItem("TOKEN_VIEW_ORDER") || "";
  // if user is not an admin then travse to accessible routes by according to their access list
  if (!isAdmin) return travseToAccessibleRoute(authSelector?.accessList);

  if (viewOrderTokenData && isAdmin) {
    const viewOrderFromSuperAdminTokenPayload = JSON.parse(viewOrderTokenData);

    if (viewOrderFromSuperAdminTokenPayload) {
      localStorage.removeItem("TOKEN_VIEW_ORDER");
      return `${OPERATION_VIEW_ORDER}/${viewOrderFromSuperAdminTokenPayload?.decoID}/${viewOrderFromSuperAdminTokenPayload?.orderID}/${viewOrderFromSuperAdminTokenPayload?.locationID}`;
    }
  }

  const setup = authSelector?.setup;

  const listOfRequiredSetup = ["delivery", "profile", "hour"];
  const requiredSetups = Object.entries(setup)
    .filter((o) => listOfRequiredSetup.includes(o[0]))
    .map((o) => o[1]);
  const truthySetup = requiredSetups.every((o) => o === true);

  // For Admin User, Check of the setup is complete
  // If complete show dashboard
  if (truthySetup) return DASHBOARD;

  // If not complete show welcome page
  return WELCOME_PAGE;
};

export default ProtectedRoute;
