import SentryTransport from "@samedaycustom/sentry-transport";
import * as Sentry from "@sentry/browser";
import * as Integrations from "@sentry/integrations";
import winston from "winston";

import * as environment from "vendor/environment";

let sentryInstance: typeof Sentry = null;

export const logger = winston.createLogger();

// Avoid setting up sentry locally and unnecessary console noise
if (!environment.isLocal()) {
  const SENTRY_RELEASE = process.env.REACT_APP_SENTRY_RELEASE;

  const sentryTransport = new SentryTransport({
    sentryOptions: {
      dsn: environment.sentryDSN,
      environment: environment.currentDomain(),
      // integration adds a global error handler (interferring with jest global error handler)
      integrations: environment.isLocal()
        ? []
        : (([new Integrations.ExtraErrorData()] as unknown) as any),
      beforeSend: (event) => {
        if (environment.isLocal()) {
          const exception = event.exception?.values?.[0];
          if (exception) {
            //eslint-disable-next-line no-console
            console.debug(exception);
          }
          return null; // this drops the event and nothing will be sent to sentry
        }
        return event;
      },
      release: SENTRY_RELEASE,
    },
    level: environment.log.level,
  });

  if (!environment.isLocal()) {
    logger.add(sentryTransport);
  }

  sentryInstance = (sentryTransport.getSentry() as unknown) as typeof Sentry;
}

if (environment.isLocal() || environment.log.consoleEnabled) {
  logger.add(
    new winston.transports.Console({
      format: winston.format.simple(),
    })
  );
}

export const sentry = sentryInstance;
