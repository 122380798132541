import {
  BUSINESS_PROFILE_ACTION_TYPE,
  START_BUSINESS_PROFILE_ACTION,
  FAIL_BUSINESS_PROFILE_ACTION,
  GET_BUSINESS_PROFILE_ACTION,
  EDIT_BUSINESS_PROFILE_ACTION,
  BUSINESS_PROFILE_STATE_RESET,
} from "vendor/providers/actions/BusinessProfile/actionTypes";
import { businessProfileState } from "../../initialState";
export default (state = businessProfileState, action: BUSINESS_PROFILE_ACTION_TYPE) => {
  switch (action.type) {
    case START_BUSINESS_PROFILE_ACTION:
      return {
        ...state,
        loading: true,
        done: false,
        error: false,
        errorMessage: "",
        type: action.payload,
        fetch: false,
      };
    case FAIL_BUSINESS_PROFILE_ACTION:
      return {
        ...state,
        loading: false,
        done: false,
        error: true,
        errorMessage: action.payload.msg,
        type: action.payload.type,
      };
    case GET_BUSINESS_PROFILE_ACTION:
      return {
        ...state,
        loading: false,
        done: true,
        error: false,
        errorMessage: "",
        type: GET_BUSINESS_PROFILE_ACTION,
        profileData: action.payload,
      };
    case EDIT_BUSINESS_PROFILE_ACTION:
      return {
        ...state,
        loading: false,
        done: true,
        error: false,
        errorMessage: "",
        type: EDIT_BUSINESS_PROFILE_ACTION,
        profileData: action.payload,
      };
    case BUSINESS_PROFILE_STATE_RESET:
      return {
        ...state,
        loading: false,
        done: false,
        error: false,
        errorMessage: "",
        type: "",
      };
    default:
      return state;
  }
};
