import { IProductionTopPanel } from "@samedaycustom/core-ui";
import { formSnakeCaseToCamelCase, isObject } from "@samedaycustom/helpers";
import { ILocationTarget, ThunkResult } from "@samedaycustom/types/app";
import {
  ArtworkImage,
  DUE_DATE_INTERVAL,
  FilterValue,
  IMarkAsDelivered,
  IMarkAsProduced,
  NoteValue,
  OPERATION_ORDERS_LIST,
  OPERATION_ORDERS_STATE,
  OrderListPaginationInfo,
  OrdersStageTypes,
  ProductionStatusValueType,
} from "@samedaycustom/types/app/Operations";
import { Rate } from "@samedaycustom/types/external/shipengine/@types/rate";
import {
  ISDCCarDelivery,
  ISDCDeliveryRecords,
  ISDCDeliveryShipping,
  ISDCOrderRecipient,
  OrdersStatusType,
} from "@samedaycustom/types/order/@types/delivery";
import { ISDCLine } from "@samedaycustom/types/order/@types/line";
import { ISDCNote } from "@samedaycustom/types/order/@types/notes";
import { ISDCOrder, ISDCOrderCancellationType } from "@samedaycustom/types/order/@types/order";
import {
  ISDCOrderTimeLine,
  ISDCOrderTimelineType,
} from "@samedaycustom/types/order/@types/timeline";
import { INote, Item, LineItems } from "@samedaycustom/types/v1/deco-order";
import request, { AxiosResponse, CancelToken } from "axios";
import { isEqual, orderBy } from "lodash";
import queryString from "querystring";
import api from "vendor/api/Api.constants";
import {
  catchError,
  isFetched,
  isFetching,
  resetError,
  setError,
  setLoading,
  setSuccess,
} from "vendor/providers/actions/App";
import { logOutAuto } from "vendor/providers/actions/Auth";
import { INTIAL_ORDER_FILTER } from "vendor/providers/reducers/operations/initialstate";
import { OPERATION_ORDERS } from "vendor/routes/screen";

import { getOrderLine, getOrderLines } from "vendor/providers/features/orderline/async";
import {
  ACTIVE_ORDER_NEW_MESSAGE_TAG,
  CAR_DELIVERY_STATUS_UPDATE,
  DELETE_ORDER_NOTE_BY_ID,
  FAILED_FETCHING_RECORD_DELIVERY_RESULT,
  FAILED_OPERATIONS,
  FETCHING_DELIVERY_RECORD,
  FETCHING_OPERATIONS,
  FETCHING_RECORD_DELIVERY_RESULT,
  FETCH_DELIVERY_RECORD,
  FETCH_OPERATION_ORDERS,
  FETCH_OPERATION_PRODUCTION,
  FETCH_OPERATION_SHIPPING,
  FETCH_ORDER_LINEITEMS,
  FETCH_ORDER_NOTES,
  FETCH_SINGLE_ORDER,
  FETCH_TIMELINE,
  FILTER_OPERATION_ORDERS,
  REMOVE_OPERATION_ORDER,
  REMOVE_ORDER_ARTWORK_IMAGE,
  RESET_FAILED_ORDERS_OPERATION,
  RESET_NOTES,
  RESET_NOTE_COUNT,
  RESET_ORDER,
  RESET_ORDER_NOTES,
  RESET_RECORD_DELIVERY_RESULT,
  SET_FILTER_PARAMS,
  SET_ISFETCHING_LINEITEM,
  SET_ISFILTERING,
  SET_IS_FETCHING_TIMELINE,
  SET_ORDERS_TAB_LOCATION,
  SET_ORDERS_TARGET_LOCATION,
  SET_PRODUCTION_TARGET_LOCATION,
  SET_SHIPPING_TARGET_LOCATION,
  UPDATEL_LINE_ITEM,
  UPDATE_CAR_DELIVERY_INFORMATION,
  UPDATE_DELIVERY_RECORD_STATUS,
  UPDATE_NEW_NOTE_COUNT,
  UPDATE_ORDERS_LINEITEMS_DELIVERED,
  UPDATE_ORDER_ARTWORK_IMAGE,
  UPDATE_ORDER_NOTES,
  UPDATE_ORDER_NOTE_BY_ID,
  UPDATE_ORDER_NOTE_VALUE,
  UPDATE_ORDER_TIMER_INTERVAL,
  UPDATE_PICKUP_NOTIFICATION,
  UPDATE_RECORD_DELIVERY_RESULT,
  UPDATE_SHIPPING_INFORMATION,
  UPDATE_SHIPPING_RATES,
  UPDATE_SINGLE_ORDER,
  UPDATE_TOP_PANEL_SELECTION,
} from "./../types";
import {
  SEARCH_ORDERS_ACTION_TYPES,
  SET_FILTER_PARAMS_ACTION_TYPES,
  SET_ISFILTERING_ACTION_TYPES,
  UPDATE_ORDERS_LISTING_ACTION_TYPE,
  UPDATE_TAB_TARGET_ACTION_TYPE,
} from "./types.d";
import { ESDCDeliveryMethod } from "@samedaycustom/sdc-types";

/** STATE CALLS */

export const removeOrderFromList = (order_id: string) => ({
  type: REMOVE_OPERATION_ORDER,
  order_id,
  stageType: "orders",
});

/**
 *
 * update orders table list
 * @param data
 * @param metadata
 */
export const updateOrders = (
  data: OPERATION_ORDERS_STATE[],
  metadata?: OrderListPaginationInfo,
  isNew?: boolean,
  orders?: OPERATION_ORDERS_LIST
): UPDATE_ORDERS_LISTING_ACTION_TYPE<OPERATION_ORDERS_STATE, OPERATION_ORDERS_LIST> => ({
  type: FETCH_OPERATION_ORDERS,
  stageType: "orders",
  data,
  metadata,
  isNew,
  orders,
});

/**
 * update production table list
 * @param data
 * @param metadata
 */
export const updateProduction = (
  data: ISDCLine[],
  metadata?: OrderListPaginationInfo
): UPDATE_ORDERS_LISTING_ACTION_TYPE<ISDCLine> => ({
  type: FETCH_OPERATION_PRODUCTION,
  stageType: "production",
  data: orderBy(data, "dateOrdered", "desc"),
  metadata,
});

/**
 *
 *
 * update delivery table list
 * @param data
 * @param metadata
 */
export const updateShipping = (
  data: ISDCOrder[],
  metadata?: OrderListPaginationInfo
): UPDATE_ORDERS_LISTING_ACTION_TYPE<ISDCLine> => ({
  type: FETCH_OPERATION_SHIPPING,
  stageType: "delivery",
  data: orderBy(data, "dateOrdered", "desc") as any,
  metadata,
});

export const setIsFetchingLineItem = (condition: boolean) => ({
  type: SET_ISFETCHING_LINEITEM,
  isFetchingLineItem: condition,
});
export const updateOperationOrderLineItem = (line: ISDCLine) => ({
  type: UPDATEL_LINE_ITEM,
  line,
});

export const updateNewNoteCount = () => ({
  type: UPDATE_NEW_NOTE_COUNT,
});

export const resetNotes = () => ({
  type: RESET_ORDER_NOTES,
});
export const updatePickupNotity = (notifyPickup: boolean) => ({
  type: UPDATE_PICKUP_NOTIFICATION,
  notifyPickup,
});

export const resetNoteCount = () => ({
  type: RESET_NOTE_COUNT,
});

/** update shipping information state */
export const updateShippingInformation = (shippingInfo: ISDCDeliveryShipping) => ({
  type: UPDATE_SHIPPING_INFORMATION,
  shippingInfo,
});

/** update car delivery information state */
export const updateCarDeliveryInformation = (carDeliveryInfo: ISDCCarDelivery) => ({
  type: UPDATE_CAR_DELIVERY_INFORMATION,
  carDeliveryInfo,
});

export const updateOrderArtworkImage = (artworkImage: ArtworkImage) => ({
  type: UPDATE_ORDER_ARTWORK_IMAGE,
  artworkImage,
});
export const removeOrderArtworkImage = (view_id: string | number) => ({
  type: REMOVE_ORDER_ARTWORK_IMAGE,
  view_id,
});

export const updateLineItemsDelivered = (deliveringItems: IMarkAsDelivered[]) => ({
  type: UPDATE_ORDERS_LINEITEMS_DELIVERED,
  deliveringItems,
});

export const updateNoteValue = (value: NoteValue) => ({
  type: UPDATE_ORDER_NOTE_VALUE,
  noteValue: value,
});

export const updateShippingRates = (rates: Rate[]) => ({
  type: UPDATE_SHIPPING_RATES,
  rates,
});

export const updateDueDateInterval = (dueDate: DUE_DATE_INTERVAL[]) => ({
  type: UPDATE_ORDER_TIMER_INTERVAL,
  dueDateInterval: dueDate,
});

export const setIsFetchingTimeline = (condition: boolean) => ({
  type: SET_IS_FETCHING_TIMELINE,
  isFetchingTimeline: condition,
});

export const updateTimeline = (timelines: ISDCOrderTimeLine[]) => ({
  type: FETCH_TIMELINE,
  timelines: orderBy(timelines, "timestamp", "desc"),
});

export const resetOrderNotes = () => ({
  type: RESET_NOTES,
});

export const updateOrderNote = (note: ISDCNote) => ({
  type: UPDATE_ORDER_NOTES,
  note,
});

export const updateOrderNoteById = (note_id: string, note: ISDCNote) => ({
  type: UPDATE_ORDER_NOTE_BY_ID,
  note_id,
  note,
});
export const deleteOrderNoteById = (note_id: string) => ({
  type: DELETE_ORDER_NOTE_BY_ID,
  note_id,
});

export const updateOrder = (data: any) => ({ type: FETCH_SINGLE_ORDER, order: data });
export const partlyUpdateOrder = (data: any) => ({ type: UPDATE_SINGLE_ORDER, data });
export const resetOrder = () => ({ type: RESET_ORDER });

// [RECORD DELIVERY]
export const updateRecordeliveryResult = (recordResult: any) => ({
  type: UPDATE_RECORD_DELIVERY_RESULT,
  recordResult,
});

export const resetRecordeliveryResult = () => ({
  type: RESET_RECORD_DELIVERY_RESULT,
});

export const fetchingRecordeliveryResult = () => ({
  type: FETCHING_RECORD_DELIVERY_RESULT,
});

export const failedFetchingRecordeliveryResult = () => ({
  type: FAILED_FETCHING_RECORD_DELIVERY_RESULT,
});

export const updateDeliveryRecord = (data: ISDCDeliveryRecords[]) => ({
  type: FETCH_DELIVERY_RECORD,
  deliveryRecord: Array.isArray(data) ? data : [],
});

export const updateDeliveryRecordStatus = (recordResult: {
  orderID: string;
  recordID: string;
  status: string;
}) => ({
  type: UPDATE_DELIVERY_RECORD_STATUS,
  recordResult,
});

export const updateCarDeliveryRecordStatus = (param: {
  orderId: string;
  trackingId: string;
  status: string;
}) => ({
  type: CAR_DELIVERY_STATUS_UPDATE,
  recordResult: {
    recordID: param.trackingId,
    orderID: param.orderId,
    status: param.status,
    type: ESDCDeliveryMethod.CAR,
  },
});
// [END RECORD DELIVERY]

export const setTopPanelSelection = (topPanelSelection: IProductionTopPanel[]) => ({
  type: UPDATE_TOP_PANEL_SELECTION,
  topPanelSelection: Array.isArray(topPanelSelection) ? topPanelSelection : [],
});

/**
 *  update orders page target
 * @param target
 */
export const updateOrdersTabTarget = (
  target: number,
  type: OrdersStageTypes
): UPDATE_TAB_TARGET_ACTION_TYPE => ({
  type: SET_ORDERS_TAB_LOCATION,
  stageType: type,
  target,
});
/**
 *
 * @param location to be set as current store location for order page
 */
export const setOrderTargetLocation = (location: ILocationTarget, target?: OrdersStageTypes) => {
  let type = null;
  switch (target) {
    case "orders":
      type = SET_ORDERS_TARGET_LOCATION;
      break;
    case "delivery":
      type = SET_SHIPPING_TARGET_LOCATION;
      break;
    case "production":
      type = SET_PRODUCTION_TARGET_LOCATION;
      break;

    default:
      type = SET_ORDERS_TARGET_LOCATION;
      break;
  }

  return {
    type,
    location,
  };
};

/**
 *
 *
 * sets orders table list filter parameters
 * @param filterParams @typedef FilterValue
 * @param type @typedef OrdersStageTypes
 */
export const setOrderFilterParams = (
  filterParams: FilterValue,
  type: OrdersStageTypes
): SET_FILTER_PARAMS_ACTION_TYPES => ({
  type: SET_FILTER_PARAMS,
  stageType: type,
  filterParams,
});

/**
 *
 * set loading state of fileration
 * @param condition
 * @param type
 */
export const setIsFilteringOrder = (
  condition: boolean,
  type: OrdersStageTypes
): SET_ISFILTERING_ACTION_TYPES => ({
  type: SET_ISFILTERING,
  stageType: type,
  isFiltering: condition,
});

export const searchOrders = (
  searchText: string,
  type: OrdersStageTypes
): SEARCH_ORDERS_ACTION_TYPES => ({
  type: FILTER_OPERATION_ORDERS,
  stageType: type,
  searchText,
});

//reset erros for production, shipping and orders types
export const resetOrdersError = (type: OrdersStageTypes) => ({
  type: RESET_FAILED_ORDERS_OPERATION,
  stageType: type || "orders",
});
/*** API CALLS */

/**
 *
 *
 * get all orders by type, filteration and searching
 * @param status
 * @param type
 */
export const getOperationOrders = (
  status: {
    type: OrdersStatusType;
    bottomOrderDate?: Date | string | number;
    topOrderDate?: Date | string | number;
    filterParams?: string;
    limit?: number;
  },
  type: OrdersStageTypes,
  cancelToken?: CancelToken,
  isSilent?: boolean
): ThunkResult<Promise<any>> => {
  return (dispatch: any, getState: any) => {
    const { token } = getState().auth;
    const { targetLocation } = getState().operationsOrders;

    if (status?.bottomOrderDate || status?.topOrderDate || status?.filterParams || isSilent) {
      dispatch(setIsFilteringOrder(true, type));
    } else if (!isSilent) {
      dispatch(
        setLoading<typeof FETCHING_OPERATIONS>(true, FETCHING_OPERATIONS, {
          stageType: type,
        })
      );
      dispatch(isFetching());
    }

    dispatch(
      resetError(FAILED_OPERATIONS, {
        stageType: type,
      })
    );

    //@ts-ignore
    const url = new URLQueryBuilder(`${api.GET_OPERATION_ORDERS}`);
    let storeId = targetLocation?.orders?.id;

    switch (type) {
      case "production":
        storeId = targetLocation?.production?.id !== "all" ? targetLocation?.production?.id : null;

        url.set("orderType", "production");
        break;
      case "orders":
        storeId = targetLocation?.orders?.id;
        break;
      case "delivery":
        storeId = targetLocation?.shipping?.id;
        url.set("orderType", "delivery");
        break;

      default:
        break;
    }

    // set query parameter for store id
    if (storeId) url.set("store", storeId);

    // set query parameter for order type
    if (status?.type) url.set("type", status?.type);

    // set query parameter for order previous date (pagination)
    if (status?.topOrderDate) url.set("prev", status?.topOrderDate as number);

    // set query parameter for order next date (pagination)
    if (status?.bottomOrderDate) url.set("next", status?.bottomOrderDate as number);

    // add limit
    if (status?.limit) url.set("limit", status?.limit as number);

    // finished url
    const finishedurl = `${url.get()}&${status.filterParams ? status.filterParams : ""}`;

    return request
      .get(finishedurl, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": api.CONTENT_TYPE,
        },
        cancelToken,
      })

      .then(async (result) => {
        if (result) {
          const {
            orders,
            pageMeta: { end, start, isFirstPage, isLastPage } = {
              end: orders?.length || 1,
              start: 1,
              isFirstPage: true,
              isLastPage: true,
            },
          } = result?.data?.data || {};
          const data = result?.data?.data?.orders || result?.data?.data;
          const metadata = {
            all: data.count,
            open: 0,
            completed: 0,
            start,
            end,
            isFirstPage,
            isLastPage,
          };

          switch (type) {
            case "production":
              dispatch(updateProduction(data.data, metadata));
              break;

            case "delivery":
              dispatch(updateShipping(data.data, metadata));
              break;

            case "orders":
              dispatch(updateOrders(data.data, metadata, false, orders)); // For backward compatibility. Remove in future
              break;

            default:
              dispatch(updateOrders(data.data, metadata, false, orders)); // For backward compatibility. Remove in future
              break;
          }
        }

        dispatch(setIsFilteringOrder(false, type));
        dispatch(isFetched());
        dispatch(
          setLoading<typeof FETCHING_OPERATIONS>(false, FETCHING_OPERATIONS, {
            stageType: type,
          })
        );

        return Promise.resolve(result);
      })
      .catch((error) => {
        if (error.response) {
          dispatch(logOutAuto(error.response));
        }
        if (status?.topOrderDate || status?.bottomOrderDate || status?.filterParams)
          dispatch(setIsFilteringOrder(false, type));

        dispatch(isFetched());
        dispatch(
          setLoading<typeof FETCHING_OPERATIONS>(false, FETCHING_OPERATIONS, {
            stageType: type,
          })
        );
        dispatch(
          catchError<{
            stageType: OrdersStageTypes;
          }>(error, FAILED_OPERATIONS, {
            stageType: type,
          })
        );
      });
  };
};

/**
 *make api call to search orders
 * @param searchText @typedef string
 * @param status
 * @param type
 */
export const searchOperationOrders = (
  searchText: string,
  status: {
    type: "open" | "close" | "all" | "completed" | "awaiting" | string;
    lastOrderDate?: Date | string | number;
    filterParams?: string;
  },
  type: OrdersStageTypes
) => async (dispatch: any) => {
  let filterstring: string = status.filterParams ? `${status.filterParams}&` : "";

  if (searchText) filterstring += `search=${searchText}`;

  dispatch(searchOrders(searchText, type));

  status.filterParams = filterstring;
  await dispatch(getOperationOrders(status, type, undefined, true));
};

/**
 *make api call to filter orders
 * @param filterParams
 * @param status
 * @param type
 */
export const filterOperationOrders = (
  filterParams: FilterValue,
  status: {
    type: "open" | "close" | "all" | "completed" | "awaiting" | string;
    lastOrderDate?: Date | string | number;
    filterParams?: string;
  },
  type: OrdersStageTypes
) => async (dispatch: any) => {
  const filterQueryString = formatFiltrationQuery(filterParams);
  dispatch(setOrderFilterParams(filterParams, type));

  status.filterParams = filterQueryString;
  dispatch(getOperationOrders(status, type));
};

/**
 *
 *
 * formats a filtration parameters to url string query
 * @param filterParams
 */
export function formatFiltrationQuery(filterParams: FilterValue) {
  const query: Partial<{
    status?: string;
    dm?: string;
    ods?: number;
    ode?: number;
    dds?: number;
    dde?: number;
  }> = {};

  if (filterParams && !isEqual(filterParams, INTIAL_ORDER_FILTER)) {
    const filteredValues = filterParams;

    const getTime = (time: string | number) => new Date(time).getTime();

    if (filteredValues.status && filteredValues.status.toLowerCase() !== "all") {
      query.status = filteredValues.status;
    }

    if (filteredValues.delivery && filteredValues.delivery.toLowerCase() !== "all") {
      query.dm = filteredValues.delivery;
    }

    if (filteredValues.order.start) {
      query.ods = getTime(filteredValues.order.start);
    }

    if (filteredValues.order.end) {
      query.ode = getTime(filteredValues.order.end);
    }

    if (filteredValues.due.start) {
      query.dds = getTime(filteredValues.due.start);
    }

    if (filteredValues.due.end) {
      query.dde = getTime(filteredValues.due.end);
    }
  }

  return queryString.stringify(query);
}
/**
 *
 * @typedef getOperationOrderById get a single order by @param id
 */
export const getOperationOrderById = (
  id: string | number,
  locationID: string | number,
  showLoader = true,
  cancelToken?: any
): ThunkResult<Promise<any>> => {
  return (dispatch: any, getState: any) => {
    const { token } = getState().auth;

    if (showLoader) {
      dispatch(setLoading(true));
      dispatch(isFetching());
    }
    return request
      .get(`${api.GET_OPERATION_ORDERS}/${id}?store=${locationID}`, {
        cancelToken: cancelToken,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": api.CONTENT_TYPE,
        },
      })
      .then(async (result) => {
        const data = result.data?.data;
        const snakeToCamel = formSnakeCaseToCamelCase([data])[0];
        await dispatch(updateOrder(snakeToCamel));

        if (showLoader) {
          dispatch(setLoading(false));
          dispatch(isFetched());
        }

        return Promise.resolve(data);
      })
      .catch((error) => {
        let message;
        if (error.response) {
          dispatch(logOutAuto(error.response));
        }
        if (showLoader) {
          dispatch(setLoading(false));
        }
        if (error?.response?.hasOwnProperty("data")) {
          message = error.response.data.message;
        }
        if (message === "This order has been cancelled") {
          window.location.href = OPERATION_ORDERS;
        }
        dispatch(setError(message || "Please check your internet connections"));
      });
  };
};

/**
 *
 * @typedef getOperationOrderNotes get a single order notes by @param id of order
 */
export const getOperationOrderNotes = (id: string | number): ThunkResult<Promise<any>> => {
  return (dispatch: any, getState: any) => {
    const { token } = getState().auth;
    return request
      .get(`${api.GET_OPERATION_ORDERS}/${id}/notes`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": api.CONTENT_TYPE,
        },
      })
      .then((result) => {
        dispatch({ type: FETCH_ORDER_NOTES, notes: result.data?.data || [] });
      })
      .catch((error) => {
        let message;
        if (error?.response?.hasOwnProperty("data")) message = error.response.data.message;
        dispatch(setError(message || "Please check your internet connections"));
      });
  };
};

/**
 *
 * @typedef editOrderNote edit a single order note by @param id of order and @param note_id of note
 */
export const editOrderNote = (
  id: string | number,
  note_id: string | number,
  location_id: string,
  noteValue: Partial<ISDCNote>
): ThunkResult<Promise<any>> => {
  return (dispatch: any, getState: any) => {
    const { token } = getState().auth;
    return request
      .patch(`${api.GET_OPERATION_ORDERS}/${id}/notes/${note_id}?store=${location_id}`, noteValue, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": api.CONTENT_TYPE,
        },
      })
      .then((result) => {
        const data = result.data?.data?.result || result.data?.data;
        const note = isObject(data)
          ? data
          : {
              content: data,
            };
        dispatch(updateOrderNoteById(note_id.toString(), note));
      })
      .catch((error) => {
        if (error.response) {
          dispatch(logOutAuto(error.response));
        }
        let message;
        if (error?.response?.hasOwnProperty("data")) message = error.response.data.message;
        dispatch(setError(message || "Please check your internet connections"));
      });
  };
};
/**
 *
 * @typedef addOrderNote add a single order note by @param id of order and @param note_id of note
 */
export const addOrderNote = (
  id: string | number,
  locationId: string,
  noteValue: Partial<ISDCNote>
): ThunkResult<Promise<any>> => {
  return (dispatch: any, getState: any) => {
    const { token } = getState().auth;
    return request
      .post(`${api.GET_OPERATION_ORDERS}/${id}/notes?store=${locationId}`, noteValue, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": api.CONTENT_TYPE,
        },
      })
      .then((_result) => {
        // const data = result.data?.data?.result || result.data?.data
        // const note = isObject(data)
        //     ? data
        //     : {
        //           content: data,
        //       }
        // dispatch(updateOrderNote(note))
      })
      .catch((error) => {
        if (error.response) {
          dispatch(logOutAuto(error.response));
        }
        let message;
        if (error?.response?.hasOwnProperty("data")) message = error.response.data.message;
        dispatch(setError(message || "Please check your internet connections"));
      });
  };
};

/**
 *
 * @typedef deleteOrderNote delete a single order note by @param id of order and @param note_id of note
 */
export const deleteOrderNote = (
  id: string | number,
  note_id: string | number,
  location_id: string
): ThunkResult<Promise<any>> => {
  return (dispatch: any, getState: any) => {
    const { token } = getState().auth;
    return request
      .delete(`${api.GET_OPERATION_ORDERS}/${id}/notes/${note_id}?store=${location_id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": api.CONTENT_TYPE,
        },
      })
      .then(() => dispatch(deleteOrderNoteById(note_id.toString())))
      .catch((error) => {
        if (error.response) {
          dispatch(logOutAuto(error.response));
        }
        let message;
        if (error?.response?.hasOwnProperty("data")) message = error.response.data.message;
        dispatch(setError(message || "Please check your internet connections"));
      });
  };
};

/**
 *
 * @typedef getOperationOrderLineItems get a single order notes by @param id of order
 */
export const getOperationOrderLineItems = (
  id: string | number,
  locationId: string | number,
  showLoader = true,
  cancelToken?: any
): ThunkResult<Promise<any>> => {
  return (dispatch: any, getState: any) => {
    const { token } = getState().auth;

    if (showLoader) dispatch(setIsFetchingLineItem(true));
    return request
      .get(`${api.GET_OPERATION_ORDERS}/${id}/lines?store=${locationId}`, {
        cancelToken: cancelToken,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": api.CONTENT_TYPE,
        },
      })
      .then(async (result) => {
        const data = result.data?.data as LineItems[];
        await dispatch({
          type: FETCH_ORDER_LINEITEMS,
          lineItems: data || [],
        });
        if (showLoader) dispatch(setIsFetchingLineItem(false));
        return Promise.resolve(data);
      })
      .catch((error) => {
        if (error.response) {
          dispatch(logOutAuto(error.response));
        }
        if (showLoader) dispatch(setIsFetchingLineItem(false));
        dispatch(catchError(error));
      });
  };
};
/**
 *
 * @typedef getOperationOrderTimeline get all timeline activities @param id order
 */
export const getOperationOrderTimeline = (
  id: string | number,
  type?: ISDCOrderTimelineType
): ThunkResult<Promise<any>> => {
  return (dispatch: any, getState: any) => {
    const { token } = getState().auth;
    dispatch(setIsFetchingTimeline(true));
    return request
      .get(
        `${api.GET_OPERATION_TIMELINE}/${id}/store${(type &&
          type !== ("all" as any) &&
          `/${type}`) ||
          ""}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": api.CONTENT_TYPE,
          },
        }
      )
      .then((result) => {
        const data = result.data?.data as ISDCOrderTimeLine[];
        dispatch(updateTimeline(data));
        dispatch(setIsFetchingTimeline(false));
        return Promise.resolve(data);
      })
      .catch((error) => {
        if (error.response) {
          dispatch(logOutAuto(error.response));
        }
        dispatch(setIsFetchingTimeline(false));
        dispatch(catchError(error));
      });
  };
};

/**
 * @function getOrderDeliveryRecord of order by id
 * @param id
 */
export const getOrderDeliveryRecord = (
  id: string | number,
  type?: "SILENT",
  record_id?: string,
  location_id?: string
): ThunkResult<Promise<any>> => {
  return (dispatch: any, getState: any) => {
    const { token } = getState().auth;

    const { order } = getState().operationsOrders;
    if (!type || type === "SILENT")
      dispatch({
        type: FETCHING_DELIVERY_RECORD,
        isFetchingDeliveryRecord: true,
      });

    let url = `${api.GET_OPERATION_DELIVERY}/${id}`;
    if (record_id) url += `/${record_id}`;

    return request
      .get(`${url}?store=${order?.locationId || location_id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": api.CONTENT_TYPE,
        },
      })
      .then((result) => {
        const data = result.data?.data;
        dispatch({
          type: FETCHING_DELIVERY_RECORD,
          isFetchingDeliveryRecord: false,
        });

        if (record_id) {
          dispatch(updateRecordeliveryResult(data));
        } else dispatch(updateDeliveryRecord(data));
        return Promise.resolve(data);
      })
      .catch((error) => {
        if (error.response) {
          dispatch(logOutAuto(error.response));
        }
        dispatch({
          type: FETCHING_DELIVERY_RECORD,
          isFetchingDeliveryRecord: false,
        });
        dispatch(catchError(error));
      });
  };
};

/**
 *
 * @param id to be updated
 * @param condition true === accept order; false  === decline order
 */
export const updateOrderStatus = (
  id: string | number,
  condition: boolean,
  content?: string,
  reason?: ISDCOrderCancellationType
): ThunkResult<Promise<any>> => {
  return (dispatch: any, getState: any) => {
    const { token } = getState().auth;

    const { order } = getState().operationsOrders;
    dispatch(setLoading(true));
    return request
      .post(
        `${api.GET_OPERATION_ORDERS}/${id}?store=${order?.locationId || ""}`,
        {
          status: condition ? "accept" : "decline",
          reason: {
            type: reason,
            message: content,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": api.CONTENT_TYPE,
          },
        }
      )
      .then((result) => {
        if (condition) dispatch(getOperationOrderById(id, order?.locationId));
        return Promise.resolve(result);
      })
      .catch((error) => {
        if (error.response) {
          dispatch(logOutAuto(error.response));
        }
        dispatch(setLoading(false));
        dispatch(catchError(error));
      });
  };
};

/**
 *
 * @param id  is order_id to be updated
 * @param line_id to be updated
 * @param isAll to update all artworks
 */
export const updateOrderArtwork = (
  id: string,
  line_id: string,
  view_id?: string,
  isAll?: boolean,
  showLoader?: boolean
): ThunkResult<Promise<any>> => {
  return (dispatch: any, getState: any) => {
    const { token } = getState().auth;

    const { order } = getState().operationsOrders;

    dispatch(setIsFetchingLineItem(showLoader || typeof line_id === "undefined" || false));

    let query = null;
    if (!isAll) {
      const qs: { line_id: string | number; view_id?: string | number } = {
        line_id,
      };
      if (view_id) qs.view_id = view_id;

      query = queryString.stringify(qs);
    }

    const http = request.post(
      `${api.GET_OPERATION_ARTWORK}/${id}?store=${order?.locationId || ""}${
        !isAll ? `&${query}` : ""
      }`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": api.CONTENT_TYPE,
        },
      }
    );

    return http
      .then((result) => {
        dispatch(setSuccess("Artwork approved successfully"));
        dispatch(getOperationOrderById(id, order?.locationId, false));
        dispatch(getOperationOrderLineItems(id, order?.locationId, false));
        dispatch(getOrderLines({ order_id: id, location_id: order?.locationId }));
        dispatch(setIsFetchingLineItem(false));
        return result;
      })
      .catch((error) => {
        if (error.response) {
          dispatch(logOutAuto(error.response));
        }
        dispatch(setIsFetchingLineItem(false));
        dispatch(catchError(error));
      });
  };
};

/**
 *
 * @param id to be updated
 * @param condition true === accept order; false  === decline order
 */
export const addOperationOrderNotes = (
  id: string | number,
  values: INote
): ThunkResult<Promise<any>> => {
  return (dispatch: any, getState: any) => {
    const { token } = getState().auth;

    const { order } = getState().operationsOrders;
    return request
      .post(
        `${api.GET_OPERATION_ORDERS}/${id}/notes/?orderID=${id}?store=${order?.locationId || ""}`,
        values,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": api.CONTENT_TYPE,
          },
        }
      )
      .then((result) => {
        // dispatch(setSuccess("Artwork approved successfully"))
        // dispatch(getOperationOrderLineItems(id))
        return result;
      })
      .catch((error) => {
        if (error.response) {
          dispatch(logOutAuto(error.response));
        }
        dispatch(setLoading(false));
        // dispatch(setIsFetchingLineItem(false))
        dispatch(catchError(error));
      });
  };
};

/**
 * cancel order
 * @param id
 */
export const cancelOrder = (
  id: string | number,
  message: string,
  reason: ISDCOrderCancellationType
): ThunkResult<Promise<any>> => {
  return (dispatch: any, getState: any) => {
    const { token } = getState().auth;

    const { order } = getState().operationsOrders;
    return request
      .patch(
        `${api.GET_OPERATION_ORDERS}/${id}?store=${order?.locationId || ""}`,
        { reason: message, type: reason },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": api.CONTENT_TYPE,
          },
        }
      )
      .then((result) => {
        // dispatch(setSuccess("Order cancelled sucessfully"))
        dispatch(getOperationOrderById(id, order?.locationId));
        return Promise.resolve(result);
      })
      .catch((error) => {
        if (error.response) {
          dispatch(logOutAuto(error.response));
        }
        dispatch(setLoading(false));
        // dispatch(setIsFetchingLineItem(false))
        dispatch(catchError(error));
      });
  };
};

/**
 * pause or start order
 * @param id
 */
export const pauseOrder = (id: string | number, reason?: INote): ThunkResult<Promise<any>> => {
  return (dispatch: any, getState: any) => {
    const { token } = getState().auth;
    const { order } = getState().operationsOrders;

    return request
      .post(
        `${api.GET_OPERATION_ORDERS}/${id}/active?store=${order?.locationId || ""}`,
        { reason },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": api.CONTENT_TYPE,
          },
        }
      )
      .then((result) => {
        // if (reason) {
        //     dispatch(addOperationOrderNotes(id, reason))
        //     dispatch(setSuccess("Order paused sucessfully"))
        //     dispatch(getOperationOrderById(id))

        //     return Promise.resolve(result)
        // }

        dispatch(setSuccess(`Order ${order?.active ? "paused" : "started"} successfully`));
        dispatch(getOperationOrderById(id, order?.locationId));
        dispatch(getOperationOrderLineItems(id, order?.locationId));
        return Promise.resolve(result);
      })
      .catch((error) => {
        if (error.response) {
          dispatch(logOutAuto(error.response));
        }
        dispatch(setLoading(false));
        // dispatch(setIsFetchingLineItem(false))
        dispatch(catchError(error));
      });
  };
};
/**
 * requests new artwork for a line id
 * @param id
 * @param line_id
 * @param values
 */
export const requestNewArtwork = (
  id: string | number,
  line_id: string | number,
  values: INote
): ThunkResult<Promise<any>> => {
  return (dispatch: any, getState: any) => {
    const { token } = getState().auth;

    const { order } = getState().operationsOrders;
    return request
      .post(
        `${api.GET_OPERATION_ARTWORK}/${id}/${line_id}/new?store=${order?.locationId || ""}`,
        values,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": api.CONTENT_TYPE,
          },
        }
      )
      .then((result) => {
        return result;
      })
      .catch((error) => {
        if (error.response) {
          dispatch(logOutAuto(error.response));
        }
        dispatch(setLoading(false));
        dispatch(catchError(error));
      });
  };
};

/**
 * @function recordDelivery order delivery
 * @param order_id
 * @param line_id
 * @param value
 */
export const recordDelivery = (
  order_id: any,
  line_id: any,
  value: any
): ((dispatch: any, getState: any) => Promise<void | AxiosResponse<any>>) => {
  return (dispatch: any, getState: any) => {
    const { token } = getState().auth;

    const { order } = getState().operationsOrders;
    dispatch(setLoading(true));
    return request
      .post(`${api.GET_OPERATION_DELIVERY}/${order_id}?store=${order?.locationId || ""}`, value, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": api.CONTENT_TYPE,
        },
      })
      .then((result) => {
        dispatch(getOperationOrderLineItems(line_id, order?.locationId));
        return result;
      })
      .catch((error) => {
        if (error.response) {
          dispatch(logOutAuto(error.response));
        }
        dispatch(setLoading(false));
        dispatch(catchError(error));
      });
  };
};

/**
 * @function markAsProduced
 * @param order_id
 * @param value
 */
export const markAsProduced = (
  order_id: any,
  value: { lines: IMarkAsProduced[] | IMarkAsProduced[] }
): ((dispatch: any, getState: any) => Promise<void | AxiosResponse<any>>) => {
  return (dispatch: any, getState: any) => {
    const { token } = getState().auth;

    const { order } = getState().operationsOrders;
    dispatch(setLoading(true));
    dispatch({ type: SET_ISFETCHING_LINEITEM, isFetchingLineItem: true });
    return request
      .post(
        `${api.GET_OPERATION_PRODUCTIONS}/${order_id}?store=${order?.locationId || ""}`,
        value,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": api.CONTENT_TYPE,
          },
        }
      )
      .then((result) => {
        dispatch(getOperationOrderById(order_id, order?.locationId));
        dispatch(getOperationOrderLineItems(order_id, order?.locationId));
        dispatch(
          getOrderLines({
            order_id,
            location_id: order?.locationId,
            hideLoader: true,
          })
        );
        dispatch(
          setSuccess(
            `Line Items: ${value?.lines?.length} of Order id ${order?.decoOrderId} updated successfully `
          )
        );
        return Promise.resolve(result);
      })
      .catch((error) => {
        if (error.response) {
          dispatch(logOutAuto(error.response));
        }
        dispatch(setLoading(false));
        dispatch({
          type: SET_ISFETCHING_LINEITEM,
          isFetchingLineItem: false,
        });
        dispatch(catchError(error));
      });
  };
};

/**
 * @function updateOrderShippingMethod
 * @param order_id
 * @param value
 */
export const updateOrderShippingMethod = (order_id: any, value: any) => {
  return (dispatch: any, getState: any) => {
    const { token } = getState().auth;

    const { order } = getState().operationsOrders;
    return request
      .post(
        `${api.GET_OPERATION_DELIVERY}/${order_id}/method?store=${order?.locationId || ""}`,
        value,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": api.CONTENT_TYPE,
          },
        }
      )
      .then((result) => {
        dispatch(getOperationOrderById(order_id, order?.locationId));
        dispatch(setSuccess("Delivery details updated successfully "));
        return Promise.resolve(result);
      })
      .catch((error) => {
        if (error.response) {
          dispatch(logOutAuto(error.response));
        }
        dispatch(setLoading(false));
        dispatch(setIsFetchingLineItem(false));
        dispatch(catchError(error));
      });
  };
};

/**
 * @function updateOrderShippingRate
 * @param order_id
 * @param value
 */
export const updateOrderShippingRate = (order_id: any, value: ISDCDeliveryShipping) => {
  return (dispatch: any, getState: any) => {
    const { token } = getState().auth;

    const { order } = getState().operationsOrders;
    return request
      .post(
        `${api.GET_OPERATION_DELIVERY}/${order_id}/shipping/rates?store=${order?.locationId || ""}`,
        value,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": api.CONTENT_TYPE,
          },
        }
      )
      .then((result) => {
        if (result["data"]["data"]?.length >= 1) {
          dispatch(getOperationOrderById(order_id, order?.locationId));
          // dispatch(setSuccess(`Delivery details updated successfully `))
          return Promise.resolve(result);
        }
        return Promise.resolve(result);
      })
      .catch((error) => {
        if (error.response) {
          dispatch(logOutAuto(error.response));
        }
        dispatch(setLoading(false));
        dispatch(setIsFetchingLineItem(false));
        dispatch(catchError(error));
      });
  };
};

/**
 * @function updateCarDeliveryQuote
 * @param order_id
 * @param value
 */
export const updateCarDeliveryQuote = (
  order_id: any,
  value: ISDCCarDelivery
): ((dispatch: any, getState: any) => Promise<void | AxiosResponse<any>>) => {
  return (dispatch: any, getState: any) => {
    const { token } = getState().auth;

    const { order } = getState().operationsOrders;
    return request
      .post(
        `${api.GET_OPERATION_DELIVERY}/${order_id}/car/quotes?store=${order?.locationId || ""}`,
        value,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": api.CONTENT_TYPE,
          },
        }
      )
      .then((result) => {
        dispatch(getOperationOrderById(order_id, order?.locationId));

        dispatch(setSuccess("Car delivery details updated successfully "));
        return Promise.resolve(result);
      })
      .catch((error) => {
        if (error.response) {
          dispatch(logOutAuto(error.response));
        }
        dispatch(catchError(error));
      });
  };
};
/**
 * @function completeDelivery
 * @param order_id
 * @param value
 */
export const completeDelivery = (
  order_id: any,
  value: {
    lines: IMarkAsProduced[] | IMarkAsDelivered[];
    rateID?: string;
    quoteID?: string;
    paymentBy?: "CUSTOMER" | "STORE";
    recipient?: ISDCOrderRecipient;
    sendCharges?: boolean;
  },
  type: "shipping" | "pickup" | "car" = "shipping"
): ((dispatch: any, getState: any) => Promise<void | AxiosResponse<any>>) => {
  return (dispatch: any, getState: any) => {
    const { token } = getState().auth;

    const { order } = getState().operationsOrders;

    delete value?.quoteID;
    return request
      .post(
        `${api.GET_OPERATION_DELIVERY}/${order_id}/${type}?store=${order?.locationId || ""}`,
        value,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": api.CONTENT_TYPE,
          },
        }
      )
      .then(async (result) => {
        /**
         *
         * notify users of pickup
         */
        if (type === "pickup")
          await request
            .post(
              `${api.GET_OPERATION_ORDERS}/${order_id}/notify_pickup?store=${order?.locationId ||
                ""}`,
              {},
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": api.CONTENT_TYPE,
                },
              }
            )
            .catch((err) => dispatch(catchError(err)));

        dispatch(getOperationOrderById(order_id, order?.locationId));
        dispatch(getOperationOrderLineItems(order_id, order?.locationId));
        dispatch(getOrderDeliveryRecord(order_id));
        dispatch(setSuccess("Delivery recorded successfully "));
        return Promise.resolve(result);
      })
      .catch((error) => {
        if (error.response) {
          dispatch(logOutAuto(error.response));
        }
        dispatch(setLoading(false));
        dispatch(catchError(error));
        // return Promise.reject(error)
      });
  };
};

/**
 * edit line items value by order id and line id
 * @param order_id
 * @param line_id
 * @param value
 */
export const editItem = (
  order_id: string,
  line_id: string,
  value: ProductionStatusValueType
): ((dispatch: any, getState: any) => Promise<void | AxiosResponse<any>>) => {
  return (dispatch: any, getState: any) => {
    const { token } = getState().auth;

    const { order } = getState().operationsOrders;
    return request
      .post(
        `${api.GET_OPERATION_PRODUCTIONS}/${order_id}/${line_id}/edit?store=${order?.locationId ||
          ""}`,
        value,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": api.CONTENT_TYPE,
          },
        }
      )
      .then((result) => {
        dispatch(getOperationOrderLineItems(order_id, order?.locationId));
        dispatch(getOrderLine({ order_id, line_id, location_id: order?.locationId }));
        dispatch(setSuccess(`line item id ${line_id} edited`));
        return Promise.resolve(result);
      })
      .catch((error) => {
        if (error.response) {
          dispatch(logOutAuto(error.response));
        }
        dispatch(catchError(error));
      });
  };
};

/**
 *
 * reassign order
 * @param orderID
 * @param vendorID
 * @param locationID
 */
export const reassignOrder = (orderID: string, vendorID: string, locationID: string) => {
  return (dispatch: any, getState: any) => {
    const { token } = getState().auth;
    const { order } = getState().operationsOrders;
    return request
      .patch(
        `${api.GET_OPERATION_ORDERS}/${orderID}/reassign?store=${order?.locationId || ""}`,
        {
          vendor: vendorID,
          location: locationID,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": api.CONTENT_TYPE,
          },
        }
      )
      .then(async (result) => {
        const response = result?.data?.code;
        if (response === 200) {
          dispatch(setSuccess("Reassignment successful"));
          return Promise.resolve(result);
        }
        return Promise.resolve(null);
      })
      .catch((error) => {
        if (error.response) {
          dispatch(logOutAuto(error.response));
        }
        dispatch(catchError(error));
      });
  };
};

/**
 *
 * @deprecated api endpoint for updating internal status
 *
 *
 * @param order_id
 * @param line_id
 * @param value
 */
export const editItemDeprecated = (
  order_id: string,
  line_id: string,
  value: { items: Item[] }
): ((dispatch: any, getState: any) => Promise<void | AxiosResponse<any>>) => {
  return (dispatch: any, getState: any) => {
    const { token } = getState().auth;
    return request
      .patch(`${api.GET_OPERATION_PRODUCTIONS}/${order_id}/lines/${line_id}/edit`, value, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": api.CONTENT_TYPE,
        },
      })
      .then((result) => {
        dispatch(getOperationOrderLineItems(order_id, null));
        dispatch(setSuccess(`line item id ${line_id} edited`));
        return Promise.resolve(result);
      })
      .catch((error) => {
        if (error.response) {
          dispatch(logOutAuto(error.response));
        }
        dispatch(catchError(error));
      });
  };
};

export const updateOrderNewMessage = ({
  order_id,
}: {
  order_id: string;
}): ((dispatch: any, getState: any) => Promise<void | AxiosResponse<any>>) => ({
  type: ACTIVE_ORDER_NEW_MESSAGE_TAG,
  order_id,
});
