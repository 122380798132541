import "./style.scss";

import { ReactComponent as PreviewImg } from "../assets/orderPreviewImage.svg";
import React from "react";
import { NavLink } from "react-router-dom";

interface Props {
  orderNo: string;
  price: string;
  date: string;
  viewLink: string;
}

export default ({ orderNo, price, date, viewLink }: Props) => {
  return (
    <div className="orderPreviewGroup">
      <div className="rightPreview">
        <PreviewImg />
        <div className="rightPreviewText">
          <h2> {orderNo} </h2>
          <span className="previewText"> {price} </span>
        </div>
      </div>
      <div className="leftPreview">
        <div className="leftPreviewText">
          <NavLink to={viewLink}>
            <span className="leftPreviewLink">View</span>
          </NavLink>
          <span className="previewText">{date}</span>
        </div>
      </div>
    </div>
  );
};
