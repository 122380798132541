import React from "react";
import { Box, Text } from "../../_components";
import { SecondaryButton } from "@samedaycustom/core-ui";

export default ({ onOkClick }) => {
  return (
    <Box
      d="flex"
      alignItems="center"
      flexDirection="column"
      textAlign="center"
      padding="40px"
    >
      <Text fontSize="20px" color="#243B53" lineHeight="32px" fontWeight={500}>
        Store location turned off
      </Text>
      <Text
        fontSize="16px"
        color="#102A43"
        lineHeight="24px"
        marginBottom="30px"
        marginTop="12px"
      >
        You are no longer able to accept orders for this store. We turned it off
        so you won’t get negative reviews from being unable to fulfill your
        orders. You can turn it back on once you get your machine fixed
      </Text>
      <Box>
        <SecondaryButton onClick={onOkClick}>Ok</SecondaryButton>
      </Box>
    </Box>
  );
};
