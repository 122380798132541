import { v4 as uuidV4 } from "uuid";
import Observable from "zen-observable";

type NextType<T> = T extends undefined ? () => void : (value: T) => void;

export interface DeferredObservable<T> {
  next: NextType<T>;
  subscribe: (payload: NextType<T>) => ZenObservable.Subscription;
}

export function deferredObservable<T = undefined>(): DeferredObservable<T> {
  let observer: ZenObservable.SubscriptionObserver<T> | undefined;
  let observers: { [key: string]: ZenObservable.SubscriptionObserver<T> } = {};
  new Observable((_observer) => {
    observer = _observer;
    return () => {
      observers = {};
    };
  }).subscribe({
    next(value: T) {
      for (const localObserver of Object.values(observers)) {
        localObserver.next(value);
      }
    },
  });

  return {
    next: (observer as ZenObservable.SubscriptionObserver<T>).next.bind(observer) as NextType<T>,
    subscribe(localCallback) {
      return new Observable((_observer) => {
        const id = uuidV4();
        observers[id] = _observer;
        return () => {
          delete observers[id];
        };
      }).subscribe({
        next(value: T) {
          localCallback(value);
        },
      });
    },
  };
}
