import * as apiConst from "vendor/api/Api.constants";

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import setRTKAuthorization from "vendor/helpers/setRTKAuthorization";
import {
  ILocationProducts,
  ILocationSurcharge,
  IProductDetails,
  ISDCLocationProduct,
  ISDCLocationProductNew,
  ISDCLocationSelectedProduct,
} from "vendor/models/IStoreLocationProduct";
import { QueryResponse } from "vendor/models/Response";

export const locationsApi = createApi({
  reducerPath: "locationsApi",
  tagTypes: ["Surcharge"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${apiConst.BASE_URL}/stores/`,
    prepareHeaders: setRTKAuthorization,
  }),
  endpoints: (builder) => ({
    getSurcharge: builder.query<QueryResponse<ILocationSurcharge>, ILocationSurcharge>({
      query: (locationId) => `wl/${locationId}/surcharges`,
    }),

    getProducts: builder.query<QueryResponse<ISDCLocationSelectedProduct[]>, any>({
      query: ({ locationId, params }) =>
        `${locationId}/products?page=${params.page}&limit=${params.limit}`,
    }),

    getListedProducts: builder.query<
      QueryResponse<ISDCLocationProductNew[]>,
      ISDCLocationProductNew[]
    >({
      query: (locationId) => `products?locId=${locationId}`,
    }),

    getProductDetails: builder.query<QueryResponse<IProductDetails>, any>({
      query: ({ locId, productId }) => `${locId}/products/${productId}`,
    }),

    updateSurcharge: builder.mutation<ILocationSurcharge, any>({
      query: ({ locationId, data }) => ({
        url: `wl/${locationId}/surcharges`,
        method: "POST",
        body: { ...data },
      }),
    }),

    saveProducts: builder.mutation<any, any>({
      query: ({ locationId, products }) => ({
        url: `${locationId}/products`,
        method: "POST",
        body: { products },
      }),
    }),

    updateProductColor: builder.mutation<any, any>({
      query: ({ locationId, productId, data }) => ({
        url: `${locationId}/products/${productId}/color`,
        method: "POST",
        body: { ...data },
      }),
    }),

    updateProductDetails: builder.mutation<any, any>({
      query: ({ locationId, productId, data }) => ({
        url: `${locationId}/products/${productId}`,
        method: "PATCH",
        body: { ...data },
      }),
    }),

    getSizeSurcharge: builder.query<QueryResponse<any>, any>({
      query: ({ locationId, productId }) => `${locationId}/products/${productId}/size-surcharges`,
    }),

    updateSizeSurcharge: builder.mutation<any, any>({
      query: ({ locationId, productId, data }) => ({
        url: `${locationId}/products/${productId}/size-surcharges`,
        method: "POST",
        body: { ...data },
      }),
    }),
  }),
});

export const {
  useGetSurchargeQuery,
  useUpdateSurchargeMutation,
  useGetProductsQuery,
  useSaveProductsMutation,
  useGetListedProductsQuery,
  useGetProductDetailsQuery,
  useUpdateProductColorMutation,
  useUpdateProductDetailsMutation,
  useGetSizeSurchargeQuery,
  useUpdateSizeSurchargeMutation,
} = locationsApi;
