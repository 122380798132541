import {
  DASHBOARD_ACTION_TYPES,
  START_DASHBOARD_ACTION,
  FAIL_DASHBOARD_ACTION,
  DASHBOARD_STATE_RESET,
  GET_DASHBOARD_STATUS_ACTION,
} from "vendor/providers/actions/DashboardData/actionTypes";
import { dashboardState } from "../../initialState";
import { DASHBOARD_PAGE_STATE } from "@samedaycustom/types/app";
export default (state = dashboardState, action: DASHBOARD_ACTION_TYPES): DASHBOARD_PAGE_STATE => {
  switch (action.type) {
    case START_DASHBOARD_ACTION:
      return {
        ...state,
        done: false,
        error: false,
        loading: true,
        errorMessage: "",
        type: action.payload,
      };
    case FAIL_DASHBOARD_ACTION:
      return {
        ...state,
        done: false,
        error: true,
        errorMessage: action.payload.msg,
        type: action.payload.type,
        loading: false,
      };
    case DASHBOARD_STATE_RESET:
      return {
        ...state,
        done: false,
        error: false,
        errorMessage: "",
        type: "",
        loading: false,
      };
    case GET_DASHBOARD_STATUS_ACTION:
      return {
        ...state,
        loading: false,
        done: true,
        error: false,
        errorMessage: "",
        dashData: action.payload,
        type: GET_DASHBOARD_STATUS_ACTION,
      };
    default:
      return state;
  }
};
