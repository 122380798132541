import { NotificationState } from "./intitalstate";

import { INotificationType, INotification } from "@samedaycustom/types/app/Settings";
import { UPDATE_NOTIFICATION_DATA } from "vendor/providers/actions/App/Settings/Store/Notification/types";
export default (
  state = NotificationState,
  action: { type?: string; data: INotificationType[] }
): INotification => {
  switch (action.type) {
    case UPDATE_NOTIFICATION_DATA:
      return { data: action.data };

    default:
      return state;
  }
};
