import "./style.scss";

import { Box, Image, Spinner } from "@chakra-ui/react";
import React from "react";
import { LightgalleryItem } from "react-lightgallery";

export default React.memo(
  ({
    thumbnail,
    label,
    loading,
    id
  }: {
    thumbnail: string;
    label: string;
    loading?: boolean;
    id?: string;
  }) => {
    return (
      <Box position="relative">
        <Box
          pos="absolute"
          bottom="0"
          top="0"
          left="0"
          right="0"
          background="rgba(0,0,0,0.1)"
          display="inline-flex"
          alignItems="center"
          justifyContent="center"
          height="100%"
          width="100%"
          opacity={loading ? 1 : 0}
          transition="all 200ms ease-in-out"
        >
          <Spinner color="red" />
        </Box>
        <LightgalleryItem src={thumbnail} group={id || thumbnail}>
          <Image
            src={thumbnail}
            alt={label}
            aria-label={label}
            role="image"
            objectFit="cover"
            objectPosition="center"
            width="161.27px"
            height="174px"
          />
        </LightgalleryItem>
      </Box>
    );
  }
);
