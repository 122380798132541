import "../style.scss";

import React from "react";
import AnimatedNumber from "react-animated-number";

interface Props {
	decline: boolean;
	Rate: number;
	Label: string;
	hidePercentage?: boolean;
}

export default ({ decline, Rate, Label, hidePercentage }: Props) => {
	return (
		<div className="dashBoardTile">
			<div className="main">
				<h2 className={decline ? "redHeading" : "greenHeading"}>
					<AnimatedNumber
						component="text"
						value={Rate}
						style={{
							transition: "1s ease-out",
							transitionProperty:
								"background-color, color, opacity"
						}}
						duration={500}
						formatValue={(n) => Math.floor(parseInt(n))}
					/>
				</h2>
				{!hidePercentage && (
					<span
						className={`percentText ${
							decline ? "redHeading" : "greenHeading"
						}`}
					>
						%
					</span>
				)}
			</div>
			<p className="tileLabelText">{Label}</p>
		</div>
	);
};
