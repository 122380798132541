import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

const useScrollRestoration = () => {
  const history = useHistory();
  useEffect(
    () => () =>
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth"
      }),
    [history.location.key]
  );
};

export default useScrollRestoration;
