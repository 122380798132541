import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import { ActionStatus } from "types/app/Messaging";
import {
  NotificationGroup,
  NotificationInfo,
} from "vendor/components/ViewNotification/Notification";

import { getNotifications, getUnreadNotifications } from "./async";

export interface NotificationState {
  notifications: {
    data: NotificationGroup;
    status: ActionStatus;
    total_unread_notification: number;
    error: any;
  };
}
const initialState: NotificationState = {
  notifications: {
    data: {
      today: [],
      yesterday: [],
      older: [],
    },
    total_unread_notification: 0,
    status: "idle",
    error: null,
  },
};

function groupNotifications(notifications: NotificationInfo[]): NotificationGroup {
  const notificationGroup: NotificationGroup = {
    today: [],
    yesterday: [],
    older: [],
  };
  for (let i = 0; i < notifications.length; i++) {
    const notification = notifications[i];

    const isSameDayNotification = moment(notification.created_at).isSame(new Date(), "day");
    const isYesterdayNotification = moment(notification.created_at).isSame(
      moment().subtract(1, "day"),
      "day"
    );
    const isOlderThanYesterdayNotification = moment(notification.created_at).isBefore(
      moment().subtract(1, "day"),
      "day"
    );
    if (isSameDayNotification) {
      notificationGroup.today.push(notification);
    } else if (isYesterdayNotification) {
      notificationGroup.yesterday.push(notification);
    } else if (isOlderThanYesterdayNotification) {
      notificationGroup.older.push(notification);
    }
  }

  return notificationGroup;
}

const notificationSplice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    addNotification(state, action: PayloadAction<NotificationInfo>) {
      //group notification into today,yesterday or older
      const { today, yesterday, older } = groupNotifications([action.payload]);
      if (today.length)
        state.notifications.data.today = [...state.notifications.data.today, ...today];
      if (yesterday.length)
        state.notifications.data.yesterday = [...state.notifications.data.yesterday, ...yesterday];
      if (older.length)
        state.notifications.data.older = [...state.notifications.data.older, ...older];

      state.notifications.total_unread_notification =
        state.notifications.total_unread_notification + 1;
    },
    resetNotifications(state) {
      state.notifications = initialState.notifications;
    },
  },
  extraReducers: {
    [getNotifications.pending]: (state, action) => {
      state.notifications.status = "loading";
    },

    [getNotifications.fulfilled]: (state, action: PayloadAction<NotificationInfo[]>) => {
      //group notification into today,yesterday or older
      const notificationGroup = groupNotifications(action.payload);
      state.notifications.status = "succeeded";
      state.notifications.data = notificationGroup;
      state.notifications.total_unread_notification = 0;
    },
    [getNotifications.rejected]: (state, action) => {
      state.notifications.status = "failed";
      state.notifications.error = action.error.message;
    },

    // unread notification
    [getUnreadNotifications.fulfilled]: (state, action: PayloadAction<number>) => {
      state.notifications.total_unread_notification = action.payload;
    },
  },
});

export const { addNotification, resetNotifications } = notificationSplice.actions;
export const notificationSelector = (state) => state[notificationSplice.name] as NotificationState;
export default notificationSplice.reducer;
