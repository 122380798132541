import { ICart } from "@samedaycustom/sdc-types";
import { ACTION_TYPE_DEFS } from "@samedaycustom/types/app";

export type CART_DATA = ICart[];

export type CustomerData = Partial<ACTION_TYPE_DEFS<ICart>>;

export type VENDOR_CART_STATE_TYPE = {
  carts: ACTION_TYPE_DEFS<CART_DATA>;
  cart: ACTION_TYPE_DEFS<ICart>;
};
export const CART_STATE: VENDOR_CART_STATE_TYPE = {
  carts: {
    loading: false,
    error: false,
    errorMessage: null,
    data: [],
  },
  cart: {
    loading: false,
    error: false,
    errorMessage: null,
    data: null,
  },
};
