import { TagType } from "@samedaycustom/core-ui";
import { ESDCOrderSource } from "@samedaycustom/sdc-types";

import { ISDCCustomer, ISDCStore } from "./customer";
import { GENERAL_STATUS, ISDCLine, SIZE_CODE } from "./line";
import { ISDCNote } from "./notes";

export enum ESDCDeliveryMethod {
  PICKUP = "pickup",
  RUSH_PICKUP = "rush_pickup",
  CAR = "car",
  SHIPPING = "shipping",
  RUSH_SHIPPING = "rush_shipping",
  SUPER_RUSH_SHIPPING = "super_rush"
}

export enum ESDCOrderStatusStr {
  // BEFORE PRODUCTION
  AWAITING_ACCEPTANCE = "A.A",
  NOT_STARTED = "N.S",

  // DURING OPERATIONS
  ARTWORK_REVIEW_STARTED = "R.S",
  PRODUCTION_STARTED = "P.S",
  PRODUCTION_COMPLETED = "P.C",
  DELIVERY_STARTED = "D.S",
  IN_PRODUCTION = "PROC",
  ON_HOLD = "O.H",
  COMFIRMATION_MISSING = "C.M",

  // AFTER OPERATION ENDS
  FULFILLED = "FUL",
  CANCELLED = "CAN",
  DECLINED = "DEC"
}

export enum ESDCOrderRefundStatus {
  // BEFORE PRODUCTION
  UNSETTLED,
  SETTLED,
}

export enum ESDCOrderState {
  ACTIVE = "active",
  INACTIVE = "inactive"
}

export type ORDER_STATUS =
  | "A.A"
  | "A.B"
  | "N.S"
  | "PROC"
  | "O.H"
  | "FUL"
  | "CAN"
  | "DEC"
  | "P.S"
  | "R.S"
  | "D.S"
  | "C.M"
  | "P.C";
export type DELIVERY_TYPE = "pickup" | "car" | "courier" | "shipping";
export type CHARGE_RATE_TYPE = "FLAT" | "PERCENT";
export type EXTRA_CHARGE_TYPE = "SHIPPING" | "OTHERS";
export type MERIDIAN_UNIT = "AM" | "PM";
export interface ISDCWorkPeriod {
  open: boolean;
  startMinute24: number;
  startHour24: number;
  endMinute24: number;
  endHour24: number;
  startMinute12: number;
  startHour12: number;
  endMinute12: number;
  endHour12: number;
  startUnit: MERIDIAN_UNIT;
  endUnit: MERIDIAN_UNIT;
}

export interface ISDCOrderCounts_ {
  orders: {
    all: number;
    open: number;
    completed: number;
  };
  production: {
    all: number;
    open: number;
    completed: number;
  };
  delivery: {
    all: number;
    open: number;
    completed: number;
  };
}
export interface ISDCWorkDays {
  sun: ISDCWorkPeriod;
  mon: ISDCWorkPeriod;
  tue: ISDCWorkPeriod;
  wed: ISDCWorkPeriod;
  thu: ISDCWorkPeriod;
  fri: ISDCWorkPeriod;
  sat: ISDCWorkPeriod;
}
export type ISDCManager = {
  firstName: string;
  id: string;
  lastName: string;
};
export interface ISDCOrder {
  id: string;
  decoOrderId: string;
  isCritical?: boolean;
  unreadMessages?: number;
  hasMessageStarted?: boolean;
  status: ORDER_STATUS | ESDCOrderStatusStr;
  customer: ISDCCustomer;
  billingAddress?: ISDCDeliveryDetails;
  deliveryDetails: ISDCDeliveryDetails;
  tzone?: string
  dateOrdered: number;
  dateFulfilled?: number;
  dateDue: number;
  dateDownloaded: number;
  dateStarted: number;
  confirmationUploaded?: boolean;
  autoAccepted?: boolean;
  urlInvoice: string;
  createdAt?: any;
  urlWorkSheet: string;
  price: number;
  totalQty?: string | number;
  billablePrice: number;
  originalDeliveryMethod?: TagType;
  charges: ISDCExtraCharges[] | { [key: string]: ISDCExtraCharges };
  deliveryMethod: DELIVERY_TYPE | ESDCDeliveryMethod;
  deliveryCharges: number;
  deliveryDiscount: number;
  deliveryDiscountType: CHARGE_RATE_TYPE;
  checkoutNote: string;
  productionMethod: string;
  productionCharges: number;
  discountName: string;
  discountType: CHARGE_RATE_TYPE;
  discountValue: number;
  taxes: Tax[];
  locationID: string;
  locationId: string;
  cancelledInDeco?: string;
  thumbnails?: string[];
  locationName: string;
  locationCity?: string;
  locationState?: string;
  isLocationOpen?: boolean;
  vendorID: string;
  vendorId: string;
  vendorName: string;
  stage: {
    artwork: GENERAL_STATUS;
    production: GENERAL_STATUS;
    delivery: GENERAL_STATUS;
    fulfilled: boolean;
    hold: boolean;
    payment: boolean;
  };
  location?: {
    acceptingOrders: boolean;
    address1: string;
    address2: string;
    city: string;
    country: string;
    email: string;
    id: string;
    locationArea: string;
    locationDescription: string;
    locationName: string;
    locationNumber: string;
    mallName: string;
    hours?: ISDCWorkDays;
    // managers: {};
    mobile: string;
    name: string;
    state: string;
    stateCode: string;
    suitNumber: string;
    tzone: string;
    tzoneIsSet: boolean;
    usersCount: number;
    vendorID: string;
    vendorName: string;
    zipcode: string;
    wlHandle?: string;
  };
  accepted: boolean | null;
  active: boolean | null;
  fulfilled: boolean;
  isNew?: boolean;
  historyId?: number;
  refundAmount?: number;
  totalRefunds?: number;
  totalPayout?: number;
  rushFeePayout?: number;
  source?: ESDCOrderSource;
  isRepeatedCustomer?: boolean;
  isLocationRepeatedCustomer?: boolean;
  hasUpdatedArtwork?: boolean;
  editedPayoutValue?: string | number;
  payoutLastEditedAt?: string | null;
  discount?: any;
  hasUnpaidCharge?: boolean;
  extraCharge?: ISDCExtraCharges;
  assignedAgents: string[];
  totalUnpaidCharges?: number;
  totalPaidCharges?: number;
  hasPendingDueDateChangeRequest?: boolean;
  orderDueDateChangeRequest?: {
    id: string;
    orderId: string
    currentDueDate: string;
    proposedDueDate: string;
    estimatedDeliveryDate: string;
    comment: string;
    status: string;
    createdBy: string;
    createdAt: string;
    updatedAt: string;
  },
  isFreeDelivery?: boolean;
  initialDeliveryMethod?: DELIVERY_TYPE | ESDCDeliveryMethod;
}
export interface ISDCExtraCharges {
  id: string;
  orderId?: string;
  description?: string;
  amount?: number;
  type: string;
  status?: boolean;
  paymentLink?: string;
  createdAt?: string;
  updatedAt?: string;
  isCancelled?: boolean;
  isRefunded?: boolean;
  statusValue?: string;
}
export interface ISDCLocation extends ISDCStore {
  id: string;
  name: string;
  active?: boolean;
  storeApproved?: boolean;
  workDays: ISDCWorkDays;
  totalRefunds?: number;
  wlHandle?: string;
  isWhitelabel?: boolean;
  note?: string;
  printingMethods?: {
    [key: string]: boolean;
  } | null;
}

export interface ISDCWorkDays {
  sun: ISDCWorkPeriod;
  mon: ISDCWorkPeriod;
  tue: ISDCWorkPeriod;
  wed: ISDCWorkPeriod;
  thu: ISDCWorkPeriod;
  fri: ISDCWorkPeriod;
  sat: ISDCWorkPeriod;
}

export interface ISDCExtraCharges {
  type: EXTRA_CHARGE_TYPE;
  title: string;
  amout: number;
  id: string;
}

export interface Tax {
  id: number;
  name: string;
  amount: number;
}

export interface ISDCOrderDetails {
  order: ISDCOrder;
  lines: ISDCLine[];
  notes: ISDCNote[];
}

export interface ISize {
  size: SIZE_CODE;
  qty_total: number;
  qty_produced: number;
  qty_shipped: number;
  status: "AB" | "P" | "PP" | "S" | "IT" | "OH" | "D";
  dps?: number; // Date production started
  dpc?: number; // Date production completed
  dss?: number; // Date shipping started
  dsc?: number; // Date shipping completed
}

export interface ISizes {
  S: ISize;
  M: ISize;
  L: ISize;
  XL: ISize;
  XXL: ISize;
  XXXL: ISize;
}

export interface ISDCDeliveryDetails {
  address1: string;
  address2?: string;
  state: US_STATES;
  stateCode: US_STATES_CODE;
  country: "United States of America" | "United States";
  countryCode: "US";
  zipcode: string;
  firstName: string;
  email?: string;
  lastName: string;
  mobile: string;
  city?: string;
  cityCode?: string;
  dropoffInstructions?: string;
  apt?: string;
}

export declare type US_STATES =
  | "Alabama"
  | "Alaska"
  | "Arizona"
  | "Arkansas"
  | "California"
  | "Colorado"
  | "Connecticut"
  | "Delaware"
  | "Florida"
  | "Georgia"
  | "Hawaii"
  | "Idaho"
  | "Illinois"
  | "Indiana"
  | "Iowa"
  | "Kansas"
  | "Kentucky"
  | "Louisiana"
  | "Maine"
  | "Maryland"
  | "Massachusetts"
  | "Michigan"
  | "Minnesota"
  | "Mississippi"
  | "Missouri"
  | "Montana"
  | "Nebraska"
  | "Nevada"
  | "New Hampshire"
  | "New Jersey"
  | "New Mexico"
  | "New York"
  | "North Carolina"
  | "North Dakota"
  | "Ohio"
  | "Oklahoma"
  | "Oregon"
  | "Pennsylvania"
  | "Rhode Island"
  | "South Carolina"
  | "South Dakota"
  | "Tennessee"
  | "Texas"
  | "Utah"
  | "Vermont"
  | "Virginia"
  | "Washington"
  | "West Virginia"
  | "Wisconsin"
  | "Wyoming";

export type US_STATES_CODE =
  | "AL"
  | "AK"
  | "AS"
  | "AZ"
  | "AR"
  | "CA"
  | "CO"
  | "CT"
  | "DE"
  | "DC"
  | "FM"
  | "FL"
  | "GA"
  | "GU"
  | "HI"
  | "ID"
  | "IL"
  | "IN"
  | "IA"
  | "KS"
  | "KY"
  | "LA"
  | "ME"
  | "MH"
  | "MD"
  | "MA"
  | "MI"
  | "MN"
  | "MS"
  | "MO"
  | "MT"
  | "NE"
  | "NV"
  | "NH"
  | "NJ"
  | "NM"
  | "NY"
  | "NC"
  | "ND"
  | "MP"
  | "OH"
  | "OK"
  | "OR"
  | "PW"
  | "PA"
  | "PR"
  | "RI"
  | "SC"
  | "SD"
  | "TN"
  | "TX"
  | "UT"
  | "VT"
  | "VI"
  | "VA"
  | "WA"
  | "WV"
  | "WI"
  | "WY"
  | "N/A";

export const US_STATE_ABBR_MAPPING = {
  Alabama: "AL",
  Alaska: "AK",
  "American Samoa": "AS",
  Arizona: "AZ",
  Arkansas: "AR",
  California: "CA",
  Colorado: "CO",
  Connecticut: "CT",
  Delaware: "DE",
  "District Of Columbia": "DC",
  "Federated States Of Micronesi": "FM",
  Florida: "FL",
  Georgia: "GA",
  Guam: "GU",
  Hawaii: "HI",
  Idaho: "ID",
  Illinois: "IL",
  Indiana: "IN",
  Iowa: "IA",
  Kansas: "KS",
  Kentucky: "KY",
  Louisiana: "LA",
  Maine: "ME",
  "Marshall Islands": "MH",
  Maryland: "MD",
  Massachusetts: "MA",
  Michigan: "MI",
  Minnesota: "MN",
  Mississippi: "MS",
  Missouri: "MO",
  Montana: "MT",
  Nebraska: "NE",
  Nevada: "NV",
  "New Hampshire": "NH",
  "New Jersey": "NJ",
  "New Mexico": "NM",
  "New York": "NY",
  "North Carolina": "NC",
  "North Dakota": "ND",
  "Northern Mariana Islands": "MP",
  Ohio: "OH",
  Oklahoma: "OK",
  Oregon: "OR",
  Palau: "PW",
  Pennsylvania: "PA",
  "Puerto Rico": "PR",
  "Rhode Island": "RI",
  "South Carolina": "SC",
  "South Dakota": "SD",
  Tennessee: "TN",
  Texas: "TX",
  Utah: "UT",
  Vermont: "VT",
  "Virgin Islands": "VI",
  Virginia: "VA",
  Washington: "WA",
  "West Virginia": "WV",
  Wisconsin: "WI",
  Wyoming: "WY"
};

export enum ISDCOrderCancellationType {
  CopyRight = "ICOR",
  DesignQuality = "IDQ",
  OtherDesignIssue = "IOD",
  UnableToFulfilOrder = "IUFO",
  CustomerRequest = "ICUR",
  TooBusy = "ITB",
  Closed = "IC",
  MachineBreakDown = "IMB"
}
export type ISDCOrderCancellationReason = {
  type: ISDCOrderCancellationType;
  message?: string;
};

export const getStateCode = (state: US_STATES): US_STATES_CODE => {
  if (!state) return "N/A";
  const keys = Object.keys(US_STATE_ABBR_MAPPING).map((val: any) =>
    val.toUpperCase()
  );
  const values = Object.values(US_STATE_ABBR_MAPPING);

  const stateIdx = keys.indexOf(state.toUpperCase());

  return stateIdx > 0 && Array.isArray(values) && values?.length
    ? (values[stateIdx] as US_STATES_CODE)
    : "N/A";
};

export const getStateFromCode = (stateCode: US_STATES_CODE) => {
  if (!stateCode) return null;
  const re = Object.entries(US_STATE_ABBR_MAPPING).find(el =>
    Array.isArray(el) ? el[1] === stateCode : null
  );

  if (re && Array.isArray(re)) return re[0];
  return null;
};

export interface ISDCShippingLabel {
  id?: string;
  labelID?: string;
  orderID: string;
  dateCreated: string | number;
  status: string;
  decoOrderId: string;
  shippingCost: number;
  qty: number;
  vendorID: string;
  vendorName: string;
  locationID: string;
  locationName: string;
  locationCity: string;
  labelStatus: string;
  trackingStatus: string;
  dateShipped: number;
  labelURL: string;
  carrier: string;
  service: string;
  insuranceCost: string;
  fulfillmentCenter: string;
  packages: any;
  createdAt?: string;
  trackingID?: string;
}
