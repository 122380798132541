import React from 'react'
import './style.scss'
import { Skeleton } from '@material-ui/lab'

export default () => {
    return (
        <div className="lineItemCardLoader__container">
            <div className="lineItemCardLoader__top">
                <Skeleton variant="rect"  width={108} height={120}/>
                <div className="lineItemCardLoader__mainTextGroup">
                    <Skeleton width={80} height={20}/>
                    <Skeleton width={150} />
                    <Skeleton width={50} />
                </div>
            </div>
            <div className="lineItemCardLoader__btm">
                <div className="lineItemCardLoader__btm--group">
                    <Skeleton width={80}/>
                    <Skeleton width={50}/>
                </div>
                <div className="lineItemCardLoader__btm--group">
                    <Skeleton width={50}/>
                </div>
            </div>
        </div>
    )
}
