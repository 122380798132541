import "./style.scss";

import { useToast } from "@samedaycustom/hooks";
import { AuthedButton } from "@samedaycustom/core-ui";
import { Input } from "@samedaycustom/core-ui";
import { UnAuthedScreenLayout as UnAuthedLayout } from "@samedaycustom/core-ui";
import { Formik } from "formik";
import { ONBOARDING_STATE_TYPE } from "@samedaycustom/types/app";
import * as onboardingAsyncActions from "vendor/providers/actions/onboarding";
import { RESET_PASSWORD_ACTION } from "vendor/providers/actions/onboarding/actionTypes";
import React, { useEffect } from "react";
import { DefaultRootState, useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import * as Yup from "yup";
import * as Routes from "vendor/routes/screen";
import QueryString from "query-string";

const ResetPasswordPage = () => {
  const dispatch = useDispatch();
  const toast = useToast();
  const onBoardingState = useSelector<DefaultRootState, ONBOARDING_STATE_TYPE>(
    (state) => state["onboarding"]
  );
  const history = useHistory();
  const parsedObj = QueryString.parse(history.location.search + history.location.hash);

  // extract query params

  useEffect(() => {
    document.title = "Reset Password";
  }, []);

  useEffect(() => {
    if (onBoardingState.error && onBoardingState.type === RESET_PASSWORD_ACTION) {
      dispatch(onboardingAsyncActions.stateReset());
      toast({
        title: "Password Reset Error",
        description: onBoardingState.errorMessage,
        position: "bottom-left",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [onBoardingState.error]);

  useEffect(() => {
    if (onBoardingState.done && onBoardingState.type === RESET_PASSWORD_ACTION) {
      dispatch(onboardingAsyncActions.stateReset());
      history.replace(Routes.SIGN_IN_PAGE);
      toast({
        title: "Password Reset",
        description: "You have successfully reset your password",
        position: "bottom-left",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [onBoardingState.done]);

  return (
    <UnAuthedLayout>
      <div className="authActionBox">
        <h1 className="authActionBox__Header">Change your password</h1>
        <div className="emailGroup">
          <h2>Email Address</h2>
          <p>{parsedObj?.email}</p>
        </div>
        <Formik
          initialValues={{
            password: "",
            confirmPassword: "",
          }}
          validationSchema={Yup.object({
            password: Yup.string()
              .required("Please enter your password")
              .min(6, "Enter a password with at least six (6) characters"),
            confirmPassword: Yup.string()
              .required("Please confirm your password")
              .oneOf([Yup.ref("password"), ""], "Passwords do not match"),
          })}
          onSubmit={(values) => {
            dispatch(
              onboardingAsyncActions.resetPasswordAsync({
                password: values.password,
                token: parsedObj.token as string,
              })
            );
          }}
        >
          {({ handleSubmit }) => {
            return (
              <>
                <div className="authActionBox__main">
                  <Input
                    type="input"
                    inputConfig={{
                      label: "Password",
                      id: "password",
                      name: "password",
                      type: "password",
                    }}
                  />
                  <Input
                    type="input"
                    inputConfig={{
                      label: "Confirm Password",
                      id: "confirmPassword",
                      name: "confirmPassword",
                      type: "password",
                    }}
                    onKeyDown={(e) => e.key === "Enter" && handleSubmit(e as any)}
                  />
                </div>
                <AuthedButton
                  onClick={handleSubmit as any}
                  loaderColor="#fff"
                  loading={
                    onBoardingState.loading && onBoardingState.type === RESET_PASSWORD_ACTION
                  }
                  className="Btn--Primary authActionBox__Btn"
                >
                  <span> Change Password </span>
                </AuthedButton>
                <NavLink to="/signin">
                  <span className="authActionBox__actionLink"> Return to login </span>
                </NavLink>
              </>
            );
          }}
        </Formik>
      </div>
    </UnAuthedLayout>
  );
};

export default ResetPasswordPage;
