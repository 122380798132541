import {
  UPDATE_STORE_PROFILE,
  GET_STORE_PROFILE,
} from "vendor/providers/actions/App/Settings/Store/StoreProfile/types";
import { storeProfileState } from "./initialstate";

export const storeProfileReducer = (
  state = storeProfileState,
  action: {
    type: string;
    data?: typeof storeProfileState;
    formValues: typeof storeProfileState;
  }
): typeof storeProfileState => {
  switch (action.type) {
    case UPDATE_STORE_PROFILE:
      return { ...state, ...action.data };
    case GET_STORE_PROFILE:
      return action.data;
    default:
      return state;
  }
};
