export const FETCH_OPERATION_ORDERS = "FETCH_OPERATION_ORDERS";
export const FETCH_OPERATION_PRODUCTION = "FETCH_OPERATION_PRODUCTION";
export const FETCH_OPERATION_SHIPPING = "FETCH_OPERATION_SHIPPING";
export const FETCH_ORDER_NOTES = "FETCH_ORDER_NOTES";
export const RESET_ORDER_NOTES = "RESET_ORDER_NOTES";
export const FETCH_ORDER_LINEITEMS = "FETCH_ORDER_LINEITEMS";
export const RESET_ORDER_LINEITEMS = "RESET_ORDER_LINEITEMS";
export const FETCH_TIMELINE = "FETCH_TIMELINE";
export const FETCH_DELIVERY_RECORD = "FETCH_DELIVERY_RECORD";
export const FETCHING_DELIVERY_RECORD = "FETCHING_DELIVERY_RECORD";
export const FETCHING_OPERATIONS = "FETCHING_OPERATIONS";
export const FAILED_OPERATIONS = "FAILED_OPERATIONS";
export const RESET_FAILED_ORDERS_OPERATION = "RESET_FAILED_ORDERS_OPERATION";
export const REMOVE_OPERATION_ORDER = "REMOVE_OPERATION_ORDER";
export const ORDER_MESSAGE_READ = "ORDER_MESSAGE_READ";

export const UPDATE_DELIVERY_RECORD_STATUS = "UPDATE_DELIVERY_RECORD_STATUS";
export const UPDATE_SHIPPING_INFORMATION = "UPDATE_SHIPPING_INFORMATION";
export const UPDATE_CAR_DELIVERY_INFORMATION = "UPDATE_CAR_DELIVERY_INFORMATION";
export const CAR_DELIVERY_STATUS_UPDATE = "CAR_DELIVERY_STATUS_UPDATE";
export const UPDATE_ORDERS_LINEITEMS_DELIVERED = "UPDATE_ORDERS_LINEITEMS_DELIVERED";
export const UPDATE_ORDER_TIMER_INTERVAL = "UPDATE_ORDER_TIMER_INTERVAL";
export const UPDATE_ORDER_NOTE_VALUE = "UPDATE_ORDER_NOTE_VALUE";
export const UPDATE_SHIPPING_RATES = "UPDATE_SHIPPING_RATES";
export const UPDATE_ORDER_NOTES = "UPDATE_ORDER_NOTES";
export const RESET_NOTES = "RESET_NOTES";
export const UPDATE_NEW_NOTE_COUNT = "UPDATE_NEW_NOTE_COUNT";
export const RESET_NOTE_COUNT = "RESET_NOTE_COUNT";
export const UPDATE_PICKUP_NOTIFICATION = "UPDATE_PICKUP_NOTIFICATION";
export const UPDATE_ORDER_NOTE_BY_ID = "UPDATE_ORDER_NOTE_BY_ID";
export const DELETE_ORDER_NOTE_BY_ID = "DELETE_ORDER_NOTE_BY_ID";
export const UPDATE_RECORD_DELIVERY_RESULT = "UPDATE_RECORD_DELIVERY_RESULT";
export const RESET_RECORD_DELIVERY_RESULT = "RESET_RECORD_DELIVERY_RESULT";
export const FETCHING_RECORD_DELIVERY_RESULT = "FETCHING_RECORD_DELIVERY_RESULT";
export const FAILED_FETCHING_RECORD_DELIVERY_RESULT = "FAILED_FETCHING_RECORD_DELIVERY_RESULT";
export const UPDATE_TOP_PANEL_SELECTION = "UPDATE_TOP_PANEL_SELECTION";
export const UPDATEL_LINE_ITEM = "UPDATEL_LINE_ITEM";

export const SET_ORDERS_TARGET_LOCATION = "SET_ORDERS_TARGET_LOCATION";
export const SET_SHIPPING_TARGET_LOCATION = "SET_SHIPPING_TARGET_LOCATION";
export const SET_PRODUCTION_TARGET_LOCATION = "SET_PRODUCTION_TARGET_LOCATION";
export const FETCH_SINGLE_ORDER = "FETCH_SINGLE_ORDER";
export const RESET_ORDER = "RESET_ORDER";
export const SEARCH_OPERATION_ORDERS = "FETCH_SINGLE_ORDER";
export const FILTER_OPERATION_ORDERS = "FILTER_OPERATION_ORDERS";
export const SET_ISFETCHING_LINEITEM = "SET_ISFETCHING_LINEITEM";
export const SHOW_ARTWORK_BAR = "SHOW_ARTWORK_BAR";
export const SHOW_NOTE_BAR = "SHOW_NOTE_BAR";
export const UPDATE_ORDER_ARTWORK_IMAGE = "UPDATE_ORDER_ARTWORK_IMAGE";
export const REMOVE_ORDER_ARTWORK_IMAGE = "REMOVE_ORDER_ARTWORK_IMAGE";

export const UPDATE_PRODUCTION_ORDER_LINEITEMS = "UPDATE_PRODUCTION_ORDER_LINEITEMS";

export const SET_IS_FETCHING_TIMELINE = "SET_IS_FETCHING_TIMELINE";

export const SET_ORDERS_TAB_LOCATION = "SET_ORDERS_TAB_LOCATION";
export const SET_SHIPPING_TAB_LOCATION = "SET_SHIPPING_TAB_LOCATION";
export const SET_PRODUCTION_TAB_LOCATION = "SET_PRODUCTION_TAB_LOCATION";

export const SET_ITEM_STATUS = "SET_ITEM_STATUS";
export const SET_ISUPDATING_STATUS = "SET_ISUPDATING_STATUS";
export const SET_ITEM_STATUS_FAILED = "SET_ITEM_STATUS_FAILED";
export const SET_ITEM_STATUS_SUCCESS = "SET_ITEM_STATUS_SUCCESS";

export const SET_FILTER_PARAMS = "SET_FILTER_PARAMS";
export const SET_ISFILTERING = "SET_ISFILTERING";

export const UPDATE_ORDER_MESSAGE_VALUE = "UPDATE_ORDER_MESSAGE_VALUE";
export const SHOW_MESSAGE_BAR = "SHOW_MESSAGE_BAR";
export const ACTIVE_ORDER_NEW_MESSAGE_TAG = "ACTIVE_ORDER_NEW_MESSAGE_TAG";

export const UPDATE_SINGLE_ORDER = "UPDATE_SINGLE_ORDER";
