import React, { useState } from "react";
import "./style.scss";
import Popup from "reactjs-popup";
import { motion, AnimatePresence } from "framer-motion";

const DropDownItem = ({ onClick, item, value, activeBar }) => {
	return (
		<React.Fragment>
			<div
				className={`ADropDown__OptionItem ${
					item?.value === value ? "activeOption" : ""
				}`}
				onClick={onClick}
			>
				{item?.label?.length > 20 ? (
					<Popup
						on="hover"
						trigger={(open) => {
							return (
								<span className="miniFlyoutItem">
									{item.label}
								</span>
							);
						}}
						position="bottom center"
						closeOnDocumentClick={true}
						arrow={false}
						contentStyle={{
							width: "unset",
							border: "none",
							padding: "0",
							height: "unset",
							borderRadius: "4px",
							boxShadow: "0px 5px 10px rgba(98, 125, 152, 0.1)"
						}}
					>
						<span className="tooltipContainer">{item.label}</span>
					</Popup>
				) : (
					<span className="miniFlyoutItem">{item.label}</span>
				)}
				{item?.value === value && activeBar && (<div className="activeSideBar"/>)}
			</div>
		</React.Fragment>
	);
};

interface Iprops {
	target: React.ReactElement | JSX.Element;
	onClick: (value: string) => any;
	options: Array<{ label: string | JSX.Element; value: any }>;
	value: string;
	loading?: boolean;
	position?:
		| "top left"
		| "top center"
		| "top right"
		| "right top"
		| "right center"
		| "right bottom"
		| "bottom left"
		| "bottom center"
		| "bottom right"
		| "left top"
		| "left center"
		| "left bottom"
		| "center center";
	activeBar?: boolean;
}

const HeadlessDropDown = ({
	target,
	onClick,
	options,
	value,
	loading,
	position,
	activeBar
}: Iprops) => {
	return (
		<Popup
			trigger={(triggerProps) => {
				return (
					<div style={{ cursor: "pointer" }} {...triggerProps}>
						{target}
					</div>
				);
			}}
			contentStyle={{
				width: "unset",
				border: "none",
				padding: "0",
				height: "unset",
				borderRadius: "4px",
				backgroundColor: "transparent"
			}}
			repositionOnResize={true}
			arrow={false}
			offsetY={25}
			offsetX={50}
			position={position || "bottom center"}
			closeOnDocumentClick={true}
		>
			{(closeFunc, isOpen) => (
				<AnimatePresence>
					{isOpen && (
						<motion.div
							key="BannerTop"
							initial={"collapsed"}
							animate="open"
							exit="collapsed"
							variants={{
								open: { opacity: 1, pointerEvents: "all" },
								collapsed: { opacity: 0, pointerEvents: "none" }
							}}
							transition={{
								duration: 0.3,
								ease: "easeOut"
							}}
						>
							<>
								<div className="optionsContainer">
									{options.length >= 1 && !loading ? (
										options.map((item) => {
											return (
												item &&
												(item.value ||
													item.value === 0) && (
													<DropDownItem
														item={item}
														value={value}
														onClick={() => {
															closeFunc();
															onClick(item.value);
														}}
														activeBar={activeBar}
													/>
												)
											);
										})
									) : (
										<p>
											{loading
												? "Loading... "
												: "No Items Found"}
										</p>
									)}
								</div>
							</>
						</motion.div>
					)}
				</AnimatePresence>
			)}
		</Popup>
	);
};

export default HeadlessDropDown;
