import React, { useState } from "react";
import { ChakraInput as Input, InputProps } from "../../_components";
import { useField } from "formik";

export interface IComputeInput extends InputProps {
  selectAll?: boolean;
}

export default React.memo(({ selectAll = true, ...props }: IComputeInput) => {
  const [field] = useField(props as any);
  return (
    <Input
      height="40px"
      borderRadius="4px"
      onFocus={selectAll ? (e: any) => e.target.select() : () => {}}
      {...field}
      {...props}
      onChange={(e: any) => {
        field.onChange(e);
      }}
    />
  );
});
