import { STORE_TYPE, ILocationTarget } from "@samedaycustom/types/app";
import { storeSettings } from "vendor/providers/initialState";

import { SET_SETTING_LOCATION } from "vendor/providers/actions/types";

export default (
  state = storeSettings,
  action: {
    type: string;
    data?: STORE_TYPE;
  }
): ILocationTarget => {
  switch (action.type) {
    case SET_SETTING_LOCATION:
      return { ...state, targetLocation: action.data };
    default:
      return state;
  }
};
