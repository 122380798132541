import "@samedaycustom/assets/index.css";
import "@samedaycustom/assets/index.scss";
import "react-datepicker/dist/react-datepicker.css";
import "./modules/pusher";
import "./react-app-env.d";

import { ChakraProvider } from "@chakra-ui/react";
import { ErrorBoundary, SameDayCustomProvider } from "@samedaycustom/core-ui";
import "lightgallery.js/dist/css/lightgallery.css";
import React from "react";
import ReactDOM from "react-dom";
import { LightgalleryProvider } from "react-lightgallery";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "vendor/providers/store";
import { initialize } from "./environment";

import App from "./App";
import theme from "./assets/theme";

initialize();

const MainApp: React.FC = () => {
  return (
    <ChakraProvider theme={theme}>
      <SameDayCustomProvider>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <ErrorBoundary>
              <LightgalleryProvider>
                <App />
              </LightgalleryProvider>
            </ErrorBoundary>
          </PersistGate>
        </Provider>
      </SameDayCustomProvider>
    </ChakraProvider>
  );
};

ReactDOM.render(<MainApp />, document.getElementById("root"));
