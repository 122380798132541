import "./style.scss";

import { CSSReset, ThemeProvider } from "@chakra-ui/react";
import {
  CustomFileInput,
  DropdownInput,
  FileItem,
  Input,
  Modal,
  Box,
} from "@samedaycustom/core-ui";
import { useToast } from "@samedaycustom/hooks";
import request from "axios";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import usePortal from "react-useportal";
import Popup from "reactjs-popup";
import * as Yup from "yup";

import { useSelector } from "react-redux";
import { ReactComponent as Bulb } from "./assets/ideasvg.svg";
import { ReactComponent as Issue } from "./assets/issue.svg";
import { ReactComponent as Other } from "./assets/more-horizotnal.svg";
import { ReactComponent as Support } from "./assets/support-icon.svg";
import theme from "./assets/theme";
import { EVendorFeedbackType, EVendorFeedbackUrgency } from "@samedaycustom/sdc-types";

type Position =
  | "top left"
  | "top center"
  | "top right"
  | "right top"
  | "right center"
  | "right bottom"
  | "bottom left"
  | "bottom center"
  | "bottom right"
  | "left top"
  | "left center"
  | "left bottom"
  | "center center";
export interface IFeedback {
  url: string;
  onUpload?: (file: File, fileid: string) => Promise<File>;
  className?: string;
  position: Position;
}

export const FeedBack = ({ url, onUpload, className, position }: IFeedback) => {
  const toast = useToast();
  const [type, setType] = useState<EVendorFeedbackType | null>(null);
  const [showPortal, setShowPortal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [fileList, setFileList] = useState<
    Array<{ name: string; id: string; url: string; loading: boolean }>
  >([]);
  const { locations } = useSelector((state) => state["locations"]);
  const { token } = useSelector((state) => state["auth"]);

  const delFunc = (id) => {
    setFileList((prev) => [...prev.filter((file) => file.id !== id)]);
  };

  useEffect(() => {
    if (type) {
      setShowPortal(true);
    }
  }, [type]);

  const animEnd = () => {
    if (!type) setShowPortal(false);
  };

  const { Portal } = usePortal();

  const sendFunc = async (values) => {
    try {
      setLoading(true);
      const masterObj = {
        ...values,
        images: fileList.map((file) => file.url),
      };

      await request.post(url, masterObj, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setLoading(false);
      setType(null);
      setFileList([]);
      toast({
        title: "Feedback submitted",
        position: "bottom-left",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (e) {
      setLoading(false);
      toast({
        title: "Submit Feedback",
        description: "An error occured",
        position: "bottom-left",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }

    return null;
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <CSSReset />
        <Popup
          trigger={(triggerProps) => {
            return (
              <Box position={"absolute"} left={"0px"} top={"0px"} width={"full"} height={"full"}>
                {" "}
              </Box>
            );
          }}
          contentStyle={{
            width: "unset",
            border: "none",
            padding: "0",
            height: "unset",
            borderRadius: "4px",
            boxShadow: "0px 6px 18px rgba(98, 125, 152, 0.24)",
          }}
          repositionOnResize={true}
          arrow={false}
          offsetX={0}
          offsetY={20}
          position={position}
          closeOnDocumentClick={true}
        >
          {(closeFunc, isOpen) => (
            <Box className="typeOptions">
              <button
                className="typeOption"
                onClick={() => {
                  setType(EVendorFeedbackType.IDEA);
                  closeFunc();
                }}
              >
                <Bulb />
                <span>Idea</span>
              </button>
              <button
                className="typeOption"
                onClick={() => {
                  setType(EVendorFeedbackType.ISSUE);
                  closeFunc();
                }}
              >
                <Issue />
                <span>Issue</span>
              </button>
              <button
                className="typeOption"
                onClick={() => {
                  setType(EVendorFeedbackType.OTHER);
                  closeFunc();
                }}
              >
                <Other />
                <span>Other</span>
              </button>
            </Box>
          )}
        </Popup>
        {showPortal && (
          <Portal>
            <Box
              className={`supportContainer ${type ? "fadePortalIn" : "fadePortalOut"}`}
              onAnimationEnd={animEnd}
            >
              <Formik
                initialValues={{
                  type,
                  category: "",
                  urgency: "",
                  images: "",
                  response: "",
                  location_id: "",
                }}
                validationSchema={Yup.object({
                  category: Yup.string().required("This field is required"),
                  urgency: Yup.string()
                    .oneOf(Object.values(EVendorFeedbackUrgency))
                    .required("This field is required"),
                  response: Yup.string().required("This field is required"),
                  location_id: Yup.string().required("this field is required"),
                })}
                onSubmit={(values) => {
                  sendFunc(values);
                }}
              >
                {({ handleSubmit, dirty, isValid }) => {
                  return (
                    <Modal
                      show={type !== null}
                      closeFunction={() => setType(null)}
                      loading={loading}
                      actionFunction={handleSubmit}
                      heading={
                        type === EVendorFeedbackType.IDEA
                          ? "Share an idea"
                          : type === EVendorFeedbackType.ISSUE
                          ? "Report an Issue"
                          : "Send feedback"
                      }
                      footer={true}
                      actionBtnClass="Btn-Fill Btn--Primary"
                      actionBtnText="Submit feedback"
                      disabled={
                        !(dirty && isValid) ||
                        (fileList.length > 0 && fileList[fileList.length - 1].loading)
                      }
                    >
                      <Box className="formContent">
                        <Box className="inputFlexGroup">
                          <DropdownInput
                            list={[
                              {
                                label: "Dashboard",
                                value: "Dashboard",
                              },
                              {
                                label: "Orders",
                                value: "Orders",
                              },
                              {
                                label: "Artwork",
                                value: "Artwork",
                              },
                              {
                                label: "Production",
                                value: "Production",
                              },
                              {
                                label: "Delivery",
                                value: "Delivery",
                              },
                              {
                                label: "Users & Permissions",
                                value: "Users & Permissions",
                              },
                              {
                                label: "Store Locations",
                                value: "Store Locations",
                              },
                              {
                                label: "Store setup",
                                value: "Store setup",
                              },
                              {
                                label: "Other",
                                value: "Other",
                              },
                            ]}
                            asyncAction={() => {}}
                            label="Category"
                            placeholder="--Choose--"
                            name="category"
                            loading={false}
                            // expandable={true}
                          />
                          <DropdownInput
                            list={[
                              {
                                label: "Nice to have",
                                value: EVendorFeedbackUrgency.NICE_TO_HAVE,
                              },
                              {
                                label: "Important",
                                value: EVendorFeedbackUrgency.IMPORTANT,
                              },
                              {
                                label: "Critical",
                                value: EVendorFeedbackUrgency.CRITICAL,
                              },
                            ]}
                            asyncAction={() => {}}
                            label="Urgency"
                            placeholder="--Choose--"
                            name="urgency"
                            loading={false}
                          />
                        </Box>
                        <DropdownInput
                          list={locations?.map((loc) => ({ label: loc.name, value: loc.id }))}
                          asyncAction={() => {}}
                          label="Location"
                          placeholder="--Choose the store you sending feedback from--"
                          name="location_id"
                          loading={false}
                          // expandable={true}
                        />
                        <CustomFileInput
                          className="cFileInput"
                          fileList={fileList}
                          setFileList={setFileList}
                          onUpload={onUpload}
                        />
                        {fileList.map((file) => (
                          <FileItem file={file} delFunc={delFunc} />
                        ))}
                        <Box className="inputtextAreaContainer">
                          <Input
                            type={"textArea"}
                            inputConfig={{
                              label:
                                type === EVendorFeedbackType.IDEA
                                  ? "Idea"
                                  : type === EVendorFeedbackType.ISSUE
                                  ? "Issue"
                                  : "Feedback",
                              type: "text",
                              name: "response",
                              id: "response",
                            }}
                          />
                        </Box>
                      </Box>
                    </Modal>
                  );
                }}
              </Formik>
            </Box>
          </Portal>
        )}
      </ThemeProvider>
    </>
  );
};
