import { UseToastOptions } from "../_components";
import { useToast } from "@samedaycustom/hooks";
import React from "react";

export interface ToastOptions extends UseToastOptions {
  show?: boolean;
}
export const Toast = ({ show, ...props }: ToastOptions) => {
  const toast = useToast();
  React.useEffect(() => {
    toast(props);
  }, [props, show, toast]);
  return null;
};
