import request from "axios";
import localforage from "localforage";
import { ThunkAction } from "redux-thunk";
import constants from "vendor/api/Api.constants";
import { pusher_beam } from "vendor/modules/pusher";

import { UPDATE_AUTH_EMAIL_NOT_CONFIRMED, UPDATE_AUTH_TOKEN_ACTION } from "./../types";
import {
  ACTION_GROUP_TYPE,
  AUTH_ACTION_TYPES,
  CHECK_EMAIL_AVAILABILITY,
  CREATE_PASSWORD_ACTION,
  FAIL_AUTH_ACTION,
  FORGOT_PASSWORD_ACTION,
  LOGOUT_ACTION,
  ONBOARDING_STATE_RESET,
  RESET_PASSWORD_ACTION,
  REVERIFY_ACTION,
  SIGN_IN_ACTION,
  SIGN_UP_ACTION,
  START_AUTH_ACTION,
  VERIFY_ACTION,
} from "./actionTypes";
import { identifyMixpanel } from "packages/helpers/src/mixpanel";

const startAction = (type: ACTION_GROUP_TYPE): AUTH_ACTION_TYPES => {
  return {
    type: START_AUTH_ACTION,
    payload: type,
  };
};

const failAction = (type: ACTION_GROUP_TYPE, msg: string): AUTH_ACTION_TYPES => {
  return {
    type: FAIL_AUTH_ACTION,
    payload: {
      type,
      msg,
    },
  };
};

const createPassword = (): AUTH_ACTION_TYPES => {
  return {
    type: CREATE_PASSWORD_ACTION,
  };
};

const forgotPassword = (): AUTH_ACTION_TYPES => {
  return {
    type: FORGOT_PASSWORD_ACTION,
  };
};

const signIn = (authData: any) => async (dispatch) => {
  await localforage.setItem("TOKEN", authData.token);
  dispatch({
    type: UPDATE_AUTH_TOKEN_ACTION,
    payload: { ...authData },
  });
};
export const signInButEmailNotConfirmed = () => ({
  type: UPDATE_AUTH_EMAIL_NOT_CONFIRMED,
  payload: null,
});

const signInView = (): AUTH_ACTION_TYPES => {
  return {
    type: SIGN_IN_ACTION,
  };
};

const signUp = (authData: any): AUTH_ACTION_TYPES => {
  return {
    type: SIGN_UP_ACTION,
    payload: { ...authData },
  };
};

const checkEmailAvailiabity = (exist: boolean): AUTH_ACTION_TYPES => {
  return {
    type: CHECK_EMAIL_AVAILABILITY,
    payload: exist,
  };
};

// exported action creators

export const stateReset = (): AUTH_ACTION_TYPES => {
  return {
    type: ONBOARDING_STATE_RESET,
  };
};

export const logout = () => async (dispatch) => {
  localforage.removeItem("TOKEN");
  if (pusher_beam) pusher_beam.stop();
  dispatch({
    type: LOGOUT_ACTION,
  });
};

export const verify = (): AUTH_ACTION_TYPES => {
  return {
    type: VERIFY_ACTION,
  };
};

export const reVerify = (): AUTH_ACTION_TYPES => {
  return {
    type: REVERIFY_ACTION,
  };
};

export const passwordReset = (): AUTH_ACTION_TYPES => {
  return {
    type: RESET_PASSWORD_ACTION,
  };
};

// async actions

type AuthAsyncActionType = ThunkAction<void, undefined, null, AUTH_ACTION_TYPES>;

export const createPasswordAsync = (data: { token: string; email: string; password: string }) => (
  dispatch: any
) => {
  dispatch(startAction(CREATE_PASSWORD_ACTION));
  request
    .post(
      `${constants.CREATE_PASSWORD}?token=${data.token}`,
      {
        ...data,
      },
      {
        headers: {
          "Content-Type": constants.CONTENT_TYPE,
        },
      }
    )
    .then((res) => {
      if (res.data.status !== "fail") {
        dispatch(createPassword());
      } else {
        if (res.data.message instanceof Array) {
          dispatch(failAction(CREATE_PASSWORD_ACTION, res.data.message[0].msg));
        } else {
          dispatch(failAction(CREATE_PASSWORD_ACTION, res.data.message));
        }
      }
    })
    .catch((err) => {
      if (err.response) {
        if (err.response.data.message instanceof Array) {
          dispatch(failAction(CREATE_PASSWORD_ACTION, err.response.data.message[0].msg));
        } else {
          dispatch(failAction(CREATE_PASSWORD_ACTION, err.response.data.message));
        }
      } else {
        dispatch(failAction(CREATE_PASSWORD_ACTION, err.message));
      }
    });
};

export const checkEmailAsync = (email: string, errorFunc: any): AuthAsyncActionType => (
  dispatch
) => {
  dispatch(startAction(CHECK_EMAIL_AVAILABILITY));
  request
    .get(`${constants.CHECK_EMAIL_AVAILABILITY_API}`, {
      params: {
        email,
      },
      headers: {
        "Content-Type": constants.CONTENT_TYPE,
      },
    })
    .then((res) => {
      if (res.data.status !== "fail") {
        if (res.data.data.exist) {
          errorFunc({ email: "This email address is already in use" });
        }
        dispatch(checkEmailAvailiabity(res.data.data.exist));
      } else {
        if (res.data.message instanceof Array) {
          dispatch(failAction(CHECK_EMAIL_AVAILABILITY, res.data.message[0].msg));
        } else {
          dispatch(failAction(CHECK_EMAIL_AVAILABILITY, res.data.message));
        }
      }
    })
    .catch((err) => {
      if (err.response) {
        if (err.response.data.message instanceof Array) {
          dispatch(failAction(CHECK_EMAIL_AVAILABILITY, err.response.data.message[0].msg));
        } else {
          dispatch(failAction(CHECK_EMAIL_AVAILABILITY, err.response.data.message));
        }
      } else {
        // errorFunc({ email: "Network Error"})
        dispatch(failAction(CHECK_EMAIL_AVAILABILITY, err.message));
      }
    });
};

export const forgotPasswordAsync = (email: string): AuthAsyncActionType => (dispatch) => {
  dispatch(startAction(FORGOT_PASSWORD_ACTION));
  request
    .get(`${constants.FORGOT_PASSWORD}`, {
      params: {
        email,
      },
      headers: {
        "Content-Type": constants.CONTENT_TYPE,
      },
    })
    .then((res) => {
      if (res.data.status !== "fail") {
        dispatch(forgotPassword());
      } else {
        if (res.data.message instanceof Array) {
          dispatch(failAction(FORGOT_PASSWORD_ACTION, res.data.message[0].msg));
        } else {
          dispatch(failAction(FORGOT_PASSWORD_ACTION, res.data.message));
        }
      }
    })
    .catch((err) => {
      if (err.response) {
        if (err.response.data.message instanceof Array) {
          dispatch(failAction(FORGOT_PASSWORD_ACTION, err.response.data.message[0].msg));
        } else {
          dispatch(failAction(FORGOT_PASSWORD_ACTION, err.response.data.message));
        }
      } else {
        dispatch(failAction(FORGOT_PASSWORD_ACTION, err.message));
      }
    });
};

export const signinAsync = (details: { email: string; password: string }): AuthAsyncActionType => (
  dispatch
) => {
  dispatch(startAction(SIGN_IN_ACTION));
  request
    .post(`${constants.LOGIN_VENDOR}`, JSON.stringify(details), {
      headers: {
        "Content-Type": constants.CONTENT_TYPE,
      },
    })
    .then(async (res) => {
      if (res.data.status !== "fail") {
        const result = res.data.data;

        if (!result.emailConfirmed) {
          dispatch(signInButEmailNotConfirmed());
          return;
        }

        identifyMixpanel(details.email);
        dispatch(signIn(result));
        dispatch(signInView());
        return;
      } else {
        if (res.data.message instanceof Array) {
          dispatch(failAction(SIGN_IN_ACTION, res.data.message[0].msg));
        } else {
          dispatch(failAction(SIGN_IN_ACTION, res.data.message));
        }
      }
    })
    .catch((err) => {
      if (err.response) {
        if (err.response.data.message instanceof Array) {
          dispatch(failAction(SIGN_IN_ACTION, err.response.data.message[0].msg));
        } else {
          dispatch(failAction(SIGN_IN_ACTION, err.response.data.message));
        }
      } else {
        dispatch(failAction(SIGN_IN_ACTION, err.message));
      }
    });
};

export const tokenSigninAsync = (token: string): AuthAsyncActionType => (dispatch) => {
  dispatch(startAction(SIGN_IN_ACTION));
  return request
    .post(
      `${constants.LOGIN_VENDOR}?token=${token}&admin=true`,
      {},
      {
        headers: {
          "Content-Type": constants.CONTENT_TYPE,
        },
      }
    )
    .then(async (res) => {
      if (res.data.status !== "fail") {
        await dispatch(logout());
        dispatch(signIn({ ...res.data.data, isAdmin: true }));
        dispatch(signInView());

        return Promise.resolve(res);
      } else {
        if (res.data.message instanceof Array) {
          dispatch(failAction(SIGN_IN_ACTION, res.data.message[0].msg));
        } else {
          dispatch(failAction(SIGN_IN_ACTION, res.data.message));
        }
      }
      return null;
    })
    .catch((err) => {
      if (err.response) {
        if (err.response.data.message instanceof Array) {
          dispatch(failAction(SIGN_IN_ACTION, err.response.data.message[0].msg));
        } else {
          dispatch(failAction(SIGN_IN_ACTION, err.response.data.message));
        }
      } else {
        dispatch(failAction(SIGN_IN_ACTION, err.message));
      }
    });
};

export const signupAsync = (details: any): AuthAsyncActionType => (dispatch) => {
  dispatch(startAction(SIGN_UP_ACTION));
  request
    .post(`${constants.CREATE_VENDORS}`, JSON.stringify(details), {
      headers: {
        "Content-Type": constants.CONTENT_TYPE,
      },
    })
    .then((res) => {
      if (res.data.status !== "fail") {
        dispatch(signUp(res.data.data));
      } else {
        if (res.data.message instanceof Array) {
          dispatch(failAction(SIGN_UP_ACTION, res.data.message[0].msg));
        } else {
          dispatch(failAction(SIGN_UP_ACTION, res.data.message));
        }
      }
    })
    .catch((err) => {
      if (err.response) {
        if (err.response.data.message instanceof Array) {
          dispatch(failAction(SIGN_UP_ACTION, err.response.data.message[0].msg));
        } else {
          dispatch(failAction(SIGN_UP_ACTION, err.response.data.message));
        }
      } else {
        dispatch(failAction(SIGN_UP_ACTION, err.message));
      }
    });
};

export const verifyAsync = (token: string): AuthAsyncActionType => (dispatch) => {
  dispatch(startAction(VERIFY_ACTION));
  request
    .get(`${constants.EMAIL_VERIFY}/${token}`, {
      headers: {
        "Content-Type": constants.CONTENT_TYPE,
      },
    })
    .then((res) => {
      if (res.data.status !== "fail") {
        dispatch(verify());
      } else {
        if (res.data.message instanceof Array) {
          dispatch(failAction(VERIFY_ACTION, res.data.message[0].msg));
        } else {
          dispatch(failAction(VERIFY_ACTION, res.data.message));
        }
      }
    })
    .catch((err) => {
      if (err.response) {
        if (err.response.data.message instanceof Array) {
          dispatch(failAction(VERIFY_ACTION, err.response.data.message[0].msg));
        } else {
          dispatch(failAction(VERIFY_ACTION, err.response.data.message));
        }
      } else {
        dispatch(failAction(VERIFY_ACTION, err.message));
      }
    });
};

export const reVerifyAsync = (email: string): AuthAsyncActionType => (dispatch) => {
  dispatch(startAction(REVERIFY_ACTION));
  request
    .post(
      `${constants.EMAIL_REVERIFY}`,
      {
        email: email,
      },
      {
        headers: {
          "Content-Type": constants.CONTENT_TYPE,
        },
      }
    )
    .then((res) => {
      if (res.data.status !== "fail") {
        dispatch(reVerify());
      }
    })
    .catch((err) => {
      if (err.response) {
        if (err.response.data.message instanceof Array) {
          dispatch(failAction(REVERIFY_ACTION, err.response.data.message[0].msg));
        } else {
          dispatch(failAction(REVERIFY_ACTION, err.response.data.message));
        }
      } else {
        dispatch(failAction(REVERIFY_ACTION, err.message));
      }
    });
};

export const resetPasswordAsync = (resetObj: {
  password: string;
  token: string;
}): AuthAsyncActionType => (dispatch) => {
  dispatch(startAction(RESET_PASSWORD_ACTION));
  request
    .post(`${constants.CREATE_PASSWORD}?token=${resetObj.token}`, JSON.stringify(resetObj), {
      headers: {
        "Content-Type": constants.CONTENT_TYPE,
      },
    })
    .then((res) => {
      if (res.data.status !== "fail") {
        dispatch(passwordReset());
      } else {
        if (res.data.message instanceof Array) {
          dispatch(failAction(RESET_PASSWORD_ACTION, res.data.message[0].msg));
        } else {
          dispatch(failAction(RESET_PASSWORD_ACTION, res.data.message));
        }
      }
    })
    .catch((err) => {
      if (err.response) {
        if (err.response.data.message instanceof Array) {
          dispatch(failAction(RESET_PASSWORD_ACTION, err.response.data.message[0].msg));
        } else {
          dispatch(failAction(RESET_PASSWORD_ACTION, err.response.data.message));
        }
      } else {
        dispatch(failAction(RESET_PASSWORD_ACTION, err.message));
      }
    });
};
