import { SETUP_DATA_TYPE } from "@samedaycustom/types/app";
export const START_SETUP_ACTION = "START_SETUP_ACTION";
export const FAIL_SETUP_ACTION = "FAIL_SETUP_ACTION";
export const GET_SETUP_STATUS_ACTION = "GET_SETUP_STATUS";
export const SETUP_STATE_RESET = "SETUP_STATE_RESET";

export type ALL_SETUP_ACTIONS_TYPE = typeof GET_SETUP_STATUS_ACTION;

interface ISTART_SETUP_ACTION {
  type: typeof START_SETUP_ACTION;
  payload: ALL_SETUP_ACTIONS_TYPE;
}

interface IFAIL_SETUP_ACTION {
  type: typeof FAIL_SETUP_ACTION;
  payload: { type: ALL_SETUP_ACTIONS_TYPE; msg: string };
}

interface IGET_SETUP_STATUS_ACTION {
  type: typeof GET_SETUP_STATUS_ACTION;
  payload: SETUP_DATA_TYPE;
}

interface ISETUP_STATE_RESET {
  type: typeof SETUP_STATE_RESET;
}

export type SETUP_ACTION_TYPES =
  | ISTART_SETUP_ACTION
  | IFAIL_SETUP_ACTION
  | IGET_SETUP_STATUS_ACTION
  | ISETUP_STATE_RESET;
