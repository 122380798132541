import { ISetupWorkHoursDaysOfWeekType } from "@samedaycustom/types/app/Settings";

export const setupWorkHoursState: ISetupWorkHoursDaysOfWeekType = {
  sun: {
    open: false,
    startMinute24: 1,
    startHour24: 1,
    endMinute24: 1,
    endHour24: 1,
    startMinute12: 1,
    startHour12: 1,
    endMinute12: 1,
    endHour12: 1,
    startUnit: "AM",
    endUnit: "AM",
  },
  mon: {
    open: false,
    startMinute24: 1,
    startHour24: 1,
    endMinute24: 1,
    endHour24: 1,
    startMinute12: 1,
    startHour12: 1,
    endMinute12: 1,
    endHour12: 1,
    startUnit: "AM",
    endUnit: "AM",
  },
  tue: {
    open: false,
    startMinute24: 1,
    startHour24: 1,
    endMinute24: 1,
    endHour24: 1,
    startMinute12: 1,
    startHour12: 1,
    endMinute12: 1,
    endHour12: 1,
    startUnit: "AM",
    endUnit: "AM",
  },
  wed: {
    open: false,
    startMinute24: 1,
    startHour24: 1,
    endMinute24: 1,
    endHour24: 1,
    startMinute12: 1,
    startHour12: 1,
    endMinute12: 1,
    endHour12: 1,
    startUnit: "AM",
    endUnit: "AM",
  },
  thu: {
    open: false,
    startMinute24: 1,
    startHour24: 1,
    endMinute24: 1,
    endHour24: 1,
    startMinute12: 1,
    startHour12: 1,
    endMinute12: 1,
    endHour12: 1,
    startUnit: "AM",
    endUnit: "AM",
  },
  fri: {
    open: false,
    startMinute24: 1,
    startHour24: 1,
    endMinute24: 1,
    endHour24: 1,
    startMinute12: 1,
    startHour12: 1,
    endMinute12: 1,
    endHour12: 1,
    startUnit: "AM",
    endUnit: "AM",
  },
  sat: {
    open: false,
    startMinute24: 1,
    startHour24: 1,
    endMinute24: 1,
    endHour24: 1,
    startMinute12: 1,
    startHour12: 1,
    endMinute12: 1,
    endHour12: 1,
    startUnit: "AM",
    endUnit: "AM",
  },
};
