import { createSlice, PayloadAction, current } from "@reduxjs/toolkit";
import { ActionStatus, ISDCMessages } from "@samedaycustom/types/app/Messaging";
import _ from "lodash";

import {
  getAdminMessages,
  getAdminThreads,
  getAdminUnreadMessagesCount,
  sendAdminMessage,
} from "./async";

function addMessage(message, state) {
  let threadData = [];

  // update newMessageCount is not in message page
  const isSameMessagePage = window.location.pathname.includes("message");
  const isSameMessagePageAndSameLocationOpen =
    isSameMessagePage && state?.activeMessageLocation === message?.locationID;

  if (!isSameMessagePage || (isSameMessagePage && !state.thread.data?.length))
    state.newMessageCount = message?.unread?.store + 1;

  if (message?.locationID) {
    /**
     * update message thread if not in message page
     */
    threadData = state.thread.data;
    const foundMessageInThreadIndex = threadData.findIndex(
      (o) => o.locationID === message?.locationID
    );

    const foundMessageInThread = state.thread.data[foundMessageInThreadIndex];
    const unreadCount = !isSameMessagePageAndSameLocationOpen ? message?.unread?.store + 1 : 0;

    const payloadData = {
      ...foundMessageInThread,
      ...message,
      lastMessageAt: message.timestamp,
      lastMessageID: message.id,
      unread: {
        ...foundMessageInThread?.unread,
        store: unreadCount,
      },
    };

    if (foundMessageInThreadIndex !== -1) {
      state.thread.data[foundMessageInThreadIndex] = payloadData;
    } else {
      state.thread.data = [payloadData, ...state.thread.data];
    }
  }

  const oldMessages = current(state.message.data);
  const oldMessageIndex = oldMessages.findIndex((o) => o?.id === message?.id);

  if (oldMessageIndex === -1) {
    state.message.data = [message, ...oldMessages];
  }
}
export interface AdminMessageState {
  message: {
    data: ISDCMessages[];
    error: any;
    status: ActionStatus;
  };
  thread: {
    data: ISDCMessages[];
    error: any;
    status: ActionStatus;
    count: number;
  };
  newMessageCount: number;
  activeMessageLocation: string;
}
const initialState: AdminMessageState = {
  message: {
    data: [],
    status: "idle",
    error: null,
  },
  thread: {
    data: [],
    status: "idle",
    error: null,
    count: 0,
  },
  newMessageCount: 0,
  activeMessageLocation: null,
};
const messageSplice = createSlice({
  name: "message",
  initialState,
  reducers: {
    updateActiveMessageLocation(state, action: PayloadAction<string>) {
      state.activeMessageLocation = action.payload;
    },
    updateNewMessageCount(state, action: PayloadAction<number>) {
      state.newMessageCount += action.payload;
    },
    markLocationMessageAsRead(state, action: PayloadAction<string>) {
      const findIndex = state.thread.data.findIndex((o) => o.locationID === action.payload);
      if (findIndex !== -1) state.thread.data[findIndex].unread.store = 0;
    },
    markLocationMessageAsUnRead(state, action: PayloadAction<string[]>) {
      const newThread = [...state.thread.data];
      action.payload.forEach((locationId) => {
        const findIndex = state.thread.data.findIndex((o) => o.locationID === locationId);
        if (findIndex !== -1) newThread[findIndex].unread.store = 1;
      });
      state.thread.data = newThread;
      state.newMessageCount += action.payload.length;
    },
    addNewAdminMessage(state, action) {
      addMessage(action.payload, state);
    },
    resetAdminMessageThread(state) {
      state.thread.data = [];
    },
  },
  extraReducers: {
    // Send message
    [sendAdminMessage.fulfilled]: (state, action) => {
      state.message.status = "succeeded";
      addMessage(action.payload, state);
    },
    [sendAdminMessage.rejected]: (state, action) => {
      state.message.status = "failed";
      state.message.error = action.error.message;
    },

    //  get Messages
    [getAdminMessages.pending]: (state, action) => {
      state.message.status = "loading";
    },
    [getAdminMessages.fulfilled]: (state, action) => {
      if (Array.isArray(action.payload)) {
        state.message.status = "succeeded";
        const message = action.payload[0];
        const locationID = message?.locationID;
        // let unreadCount = 0
        if (locationID) {
          const threadData = state.thread.data;
          const foundMessageInThread = threadData.find((o) => o.locationID === locationID);
          const foundMessageInThreadIndex = threadData.findIndex(
            (o) => o.locationID === locationID
          );
          const unreadCount = foundMessageInThread?.unread?.store;

          const payloadData = {
            ...foundMessageInThread,
            lastMessageAt: message.timestamp,
            lastMessageID: message.id,
            unread: {
              ...foundMessageInThread?.unread,
              store: 0,
            },
          };

          if (foundMessageInThreadIndex !== -1 && unreadCount > 0) {
            state.thread.data[foundMessageInThreadIndex] = payloadData;
          }
        }
        state.message.data = action.payload;
      }
    },
    [getAdminMessages.rejected]: (state, action) => {
      state.message.status = "failed";
      state.message.error = action.error.message;
    },

    //  get Messages thread
    [getAdminThreads.pending]: (state, action) => {
      state.thread.status = "loading";
    },
    [getAdminThreads.fulfilled]: (state, action) => {
      state.thread.status = "succeeded";
      state.thread.data = action.payload.data;
      state.thread.count = action.payload.count;
    },
    [getAdminThreads.rejected]: (state, action) => {
      state.thread.status = "failed";
      state.thread.error = action.error.message;
    },

    //  unread message counts
    [getAdminUnreadMessagesCount.fulfilled]: (state, action) => {
      state.newMessageCount = action.payload;
    },
  },
});

export const {
  addNewAdminMessage,
  updateNewMessageCount,
  updateActiveMessageLocation,
  resetAdminMessageThread,
  markLocationMessageAsRead,
  markLocationMessageAsUnRead,
} = messageSplice.actions;

export default messageSplice.reducer;
