import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import qs from "query-string";
import { ISDCMessage } from "types/app/Messaging";
import url from "vendor/api/Api.constants";
import { logOutAuto } from "vendor/providers/actions/Auth";
import { failAction } from "vendor/providers/actions/Messages";
import { GET_MESSAGES_THREADS_LIST } from "vendor/providers/actions/Messages/actionTypes.d";

import { api } from "../../../api";

export const sendAdminMessage = createAsyncThunk(
  "message/sendAdminMessage",
  async (messageBody: ISDCMessage) => {
    const response = await api.post(
      `${url.STORE_MESSAGING}/${messageBody?.locationID}`,
      messageBody
    );
    return response.data?.data;
  }
);

export const getAdminUnreadMessagesCount = createAsyncThunk(
  "message/getAdminUnreadMessagesCount",
  async (_D, { getState }) => {
    const auth = getState()?.auth;
    const response = await api.get(`${url.STORE_MESSAGING}/count`, {
      headers: { Authorization: "Bearer " + auth?.token },
    });
    return Number(response.data?.data?.unread_store_count);
  }
);

export const getAdminMessages = createAsyncThunk(
  "message/getAdminMessages",
  async (param: { location_id: string }, { dispatch, getState }) => {
    const token = getState().auth?.token;
    const response = await api.get(`${url.STORE_MESSAGING}/${param?.location_id}`, {
      headers: { Authorization: "Bearer " + token },
    });
    dispatch(getAdminUnreadMessagesCount());
    return response.data?.data;
  }
);

export const getAdminThreads = createAsyncThunk(
  "message/getAdminThreads",
  async (
    param?: {
      limit: number;
      search?: string;
      locationId?: string;
      cursor?: number;
    },
    { dispatch }
  ) => {
    let baseUrl = `${url.STORE_MESSAGING}`;
    baseUrl = `${baseUrl}?${qs.stringify({
      limit: param.limit,
      search: param?.search || "",
      locationId: param?.locationId || "",
      cursorId: param?.cursor || 0,
    })}`;
    const response = await api.get(`${baseUrl}`).catch((err) => {
      if (err.response) {
        dispatch(logOutAuto(err.response));
        if (err.response.data.message instanceof Array) {
          dispatch(failAction(GET_MESSAGES_THREADS_LIST, err.response.data.message[0].msg));
        } else {
          dispatch(failAction(GET_MESSAGES_THREADS_LIST, err.response.data.message));
        }
      } else {
        dispatch(failAction(GET_MESSAGES_THREADS_LIST, err.message));
      }
    });
    dispatch(getAdminUnreadMessagesCount());
    return response.data?.data;
  }
);

export const imageUpload = createAsyncThunk(
  "message/imageUpload",
  async (file: File, onProgress?: (progressEvent: any) => void) => {
    const formData = new FormData();
    formData.append("image", file as any);
    const result = await api.post(`${url.NOTE_IMAGE_UPLOAD}?id=${file?.id || ""}`, formData, {
      onUploadProgress: onProgress,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return result?.data?.data;
  }
);
