import { createAsyncThunk } from "@reduxjs/toolkit";
import { ISDCLine } from "@samedaycustom/types/order/@types/line";
import API from "vendor/api/Api.constants";
import { updateOperationOrderLineItem } from "vendor/providers/actions/operations/Orders";
import { FETCH_ORDER_LINEITEMS } from "vendor/providers/actions/operations/types";
import { api } from "vendor/providers/api";
export const getOrderLine = createAsyncThunk(
  "events/getOrderLine",
  async (param: { order_id: string; line_id: string; location_id: string }, { dispatch }) => {
    const response = await api
      .get(
        `${API.GET_OPERATION_ORDERS}/${param?.order_id}/lines/${param?.line_id}?store=${param.location_id}`
      )
      .catch((err) => Promise.reject(err.response?.data?.message));
    const data = response.data?.data;
    dispatch(updateOperationOrderLineItem(data as ISDCLine));
    return data;
  }
);

export const getOrderLines = createAsyncThunk(
  "events/getOrderLines",
  async (param: { order_id: string; location_id: string; hideLoader?: boolean }, { dispatch }) => {
    const response = await api
      .get(`${API.GET_OPERATION_ORDERS}/${param?.order_id}/lines?store=${param.location_id}`)
      .catch((err) => Promise.reject(err.response?.data?.message));

    const data = response.data?.data;

    await dispatch({
      type: FETCH_ORDER_LINEITEMS,
      lineItems: data || [],
    });
    return data;
  }
);
