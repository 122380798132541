import {
  FAILED_OPERATION_ORDERS_REPORTS,
  FETCH_OPERATION_ORDERS_REPORTS,
  FETCHING_OPERATION_ORDERS_REPORTS,
  REPORT_LIST_ACTION,
  REPORT_STATE,
} from "vendor/providers/actions/Reports/types.d";

import { reportState } from "./initialstate";

export default (state = reportState, action: REPORT_LIST_ACTION<any>): REPORT_STATE => {
  switch (action.type) {
    case FETCH_OPERATION_ORDERS_REPORTS:
      return {
        ...state,
        reports: {
          ...state.reports,
          data: action.data,
          metadata: action.metadata,
        },
      };
    case FETCHING_OPERATION_ORDERS_REPORTS:
      return {
        ...state,
        reports: {
          ...state.reports,
          loading: action.loading,
          error: false,
          errorMessage: null,
        },
      };

    case FAILED_OPERATION_ORDERS_REPORTS:
      return {
        ...state,
        reports: {
          ...state.reports,
          error: action.error,
          errorMessage: action.errorMessage,
        },
      };

    default:
      return state;
  }
};
