import React from "react";
import { Box } from "../../_components";
import MessageView from "./MessageView";
export type ArtworkNoteMessage = {
  _id: string | number;
  username?: string;
  timestamp: string | number;
  message?: string;
};
export interface ArtworkChatCard {
  user: {
    _id: string | number;
  };
  messages: ArtworkNoteMessage[];
}

export default ({ messages, user }: ArtworkChatCard) => {
  return (
    <Box
      background="#FFFFFF"
      boxShadow="0px 2px 4px rgba(217, 226, 236, 0.8)"
      borderRadius="4px"
      padding="20px"
      width="100%"
      d="flex"
      flexDirection="column"
    >
      {messages &&
        messages.length > 0 &&
        messages.map(message => {
          if (message._id === user._id)
            return (
              <MessageView
                message={message}
                style={{
                  background: "#F0F4F8",
                  marginLeft: "2rem"
                }}
              />
            );
          return (
            <MessageView
              message={message}
              style={{
                background: "#E5F1FF",
                marginRight: "2rem"
              }}
            />
          );
        })}
    </Box>
  );
};
