import "./style.scss";

import { Box, Image, Text } from "../../_components";
import { ReactComponent as Download } from "@samedaycustom/assets/images/download-outline-o.svg";
import { ReactComponent as Delete } from "@samedaycustom/assets/images/trash.svg";
import { SecondaryButton, Spinner } from "@samedaycustom/core-ui";
import { downloadUrl } from "@samedaycustom/helpers";
import moment from "moment";
import React from "react";

export interface IArtworkVersionCard {
  artworkId: string | number;
  viewId: string | number;
  version: "original" | "uploaded" | "awaiting" | "approved";
  artworkUrl: string;
  dateAdded?: number;
  dimension?: string;
  onApprove?: (viewId: string | number) => void;
  onRemindCustomer?: (viewId: string | number) => void;
  onDeleteArtwork?: (artworkId: string | number, url: string) => Promise<any>;
}
export default ({
  version,
  artworkId,
  viewId,
  artworkUrl,
  onDeleteArtwork,
  onRemindCustomer,
  onApprove,
  dateAdded
}: IArtworkVersionCard) => {
  const [isDeletingArtwork, setisDeletingArtwork] = React.useState(false);
  const isOriginal = version === "original";
  const isUploaded = version === "uploaded";
  const isAwaiting = version === "awaiting";
  return (
    <Box>
      <Box d="inline-flex" marginBottom="32px" alignItems="flex-start">
        <Box width="140px" height="140px" borderRadius="6px">
          <Image
            src={artworkUrl}
            borderRadius="6px"
            objectFit="cover"
            height="100%"
            width="100%"
          />
        </Box>
        <Box paddingLeft="24px">
          {/* <Box marginBottom="24px">
						<Text
							color="#829AB1"
							fontSize="13px"
							fontWeight={500}
							lineHeight="16px"
						>
							Dimensions
						</Text>
						<Text
							color="#102A43"
							fontWeight="normal"
							fontSize="14px"
							lineHeight="20px"
						>
							{dimension ? dimension : "-"}
						</Text>
					</Box> */}
          <Box marginTop="20px">
            <Text
              color="#829AB1"
              fontSize="13px"
              fontWeight={500}
              lineHeight="16px"
            >
              Date added
            </Text>
            <Text
              color="#102A43"
              fontWeight="normal"
              fontSize="14px"
              lineHeight="20px"
            >
              {dateAdded ? moment(dateAdded).format("DD MMM, YYYY hh:mm") : "-"}
            </Text>
          </Box>
        </Box>
      </Box>
      <Box d="flex" justifyContent="space-between" width="90%">
        {(isUploaded || isAwaiting) && (
          <SecondaryButton
            onClick={() =>
              isAwaiting && (onRemindCustomer || onApprove)
                ? onRemindCustomer(viewId)
                : onApprove(viewId)
            }
          >
            {isAwaiting ? "Remind customer" : "Request approval"}
          </SecondaryButton>
        )}
        <Box d="flex" justifyContent="space-between" width="50%">
          <Box
            as="button"
            outline="none !important"
            d="flex"
            color="#007BFF"
            alignItems="center"
            onClick={() => artworkUrl && downloadUrl(artworkUrl)}
          >
            <Download fill="#007BFF" />

            <Text fontWeight={500} paddingLeft="5px">
              Download
            </Text>
          </Box>
          {!isOriginal && !isAwaiting && (
            <Box
              as="button"
              outline="none !important"
              d="flex"
              color="#EF4E4E"
              alignItems="center"
              disabled={isDeletingArtwork}
              onClick={() => {
                if (onDeleteArtwork && !isDeletingArtwork) {
                  setisDeletingArtwork(true);
                  onDeleteArtwork(artworkId, artworkUrl)
                    .then(() => setisDeletingArtwork(false))
                    .catch(() => setisDeletingArtwork(false));
                }
              }}
            >
              {isDeletingArtwork ? (
                <Spinner
                  color="#EF4E4E"
                  className="miniSpinner__ArtworkVersion"
                />
              ) : (
                <Delete fill="#EF4E4E" />
              )}

              <Text fontWeight={500} paddingLeft="5px">
                Delete
              </Text>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};
