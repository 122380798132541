import React from "react";
import convertColor from "color-convert";
import { Box } from "../../_components";
import Style from "style-it";
export default ({
  color,
  height,
  width
}: {
  color: string;
  height?: string;
  width?: string;
}) => {
  color = color?.toLowerCase();
  let hexcolor = `#${convertColor.rgb.hex(convertColor.keyword.rgb(color))}`;

  return Style.it(
    `
        .row-color-container{
            height: ${height || "15px"};
            width: ${width || "15px"};
            border-radius: 100%;
            background-color: ${hexcolor};
            box-shadow: ${
              color === "white" || color === "#fff" || color === "White"
                ? "0px 2px 4px rgba(159,179,200,0.06), 0px 4px 6px rgba(159,179,200,0.1)"
                : "none"
            };
            border: ${
              color === "white" || color === "#fff" || color === "White"
                ? "1px solid #ddd"
                : "none"
            };
        }
        `,
    <Box className="row-color-container"></Box>
  );
};
