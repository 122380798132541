import { Text } from "../_components";
import { ReactComponent as FlySend } from "./assets/flysend.svg";
import React from "react";

export interface FlySendProps {
  text: string;
  onClick?: () => void;
}
export const Flysend = ({ text, ...props }: FlySendProps) => {
  return (
    <Text
      d="flex"
      flexDirection="row"
      color="#007BFF"
      fontFamily="Helvetica Neue"
      fontSize="11px"
      cursor="pointer"
      {...props}
    >
      <Text paddingRight="5px">
        <FlySend />
      </Text>
      {text}
    </Text>
  );
};
