import {
  APP_STATE_TYPE,
  AUTH_STATE_TYPE,
  ILocationTarget,
  ROLE_STATE_TYPE,
  SIGNUP_FLOW_STATE_TYPE,
  STORES_STATE_TYPE,
  USERS_STATE_TYPE,
} from "@samedaycustom/types/app";

import {
  businessProfileStateType,
  DASHBOARD_PAGE_STATE,
  IMAGE_UPLOADS_STATE,
  ONBOARDING_STATE_TYPE,
  WELCOME_PAGE_STATE,
} from "@samedaycustom/types/app";
import { MESSAGES_STATE_TYPE } from "@samedaycustom/types/app/Messaging/index";

export const APP_STATE: APP_STATE_TYPE = {
  isLoading: false,
  appLoading: false,
  fetching: false,
  fetched: false,
  deleting: false,
  deleted: false,
  done: false,
  error: false,
  errorMessage: "",
  deleteMessage: "",
  duration: 3000,
  showModal: false,
  modal: {
    modalType: "success",
    message: "",
  },
  valueChangesData: {},
  navIsOpen: false,
  targetLocation: null,
  dirty: {
    isDirty: false,
    handleSubmit: null,
    values: {},
  },
  recentOrders: [],
  recentDelivered: [],
  recentProduced: [],
  appTitle: "",
  PreviousComponent: null,
  internetAccess: true,
  showAutoLogoutModal: false,
  updatedOrderStatus: {
    orderId: null,
    status: null,
  },
  updatedOrderDeliveryMethod: {
    orderId: null,
    deliveryMethod: null,
  },
};
export const AUTH_STATE: AUTH_STATE_TYPE = {
  token: "",
  emailConfirmed: false,
  isSuperAdmin: false,
  roleName: "",
  isManager: false,
  firstName: "",
  lastName: "",
  email: "",
  vendorApproved: false,
  setup: {
    completed: false,
    delivery: false,
    hour: false,
    products: false,
    profile: false,
    speed: false,
    users: false,
  },
};
export const SIGN_UP_FLOW_STATE: SIGNUP_FLOW_STATE_TYPE = {
  disableNextBtn: false,
  disableBackBtn: false,
  done: false,
  error: false,
  type: "",
  vendorDetails: {
    storeCount: 0,
    vendorName: "",
    address: "",
    zipcode: "",
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    password: "",
    dtg: false,
    ssc: false,
    emb: false,
    vin: false,
    htf: false,
    pct: false,
    sub: false,
    ubt: false,
    ubh: false,
    minProdQty: "",
    minProdTimeMin: "",
    minProdTimeHour: "",
  },
};

export const STORE_USERS_FLOW_INITIALSTATE: USERS_STATE_TYPE = {
  loading: false,
  error: false,
  type: "",
  users: [],
  done: false,
  errorMessage: "",
  fetch: true,
};

export const STORE_ROLES_FLOW_INITIALSTATE: ROLE_STATE_TYPE = {
  loading: false,
  error: false,
  type: "",
  roles: [],
  targetRole: {},
  done: false,
  errorMessage: "",
  fetch: true,
};

export const USER_PROFILE_INITIALSTATE = {
  loading: false,
  error: false,
  type: "",
  profile: {},
  done: false,
  errorMessage: "",
  imageUploadLoading: false,
  imageUploadError: false,
  imageUploadSuccess: false,
};

export const STORE_LOCATIONS_INITIALSTATE: STORES_STATE_TYPE = {
  loading: false,
  error: false,
  type: "",
  locations: [],
  done: false,
  errorMessage: "",
  fetch: true,
};

export const onBoardingState: ONBOARDING_STATE_TYPE = {
  loading: false,
  error: false,
  type: "",
  done: false,
  errorMessage: "",
  fetch: false,
  confirmation: "",
  validMail: false,
};

export const businessProfileState: businessProfileStateType = {
  loading: false,
  error: false,
  type: "",
  profileData: {
    id: "",
    name: "",
    status: "",
    storeCount: 0,
    usersCount: 0,
    listedStoresCount: 0,
    email: "",
    mobile: "",
    vendorType: "SINGLE",
    businessNumber: "",
    billingEmail: "",
    websiteURL: "",
    address1: "",
    city: "",
    state: "",
    stateCode: "",
    zipcode: "",
    contactInfo: {
      name: "",
      mobile: "",
      email: "",
    },
    profileImageURL: "",
  },
  done: false,
  errorMessage: "",
  fetch: true,
};

export const imageUploadState: IMAGE_UPLOADS_STATE = {
  loading: false,
  error: false,
  errorMessage: "",
  done: false,
  type: "",
  imageUrl: "",
};

export const storeSettings: ILocationTarget = {};

export const welcomeState: WELCOME_PAGE_STATE = {
  loading: false,
  error: false,
  type: "",
  errorMessage: "",
  done: false,
  fetch: true,
  setupData: {
    speed: false,
    profile: false,
    users: false,
    hour: false,
    products: false,
    delivery: false,
    completed: false,
  },
};

export const dashboardState: DASHBOARD_PAGE_STATE = {
  loading: false,
  error: false,
  type: "",
  errorMessage: "",
  done: false,
  fetch: true,
  dashData: {
    accuracy: 0,
    rating: 0,
    customer: 0,
    turnTime: 0,
    accepted: 0,
    declined: 0,
    due: 0,
    fullfilled_early: 0,
    total: 0,
    fulfilled: 0,
    series: [],
  },
};

export const MessagesState: MESSAGES_STATE_TYPE = {
  loading: false,
  error: false,
  type: "",
  errorMessage: "",
  done: false,
  isNew: false,
  fetch: true,
  messagingData: {},
  newMessageCount: 0,
  messageThreadCount: 0,
  hasMore: false,
  messages: {
    loading: false,
    error: false,
    type: "",
    errorMessage: "",
    done: false,
    data: [],
  },
};
