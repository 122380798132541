import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { ReactComponent as Attachment } from "../../assets/file-text.svg";
export interface FancyNoteProp {
  title?: string;
  description?: string;
  attachmentName?: string;
  leftBorderColor?: string;
}
export default ({
  attachmentName,
  description,
  title,
  leftBorderColor,
  ...props
}: FancyNoteProp) => {
  return (
    <Box
      d="flex"
      flexDirection="column"
      borderLeft={`4px solid ${leftBorderColor || "#006EE5"}`}
      fontFamily="Inter"
      fontWeight="normal"
      {...props}
    >
      <Box paddingLeft="10px" fontSize="12px">
        <Text
          color="#243B53"
          paddingTop="2px"
          paddingBottom="5px"
          fontFamily="Inter"
          fontWeight={500}
        >
          {title}
        </Text>
        <Text color="#102A43">{description}</Text>
        {attachmentName && (
          <Text
            color="#102A43"
            d="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="flex-end"
            fontFamily="Inter"
            fontWeight={500}
            marginTop="0.5rem"
          >
            <Text paddingRight="5px">
              <Attachment />
            </Text>
            {attachmentName}
          </Text>
        )}
      </Box>
    </Box>
  );
};
