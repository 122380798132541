import { Box, Text, useMediaQuery } from "../../_components";
import { ReactComponent as Eye } from "@samedaycustom/assets/images/eye.svg";
import { ReactComponent as Location } from "@samedaycustom/assets/images/location-o.svg";
import { ReactComponent as Chat } from "@samedaycustom/assets/images/chat.svg";
import { ReactComponent as ChatWl } from "@samedaycustom/assets/images/chat_wl.svg";
import { ReactComponent as EyeWl } from "@samedaycustom/assets/images/eye_wl.svg";
import { ReactComponent as LocationWl } from "@samedaycustom/assets/images/location_wl.svg";
import { ReactComponent as SDCLogoWhite } from "@samedaycustom/assets/images/sdc-white-logo.svg";
import { ImageSlider, PrimaryButton, RowTag, TagType } from "@samedaycustom/core-ui";
import { formatCurrency } from "@samedaycustom/helpers/src";
import { ISDCOrder } from "@samedaycustom/types/order/@types/order";
import { formatInTimeZone } from "date-fns-tz";
import moment from "moment";
import React, { useMemo } from "react";
import "./style.scss";

interface Iprops {
  reorderUrl?: string;
  order: ISDCOrder;
  onChatClick: () => void;
  onTrackClick: () => void;
  onViewOrderClick: () => void;
  onReorder?: () => void;
  primaryButtonStyle?: {
    backgroundColor?: string;
    color?: string;
  };
  secondaryButtonStyle?: {
    backgroundColor?: string;
    color?: string;
  };
  accentColor?: string;
  primaryColor?: string;
  isWhiteLabel?: boolean;
}

const OrderCard = ({
  reorderUrl,
  order,
  onChatClick,
  onTrackClick,
  onViewOrderClick,
  onReorder = () => {},
  primaryButtonStyle,
  accentColor,
  primaryColor,
  isWhiteLabel,
}: Iprops) => {
  const [isLessThan800] = useMediaQuery("(max-width: 800px)");

  const ordertag = useMemo(() => {
    let status = order.status;
    if (status === "N.S") status = "R.S";
    if (status === "C.M") status = "FUL";
    return status;
  }, [order]);
  const isAcccepted = useMemo(() => ordertag !== "A.A", [ordertag]);
  const grandTotal = order?.billablePrice || order?.price || 0;
  const isVersion1 = order?.decoOrderId?.endsWith("V1");

  return (
    <Box className="orderCard">
      <Box className="orderCard__imageContainer">
        <Box
          width="100%"
          d="flex"
          flexDirection="column"
          justifyContent="flex-end"
          alignItems="flex-end"
          height="100%"
          position="absolute"
          zIndex="9"
        >
          {!isWhiteLabel && (<SDCLogoWhite />)}
        </Box>
        {Array.isArray(order?.thumbnails) && (
          <ImageSlider imageUrlArr={order.thumbnails} className="savedlayoutCard__image" />
        )}
      </Box>
      <Box className="orderCard__info">
        <Box d="flex" flexDirection={["row", "column"]} justifyContent="space-between" width="100%">
          <Box>
            <Text fontSize={["18px", "16px"]} fontWeight={600} color="#334E68">
              {order.decoOrderId}
            </Text>
            {isLessThan800 && (
              <Text lineHeight="20px" className="orderPrice" paddingTop="4px" fontSize="16px">
                {formatCurrency(grandTotal)}
              </Text>
            )}
          </Box>
          <Box
            paddingTop="3px"
            d="flex"
            justifyContent="space-between"
            flexDirection={["column", "row"]}
          >
            {!isLessThan800 && (
              <Box>
                <Text lineHeight="20px" className="orderPrice" paddingBottom="3px">
                  {formatCurrency(grandTotal)}
                </Text>
                <RowTag tag={ordertag as TagType} isTag={true} />
              </Box>
            )}
            <Box className="orderCard__info__group" marginBottom={["5px", "16px"]}>
              <Box d="flex" flexDirection="column" alignItems="flex-end" textAlign="right">
                <h3>Date ordered</h3>
                <span>
                  {" "}
                  {order?.tzone
                    ? formatInTimeZone(order?.dateOrdered, order?.tzone, "dd MMM, yyyy")
                    : moment(order.dateOrdered).format("D MMMM, YYYY ")}
                </span>
                {isLessThan800 && (
                  <Box marginTop="6px">
                    <RowTag tag={ordertag as TagType} isTag={true} />
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Box>

        <a
          href={!isVersion1 ? reorderUrl : ""}
          style={{ height: "52px" }}
          onClick={(e) => {
            if (isVersion1) {
              e.preventDefault();
              onReorder();
            }
          }}
        >
          <PrimaryButton
            height={["52px", "36px"]}
            width="100%"
            margin="15px 0px 24px 0px"
            {...(primaryButtonStyle && {
              backgroundColor: primaryButtonStyle.backgroundColor,
              color: primaryButtonStyle.color,
              _hover: {
                backgroundColor: primaryButtonStyle.backgroundColor,
                color: primaryButtonStyle.color,
              }
            })}
          >
            Reorder
          </PrimaryButton>
        </a>
        <Box className="orderCard__info__tripleFLex" paddingTop="20px">
          <Box onClick={onTrackClick} className="orderCard__link">
            <Box
              className="svg_container"
              {...(accentColor && {
                backgroundColor: accentColor,
              })}
              {...(primaryColor && {
                fill: primaryColor,
              })}
            >
              {isWhiteLabel ? <LocationWl /> : <Location />}
            </Box>
            <Text
              {...(primaryColor && {
                color: `${primaryColor} !important}`,
              })}
            >
              Track order
            </Text>
          </Box>
          {isAcccepted || order?.hasMessageStarted ? (
            <Box className="orderCard__link" position="relative">
              <Box
                className="svg_container"
                onClick={onChatClick}
                {...(accentColor && {
                  backgroundColor: accentColor,
                })}
                {...(primaryColor && {
                  fill: primaryColor,
                })}
              >
                {isWhiteLabel ? <ChatWl /> : <Chat />}
              </Box>
              <Text
                {...(primaryColor && {
                  color: `${primaryColor} !important}`,
                })}
              >
                Chat
              </Text>
              {order?.unreadMessages > 0 ? (
                <span
                  style={{
                    position: "absolute",
                    top: "-15px",
                    right: "-10px",
                  }}
                >
                  <RowTag tag="new" />
                </span>
              ) : null}
            </Box>
          ) : null}
          {/* {isAcccepted && (
            <Box className="orderCard__link">
              <Box className="svg_container" onClick={onCallClick}>
                <Call />
              </Box>
              <Text>Call</Text>
            </Box>
          )} */}

          <Box onClick={onViewOrderClick} className="orderCard__link">
            <Box
              className="svg_container"
              {...(accentColor && {
                backgroundColor: accentColor,
              })}
              {...(primaryColor && {
                fill: primaryColor,
              })}
            >
              {isWhiteLabel ? <EyeWl />  : <Eye />}
            </Box>
            <Text
              {...(primaryColor && {
                color: `${primaryColor} !important}`,
              })}
            >
              View order
            </Text>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default OrderCard;
