import React from "react";
import "../style.scss";

import { ReactComponent as Star } from "../assets/ratingStar.svg";

interface Props {
	Label: string;
	rating: number | string;
}

export default ({ rating, Label }: Props) => {
	return (
		<div className="dashBoardTile">
			<div className="main">
				<h2>{rating}</h2>
				<Star className="star" />
			</div>
			<p className="tileLabelText">{Label}</p>
		</div>
	);
};
