import React from "react";
import { Box, Text } from "../../_components";
import { DefaultButton, SecondaryButton } from "@samedaycustom/core-ui";

export default ({ onNotBusy, onBusy }) => {
  return (
    <Box
      d="flex"
      alignItems="center"
      flexDirection="column"
      textAlign="center"
      padding="40px"
    >
      <Text fontSize="20px" color="#243B53" lineHeight="32px" fontWeight={500}>
        It seems you are busy
      </Text>
      <Text
        fontSize="16px"
        color="#102A43"
        lineHeight="24px"
        marginBottom="30px"
        marginTop="12px"
      >
        If you think you cannot accept orders anytime soon, we advise that you
        turn off your location from accepting orders so as not to get negative
        reviews from customers.
      </Text>
      <Box d="flex" width="100%" justifyContent="space-around">
        <DefaultButton onClick={onNotBusy}>I'm not busy</DefaultButton>
        <SecondaryButton onClick={onBusy}>Yes, I'm busy</SecondaryButton>
      </Box>
    </Box>
  );
};
