import "./style.scss";

import { ReactComponent as RemoveImg } from "../assets/remove.svg";
import React from "react";
import { Box } from "../../_components";

type MiniTableProps = {
  mode: "edit" | "view";
  list: Array<any>;
  targetKeys: [string, string];
  onItemClick?: (id) => void;
  listEmptyText: string;
  headers: [string, string];
  className?: string;
};

const MiniTable: React.FC<MiniTableProps> = ({
  mode,
  list,
  targetKeys,
  onItemClick,
  listEmptyText,
  headers,
  className
}) => {
  return (
    <Box className={`MiniTable ${className ? className : ""}`}>
      <Box
        className={
          mode === "edit"
            ? "MiniTable__Header MiniTable__Header--edit"
            : "MiniTable__Header"
        }
      >
        {mode === "edit" ? <span></span> : null}
        <span> {headers[0]} </span>
        <span> {headers[1]} </span>
      </Box>
      {list.length > 0 ? (
        list.map(item => (
          <Box
            className={
              mode === "edit"
                ? "MiniTable__item MiniTable__item--edit"
                : "MiniTable__item"
            }
          >
            {mode === "edit" &&
            String(item[targetKeys[1]] || "").toLowerCase() !== "admin" ? (
              <Box className="imgHolder" onClick={() => onItemClick(item.id)}>
                <RemoveImg className="rmoveImg" />
              </Box>
            ) : (
              mode === "edit" && <Box className="imgHolder" />
            )}
            <span>{item[targetKeys[0]]}</span>
            <span>{item[targetKeys[1]]}</span>
          </Box>
        ))
      ) : (
        <p> {listEmptyText} </p>
      )}
    </Box>
  );
};

export default MiniTable;
