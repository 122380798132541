import { Box, Text } from "../../_components";
import { ReactComponent as DownTick } from "@samedaycustom/assets/images/downTick.svg";
import { ReactComponent as Rating } from "@samedaycustom/assets/images/ratingStar.svg";
import { ReactComponent as UpTick } from "@samedaycustom/assets/images/upTick.svg";
import React from "react";
import AnimatedNumber from "react-animated-number";

export interface IAnalysticInfo {
  title: string;
  goingup: boolean;
  isRating?: boolean;
  isCurrency?: boolean;
  percentage: number;
  amount: number;
  hidePercentage?: boolean;
}
export const AnalysticInfo = ({
  title,
  goingup,
  percentage,
  amount,
  isRating,
  hidePercentage,
  isCurrency
}: IAnalysticInfo) => (
  <Box
    minW="240px"
    height="76px"
    className="AnalysticInfo"
    paddingX="24px"
    borderRight="1px solid #BCCCDC"
    display="flex"
    flexDirection="column"
    justifyContent="center"
  >
    <Box
      d="inline-flex"
      justifyContent="space-between"
      alignItems="center"
      width="100%"
    >
      <Text color="#627D98" fontWeight={500} fontSize="14px" lineHeight="20px">
        {title}
      </Text>
      {!hidePercentage && (
        <AnalysisPercentile goingUp={goingup} percentage={percentage} />
      )}
    </Box>
    <Text
      fontWeight={600}
      d="flex"
      fontSize="36px"
      color="#243B53"
      alignItems="center"
    >
      {isCurrency && (
        <Text
          d="flex"
          alignItems="flex-end"
          height="65%"
          fontSize="20px"
          lineHeight="28px"
          color="#334E68"
          paddingRight="3px"
        >
          $
        </Text>
      )}

      <AnimatedNumber
        component="text"
        value={amount}
        style={{
          transition: "1s ease-out",
          transitionProperty: "background-color, color, opacity"
        }}
        duration={500}
        stepPrecision={0}
      />
      {isRating && (
        <Text marginTop="7.5px" paddingLeft="5px">
          <Rating />
        </Text>
      )}
    </Text>
  </Box>
);

export const AnalysisPercentile = ({
  goingUp,
  percentage
}: {
  goingUp: boolean;
  percentage: number;
}) => {
  return (
    <Box d="flex">
      {!goingUp ? <DownTick /> : <UpTick />}
      <span
        className={`percentText`}
        style={{
          color: goingUp ? "#31b237" : "#ef4e4e",
          fontWeight: 600,
          fontSize: "14px",
          paddingLeft: "4px"
        }}
      >
        <AnimatedNumber
          component="text"
          value={percentage}
          style={{
            transition: "1s ease-out",
            transitionProperty: "background-color, color, opacity"
          }}
          duration={500}
          formatValue={n => {
            const num = Number(n);
            return num % 2 ? num.toFixed(2) : num;
          }}
        />
        %
      </span>
    </Box>
  );
};
