/// <reference types="react-scripts" />

import { ISDCLine } from "order/@types/line";
import { Line } from "@samedaycustom/types/order/@types/delivery.d";

declare module "*.worker.ts" {
  class WebpackWorker extends Worker {
    constructor();
  }

  export default WebpackWorker;
}
interface RefObject<T> {
  // immutable
  readonly current: null;
}
declare interface PromiseConstructor {
  allSettled(
    promises: Promise<any>[]
  ): Promise<{ status: "fulfilled" | "rejected"; value?: any; reason?: any }[]>;
}
declare interface SharedWorkerGlobalScope {
  onconnect: (event: MessageEvent) => void;
}

export class URLQueryBuilder {
  private url;
  private queries;
  constructor(url?: string, queries?: URLQueryParam);
  get(): string;
  getClearUrl(): string;
  delete(name: string): this;
  set(name: URLQueryParam, value?: URLQueryValue): this;
  reset(queries?: URLQueryParam): this;
  has(name: string): boolean;
}

export declare type URLQueryValue = string | undefined | null | number;
export interface URLQueryObject {
  [key: string]: URLQueryValue;
}
export declare type URLQueryParam = URLQueryObject | string;

declare global {
  interface Array<T> {
    section(startingPos, length): Array<T>;
    filterExtended: (targetListPropery: string, targetKey: string, value: string) => Array<T>;
    filterFunc: (value: string, targetKeys: Array<string>) => Array<T>;
    filterOut: (arrExclude: Array<any>, sharedKey: string) => Array<T>;
    filterApprovedArtwork: () => Array<T>;
    filterNotApprovedArtwork: () => Array<T>;

    itemsProductionInfo: () => Array<{
      qty: number;
      awaiting: number;
      produced: number;
      complete: boolean;
      deliveryComplete: boolean;
    }>;
    isProductionReady: () => boolean;
    isProductionStarted: () => boolean;
    isProductionComplete: () => boolean;
    isDeliveryCompleted: () => boolean;
    approvedArtworkAndProductionStarted: () => boolean;
    approvedArtworkAndProductionComplete: () => boolean;
    approvedArtworkAndProductionCompleteAndDeliveryStarted: () => boolean;
    approvedArtworkAndProductionStartedAndDeliveryStarted: () => boolean;
    formRecoredDeliveryItems: () => IMarkAsDelivered[];
    reformItemsForDelivery: () => IMarkAsDelivered[];
    productionCompleted: () => Array<{
      qty: number;
      awaiting: number;
      produced: number;
      complete: boolean;
      deliveryComplete: boolean;
    }>;
    search: (targetKeys: string[], targetValue: string) => T[];
  }
}

/**
 * search parameters of an
 */
Array.prototype.search = function(targetKeys: string[], targetValue: string): any[] {
  let resultsArr = [];
  if (!targetValue) {
    return this;
  } else {
    resultsArr = this.filter((item) => {
      for (let i = 0; i < targetKeys.length; i++) {
        if (item[targetKeys[i]]) {
          if (
            item?.[targetKeys?.[i]]
              ?.toString()
              ?.toLowerCase()
              .includes(targetValue.toLowerCase())
          ) {
            return item;
          }
        }
      }
    });
  }
  return resultsArr;
};

Array.prototype.section = function(startingPos, length) {
  const array = [];
  const endpoint = this.length < startingPos + length ? this.length - startingPos : length;
  for (let i = startingPos; i < startingPos + endpoint; i++) {
    array.push(this[i]);
  }
  return array;
};

Array.prototype.filterFunc = function(value, targetKeys) {
  if (value !== "") {
    return this.filter((user) => {
      for (let i = 0; i < targetKeys.length; i++) {
        if (user[targetKeys[i]] === value) {
          return true;
        }
      }
      return false;
    });
  }
  return this;
};

Array.prototype.filterOut = function(arrExclude, sharedKey) {
  if (arrExclude.length < 1) {
    return this;
  }

  if (typeof arrExclude[0] === "string") {
    return this.filter((a) => {
      let valid = true;
      arrExclude.forEach((keyItem) => {
        if (a === keyItem) {
          valid = false;
        }
      });
      return valid;
    });
  } else {
    return this.filter((a) => {
      let valid = true;
      arrExclude.forEach((option) => {
        if (option[sharedKey] === a[sharedKey]) {
          valid = false;
        }
      });
      return valid;
    });
  }
};

Array.prototype.filterExtended = function(targetListProperty, targetKey, value) {
  const array = [];
  if (!value) {
    return [...this];
  }
  for (let i = 0; i < this.length; i++) {
    let validItem = false;
    if (this[i][targetListProperty])
      for (let j = 0; j < this[i][targetListProperty].length; j++) {
        if (this[i][targetListProperty][j][targetKey].toLowerCase() === value.toLowerCase()) {
          validItem = true;
          break;
        }
      }
    if (validItem) {
      array.push(this[i]);
    }
  }

  return array;
};

Array.prototype.isProductionReady = function() {
  return (
    this.filterApprovedArtwork()
      .map((el) => {
        let produced = 0;
        let delivered = 0;
        let awaiting = 0;
        let qty = 0;
        el?.items.forEach((element) => {
          produced += element?.qty_produced;
          qty += element?.qty_total;
          delivered += element?.qty_delivered;
          awaiting += element?.qty_awaiting;
        });
        return { qty, produced, delivered, awaiting, complete: produced >= qty };
      })
      .filter((el) => el.complete === true).length >= 1
  );
};
Array.prototype.isProductionStarted = function() {
  return (
    this.filterApprovedArtwork()
      .map((el) => {
        let produced = 0;
        el?.items.forEach((element) => {
          produced += element?.qty_produced;
        });
        return { started: produced >= 1 };
      })
      .filter((el) => el.started === true).length >= 1
  );
};

Array.prototype.isProductionComplete = function() {
  const completedProduction = this.filterApprovedArtwork()
    .map((el) => {
      let produced = 0;
      let delivered = 0;
      let awaiting = 0;
      let qty = 0;
      el?.items?.forEach((element) => {
        produced += element?.qty_produced;
        qty += element?.qty_total;
        delivered += element?.qty_delivered;
        awaiting += element?.qty_awaiting;
      });

      return { qty, produced, delivered, awaiting, complete: produced >= qty };
    })
    .filter((el) => el.complete === true).length;
  return completedProduction >= 1 && completedProduction >= this.filterApprovedArtwork().length;
};

Array.prototype.isDeliveryCompleted = function() {
  const completedDelivery = this.filterApprovedArtwork()
    .map((el) => {
      let delivered = 0;
      let qty = 0;
      el?.items?.forEach((element) => {
        delivered += element?.qty_delivered;
        qty += element?.qty_total;
      });

      return {
        deliveryComplete: delivered >= qty,
      };
    })
    .filter((el) => el.deliveryComplete === true).length;
  return completedDelivery >= 1 && completedDelivery >= this.filterApprovedArtwork().length;
};

Array.prototype.productionCompleted = function() {
  return this.filterApprovedArtwork()
    .map((el) => {
      let produced = 0;
      let delivered = 0;
      let awaiting = 0;
      let qty = 0;
      el?.items.forEach((element) => {
        produced += element?.qty_produced;
        qty += element?.qty_total;
        delivered += element?.qty_delivered;
        awaiting += element?.qty_awaiting;
      });
      return {
        qty,
        produced,
        delivered,
        awaiting,
        complete: produced >= qty,
      };
    })
    .filter((el) => el.complete === true);
};

Array.prototype.itemsProductionInfo = function() {
  return this.map((el) => {
    let produced = 0;
    let delivered = 0;
    let awaiting = 0;
    let qty = 0;
    el?.items.forEach((element) => {
      produced += element?.qty_produced;
      qty += element?.qty_total;
      delivered += element?.qty_delivered;
      awaiting += element?.qty_awaiting;
    });
    return {
      qty,
      produced,
      delivered,
      awaiting,
      complete: produced >= qty,
      deliveryComplete: delivered >= qty,
    };
  });
};

Array.prototype.filterApprovedArtwork = function() {
  return this.filter((val: any) => val.status.artwork === "C") as ISDCLine[];
};

Array.prototype.filterNotApprovedArtwork = function() {
  return this.filter((val: any) => val.status.artwork !== "C") as ISDCLine[];
};

Array.prototype.approvedArtworkAndProductionStarted = function() {
  return this.filterApprovedArtwork().filter((o) => o.status.production === "S").length >= 1;
};

Array.prototype.approvedArtworkAndProductionComplete = function() {
  return (
    this.filterApprovedArtwork().filter(
      (o) => this.productionCompleted().length >= 1 || o.status.production === "C"
    ).length >= 1
  );
};

Array.prototype.approvedArtworkAndProductionCompleteAndDeliveryStarted = function() {
  return (
    this.filterApprovedArtwork()
      .map((o) => {
        let produced = 0;
        let delivered = 0;
        let qty = 0;
        o?.items.forEach((element) => {
          produced += element?.qty_produced;
          delivered += element?.qty_delivered;
          qty += element?.qty_total;
        });
        return {
          started: produced >= qty && delivered >= 1,
        };
      })
      .filter((el) => el.started === true).length >= 1
  );
};

/**
 *
 * if artwork is approved and produced quantity is greater or equal to 1 and less to quantity delivered
 * and quantity delivered  is less than total quantity
 * @return true else
 * @return false
 */
Array.prototype.approvedArtworkAndProductionStartedAndDeliveryStarted = function() {
  return (
    this.filterApprovedArtwork()
      .map((o) => {
        let produced = 0;
        let delivered = 0;
        let qty = 0;
        o?.items.forEach((element) => {
          produced += element?.qty_produced;
          delivered += element?.qty_delivered;
          qty += element?.qty_total;
        });
        return {
          started: produced >= 1 && delivered < qty && delivered < produced,
        };
      })
      .filter((el) => el.started === true).length >= 1
  );
};

Array.prototype.formRecoredDeliveryItems = function() {
  return this.map((el) => {
    return {
      lineID: el.id,
      sizes: el.sizes.map((element) => ({
        ...element,
        qty_delivered: element.qty,
      })),
    };
  });
};

Array.prototype.reformItemsForDelivery = function() {
  const items = [];
  for (let index = 0; index < this.length; index++) {
    const line = this[index];
    const readyForDeliveryItems = line.items.filter(
      (element) => element.qty_produced - element.qty_delivered >= 1
    );
    if (readyForDeliveryItems.length) items.push({ id: line.id, items: readyForDeliveryItems });
  }

  return items.map((el) => {
    return {
      lineID: el.id,
      sizes: el.items.map((element) => ({
        ...element,
        qty_delivered: element.qty_produced - element.qty_delivered,
      })),
    };
  });
};
