import { Box, Button, Text } from "@chakra-ui/react";
import { ReactComponent as Check } from "@samedaycustom/assets/images/artwork-check.svg";
import { ReactComponent as TickUp } from "@samedaycustom/assets/images/artwork-tick-up.svg";
import { ReactComponent as Upload } from "@samedaycustom/assets/images/artwork-upload.svg";
import { ReactComponent as DownloadProdFile } from "@samedaycustom/assets/images/downloadProdFile.svg";
import React from "react";

export interface ArtworkVersionProp {
  version: "original" | "uploaded" | "awaiting" | "approved" | "production" | "updated-production";
  url?: boolean;
  updated_proof_url?: string;
  onClick: () => void;
}
const ArtworkVersion: React.FC<ArtworkVersionProp> = props => {
  const composeVersion = React.useMemo(() => {
    let label = "Production file";
    if (props.version === "original" || props.version === "production") {
      if (props.updated_proof_url) {
        label += " (Old)";
      }
    }

    switch (props.version) {
      case "original":
      case "production":
        return {
          label: label,
          background: "#E3F9E5",
          color: "#0F8613",
          iconBackground: "#C1F2C7",
          icon: <Check />
        };
      case "uploaded":
        return {
          label: "Uploaded",
          background: "#E5F1FF",
          iconBackground: "#B2D7FF",
          color: "#007BFF",
          icon: <Upload />
        };
      case "awaiting":
        return {
          label: "Awaiting approval",
          background: "#E5F1FF",
          iconBackground: "#B2D7FF",
          color: "#007BFF",
          whitespace: "normal",
          icon: <TickUp />
        };
      case "approved":
        return {
          label: "Approved",
          background: "#E3F9E5",
          color: "#0F8613",
          iconBackground: "#C1F2C7",
          icon: <Check />
        };
      case "updated-production":
        return {
          label: "Production file (New)",
          background: "#E3F9E5",
          color: "#0F8613",
          iconBackground: "#C1F2C7",
          icon: <Check />
        };
      default:
        break;
    }
  }, [props.version, props.updated_proof_url]);

  return props.url ? (
    <a href={props.url} target="_blank" rel="noopener noreferrer" download>
      <Box
        display="inline-flex"
        height="fit-content"
        background="none"
        color="#007BFF"
        width="100%"
        alignItems="center"
        justifyContent="space-between"
        paddingInlineEnd="unset"
        paddingInlineStart="unset"
        paddingBottom="11px"
        _hover={{ background: "none" }}
        _focus={{ background: "none" }}
        _active={{ background: "none" }}
      >
        <Box display={"flex"} alignItems={"center"}>
          {props.version === "updated-production" ?
            (<Text fontSize={"10px"} fontWeight={"700"} textColor={"#006EE5"} display={"fex"} justifyContent={"center"} alignItems={"center"} paddingY={"9px"} paddingX={"4px"} height={"24px"} backgroundColor={"#F7F9FA"} boxShadow={"0px 1px 4px #DBDEE2"}>PNG</Text>) :
            (<Text fontSize={"10px"} fontWeight={"700"} textColor={"#E12D39"} display={"fex"} justifyContent={"center"} alignItems={"center"} paddingY={"9px"} paddingX={"4px"} height={"24px"} backgroundColor={"#F7F9FA"} boxShadow={"0px 1px 4px #DBDEE2"}>PDF</Text>)
          }
          <Text
            paddingLeft="8px"
            fontWeight={500}
            fontSize="12px"
            color={"#07121D"}
          >
            {composeVersion.label}
          </Text>
        </Box>

        <Box width={"24px"} display={"flex"} justifyContent={"center"} alignItems={"center"} height={"24px"} backgroundColor={"#E5F1FF"} borderRadius={"4px"}>
          <DownloadProdFile fill="#007BFF" />
        </Box>
      </Box>
    </a>
  ) : (
    <Button
      display="inline-flex"
      height="fit-content"
      background="none"
      color="#007BFF"
      width="100%"
      onClick={props.onClick}
      alignItems="center"
      justifyContent="space-between"
      paddingInlineEnd="unset"
      paddingInlineStart="unset"
      paddingBottom="11px"
      _hover={{ background: "none" }}
      _focus={{ background: "none" }}
      _active={{ background: "none" }}
    >
      <Box display={"flex"} alignItems={"center"}>
        <Text fontSize={"10px"} fontWeight={"700"} textColor={"#006EE5"} display={"fex"} justifyContent={"center"} alignItems={"center"} paddingY={"9px"} paddingX={"4px"} height={"24px"} backgroundColor={"#F7F9FA"} boxShadow={"0px 1px 4px #DBDEE2"}>PNG</Text>
        <Text
          paddingLeft="8px"
          fontWeight={500}
          fontSize="12px"
          color={"#07121D"}
        >
          {composeVersion.label}
        </Text>
      </Box>

      <Box width={"24px"} display={"flex"} justifyContent={"center"} alignItems={"center"} height={"24px"} backgroundColor={"#E5F1FF"} borderRadius={"4px"}>
        <DownloadProdFile fill="#007BFF" />
      </Box>
    </Button>
  );
};

export default ArtworkVersion;
