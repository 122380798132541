import React, { useState, useEffect } from "react";
import Pagination from "@atlaskit/pagination";
import { UIDropDown as Dropdown } from "@samedaycustom/core-ui";
import "./style.scss";

type PaginationProps = {
  setNumberOfRowsFunc: (val) => void;
  setCurrentPage: (val) => void;
  targetListLength: number;
  label: string;
  hideInput?: boolean;
};

const TablePagination: React.FC<PaginationProps> = ({
  setNumberOfRowsFunc,
  setCurrentPage,
  label,
  targetListLength,
  hideInput
}) => {
  const [numberOfRows, setNumberOfRows] = useState(10);
  const [selectedPage, setSelectedPage] = useState(1);

  useEffect(() => {
    setNumberOfRowsFunc && setNumberOfRowsFunc(numberOfRows);
  }, [numberOfRows]);
  useEffect(() => {
    setCurrentPage && setCurrentPage(selectedPage);
  }, [selectedPage]);

  const dropDownList = () => {
    const dropArray = [];
    if (targetListLength <= 10) {
      return [{ label: targetListLength, value: targetListLength }];
    } else {
      let mulitples = 0;
      for (let i = 0; i < targetListLength; i++) {
        if (targetListLength >= 500) {
          dropArray.push({
            value: targetListLength,
            label: targetListLength
          });
          break;
        }
        mulitples = i * 10;
        if (targetListLength % i === 0 && mulitples <= targetListLength) {
          dropArray.push({
            value: mulitples,
            label: mulitples
          });
        }
      }
      // if (targetListLength > 10) {
      //     dropArray.push({
      //         value: 20,
      //         label: 20,
      //     })
      // } else if (targetListLength > 20) {
      //     dropArray.push({
      //         value: 50,
      //         label: 50,
      //     })
      // } else if (targetListLength > 50) {
      //     dropArray.push({
      //         value: 100,
      //         label: 100,
      //     })
      // } else if (targetListLength > 100) {
      //     dropArray.push({
      //         value: 200,
      //         label: 200,
      //     })
      // } else if (targetListLength > 200) {
      //     dropArray.push({
      //         value: 500,
      //         label: 500,
      //     })
      // } else if (targetListLength > 500) {
      //     dropArray.push({
      //         value: targetListLength,
      //         label: targetListLength,
      //     })
      // }
      return dropArray;
    }
  };
  const pages = () => {
    const array = [];
    for (let i = 1; i <= Math.ceil(targetListLength / numberOfRows); i++) {
      array.push(i);
    }
    return array;
  };

  // class PageComponent extends React.Component{
  // 	render() {
  // 		return (<span> Me </span>)
  // 	}
  // }

  const renderFunc = type => {
    return ({ page, pages, selectedIndex, className, ...rest }) => {
      if (type === "previous") {
        return (
          <span
            className={
              selectedPage === 1
                ? "paginationBtn paginationDisabled"
                : "paginationBtn"
            }
            onClick={() => {
              if (selectedPage > 1) {
                setSelectedPage(selectedPage - 1);
              }
            }}
          >
            {" "}
            Previous{" "}
          </span>
        );
      } else if (type === "next") {
        return (
          <span
            className={
              selectedPage === Math.ceil(targetListLength / numberOfRows)
                ? "paginationBtn paginationDisabled"
                : "paginationBtn"
            }
            onClick={() => {
              if (selectedPage < pages[pages.length - 1]) {
                setSelectedPage(selectedPage + 1);
              }
            }}
          >
            {" "}
            Next{" "}
          </span>
        );
      }
      return (
        <div
          className={
            page === selectedPage ? "pageBtn pageBtn--active" : "pageBtn"
          }
          onClick={() => setSelectedPage(page)}
        >
          {" "}
          <span> {page} </span>{" "}
        </div>
      );
    };
  };

  // <span className={selectedPage === 1 ? 'paginationBtn paginationDisabled' : 'paginationBtn'}> Previous </span>
  // <span className={selectedPage === Math.round(targetListLength / numberOfRows) ? 'paginationBtn paginationDisabled' : 'paginationBtn'}> Next </span>

  return (
    <div className="paginationBlock">
      {hideInput ? (
        <div></div>
      ) : (
        <div className="leftPaginationContent">
          <span> Showing </span>
          <div className="dropDownBox">
            <Dropdown
              list={dropDownList()}
              setValueFunc={({ value }) => setNumberOfRows(value)}
              placeholder=""
              loading={false}
              value={{
                label:
                  targetListLength < numberOfRows
                    ? targetListLength
                    : numberOfRows,
                value:
                  targetListLength < numberOfRows
                    ? targetListLength
                    : numberOfRows
              }}
              isSearchable={false}
            />
          </div>
          <span>
            of {targetListLength} {label}{" "}
          </span>
        </div>
      )}
      <div className="paginationContainer">
        <span
          className={`paginationBtn paginationException ${
            selectedPage === 1 ? "paginationDisabled" : ""
          }`}
          onClick={() => setSelectedPage(1)}
        >
          {" "}
          First
        </span>
        <Pagination
          selectedIndex={selectedPage}
          pages={pages()}
          onChange={(e, page) => setSelectedPage(page)}
          max={5}
          components={{
            Page: renderFunc("page"),
            Previous: renderFunc("previous"),
            Next: renderFunc("next")
          }}
        />
        <span
          className={`paginationBtn paginationException ${
            selectedPage === Math.ceil(targetListLength / numberOfRows)
              ? "paginationDisabled"
              : ""
          }`}
          onClick={() =>
            setSelectedPage(Math.ceil(targetListLength / numberOfRows))
          }
        >
          {" "}
          Last
        </span>
      </div>
    </div>
  );
};

export default TablePagination;
