import { FormikHelpers } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import { DIRTY_TYPE } from "@samedaycustom/types/app";
export interface IPreventBrowserDirtyInputs {
	values?: Object;
	options?: FormikHelpers<any>;
	showCon?: boolean;
	isDirty: boolean;
	initialValues?: Object;
	setShow?: (condition: boolean) => void;
	handleSubmit: (values, options) => any;
	setValues?: (values: any) => void;
	setIsDirty?: (dirty: DIRTY_TYPE) => void;
	onReset?: <T>(nextState?: Partial<T>) => void;
	setDirtyManual?: (dirty: DIRTY_TYPE) => void;
}
export default React.memo(
	({
		values,
		isDirty,
		handleSubmit,
		initialValues,
		setValues,
		setIsDirty,
		setDirtyManual,
		onReset
	}: IPreventBrowserDirtyInputs) => {
		const dispatch = useDispatch();

		React.useMemo(() => {
			if (setDirtyManual) {
				setDirtyManual({
					isDirty,
					handleSubmit,
					values,
					initialValues,
					setValues,
					onReset
				});
			} else {
				dispatch(
					setIsDirty({
						isDirty,
						handleSubmit,
						values,
						initialValues,
						setValues,
						onReset
					})
				);
			}

			return () => {
				if (setDirtyManual) {
					setDirtyManual({
						isDirty: false,
						handleSubmit: null,
						values: null,
						initialValues: null,
						setValues: null,
						onReset: null
					});
				} else {
					dispatch(
						setIsDirty({
							isDirty: false,
							handleSubmit: null,
							values: null,
							initialValues: null,
							setValues: null,
							onReset: null
						})
					);
				}
			};
		}, [isDirty]);

		return <React.Fragment />;
	}
);
