export enum ACCESS_LIST {
  ACCEPT_ORDERS = "accept_orders",
  BUSINESS_PROFILE = "business_profile",
  LOCATIONS = "locations",
  OPERATIONS = "operations",
  PAYMENT_SETTINGS = "payment_settings",
  SETTINGS = "settings",
  STORE_PROFILE_SETTINGS = "store_profile_settings",
  USER_AND_PERMISSIONS = "users_and_permissions",
  DASHBOARD = "dashboard",
  MESSAGES = "messages",
  REPORTS = "reports",
}
export const permissionShape = {
  [ACCESS_LIST.REPORTS]: [
    {
      targetKey: "reports",
      label: "View reports",
    },
  ],
  [ACCESS_LIST.USER_AND_PERMISSIONS]: [
    {
      targetKey: "listUsers",
      label: "Get get list of store's users",
    },
    {
      targetKey: "getUser",
      label: "Get information about a store's user",
    },
    {
      targetKey: "editUser",
      label: "Edit store user's information",
    },
    {
      targetKey: "createUser",
      label: "Create a store user",
    },
    {
      targetKey: "deleteUser",
      label: "Delete a store user",
    },
    {
      targetKey: "listRoles",
      label: "Get list of roles",
    },
    {
      targetKey: "getRoles",
      label: "Get more information about a role",
    },
    {
      targetKey: "editRoles",
      label: "Edit a role",
    },
    {
      targetKey: "createRoles",
      label: "Create a role",
    },
    {
      targetKey: "deleteRoles",
      label: "Delete a role",
    },
  ],
  [ACCESS_LIST.LOCATIONS]: [
    {
      targetKey: "listLocation",
      label: "Get list of stores",
    },
    {
      targetKey: "getLocation",
      label: "Get more information about a store",
    },
    {
      targetKey: "editLocation",
      label: "Edit a store",
    },
    {
      targetKey: "createLocation",
      label: "Create a store",
    },
    {
      targetKey: "deleteLocation",
      label: "Delete a store",
    },
  ],
  [ACCESS_LIST.PAYMENT_SETTINGS]: [
    {
      targetKey: "locationSettingPayments",
      label: "Store payment permissions",
      children: [
        {
          targetKey: "locationSettingPrintingPricing",
          label: "Store's print pricing permissions",
        },
        {
          targetKey: "locationSettingOutsourcePricing",
          label: "Store's outsource pricing permissions",
        },
      ],
    },
  ],
  [ACCESS_LIST.BUSINESS_PROFILE]: [
    {
      targetKey: "getVendor",
      label: "View vendor details",
    },
    {
      targetKey: "editVendor",
      label: "Edit vendor details",
    },
  ],
  [ACCESS_LIST.DASHBOARD]: [
    {
      targetKey: "dashboard",
      label: "Access the dashboard",
    },
  ],
  [ACCESS_LIST.MESSAGES]: [
    {
      targetKey: "messages",
      label: "Acccess and send messages",
    },
  ],
  [ACCESS_LIST.ACCEPT_ORDERS]: [
    {
      targetKey: "acceptOrders",
      label: "Acccept or decline orders for a store location",
    },
  ],
  [ACCESS_LIST.STORE_PROFILE_SETTINGS]: [
    {
      targetKey: "locationSettingProfile",
      label: "Store profile permissions",
      children: [
        {
          targetKey: "getLocationSettingProfile",
          label: "Get store profile",
        },
        {
          targetKey: "editLocationSettingProfile",
          label: "Edit store profile",
        },
      ],
    },
  ],
  [ACCESS_LIST.SETTINGS]: [
    {
      targetKey: "locationSetting",
      label: "Store Settings permissions",
      children: [
        {
          targetKey: "locationSettingWorkHours",
          label: "Store work hours permissions",
          children: [
            // {
            //   targetKey: "listLocationSettingWorkHour",
            //   label: "Get list of store's work hours",
            // },
            {
              targetKey: "getLocationSettingWorkHour",
              label: "See store's work hours",
            },
            {
              targetKey: "editLocationSettingWorkHour",
              label: "Edit store's work hours",
            },
            // {
            //   targetKey: "createLocationSettingWorkHour",
            //   label: "Create store's work hours",
            // },
            // {
            //   targetKey: "deleteLocationSettingWorkHour",
            //   label: "Create store's work hours",
            // },
          ],
        },
        // {
        //     targetKey: "locationSettingProductionSpeed",
        //     label: "Store production speed permissions",
        //     children: [
        //         {
        //             targetKey: "listLocationSettingProductionSpeed",
        //             label: "Get list of store's production speeds",
        //         },
        //         {
        //             targetKey: "getLocationSettingProductionSpeed",
        //             label: "Get more information about store's production speed",
        //         },
        //         {
        //             targetKey: "createLocationSettingProductionSpeed",
        //             label: "Create production speed for store",
        //         },
        //         {
        //             targetKey: "deleteLocationSettingProductionSpeed",
        //             label: "Delete store's production speed",
        //         },
        //         {
        //             targetKey: "editLocationSettingProductionSpeed",
        //             label: "Edit store's production speed",
        //         },
        //     ],
        // },
        {
          targetKey: "locationSettingPrintingMethods",
          label: "Store printing methods permissions",
          children: [
            {
              targetKey: "getLocationSettingPrintingMethods",
              label: "Get more information about store's printing methods",
            },
            {
              targetKey: "listLocationSettingPrintingMethods",
              label: "Get list of store's printing methods",
            },
            {
              targetKey: "editLocationSettingPrintingMethod",
              label: "Edit store's printing methods",
            },
          ],
        },
        {
          targetKey: "locationSettingProducts",
          label: "Store product permissions",
          children: [
            {
              targetKey: "getLocationSettingProducts",
              label: "Get more information about store's products",
            },
            {
              targetKey: "listLocationSettingProducts",
              label: "Get list of store's products",
            },
            {
              targetKey: "editLocationSettingProducts",
              label: "Edit store's products",
            },
            {
              targetKey: "createLocationSettingProducts",
              label: "Create store's products",
            },
            {
              targetKey: "deleteLocationSettingProducts",
              label: "Delete store's products",
            },
          ],
        },
        {
          targetKey: "locationSettingDelivery",
          label: "Store delivery methods permissions",
          children: [
            {
              targetKey: "listLocationSettingDelivery",
              label: "Get list of store's delivery methods",
            },
            {
              targetKey: "getLocationSettingDelivery",
              label: "get more information about a delivery method",
            },
            {
              targetKey: "editLocationSettingDelivery",
              label: "Edit store's delivery method",
            },
          ],
        },
      ],
    },
  ],
  [ACCESS_LIST.OPERATIONS]: [
    {
      targetKey: "orders",
      label: "Store orders permissions",
      children: [
        {
          targetKey: "getOrders",
          label: "Get more information about orders",
        },
        {
          targetKey: "listOrders",
          label: "Get list of orders",
        },
        {
          targetKey: "editOrders",
          label: "Edit orders",
        },
      ],
    },
    {
      targetKey: "ordersProduction",
      label: "Orders in production permissions",
      children: [
        {
          targetKey: "getOrdersProduction",
          label: "Get more information about orders in production",
        },
        {
          targetKey: "listOrdersProduction",
          label: "Get list of orders in production",
        },
        {
          targetKey: "editOrdersProduction",
          label: "Edit orders in production",
        },
      ],
    },
    {
      targetKey: "ordersCharges",
      label: "Order's charges permissions",
      children: [
        {
          targetKey: "getOrdersCharges",
          label: "Get more information about an order's charge",
        },
        {
          targetKey: "listOrdersCharges",
          label: "Get list of order's charges",
        },
        {
          targetKey: "createOrdersCharges",
          label: "Create an order charge",
        },
        {
          targetKey: "editOrdersCharges",
          label: "Edit an order charge",
        },
      ],
    },
    {
      targetKey: "ordersRefund",
      label: "Refund permissions",
      children: [
        {
          targetKey: "getOrdersRefund",
          label: "Get more information about a refund",
        },
        {
          targetKey: "listOrdersRefund",
          label: "Get list of refunds",
        },
        {
          targetKey: "createOrdersRefund",
          label: "Create a refund",
        },
        {
          targetKey: "editOrdersRefund",
          label: "Edit a refund",
        },
      ],
    },
    {
      targetKey: "ordersShipping",
      label: "Shipping permissions",
      children: [
        {
          targetKey: "getOrdersShipping",
          label: "Get more information about orders in shipping",
        },
        {
          targetKey: "listOrdersShipping",
          label: "Get list of orders in shipping",
        },
        {
          targetKey: "createOrdersShipping",
          label: "Create orders in shipping",
        },
        {
          targetKey: "deleteOrdersShipping",
          label: "Delete orders in shipping",
        },
      ],
    },
    {
      targetKey: "orderBidding",
      label: "Bids permissions",
      children: [
        {
          targetKey: "getOrdersBidding",
          label: "Get more information about orders in bids",
        },
        {
          targetKey: "listOrdersBidding",
          label: "Get list of orders in bids",
        },
        {
          targetKey: "editOrdersBidding",
          label: "Edit orders in bids",
        },
      ],
    },
  ],
};
