import React from "react";
import { Box, Text } from "../../_components";
import {
  ItemList as NotificationItemList,
  INotificationItemListMessages
} from "@samedaycustom/core-ui";
import ViewBar from "../ViewBar";

export interface INotificationView {
  showNotificationBar: boolean;
  notificationNumber: number;
  onClose: () => void;
  messages: INotificationItemListMessages;
  date: Date;
}
export default ({
  showNotificationBar,
  notificationNumber,
  onClose,
  messages,
  date
}: Partial<INotificationView>) => (
  <ViewBar
    show={showNotificationBar}
    noPortal={true}
    heading={
      <Box d="flex">
        Notifications
        <Text
          marginLeft="0.5rem"
          padding="2px"
          background="#D9E2EC"
          borderRadius="8px"
          fontFamily="Inter Bold"
          fontSize="0.9rem"
          color="#243B53"
        >
          {notificationNumber}
        </Text>
      </Box>
    }
    closeFunc={onClose}
    style={{ padding: 0 }}
  >
    <NotificationItemList date={date} messages={messages} />
  </ViewBar>
);
