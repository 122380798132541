import "./style.scss";

import { Box, Text } from "@chakra-ui/react";
import { ISDCOrderTimeLine } from "@samedaycustom/types/order/@types/timeline";
import { motion } from "framer-motion";
import moment from "moment";
import React from "react";

import { ReactComponent as TrackImage } from "@samedaycustom/assets/images/track-o.svg";
import { ReactComponent as TrackWl } from "@samedaycustom/assets/images/track_wl.svg";
import { AlternativeDropDowns } from "@samedaycustom/core-ui";
import { formatInTimeZone } from "date-fns-tz";
import { ReactComponent as Check } from "../../assets/check.svg";
import { ReactComponent as CheckWl } from "../../assets/check.svg";
const variants = {
  visible: (i) => ({
    opacity: 1,
    transition: {
      delay: i * 0.3,
      ease: "easeOut",
    },
  }),
};

const TlCard = ({
  tl,
  active,
  idx,
  trackLinks,
  onReview,
  tzone,
  isWhiteLabel,
  primaryColor,
}: {
  tl: ISDCOrderTimeLine;
  active: boolean;
  idx: number;
  trackLinks: ISDCOrderTimeLine[];
  onReview?: () => void;
  tzone?: string;
  isWhiteLabel?: boolean;
  primaryColor?: string;
}) => {
  const preSubtitle = {
    "order placed": "Assigning a partner to your order",
    "order assigned": "Reviewing your artwork",
    "artwork approved": "Printing your order",
    "production complete": "Packaging your order",
    "ready for pickup": "Order awaiting Pickup",
    "order fufilled": "Assigning a partner to your order",
  };

  const TrackIcon = isWhiteLabel ? TrackWl : TrackImage;
  const CheckIcon = isWhiteLabel ? CheckWl : Check;

  const getPreSubtitle = (pre: string) => {
    const value = Object.entries(preSubtitle).find((o) => {
      const pres = o[0];
      return pres?.includes(pre.toLowerCase());
    });
    return value?.[1];
  };

  const trackLinksItems = React.useMemo(
    () =>
      Array.isArray(trackLinks)
        ? trackLinks.map((o) => ({
            label: (
              <a href={`${o.trackLink}`} target="_blank" rel="noopener noreferrer">
                {tzone
                  ? formatInTimeZone(o.timestamp, tzone, "dd MMM, yyyy hh:mm a")
                  : moment(o.timestamp).format("DD/MM/YYYY, hh:mm A")}
              </a>
            ),
            shortLabel: tzone
              ? formatInTimeZone(o.timestamp, tzone, "dd MMM, yyyy hh:mm a")
              : moment(o.timestamp).format("DD/MM/YYYY, hh:mm A"),
            value: o.trackLink,
          }))
        : [],
    [trackLinks]
  );

  const subTitle = React.useMemo(() => {
    if (trackLinksItems.length)
      return (
        <Text>
          Your order is on it's way to you. To track your shipment, click this link{" "}
          {trackLinksItems.length > 1 ? (
            <AlternativeDropDowns
              onClick={(value) => console.log(value)}
              position="top center"
              placeholder={
                <Box d="inline-flex" alignItems="center" marginTop="-25px">
                  <TrackImage
                    height="18px"
                    width="18px"
                    fill="#007BFF"
                    style={{
                      position: "absolute",
                      left: "0",
                      top: "11px",
                    }}
                  />
                  <Text
                    fontWeight="500 !important"
                    marginLeft="10px"
                    textTransform="none !important"
                  >
                    Track package
                  </Text>
                </Box>
              }
              value={null}
              listItems={trackLinksItems}
              className="__Trackdropdown"
              containerStyle={{ marginTop: "20px", marginLeft: "-95px" }}
            />
          ) : (
            trackLinksItems.length === 1 && (
              <a href={`${trackLinksItems[0].value}`} target="_blank" rel="noopener noreferrer">
                <Box as="button" d="inline-flex" alignItems="center" marginTop="5px">
                  <TrackIcon
                    height="18px"
                    width="18px"
                    fill="#007BFF"
                    style={{
                      marginTop: "5px",
                    }}
                  />
                  <Text
                    fontWeight="500 !important"
                    marginLeft="5px"
                    color={isWhiteLabel ? primaryColor : "#007BFF"}
                    textTransform="none !important"
                  >
                    Track package
                  </Text>
                </Box>
              </a>
            )
          )}
        </Text>
      );

    if (tl.subtitle.includes("Please review your experience by clicking this link"))
      return (
        <Text as="button" textAlign="left" fontWeight="500" fontSize="14px" onClick={onReview}>
          {tl.subtitle}
        </Text>
      );
    return tl.subtitle || getPreSubtitle(tl.title);
  }, [trackLinksItems]);

  return (
    <motion.div variants={variants} custom={idx} animate="visible" className="TLCard">
      <div className={`tlCheckMark`}>
        <CheckIcon />
      </div>
      <div className="TLCardInfo">
        <h2>{tl.title}</h2>

        <p>
          {tzone
            ? formatInTimeZone(tl.timestamp, tzone, "dd MMM, yyyy hh:mm a")
            : moment(tl.timestamp).format("D MMM YYYY, h:mm a")}
        </p>

        {active && (
          <motion.div
            className="tl__sub"
            animate={{ scale: 1, opacity: 1 }}
            transition={{
              ease: "easeOut",
              duration: 1,
              delay: 0.2,
            }}
          >
            <span
              style={{
                color: active ? primaryColor ?? "#007bff" : "#102a43",
              }}
            >
              {subTitle}
            </span>
          </motion.div>
        )}
      </div>
    </motion.div>
  );
};

export default TlCard;
